// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { TypeScriptBuildManifest } from './TypeScriptBuildManifest';
/**
  * Class io.protoforce.projects/ValidationTarget:TypeScript (member of ADT io.protoforce.projects:ValidationTarget)
  *
  * Defined at /projects/tasks.model @ 31:5
  */
export class ValidationTargetTypeScript {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
    }
    get RTTI_CLASS() {
        return ValidationTargetTypeScript.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ValidationTargetTypeScript.RTTI_FQN;
    }
    toJSON() {
        return ValidationTargetTypeScript.toJSON(this);
    }
}
ValidationTargetTypeScript.RTTI_CLASS = 'TypeScript';
ValidationTargetTypeScript.RTTI_FQN = 'io.protoforce.projects/ValidationTarget:TypeScript';
ValidationTargetTypeScript.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON()
    };
};
ValidationTargetTypeScript.fromJSON = (value) => {
    return new ValidationTargetTypeScript({
        manifest: TypeScriptBuildManifest.fromJSON(value.manifest)
    });
};
