// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { IRTErrorTasks } from './IRTErrorTasks';
import { RuntimeError } from '../../../irt';
/**
  * Class io.protoforce.tasks/TaskOperationFailure:CannotCancel (member of ADT io.protoforce.tasks:TaskOperationFailure)
  *
  * Defined at tasks.domain @ 21:5
  */
export class TaskOperationFailureCannotCancel extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return TaskOperationFailureCannotCancel.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TaskOperationFailureCannotCancel.RTTI_FQN;
    }
    toIRTErrorTasks() {
        return new IRTErrorTasks({
            message: this.message
        });
    }
    toJSON() {
        return TaskOperationFailureCannotCancel.toJSON(this);
    }
}
TaskOperationFailureCannotCancel.RTTI_CLASS = 'CannotCancel';
TaskOperationFailureCannotCancel.RTTI_FQN = 'io.protoforce.tasks/TaskOperationFailure:CannotCancel';
TaskOperationFailureCannotCancel.toJSON = (value) => {
    return {
        message: value.message
    };
};
TaskOperationFailureCannotCancel.fromJSON = (value) => {
    return new TaskOperationFailureCannotCancel({
        message: value.message
    });
};
