// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { JSONConverter, CodecError } from '../../../irt';
import { LoginWithEmail } from './LoginWithEmail';
import { LoginWithGithub } from './LoginWithGithub';
import { LoginWithGoogle } from './LoginWithGoogle';
import { LoginWithPhone } from './LoginWithPhone';
/**
  *
  *  LoginWith encapsulates all possible login options available to the user
  *
  * ADT io.protoforce.auth:LoginWith
  *
  * Defined at /auth/login.model @ 35:1
  */
export class LoginWith {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT LoginWith must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.email !== 'undefined') {
            this.value = value.email;
        }
        else if (typeof value.phone !== 'undefined') {
            this.value = value.phone;
        }
        else if (typeof value.google !== 'undefined') {
            this.value = value.google;
        }
        else if (typeof value.github !== 'undefined') {
            this.value = value.github;
        }
        else {
            throw new Error('ADT LoginWith constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenEmail, whenPhone, whenGoogle, whenGithub) {
        const v = this.value;
        if (v instanceof LoginWithEmail) {
            return whenEmail(v);
        }
        else if (v instanceof LoginWithPhone) {
            return whenPhone(v);
        }
        else if (v instanceof LoginWithGoogle) {
            return whenGoogle(v);
        }
        else if (v instanceof LoginWithGithub) {
            return whenGithub(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenEmail, whenPhone, whenGoogle, whenGithub) {
        const v = this.value;
        if (v instanceof LoginWithEmail) {
            return whenEmail(v);
        }
        else if (v instanceof LoginWithPhone) {
            return whenPhone(v);
        }
        else if (v instanceof LoginWithGoogle) {
            return whenGoogle(v);
        }
        else if (v instanceof LoginWithGithub) {
            return whenGithub(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromEmail(value) {
        return new LoginWith({ email: value });
    }
    static fromPhone(value) {
        return new LoginWith({ phone: value });
    }
    static fromGoogle(value) {
        return new LoginWith({ google: value });
    }
    static fromGithub(value) {
        return new LoginWith({ github: value });
    }
    static from(value) {
        return value instanceof LoginWith ? value : new LoginWith(undefined, value);
    }
    get RTTI_CLASS() {
        return LoginWith.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginWith.RTTI_FQN;
    }
    toJSON() {
        return LoginWith.toJSON(this);
    }
}
LoginWith.RTTI_CLASS = 'LoginWith';
LoginWith.RTTI_FQN = 'io.protoforce.auth:LoginWith';
LoginWith.toJSON = (value) => {
    const v = value.value;
    if (v instanceof LoginWithEmail) {
        return JSONConverter.withTypeField(v, '$type', 'email');
    }
    else if (v instanceof LoginWithPhone) {
        return JSONConverter.withTypeField(v, '$type', 'phone');
    }
    else if (v instanceof LoginWithGoogle) {
        return JSONConverter.withTypeField(v, '$type', 'google');
    }
    else if (v instanceof LoginWithGithub) {
        return JSONConverter.withTypeField(v, '$type', 'github');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
LoginWith.fromJSON = (value) => {
    const keyarr = Array.isArray(value.$type) ? value.$type : [value.$type];
    const key = keyarr[0];
    const val = keyarr.length > 1 ?
        Object.assign({}, value, { '$type': keyarr.length === 2 ? keyarr[1] : keyarr.slice(1) }) : value;
    switch (key) {
        case 'email': {
            const vc = val;
            return LoginWith.fromEmail(LoginWithEmail.fromJSON(vc));
        }
        case 'phone': {
            const vc = val;
            return LoginWith.fromPhone(LoginWithPhone.fromJSON(vc));
        }
        case 'google': {
            const vc = val;
            return LoginWith.fromGoogle(LoginWithGoogle.fromJSON(vc));
        }
        case 'github': {
            const vc = val;
            return LoginWith.fromGithub(LoginWithGithub.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'LoginWith'`);
    }
};
