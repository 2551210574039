// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BgTaskScope } from './BgTaskScope';
import { UUIDDefault, Validator, CodecError } from '../../../irt';
/**
  * Identifier io.protoforce.projects:BgTaskId
  *
  * Defined at /projects/tasks.model @ 62:1
  */
export class BgTaskId {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.id = UUIDDefault;
            }
            return;
        }
        this.scope = data.scope;
        this.id = data.id;
    }
    get id() {
        return this._id;
    }
    set id(value) {
        Validator.guid(value, 'id');
        this._id = value;
    }
    get RTTI_CLASS() {
        return BgTaskId.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskId.RTTI_FQN;
    }
    toJSON() {
        return BgTaskId.toJSON(this);
    }
}
BgTaskId.RTTI_CLASS = 'BgTaskId';
BgTaskId.RTTI_FQN = 'io.protoforce.projects:BgTaskId';
BgTaskId.toJSON = (value) => {
    const kvs = [
        `scope=${encodeURIComponent(value.scope.toJSON())}`,
        `id=${encodeURIComponent(value.id)}`
    ];
    return `${BgTaskId.RTTI_CLASS}#${kvs.join(';')}`;
};
BgTaskId.fromJSON = (value) => {
    const err = `Identifier ${BgTaskId.RTTI_CLASS}.fromJSON input (${value}) is malformed.`;
    const chunks = value.split('#');
    if (chunks.length !== 2) {
        throw new CodecError(err);
    }
    if (chunks[0] !== BgTaskId.RTTI_CLASS) {
        throw new CodecError(`${err} Expected to start with '${BgTaskId.RTTI_CLASS}#'`);
    }
    const values = chunks[1].split(';');
    if (values.length !== 2) {
        throw new CodecError(`${err} Expects 2 key values, got ${values.length}`);
    }
    const valueMap = {};
    for (let i = 0; i < values.length; i++) {
        const kv = values[i].split('=');
        if (kv.length !== 2) {
            throw new CodecError(`${err} Must have key=value pairs comma separated.`);
        }
        valueMap[kv[0]] = kv[1];
    }
    const data = {
        scope: BgTaskScope.fromJSON(decodeURIComponent(valueMap['scope'])),
        id: decodeURIComponent(valueMap['id'])
    };
    return new BgTaskId(data);
};
