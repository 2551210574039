import { useCallback } from 'react';
import { useMutation } from '@dyssent/fetcherjs';
export function useEitherMutation(request, options = {}) {
    // With either, success is not always success, so we may rebind here
    // and take into account GenericFailure instance possibility
    const sucessCallback = useCallback((data, captured, manager, ...args) => {
        const success = options.onSuccess;
        if (!success) {
            return;
        }
        data.match(right => success(right, captured, manager, ...args), left => {
            if (!options.onError) {
                return;
            }
            options.onError(left, captured, manager, ...args);
        });
    }, [options.onSuccess, options.onError]);
    const completeCallback = useCallback((data, error, captured, manager, ...args) => {
        const complete = options.onComplete;
        if (!complete) {
            return;
        }
        if (data) {
            data.match(right => complete(right, undefined, captured, manager, ...args), left => complete(undefined, left, captured, manager, ...args));
        }
        else {
            complete(undefined, error, captured, manager, ...args);
        }
    }, [options.onComplete]);
    return useMutation(request, Object.assign(Object.assign({}, options), { onSuccess: sucessCallback, onComplete: completeCallback }));
}
