// tslint:disable
// eslint-disable
// package io.protoforce.docs.imagesearchservice.models
/**
  * Class io.protoforce.docs.imagesearchservice.models:UnsplashInput
  *
  * Defined at /docs/doc.unsplash.model @ 19:5
  */
export class ImageSearchServiceUnsplashInput {
    constructor(data) {
        this.filter = data.filter;
        this.page = data.page;
    }
    get RTTI_CLASS() {
        return ImageSearchServiceUnsplashInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ImageSearchServiceUnsplashInput.RTTI_FQN;
    }
    toJSON() {
        return ImageSearchServiceUnsplashInput.toJSON(this);
    }
}
ImageSearchServiceUnsplashInput.RTTI_CLASS = 'UnsplashInput';
ImageSearchServiceUnsplashInput.RTTI_FQN = 'io.protoforce.docs.imagesearchservice.models:UnsplashInput';
ImageSearchServiceUnsplashInput.toJSON = (value) => {
    return {
        filter: typeof value.filter !== 'undefined' && value.filter != null ? value.filter : undefined,
        page: typeof value.page !== 'undefined' && value.page != null ? value.page : undefined
    };
};
ImageSearchServiceUnsplashInput.fromJSON = (value) => {
    return new ImageSearchServiceUnsplashInput({
        filter: typeof value.filter !== 'undefined' && value.filter != null ? value.filter : undefined,
        page: typeof value.page !== 'undefined' && value.page != null ? value.page : undefined
    });
};
