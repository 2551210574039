// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
import { TasksFilter } from '../../TasksFilter';
/**
  * Class io.protoforce.tasks.tasksservice.models:ListActiveTasksInput
  *
  * Defined at tasks.domain @ 43:5
  */
export class TasksServiceListActiveTasksInput {
    constructor(data) {
        this.filter = data.filter;
    }
    get RTTI_CLASS() {
        return TasksServiceListActiveTasksInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksServiceListActiveTasksInput.RTTI_FQN;
    }
    toJSON() {
        return TasksServiceListActiveTasksInput.toJSON(this);
    }
}
TasksServiceListActiveTasksInput.RTTI_CLASS = 'ListActiveTasksInput';
TasksServiceListActiveTasksInput.RTTI_FQN = 'io.protoforce.tasks.tasksservice.models:ListActiveTasksInput';
TasksServiceListActiveTasksInput.toJSON = (value) => {
    return {
        filter: value.filter.toJSON()
    };
};
TasksServiceListActiveTasksInput.fromJSON = (value) => {
    return new TasksServiceListActiveTasksInput({
        filter: TasksFilter.fromJSON(value.filter)
    });
};
