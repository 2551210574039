// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { Action } from './Action';
import { Notification } from './Notification';
import { IRTZonedDateTime } from '../../../irt';
/**
  * Class io.protoforce.notifications:GetUpdatesResponse
  *
  * Defined at /notifications/notifications.service.model @ 12:1
  */
export class GetUpdatesResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.actions = [];
                this.notifications = [];
                this.readTill = new IRTZonedDateTime();
            }
            return;
        }
        this.actions = data.actions;
        this.notifications = data.notifications;
        this.readTill = data.readTill;
    }
    get readTillAsDate() {
        return this.readTill.toDate();
    }
    set readTillAsDate(value) {
        this.readTill = IRTZonedDateTime.fromDate(value);
    }
    get readTillAsString() {
        return this.readTill.toJSON();
    }
    set readTillAsString(value) {
        this.readTill = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return GetUpdatesResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GetUpdatesResponse.RTTI_FQN;
    }
    toJSON() {
        return GetUpdatesResponse.toJSON(this);
    }
}
GetUpdatesResponse.RTTI_CLASS = 'GetUpdatesResponse';
GetUpdatesResponse.RTTI_FQN = 'io.protoforce.notifications:GetUpdatesResponse';
GetUpdatesResponse.toJSON = (value) => {
    return {
        actions: value.actions.map((e) => e.toJSON()),
        notifications: value.notifications.map((e) => e.toJSON()),
        readTill: value.readTill.toJSON()
    };
};
GetUpdatesResponse.fromJSON = (value) => {
    return new GetUpdatesResponse({
        actions: value.actions.map((e) => Action.fromJSON(e)),
        notifications: value.notifications.map((e) => Notification.fromJSON(e)),
        readTill: IRTZonedDateTime.fromJSON(value.readTill)
    });
};
