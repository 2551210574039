// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { ActionBaseCodec } from './codecs/ActionBaseCodec';
import { ActionBaseImpl } from './ActionBaseImpl';
import { ActionID } from './ActionID';
import { ConfirmActionCodec } from './codecs/ConfirmActionCodec';
import { ConfirmActionImpl } from './ConfirmActionImpl';
import { OrganizationBrief } from '../auth';
import { IRTZonedDateTime } from '../../../irt';
/**
  *
  *  Issued when a user is invited to an organization
  *
  * Class io.protoforce.notifications:ConfirmInviteAction
  *
  * Defined at /notifications/action.model @ 38:1
  */
export class ConfirmInviteAction {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
        this.org = data.org;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return ConfirmInviteAction.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ConfirmInviteAction.RTTI_FQN;
    }
    static fromActionBase(from, org) {
        return new ConfirmInviteAction({
            org: org,
            id: from.id,
            at: from.at
        });
    }
    loadActionBase(from) {
        this.id = from.id;
        this.at = from.at;
    }
    toConfirmAction() {
        return new ConfirmActionImpl({
            id: this.id,
            at: this.at
        });
    }
    static fromConfirmAction(from, org) {
        return new ConfirmInviteAction({
            org: org,
            id: from.id,
            at: from.at
        });
    }
    loadConfirmAction(from) {
        this.id = from.id;
        this.at = from.at;
    }
    toActionBase() {
        return new ActionBaseImpl({
            id: this.id,
            at: this.at
        });
    }
    toJSON() {
        return ConfirmInviteAction.toJSON(this);
    }
}
ConfirmInviteAction.RTTI_CLASS = 'ConfirmInviteAction';
ConfirmInviteAction.RTTI_FQN = 'io.protoforce.notifications:ConfirmInviteAction';
ConfirmInviteAction.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON(),
        org: value.org.toJSON()
    };
};
ConfirmInviteAction.fromJSON = (value) => {
    return new ConfirmInviteAction({
        id: ActionID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at),
        org: OrganizationBrief.fromJSON(value.org)
    });
};
ActionBaseCodec.register(ConfirmInviteAction.RTTI_FQN, ConfirmInviteAction.fromJSON);
ConfirmActionCodec.register(ConfirmInviteAction.RTTI_FQN, ConfirmInviteAction.fromJSON);
