// tslint:disable
// eslint-disable
// package io.protoforce.docs
/**
  * Class io.protoforce.docs:UnsplashImage
  *
  * Defined at /docs/doc.unsplash.model @ 2:1
  */
export class UnsplashImage {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.id = '';
                this.author = '';
                this.imageURL = '';
                this.thumbURL = '';
                this.downloadURL = '';
                this.unsplashURL = '';
            }
            return;
        }
        this.id = data.id;
        this.author = data.author;
        this.imageURL = data.imageURL;
        this.thumbURL = data.thumbURL;
        this.downloadURL = data.downloadURL;
        this.unsplashURL = data.unsplashURL;
    }
    get RTTI_CLASS() {
        return UnsplashImage.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UnsplashImage.RTTI_FQN;
    }
    toJSON() {
        return UnsplashImage.toJSON(this);
    }
}
UnsplashImage.RTTI_CLASS = 'UnsplashImage';
UnsplashImage.RTTI_FQN = 'io.protoforce.docs:UnsplashImage';
UnsplashImage.toJSON = (value) => {
    return {
        id: value.id,
        author: value.author,
        imageURL: value.imageURL,
        thumbURL: value.thumbURL,
        downloadURL: value.downloadURL,
        unsplashURL: value.unsplashURL
    };
};
UnsplashImage.fromJSON = (value) => {
    return new UnsplashImage({
        id: value.id,
        author: value.author,
        imageURL: value.imageURL,
        thumbURL: value.thumbURL,
        downloadURL: value.downloadURL,
        unsplashURL: value.unsplashURL
    });
};
