// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AuthTokens } from './AuthTokens';
import { PlanState } from '../shared';
import { Project } from '../projects';
import { User } from './User';
import { UserOrganization } from './UserOrganization';
/**
  *
  *  LoginResponse gives a set of basic information about the user.
  *
  * Class io.protoforce.auth:LoginResponse
  *
  * Defined at /auth/auth.service.model @ 95:1
  */
export class LoginResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.orgs = [];
                this.projects = [];
            }
            return;
        }
        this.tokens = data.tokens;
        this.user = data.user;
        this.userPlan = data.userPlan;
        this.orgs = data.orgs;
        this.projects = data.projects;
    }
    get RTTI_CLASS() {
        return LoginResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginResponse.RTTI_FQN;
    }
    toJSON() {
        return LoginResponse.toJSON(this);
    }
}
LoginResponse.RTTI_CLASS = 'LoginResponse';
LoginResponse.RTTI_FQN = 'io.protoforce.auth:LoginResponse';
LoginResponse.toJSON = (value) => {
    return {
        tokens: value.tokens.toJSON(),
        user: value.user.toJSON(),
        userPlan: value.userPlan.toJSON(),
        orgs: value.orgs.map((e) => e.toJSON()),
        projects: value.projects.map((e) => e.toJSON())
    };
};
LoginResponse.fromJSON = (value) => {
    return new LoginResponse({
        tokens: AuthTokens.fromJSON(value.tokens),
        user: User.fromJSON(value.user),
        userPlan: PlanState.fromJSON(value.userPlan),
        orgs: value.orgs.map((e) => UserOrganization.fromJSON(e)),
        projects: value.projects.map((e) => Project.fromJSON(e))
    });
};
