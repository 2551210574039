// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:TypeScriptPackagedOptions
  *
  * Defined at /projects/manifests/typescript.model @ 45:1
  */
export class TypeScriptPackagedOptions {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.scope = '';
            }
            return;
        }
        this.scope = data.scope;
    }
    get RTTI_CLASS() {
        return TypeScriptPackagedOptions.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptPackagedOptions.RTTI_FQN;
    }
    toJSON() {
        return TypeScriptPackagedOptions.toJSON(this);
    }
}
TypeScriptPackagedOptions.RTTI_CLASS = 'TypeScriptPackagedOptions';
TypeScriptPackagedOptions.RTTI_FQN = 'io.protoforce.projects:TypeScriptPackagedOptions';
TypeScriptPackagedOptions.toJSON = (value) => {
    return {
        scope: value.scope
    };
};
TypeScriptPackagedOptions.fromJSON = (value) => {
    return new TypeScriptPackagedOptions({
        scope: value.scope
    });
};
