// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingTask } from './PublishingTask';
/**
  * Class io.protoforce.projects/BgTaskType:Publish (member of ADT io.protoforce.projects:BgTaskType)
  *
  * Defined at /projects/tasks.model @ 48:5
  */
export class BgTaskTypePublish {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.release = false;
            }
            return;
        }
        this.task = data.task;
        this.release = data.release;
    }
    get RTTI_CLASS() {
        return BgTaskTypePublish.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskTypePublish.RTTI_FQN;
    }
    toJSON() {
        return BgTaskTypePublish.toJSON(this);
    }
}
BgTaskTypePublish.RTTI_CLASS = 'Publish';
BgTaskTypePublish.RTTI_FQN = 'io.protoforce.projects/BgTaskType:Publish';
BgTaskTypePublish.toJSON = (value) => {
    return {
        task: value.task.toJSON(),
        release: value.release
    };
};
BgTaskTypePublish.fromJSON = (value) => {
    return new BgTaskTypePublish({
        task: PublishingTask.fromJSON(value.task),
        release: value.release
    });
};
