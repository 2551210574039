// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { FQNRuleDropLeft } from './FQNRuleDropLeft';
import { FQNRuleKeep } from './FQNRuleKeep';
import { FQNRuleLast } from './FQNRuleLast';
import { FQNRuleTakeRight } from './FQNRuleTakeRight';
import { JSONConverter, CodecError } from '../../../irt';
/**
  * ADT io.protoforce.projects:FQNRule
  *
  * Defined at /projects/manifests/naming.model @ 10:1
  */
export class FQNRule {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT FQNRule must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.keep !== 'undefined') {
            this.value = value.keep;
        }
        else if (typeof value.last !== 'undefined') {
            this.value = value.last;
        }
        else if (typeof value.takeRight !== 'undefined') {
            this.value = value.takeRight;
        }
        else if (typeof value.dropLeft !== 'undefined') {
            this.value = value.dropLeft;
        }
        else {
            throw new Error('ADT FQNRule constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenKeep, whenLast, whenTakeRight, whenDropLeft) {
        const v = this.value;
        if (v instanceof FQNRuleKeep) {
            return whenKeep(v);
        }
        else if (v instanceof FQNRuleLast) {
            return whenLast(v);
        }
        else if (v instanceof FQNRuleTakeRight) {
            return whenTakeRight(v);
        }
        else if (v instanceof FQNRuleDropLeft) {
            return whenDropLeft(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenKeep, whenLast, whenTakeRight, whenDropLeft) {
        const v = this.value;
        if (v instanceof FQNRuleKeep) {
            return whenKeep(v);
        }
        else if (v instanceof FQNRuleLast) {
            return whenLast(v);
        }
        else if (v instanceof FQNRuleTakeRight) {
            return whenTakeRight(v);
        }
        else if (v instanceof FQNRuleDropLeft) {
            return whenDropLeft(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromKeep(value) {
        return new FQNRule({ keep: value });
    }
    static fromLast(value) {
        return new FQNRule({ last: value });
    }
    static fromTakeRight(value) {
        return new FQNRule({ takeRight: value });
    }
    static fromDropLeft(value) {
        return new FQNRule({ dropLeft: value });
    }
    static from(value) {
        return value instanceof FQNRule ? value : new FQNRule(undefined, value);
    }
    get RTTI_CLASS() {
        return FQNRule.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FQNRule.RTTI_FQN;
    }
    toJSON() {
        return FQNRule.toJSON(this);
    }
}
FQNRule.RTTI_CLASS = 'FQNRule';
FQNRule.RTTI_FQN = 'io.protoforce.projects:FQNRule';
FQNRule.toJSON = (value) => {
    const v = value.value;
    if (v instanceof FQNRuleKeep) {
        return JSONConverter.withTypeNested(v, 'Keep');
    }
    else if (v instanceof FQNRuleLast) {
        return JSONConverter.withTypeNested(v, 'Last');
    }
    else if (v instanceof FQNRuleTakeRight) {
        return JSONConverter.withTypeNested(v, 'TakeRight');
    }
    else if (v instanceof FQNRuleDropLeft) {
        return JSONConverter.withTypeNested(v, 'DropLeft');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
FQNRule.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Keep': {
            const vc = val;
            return FQNRule.fromKeep(FQNRuleKeep.fromJSON(vc));
        }
        case 'Last': {
            const vc = val;
            return FQNRule.fromLast(FQNRuleLast.fromJSON(vc));
        }
        case 'TakeRight': {
            const vc = val;
            return FQNRule.fromTakeRight(FQNRuleTakeRight.fromJSON(vc));
        }
        case 'DropLeft': {
            const vc = val;
            return FQNRule.fromDropLeft(FQNRuleDropLeft.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'FQNRule'`);
    }
};
