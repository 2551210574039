// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { ProjectID } from '../shared';
/**
  * Identifier io.protoforce.projects:BgTaskScope
  *
  * Defined at /projects/tasks.model @ 57:1
  */
export class BgTaskScope {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.commit = '';
            }
            return;
        }
        this.project = data.project;
        this.commit = data.commit;
    }
    get RTTI_CLASS() {
        return BgTaskScope.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskScope.RTTI_FQN;
    }
    toJSON() {
        return BgTaskScope.toJSON(this);
    }
}
BgTaskScope.RTTI_CLASS = 'BgTaskScope';
BgTaskScope.RTTI_FQN = 'io.protoforce.projects:BgTaskScope';
BgTaskScope.toJSON = (value) => {
    const kvs = [
        `project=${encodeURIComponent(value.project.toJSON())}`,
        `commit=${encodeURIComponent(value.commit)}`
    ];
    return `${BgTaskScope.RTTI_CLASS}#${kvs.join(';')}`;
};
BgTaskScope.fromJSON = (value) => {
    const err = `Identifier ${BgTaskScope.RTTI_CLASS}.fromJSON input (${value}) is malformed.`;
    const chunks = value.split('#');
    if (chunks.length !== 2) {
        throw new CodecError(err);
    }
    if (chunks[0] !== BgTaskScope.RTTI_CLASS) {
        throw new CodecError(`${err} Expected to start with '${BgTaskScope.RTTI_CLASS}#'`);
    }
    const values = chunks[1].split(';');
    if (values.length !== 2) {
        throw new CodecError(`${err} Expects 2 key values, got ${values.length}`);
    }
    const valueMap = {};
    for (let i = 0; i < values.length; i++) {
        const kv = values[i].split('=');
        if (kv.length !== 2) {
            throw new CodecError(`${err} Must have key=value pairs comma separated.`);
        }
        valueMap[kv[0]] = kv[1];
    }
    const data = {
        project: ProjectID.fromJSON(decodeURIComponent(valueMap['project'])),
        commit: decodeURIComponent(valueMap['commit'])
    };
    return new BgTaskScope(data);
};
