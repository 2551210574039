// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { NotificationID } from './NotificationID';
import { NotificationbaseCodec } from './codecs/NotificationbaseCodec';
import { NotificationbaseImpl } from './NotificationbaseImpl';
import { ProjectName, AccessLevelHelper } from '../shared';
import { IRTZonedDateTime } from '../../../irt';
/**
  *
  *  User's access has changed
  *
  * Class io.protoforce.notifications:AccessChangedNotification
  *
  * Defined at /notifications/project.model @ 5:1
  */
export class AccessChangedNotification {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
                this.access = AccessLevelHelper.all[0];
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
        this.project = data.project;
        this.access = data.access;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return AccessChangedNotification.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccessChangedNotification.RTTI_FQN;
    }
    static fromNotificationbase(from, project, access) {
        return new AccessChangedNotification({
            project: project,
            access: access,
            id: from.id,
            at: from.at
        });
    }
    loadNotificationbase(from) {
        this.id = from.id;
        this.at = from.at;
    }
    toNotificationbase() {
        return new NotificationbaseImpl({
            id: this.id,
            at: this.at
        });
    }
    static fromUserJoinedNotification(from, project, access) {
        return new AccessChangedNotification({
            project: project,
            access: access,
            id: from.id,
            at: from.at
        });
    }
    loadUserJoinedNotification(from) {
        this.id = from.id;
        this.at = from.at;
    }
    static fromLevelChangedNotification(from, project, access) {
        return new AccessChangedNotification({
            project: project,
            access: access,
            id: from.id,
            at: from.at
        });
    }
    loadLevelChangedNotification(from) {
        this.id = from.id;
        this.at = from.at;
    }
    static fromInviteAcceptedNotification(from, project, access) {
        return new AccessChangedNotification({
            project: project,
            access: access,
            id: from.id,
            at: from.at
        });
    }
    loadInviteAcceptedNotification(from) {
        this.id = from.id;
        this.at = from.at;
    }
    toJSON() {
        return AccessChangedNotification.toJSON(this);
    }
}
AccessChangedNotification.RTTI_CLASS = 'AccessChangedNotification';
AccessChangedNotification.RTTI_FQN = 'io.protoforce.notifications:AccessChangedNotification';
AccessChangedNotification.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON(),
        project: value.project.toJSON(),
        access: AccessLevelHelper.toJSON(value.access)
    };
};
AccessChangedNotification.fromJSON = (value) => {
    return new AccessChangedNotification({
        id: NotificationID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at),
        project: ProjectName.fromJSON(value.project),
        access: AccessLevelHelper.fromJSON(value.access)
    });
};
NotificationbaseCodec.register(AccessChangedNotification.RTTI_FQN, AccessChangedNotification.fromJSON);
