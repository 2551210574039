// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JavaBuildManifest } from './JavaBuildManifest';
/**
  * Class io.protoforce.projects/ValidationTarget:Java (member of ADT io.protoforce.projects:ValidationTarget)
  *
  * Defined at /projects/tasks.model @ 37:5
  */
export class ValidationTargetJava {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
    }
    get RTTI_CLASS() {
        return ValidationTargetJava.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ValidationTargetJava.RTTI_FQN;
    }
    toJSON() {
        return ValidationTargetJava.toJSON(this);
    }
}
ValidationTargetJava.RTTI_CLASS = 'Java';
ValidationTargetJava.RTTI_FQN = 'io.protoforce.projects/ValidationTarget:Java';
ValidationTargetJava.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON()
    };
};
ValidationTargetJava.fromJSON = (value) => {
    return new ValidationTargetJava({
        manifest: JavaBuildManifest.fromJSON(value.manifest)
    });
};
