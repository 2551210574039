// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { AccountID } from './AccountID';
import { AccountName } from './AccountName';
import { JSONConverter, CodecError } from '../../../irt';
/**
  * ADT io.protoforce.shared:AccountIdentity
  *
  * Defined at /shared/ids.model @ 14:1
  */
export class AccountIdentity {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT AccountIdentity must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.accountID !== 'undefined') {
            this.value = value.accountID;
        }
        else if (typeof value.accountName !== 'undefined') {
            this.value = value.accountName;
        }
        else {
            throw new Error('ADT AccountIdentity constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenAccountID, whenAccountName) {
        const v = this.value;
        if (v instanceof AccountID) {
            return whenAccountID(v);
        }
        else if (v instanceof AccountName) {
            return whenAccountName(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenAccountID, whenAccountName) {
        const v = this.value;
        if (v instanceof AccountID) {
            return whenAccountID(v);
        }
        else if (v instanceof AccountName) {
            return whenAccountName(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromAccountID(value) {
        return new AccountIdentity({ accountID: value });
    }
    static fromAccountName(value) {
        return new AccountIdentity({ accountName: value });
    }
    static from(value) {
        return value instanceof AccountIdentity ? value : new AccountIdentity(undefined, value);
    }
    get RTTI_CLASS() {
        return AccountIdentity.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountIdentity.RTTI_FQN;
    }
    toJSON() {
        return AccountIdentity.toJSON(this);
    }
}
AccountIdentity.RTTI_CLASS = 'AccountIdentity';
AccountIdentity.RTTI_FQN = 'io.protoforce.shared:AccountIdentity';
AccountIdentity.toJSON = (value) => {
    const v = value.value;
    if (v instanceof AccountID) {
        return JSONConverter.withTypeNested(v, 'AccountID');
    }
    else if (v instanceof AccountName) {
        return JSONConverter.withTypeNested(v, 'AccountName');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
AccountIdentity.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'AccountID': {
            const vc = val;
            return AccountIdentity.fromAccountID(AccountID.fromJSON(vc));
        }
        case 'AccountName': {
            const vc = val;
            return AccountIdentity.fromAccountName(AccountName.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'AccountIdentity'`);
    }
};
