// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
export class AccountBriefDataImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
    }
    get RTTI_CLASS() {
        return AccountBriefDataImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountBriefDataImpl.RTTI_FQN;
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    toJSON() {
        return AccountBriefDataImpl.toJSON(this);
    }
}
AccountBriefDataImpl.RTTI_CLASS = 'AccountBriefData';
AccountBriefDataImpl.RTTI_FQN = 'io.protoforce.auth:AccountBriefData';
AccountBriefDataImpl.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined
    };
};
AccountBriefDataImpl.fromJSON = (value) => {
    return new AccountBriefDataImpl({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined
    });
};
AccountBriefDataCodec.register(AccountBriefDataImpl.RTTI_FQN, AccountBriefDataImpl.fromJSON);
