// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { ScalaExtension } from './ScalaExtension';
export class ScalaExtensionHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return ScalaExtensionHelper.toString(this.value);
    }
    static toString(e) {
        return ScalaExtension[e];
    }
    static fromString(e) {
        return ScalaExtension[e];
    }
    get RTTI_CLASS() {
        return ScalaExtensionHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaExtensionHelper.RTTI_FQN;
    }
    toJSON() {
        return ScalaExtensionHelper.toJSON(this.value);
    }
}
ScalaExtensionHelper.RTTI_CLASS = 'ScalaExtension';
ScalaExtensionHelper.RTTI_FQN = 'io.protoforce.projects:ScalaExtension';
ScalaExtensionHelper.all = [
    ScalaExtension.Random,
    ScalaExtension.Metadata,
    ScalaExtension.CodecsIRT,
    ScalaExtension.CodecsCirce,
    ScalaExtension.CodecTests
];
ScalaExtensionHelper.toJSON = (value) => {
    return ScalaExtension[value];
};
ScalaExtensionHelper.fromJSON = (value) => {
    switch (value) {
        case 'Random': return ScalaExtension.Random;
        case 'Metadata': return ScalaExtension.Metadata;
        case 'CodecsIRT': return ScalaExtension.CodecsIRT;
        case 'CodecsCirce': return ScalaExtension.CodecsCirce;
        case 'CodecTests': return ScalaExtension.CodecTests;
        default: throw new CodecError(`Unexpected value for enum ScalaExtension: '${value}'`);
    }
};
