// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  Plans
  *
  * Enum io.protoforce.shared:Plan
  *
  * Defined at /shared/plans.model @ 5:1
  */
export var Plan;
(function (Plan) {
    /**
      *
      *  Free plan
      *
      * Defined at /shared/plans.model @ 9:5
      */
    Plan[Plan["Free"] = 0] = "Free";
    /**
      *
      *  Plus plan
      *
      * Defined at /shared/plans.model @ 13:5
      */
    Plan[Plan["Plus"] = 1] = "Plus";
    /**
      *
      *  Pro plan
      *
      * Defined at /shared/plans.model @ 17:5
      */
    Plan[Plan["Pro"] = 2] = "Pro";
})(Plan || (Plan = {}));
