// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
import { BgTask } from '../../../projects';
import { fromEitherJSON } from '../../../../../irt';
import { GenericFailure } from '../../../shared';
export class TasksServicelistActiveTasksMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.map((e2) => e2.toJSON());
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return r.map((e2) => BgTask.fromJSON(e2));
        }, (l) => {
            return GenericFailure.fromJSON(l);
        });
    }
}
