// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { TypeScriptProjectLayout } from './TypeScriptProjectLayout';
export class TypeScriptProjectLayoutHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return TypeScriptProjectLayoutHelper.toString(this.value);
    }
    static toString(e) {
        return TypeScriptProjectLayout[e];
    }
    static fromString(e) {
        return TypeScriptProjectLayout[e];
    }
    get RTTI_CLASS() {
        return TypeScriptProjectLayoutHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptProjectLayoutHelper.RTTI_FQN;
    }
    toJSON() {
        return TypeScriptProjectLayoutHelper.toJSON(this.value);
    }
}
TypeScriptProjectLayoutHelper.RTTI_CLASS = 'TypeScriptProjectLayout';
TypeScriptProjectLayoutHelper.RTTI_FQN = 'io.protoforce.projects:TypeScriptProjectLayout';
TypeScriptProjectLayoutHelper.all = [
    TypeScriptProjectLayout.PACKAGED,
    TypeScriptProjectLayout.PLAIN
];
TypeScriptProjectLayoutHelper.toJSON = (value) => {
    return TypeScriptProjectLayout[value];
};
TypeScriptProjectLayoutHelper.fromJSON = (value) => {
    switch (value) {
        case 'PACKAGED': return TypeScriptProjectLayout.PACKAGED;
        case 'PLAIN': return TypeScriptProjectLayout.PLAIN;
        default: throw new CodecError(`Unexpected value for enum TypeScriptProjectLayout: '${value}'`);
    }
};
