// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JavaBuildManifest } from './JavaBuildManifest';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
/**
  * Class io.protoforce.projects/JavaCompiledPublic:Maven (member of ADT io.protoforce.projects:JavaCompiledPublic)
  *
  * Defined at /projects/publishing.model @ 158:5
  */
export class JavaCompiledPublicMaven {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
    }
    get RTTI_CLASS() {
        return JavaCompiledPublicMaven.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return JavaCompiledPublicMaven.RTTI_FQN;
    }
    toJSON() {
        return JavaCompiledPublicMaven.toJSON(this);
    }
}
JavaCompiledPublicMaven.RTTI_CLASS = 'Maven';
JavaCompiledPublicMaven.RTTI_FQN = 'io.protoforce.projects/JavaCompiledPublic:Maven';
JavaCompiledPublicMaven.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON()
    };
};
JavaCompiledPublicMaven.fromJSON = (value) => {
    return new JavaCompiledPublicMaven({
        manifest: JavaBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target)
    });
};
