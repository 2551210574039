// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
import { AccountBriefDataImpl } from './AccountBriefDataImpl';
import { AccountID } from '../shared';
import { UserBrief } from './UserBrief';
/**
  *
  *  Organization brief info
  *
  * Class io.protoforce.auth:OrganizationBrief
  *
  * Defined at /auth/organization.model @ 20:1
  */
export class OrganizationBrief {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return OrganizationBrief.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OrganizationBrief.RTTI_FQN;
    }
    static fromAccountBriefData(from, id) {
        return new OrganizationBrief({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountBriefData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromAccountData(from, id) {
        return new OrganizationBrief({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toUserBrief() {
        return new UserBrief({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            id: this.id
        });
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    static fromOrganizationCreateRequest(from, id) {
        return new OrganizationBrief({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrganizationCreateRequest(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromOrganizationData(from, id) {
        return new OrganizationBrief({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrganizationData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toJSON() {
        return OrganizationBrief.toJSON(this);
    }
}
OrganizationBrief.RTTI_CLASS = 'OrganizationBrief';
OrganizationBrief.RTTI_FQN = 'io.protoforce.auth:OrganizationBrief';
OrganizationBrief.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        id: value.id.toJSON()
    };
};
OrganizationBrief.fromJSON = (value) => {
    return new OrganizationBrief({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        id: AccountID.fromJSON(value.id)
    });
};
AccountBriefDataCodec.register(OrganizationBrief.RTTI_FQN, OrganizationBrief.fromJSON);
