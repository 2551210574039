// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  * Enum io.protoforce.shared:AccessLevel
  *
  * Defined at /shared/perms.model @ 2:1
  */
export var AccessLevel;
(function (AccessLevel) {
    /**
      * Defined at /shared/perms.model @ 3:5
      */
    AccessLevel[AccessLevel["Read"] = 0] = "Read";
    /**
      * Defined at /shared/perms.model @ 4:5
      */
    AccessLevel[AccessLevel["Write"] = 1] = "Write";
    /**
      * Defined at /shared/perms.model @ 5:5
      */
    AccessLevel[AccessLevel["Admin"] = 2] = "Admin";
})(AccessLevel || (AccessLevel = {}));
