import { Position, Toaster, Intent } from '@blueprintjs/core';
let toaster;
function getToaster() {
    if (!toaster) {
        toaster = Toaster.create({
            position: Position.BOTTOM
        });
    }
    return toaster;
}
export function showErrorToast(message, timeout = 2500) {
    const toast = {
        icon: 'warning-sign',
        intent: Intent.DANGER,
        message,
        timeout
    };
    try {
        getToaster().show(toast);
    }
    catch (err) {
        console.error(`Failed to create a toaster: ${err}`);
    }
}
export function showSuccessToast(message, icon = 'tick', timeout = 2500) {
    const toast = {
        icon,
        intent: Intent.SUCCESS,
        message,
        timeout
    };
    try {
        getToaster().show(toast);
    }
    catch (err) {
        console.error(`Failed to create a toaster: ${err}`);
    }
}
