// tslint:disable
// eslint-disable
// package io.protoforce.docs.react.internaldocsservice
import { InternalDocsServiceClient } from '../../internaldocsservice/InternalDocsServiceClient';
import { useClientTransport } from '../../../../../irt-react';
import { useMemo } from 'react';
/**
  * Defined at docs.domain @ 17:1
  */
export function useInternalDocsService(transport) {
    const clientTransport = useClientTransport(InternalDocsServiceClient, { transport });
    return useMemo(() => {
        return new InternalDocsServiceClient(clientTransport);
    }, [clientTransport]);
}
