// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
import { AccountBriefDataImpl } from './AccountBriefDataImpl';
import { AccountDataCodec } from './codecs/AccountDataCodec';
import { AccountDataImpl } from './AccountDataImpl';
import { AccountID, PlanState, UserLevelHelper } from '../shared';
import { Organization } from './Organization';
import { OrganizationBrief } from './OrganizationBrief';
import { OrganizationDataCodec } from './codecs/OrganizationDataCodec';
import { OrganizationDataImpl } from './OrganizationDataImpl';
import { User } from './User';
import { UserBrief } from './UserBrief';
/**
  *
  *  An organization to which a user belong
  *
  * Class io.protoforce.auth:UserOrganization
  *
  * Defined at /auth/organization.model @ 42:1
  */
export class UserOrganization {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.isBusiness = false;
                this.level = UserLevelHelper.all[0];
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
        this.bio = data.bio;
        this.url = data.url;
        this.isBusiness = data.isBusiness;
        this.businessName = data.businessName;
        this.id = data.id;
        this.level = data.level;
        this.planState = data.planState;
    }
    get RTTI_CLASS() {
        return UserOrganization.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserOrganization.RTTI_FQN;
    }
    static fromOrganizationData(from, id, level, planState) {
        return new UserOrganization({
            id: id,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url,
            isBusiness: from.isBusiness,
            businessName: from.businessName
        });
    }
    loadOrganizationData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
        this.isBusiness = from.isBusiness;
        this.businessName = from.businessName;
    }
    static fromAccountBriefData(from, bio, url, isBusiness, businessName, id, level, planState) {
        return new UserOrganization({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            id: id,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountBriefData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toOrganizationBrief() {
        return new OrganizationBrief({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            id: this.id
        });
    }
    static fromUser(from, isBusiness, businessName, level, planState) {
        return new UserOrganization({
            isBusiness: isBusiness,
            businessName: businessName,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url,
            id: from.id
        });
    }
    loadUser(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
        this.id = from.id;
    }
    toUser() {
        return new User({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url,
            id: this.id
        });
    }
    static fromAccountData(from, isBusiness, businessName, id, level, planState) {
        return new UserOrganization({
            isBusiness: isBusiness,
            businessName: businessName,
            id: id,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadAccountData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    toOrganization() {
        return new Organization({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url,
            isBusiness: this.isBusiness,
            businessName: this.businessName,
            id: this.id
        });
    }
    static fromOrganizationCreateRequest(from, id, level, planState) {
        return new UserOrganization({
            id: id,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url,
            isBusiness: from.isBusiness,
            businessName: from.businessName
        });
    }
    loadOrganizationCreateRequest(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
        this.isBusiness = from.isBusiness;
        this.businessName = from.businessName;
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    toOrganizationData() {
        return new OrganizationDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url,
            isBusiness: this.isBusiness,
            businessName: this.businessName
        });
    }
    static fromOrgMember(from, bio, url, isBusiness, businessName, planState) {
        return new UserOrganization({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id,
            level: from.level
        });
    }
    loadOrgMember(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
        this.level = from.level;
    }
    static fromOrganization(from, level, planState) {
        return new UserOrganization({
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url,
            isBusiness: from.isBusiness,
            businessName: from.businessName,
            id: from.id
        });
    }
    loadOrganization(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
        this.isBusiness = from.isBusiness;
        this.businessName = from.businessName;
        this.id = from.id;
    }
    static fromUserBrief(from, bio, url, isBusiness, businessName, level, planState) {
        return new UserOrganization({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id
        });
    }
    loadUserBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
    }
    toUserBrief() {
        return new UserBrief({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            id: this.id
        });
    }
    toAccountData() {
        return new AccountDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url
        });
    }
    static fromOrganizationBrief(from, bio, url, isBusiness, businessName, level, planState) {
        return new UserOrganization({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            level: level,
            planState: planState,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id
        });
    }
    loadOrganizationBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
    }
    toJSON() {
        return UserOrganization.toJSON(this);
    }
}
UserOrganization.RTTI_CLASS = 'UserOrganization';
UserOrganization.RTTI_FQN = 'io.protoforce.auth:UserOrganization';
UserOrganization.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        isBusiness: value.isBusiness,
        businessName: typeof value.businessName !== 'undefined' && value.businessName != null ? value.businessName : undefined,
        id: value.id.toJSON(),
        level: UserLevelHelper.toJSON(value.level),
        planState: value.planState.toJSON()
    };
};
UserOrganization.fromJSON = (value) => {
    return new UserOrganization({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        isBusiness: value.isBusiness,
        businessName: typeof value.businessName !== 'undefined' && value.businessName != null ? value.businessName : undefined,
        id: AccountID.fromJSON(value.id),
        level: UserLevelHelper.fromJSON(value.level),
        planState: PlanState.fromJSON(value.planState)
    });
};
AccountBriefDataCodec.register(UserOrganization.RTTI_FQN, UserOrganization.fromJSON);
AccountDataCodec.register(UserOrganization.RTTI_FQN, UserOrganization.fromJSON);
OrganizationDataCodec.register(UserOrganization.RTTI_FQN, UserOrganization.fromJSON);
