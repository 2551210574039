export function signupReducer(state, action) {
    switch (action.type) {
        case 'reset': return {};
        case 'presignup': return Object.assign(Object.assign({}, state), { pre: {
                name: action.name,
                email: action.email,
                provider: action.provider,
                token: action.token
            } });
        case 'signup': return Object.assign(Object.assign({}, state), { error: undefined, signingUp: {
                signupWith: action.signupWith
            } });
        case 'signupError': return Object.assign(Object.assign({}, state), { error: action.error, signingUp: undefined });
    }
}
