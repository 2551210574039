// tslint:disable
// eslint-disable
// package io.protoforce.auth.fileuploadservice.methods
import { FileUploadServiceBlogUploadInput } from '../models/FileUploadServiceBlogUploadInput';
import { FileUploadServiceblogUploadMethodOutputHelper } from '../models/FileUploadServiceblogUploadMethodOutputHelper';
import { fileUploadServiceBlogUploadName } from './fileUploadServiceBlogUploadName';
import { fileUploadServiceRTTI } from './fileUploadServiceRTTI';
export function fileUploadServiceBlogUpload(input, transport, context) {
    return transport.send(fileUploadServiceRTTI, fileUploadServiceBlogUploadName, new FileUploadServiceBlogUploadInput(input), {
        in: FileUploadServiceBlogUploadInput,
        out: FileUploadServiceblogUploadMethodOutputHelper,
        alternative: true,
        context
    });
}
