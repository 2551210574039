// tslint:disable
// eslint-disable
// package io.protoforce.search.searchservice.methods
import { SearchResponse } from '../../SearchResponse';
import { SearchServiceSearchInput } from '../models/SearchServiceSearchInput';
import { searchServiceRTTI } from './searchServiceRTTI';
import { searchServiceSearchName } from './searchServiceSearchName';
export function searchServiceSearch(input, transport, context) {
    return transport.send(searchServiceRTTI, searchServiceSearchName, new SearchServiceSearchInput(input), {
        in: SearchServiceSearchInput,
        out: SearchResponse,
        context
    });
}
