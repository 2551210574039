// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ScalaCompiledMaven } from './ScalaCompiledMaven';
/**
  * ADT io.protoforce.projects:ScalaCompiled
  *
  * Defined at /projects/publishing.model @ 109:1
  */
export class ScalaCompiled {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ScalaCompiled must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.maven !== 'undefined') {
            this.value = value.maven;
        }
        else {
            throw new Error('ADT ScalaCompiled constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenMaven) {
        const v = this.value;
        if (v instanceof ScalaCompiledMaven) {
            return whenMaven(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenMaven) {
        const v = this.value;
        if (v instanceof ScalaCompiledMaven) {
            return whenMaven(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromMaven(value) {
        return new ScalaCompiled({ maven: value });
    }
    static from(value) {
        return value instanceof ScalaCompiled ? value : new ScalaCompiled(undefined, value);
    }
    get RTTI_CLASS() {
        return ScalaCompiled.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaCompiled.RTTI_FQN;
    }
    toJSON() {
        return ScalaCompiled.toJSON(this);
    }
}
ScalaCompiled.RTTI_CLASS = 'ScalaCompiled';
ScalaCompiled.RTTI_FQN = 'io.protoforce.projects:ScalaCompiled';
ScalaCompiled.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaCompiledMaven) {
        return JSONConverter.withTypeNested(v, 'Maven');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ScalaCompiled.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Maven': {
            const vc = val;
            return ScalaCompiled.fromMaven(ScalaCompiledMaven.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ScalaCompiled'`);
    }
};
