// tslint:disable
// eslint-disable
// package io.protoforce.notifications.react.notificationsservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { notificationsServiceRTTI } from '../../notificationsservice/methods/notificationsServiceRTTI';
import { notificationsServiceReject } from '../../notificationsservice/methods/notificationsServiceReject';
import { useCallback } from 'react';
/**
  *
  *  Reject an action
  *
  *  @id Action ID
  *
  * Defined at /notifications/notifications.service.model @ 65:5
  */
export function useNotificationsServiceReject(options, context) {
    const clientTransport = useClientTransport(notificationsServiceRTTI, options);
    const request = useCallback((id) => {
        return notificationsServiceReject({ id }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
