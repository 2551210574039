// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { Organization } from './Organization';
import { PinnedProjects } from './PinnedProjects';
/**
  * Class io.protoforce.auth:GetAccountOrganizationResponse
  *
  * Defined at /auth/account.service.model @ 16:1
  */
export class GetAccountOrganizationResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.organization = data.organization;
        this.pinned = data.pinned;
    }
    get RTTI_CLASS() {
        return GetAccountOrganizationResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GetAccountOrganizationResponse.RTTI_FQN;
    }
    toJSON() {
        return GetAccountOrganizationResponse.toJSON(this);
    }
}
GetAccountOrganizationResponse.RTTI_CLASS = 'GetAccountOrganizationResponse';
GetAccountOrganizationResponse.RTTI_FQN = 'io.protoforce.auth:GetAccountOrganizationResponse';
GetAccountOrganizationResponse.toJSON = (value) => {
    return {
        organization: value.organization.toJSON(),
        pinned: value.pinned.toJSON()
    };
};
GetAccountOrganizationResponse.fromJSON = (value) => {
    return new GetAccountOrganizationResponse({
        organization: Organization.fromJSON(value.organization),
        pinned: PinnedProjects.fromJSON(value.pinned)
    });
};
