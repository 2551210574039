// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/ScalaExtensionSpec:All (member of ADT io.protoforce.projects:ScalaExtensionSpec)
  *
  * Defined at /projects/manifests/build.model @ 81:5
  */
export class ScalaExtensionSpecAll {
    get RTTI_CLASS() {
        return ScalaExtensionSpecAll.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaExtensionSpecAll.RTTI_FQN;
    }
    toJSON() {
        return ScalaExtensionSpecAll.toJSON(this);
    }
}
ScalaExtensionSpecAll.RTTI_CLASS = 'All';
ScalaExtensionSpecAll.RTTI_FQN = 'io.protoforce.projects/ScalaExtensionSpec:All';
ScalaExtensionSpecAll.toJSON = (value) => {
    return {};
};
ScalaExtensionSpecAll.fromJSON = (value) => {
    return new ScalaExtensionSpecAll();
};
