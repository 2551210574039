// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { FailureBase } from './FailureBase';
import { IRTErrorShared } from './IRTErrorShared';
import { RuntimeError } from '../../../irt';
/**
  *
  *  UnknownFailure is an unexpected failure which
  *  can not be categorized or further narrowed down.
  *
  * Class io.protoforce.shared:UnknownFailure
  *
  * Defined at /shared/error.model @ 12:1
  */
export class UnknownFailure extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return UnknownFailure.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UnknownFailure.RTTI_FQN;
    }
    toIRTErrorShared() {
        return new IRTErrorShared({
            message: this.message
        });
    }
    toFailureBase() {
        return new FailureBase({
            message: this.message
        });
    }
    toJSON() {
        return UnknownFailure.toJSON(this);
    }
}
UnknownFailure.RTTI_CLASS = 'UnknownFailure';
UnknownFailure.RTTI_FQN = 'io.protoforce.shared:UnknownFailure';
UnknownFailure.toJSON = (value) => {
    return {
        message: value.message
    };
};
UnknownFailure.fromJSON = (value) => {
    return new UnknownFailure({
        message: value.message
    });
};
