// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { TypeScriptBuildManifest } from './TypeScriptBuildManifest';
/**
  * Class io.protoforce.projects/TypescriptCompiledPublic:NPM (member of ADT io.protoforce.projects:TypescriptCompiledPublic)
  *
  * Defined at /projects/publishing.model @ 170:5
  */
export class TypescriptCompiledPublicNPM {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
    }
    get RTTI_CLASS() {
        return TypescriptCompiledPublicNPM.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptCompiledPublicNPM.RTTI_FQN;
    }
    toJSON() {
        return TypescriptCompiledPublicNPM.toJSON(this);
    }
}
TypescriptCompiledPublicNPM.RTTI_CLASS = 'NPM';
TypescriptCompiledPublicNPM.RTTI_FQN = 'io.protoforce.projects/TypescriptCompiledPublic:NPM';
TypescriptCompiledPublicNPM.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON()
    };
};
TypescriptCompiledPublicNPM.fromJSON = (value) => {
    return new TypescriptCompiledPublicNPM({
        manifest: TypeScriptBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target)
    });
};
