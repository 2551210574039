// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Enum io.protoforce.projects:TypeScriptExtension
  *
  * Defined at /projects/manifests/typescript.model @ 19:1
  */
export var TypeScriptExtension;
(function (TypeScriptExtension) {
    /**
      * Defined at /projects/manifests/typescript.model @ 21:5
      */
    TypeScriptExtension[TypeScriptExtension["Random"] = 0] = "Random";
    /**
      * Defined at /projects/manifests/typescript.model @ 22:5
      */
    TypeScriptExtension[TypeScriptExtension["REST"] = 1] = "REST";
    /**
      * Defined at /projects/manifests/typescript.model @ 25:5
      */
    TypeScriptExtension[TypeScriptExtension["Tests"] = 2] = "Tests";
    /**
      * Defined at /projects/manifests/typescript.model @ 26:5
      */
    TypeScriptExtension[TypeScriptExtension["JSON"] = 3] = "JSON";
    /**
      * Defined at /projects/manifests/typescript.model @ 29:5
      */
    TypeScriptExtension[TypeScriptExtension["React"] = 4] = "React";
    /**
      * Defined at /projects/manifests/typescript.model @ 32:5
      */
    TypeScriptExtension[TypeScriptExtension["Node"] = 5] = "Node";
})(TypeScriptExtension || (TypeScriptExtension = {}));
