// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { ConfirmMFAResponse } from '../../ConfirmMFAResponse';
import { GenericFailure } from '../../../shared';
import { JSONConverter, CodecError } from '../../../../../irt';
import { LoginResponse } from '../../LoginResponse';
/**
  *
  *  login into the ProtoForce system and obtain user details. If a 2FA/MFA was
  *  set for an account - it will require an extra step to confirm MFA
  *
  *  @data One of the possible credentials of the LoginWith type
  *
  * ADT io.protoforce.auth.authservice.models:LoginOutput
  *
  * Defined at /auth/auth.service.model @ 154:5
  */
export class AuthServiceLoginOutput {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT AuthServiceLoginOutput must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.loginResponse !== 'undefined') {
            this.value = value.loginResponse;
        }
        else if (typeof value.confirmMFAResponse !== 'undefined') {
            this.value = value.confirmMFAResponse;
        }
        else if (typeof value.genericFailure !== 'undefined') {
            this.value = value.genericFailure;
        }
        else {
            throw new Error('ADT AuthServiceLoginOutput constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenLoginResponse, whenConfirmMFAResponse, whenGenericFailure) {
        const v = this.value;
        if (v instanceof LoginResponse) {
            return whenLoginResponse(v);
        }
        else if (v instanceof ConfirmMFAResponse) {
            return whenConfirmMFAResponse(v);
        }
        else if (v instanceof GenericFailure) {
            return whenGenericFailure(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenLoginResponse, whenConfirmMFAResponse, whenGenericFailure) {
        const v = this.value;
        if (v instanceof LoginResponse) {
            return whenLoginResponse(v);
        }
        else if (v instanceof ConfirmMFAResponse) {
            return whenConfirmMFAResponse(v);
        }
        else if (v instanceof GenericFailure) {
            return whenGenericFailure(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromLoginResponse(value) {
        return new AuthServiceLoginOutput({ loginResponse: value });
    }
    static fromConfirmMFAResponse(value) {
        return new AuthServiceLoginOutput({ confirmMFAResponse: value });
    }
    static fromGenericFailure(value) {
        return new AuthServiceLoginOutput({ genericFailure: value });
    }
    static from(value) {
        return value instanceof AuthServiceLoginOutput ? value : new AuthServiceLoginOutput(undefined, value);
    }
    get RTTI_CLASS() {
        return AuthServiceLoginOutput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthServiceLoginOutput.RTTI_FQN;
    }
    toJSON() {
        return AuthServiceLoginOutput.toJSON(this);
    }
}
AuthServiceLoginOutput.RTTI_CLASS = 'LoginOutput';
AuthServiceLoginOutput.RTTI_FQN = 'io.protoforce.auth.authservice.models:LoginOutput';
AuthServiceLoginOutput.toJSON = (value) => {
    const v = value.value;
    if (v instanceof LoginResponse) {
        return JSONConverter.withTypeNested(v, 'LoginResponse');
    }
    else if (v instanceof ConfirmMFAResponse) {
        return JSONConverter.withTypeNested(v, 'ConfirmMFAResponse');
    }
    else if (v instanceof GenericFailure) {
        return JSONConverter.withTypeNested(v, 'GenericFailure');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
AuthServiceLoginOutput.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'LoginResponse': {
            const vc = val;
            return AuthServiceLoginOutput.fromLoginResponse(LoginResponse.fromJSON(vc));
        }
        case 'ConfirmMFAResponse': {
            const vc = val;
            return AuthServiceLoginOutput.fromConfirmMFAResponse(ConfirmMFAResponse.fromJSON(vc));
        }
        case 'GenericFailure': {
            const vc = val;
            return AuthServiceLoginOutput.fromGenericFailure(GenericFailure.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'LoginOutput'`);
    }
};
