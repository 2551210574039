// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { TaskOperationFailureCannotCancel } from './TaskOperationFailureCannotCancel';
import { RuntimeError } from '../../../irt';
/**
  * Class io.protoforce.tasks:IRTErrorTasks
  */
export class IRTErrorTasks extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return IRTErrorTasks.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return IRTErrorTasks.RTTI_FQN;
    }
    toTaskOperationFailureCannotCancel() {
        return new TaskOperationFailureCannotCancel({
            message: this.message
        });
    }
    toJSON() {
        return IRTErrorTasks.toJSON(this);
    }
}
IRTErrorTasks.RTTI_CLASS = 'IRTErrorTasks';
IRTErrorTasks.RTTI_FQN = 'io.protoforce.tasks:IRTErrorTasks';
IRTErrorTasks.toJSON = (value) => {
    return {
        message: value.message
    };
};
IRTErrorTasks.fromJSON = (value) => {
    return new IRTErrorTasks({
        message: value.message
    });
};
