// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { ProjectID } from '../shared';
/**
  * Class io.protoforce.tasks/TasksFilter:AllInProject (member of ADT io.protoforce.tasks:TasksFilter)
  *
  * Defined at tasks.domain @ 29:5
  */
export class TasksFilterAllInProject {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.project = data.project;
    }
    get RTTI_CLASS() {
        return TasksFilterAllInProject.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksFilterAllInProject.RTTI_FQN;
    }
    toJSON() {
        return TasksFilterAllInProject.toJSON(this);
    }
}
TasksFilterAllInProject.RTTI_CLASS = 'AllInProject';
TasksFilterAllInProject.RTTI_FQN = 'io.protoforce.tasks/TasksFilter:AllInProject';
TasksFilterAllInProject.toJSON = (value) => {
    return {
        project: value.project.toJSON()
    };
};
TasksFilterAllInProject.fromJSON = (value) => {
    return new TasksFilterAllInProject({
        project: ProjectID.fromJSON(value.project)
    });
};
