// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { ScalaBuildManifest } from './ScalaBuildManifest';
/**
  * Class io.protoforce.projects/ScalaCompiledPublic:Maven (member of ADT io.protoforce.projects:ScalaCompiledPublic)
  *
  * Defined at /projects/publishing.model @ 147:5
  */
export class ScalaCompiledPublicMaven {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
    }
    get RTTI_CLASS() {
        return ScalaCompiledPublicMaven.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaCompiledPublicMaven.RTTI_FQN;
    }
    toJSON() {
        return ScalaCompiledPublicMaven.toJSON(this);
    }
}
ScalaCompiledPublicMaven.RTTI_CLASS = 'Maven';
ScalaCompiledPublicMaven.RTTI_FQN = 'io.protoforce.projects/ScalaCompiledPublic:Maven';
ScalaCompiledPublicMaven.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON()
    };
};
ScalaCompiledPublicMaven.fromJSON = (value) => {
    return new ScalaCompiledPublicMaven({
        manifest: ScalaBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target)
    });
};
