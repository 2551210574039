import React, { forwardRef, useContext, useEffect, useImperativeHandle, } from 'react';
const ChatWidgetTawk = (props, ref) => {
    // const [instance, setInstance] = useState<TawkToAPI>();
    // const attrsRef = useRef<{[key: string]: string}>({});
    // const [visible, setVisible] = useState(true);
    useImperativeHandle(ref, () => {
        return {
            show: () => {
                // setVisible(true);
                // if (instance) {
                //   instance.showWidget();
                // }
            },
            hide: () => {
                // setVisible(false);
                // if (instance) {
                //   instance.hideWidget();
                // }
            },
            clearUser: () => {
                // attrsRef.current = {};
                // if (instance) {
                //   instance.setAttributes(attrsRef.current);
                // }
            },
            setUser: (name, id, payer) => {
                // attrsRef.current = {
                //   name,
                //   accountID: id,
                //   payer: payer ? 'Yes' : 'No'
                // };
                // if (instance) {
                //   instance.setAttributes(attrsRef.current);
                // }
            }
        };
    });
    // useEffect(() => {
    //   if (!instance) {
    //     return;
    //   }
    //   if (visible) {
    //     instance.showWidget();
    //   } else {
    //     instance.hideWidget();
    //   }
    // }, [visible, instance]);
    // useEffect(() => {
    //   const s = createTawkTo((i: TawkToAPI) => {
    //     setInstance(i);
    //     i.setAttributes(attrsRef.current);
    //   });
    //   return () => {
    //     if (document && s) {
    //       document.body.removeChild(s);
    //     }
    //   };
    // }, []);
    return null;
};
export const ChatWidget = React.memo(forwardRef(ChatWidgetTawk));
// @ts-ignore
export const ChatWidgetContext = React.createContext();
export function useChatWidget(hidden) {
    const chat = useContext(ChatWidgetContext);
    useEffect(() => {
        if (!hidden || !chat) {
            return;
        }
        chat.hide();
        return () => chat.show();
    }, [chat, hidden]);
}
