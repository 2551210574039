// tslint:disable
// eslint-disable
// package io.protoforce.auth.fileuploadservice.models
import { FileUpload, ProjectID } from '../../../shared';
/**
  *
  *  Upload a file related to a prokect scope.
  *
  *  @data File details
  *  @project Project ID which the file belongs to
  *
  * Class io.protoforce.auth.fileuploadservice.models:ProjectUploadInput
  *
  * Defined at /auth/upload.service.model @ 16:5
  */
export class FileUploadServiceProjectUploadInput {
    constructor(data) {
        this.data = data.data;
        this.project = data.project;
    }
    get RTTI_CLASS() {
        return FileUploadServiceProjectUploadInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FileUploadServiceProjectUploadInput.RTTI_FQN;
    }
    toJSON() {
        return FileUploadServiceProjectUploadInput.toJSON(this);
    }
}
FileUploadServiceProjectUploadInput.RTTI_CLASS = 'ProjectUploadInput';
FileUploadServiceProjectUploadInput.RTTI_FQN = 'io.protoforce.auth.fileuploadservice.models:ProjectUploadInput';
FileUploadServiceProjectUploadInput.toJSON = (value) => {
    return {
        data: value.data.toJSON(),
        project: value.project.toJSON()
    };
};
FileUploadServiceProjectUploadInput.fromJSON = (value) => {
    return new FileUploadServiceProjectUploadInput({
        data: FileUpload.fromJSON(value.data),
        project: ProjectID.fromJSON(value.project)
    });
};
