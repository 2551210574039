// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  Languages supported by the compiler.
  *
  * Enum io.protoforce.shared:Lang
  *
  * Defined at /shared/langs.model @ 5:1
  */
export var Lang;
(function (Lang) {
    /**
      * Defined at /shared/langs.model @ 6:3
      */
    Lang[Lang["CSharp"] = 0] = "CSharp";
    /**
      * Defined at /shared/langs.model @ 7:3
      */
    Lang[Lang["TypeScript"] = 1] = "TypeScript";
    /**
      * Defined at /shared/langs.model @ 8:3
      */
    Lang[Lang["Scala"] = 2] = "Scala";
    /**
      * Defined at /shared/langs.model @ 9:3
      */
    Lang[Lang["Golang"] = 3] = "Golang";
    /**
      * Defined at /shared/langs.model @ 10:3
      */
    Lang[Lang["Curl"] = 4] = "Curl";
    /**
      * Defined at /shared/langs.model @ 11:3
      */
    Lang[Lang["Java"] = 5] = "Java";
    /**
      * Defined at /shared/langs.model @ 12:3
      */
    Lang[Lang["Python"] = 6] = "Python";
    /**
      * Defined at /shared/langs.model @ 13:3
      */
    Lang[Lang["PHP"] = 7] = "PHP";
    /**
      * Defined at /shared/langs.model @ 14:3
      */
    Lang[Lang["Ruby"] = 8] = "Ruby";
    /**
      * Defined at /shared/langs.model @ 15:3
      */
    Lang[Lang["Swift"] = 9] = "Swift";
    /**
      * Defined at /shared/langs.model @ 16:3
      */
    Lang[Lang["Kotlin"] = 10] = "Kotlin";
    /**
      * Defined at /shared/langs.model @ 17:3
      */
    Lang[Lang["Rust"] = 11] = "Rust";
    /**
      * Defined at /shared/langs.model @ 18:3
      */
    Lang[Lang["CPP"] = 12] = "CPP";
    /**
      * Defined at /shared/langs.model @ 19:3
      */
    Lang[Lang["Protobuf"] = 13] = "Protobuf";
})(Lang || (Lang = {}));
