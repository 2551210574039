var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useMemo } from 'react';
import localforage from 'localforage';
export function createStorage(name, storeName) {
    const storage = localforage.createInstance({
        name,
        storeName
    });
    function has(key) {
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            const allKeys = yield storage.keys();
            resolve(allKeys.indexOf(key) >= 0);
        }));
    }
    function keys() {
        return storage.keys();
    }
    function put(key, value) {
        storage.setItem(key, value);
        return true;
    }
    function get(key) {
        return storage.getItem(key);
    }
    function clear(key) {
        storage.removeItem(key);
    }
    function clearAll() {
        storage.clear();
    }
    return {
        has,
        keys,
        put,
        get,
        clear,
        clearAll
    };
}
export const databaseName = 'ProtoForce Store';
export const databaseTableName = 'pfstore';
export const StorageContext = React.createContext(createStorage(databaseName, databaseTableName));
export function useStorage(key) {
    const storage = useContext(StorageContext);
    return useMemo(() => {
        return {
            has: () => storage.has(key),
            put: (value) => storage.put(key, value),
            get: () => storage.get(key),
            clear: () => storage.clear(key),
            clearAll: () => storage.clearAll()
        };
    }, [key, storage]);
}
