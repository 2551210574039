// tslint:disable
// eslint-disable
// package io.protoforce.auth.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class SignupWithBaseCodec {
    static register(fqn, ctor) {
        SignupWithBaseCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete SignupWithBaseCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in SignupWithBaseCodec.registry;
    }
    static getRegistered() {
        return Object.keys(SignupWithBaseCodec.registry);
    }
}
SignupWithBaseCodec.registry = {};
SignupWithBaseCodec.fromFQN = (fqn, value) => {
    const ctor = SignupWithBaseCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface SignupWithBase. Register at SignupWithBaseCodec before it can be used.`);
    }
    return ctor(value);
};
SignupWithBaseCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return SignupWithBaseCodec.fromFQN(key, val);
};
SignupWithBaseCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return SignupWithBaseCodec.fromFQN(key, val);
};
SignupWithBaseCodec.fromJSON = (value) => {
    return SignupWithBaseCodec.fromTypeNestedJSON(value);
};
SignupWithBaseCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
SignupWithBaseCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
SignupWithBaseCodec.toJSON = (value) => {
    return SignupWithBaseCodec.toTypeNestedJSON(value);
};
