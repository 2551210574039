import React, { useCallback, useState } from 'react';
import { Button, Card, Tooltip } from '@blueprintjs/core';
import { useTasksServiceTryCancel } from '@protoforce/tasks';
import { BgTask, BgTaskPublic } from '@protoforce/projects';
import { UserAvatar, UserAvatarDisplay } from '~/components/user-avatar';
import { TaskPublishView } from './TaskPublish';
import { TaskValidateView } from './TaskValidate';
import { TaskStatusView } from './TaskStatus';
import { TaskLog } from './TaskLog';
export const TaskView = React.memo((props) => {
    const { task } = props;
    const view = task instanceof BgTask ?
        task.meta.kind.match(whenValidate => React.createElement(TaskValidateView, { target: whenValidate }), whenPublish => React.createElement(TaskPublishView, { task: whenPublish })) :
        task instanceof BgTaskPublic ?
            task.meta.kind.match(whenValidate => React.createElement(TaskValidateView, { target: whenValidate }), whenPublish => React.createElement(TaskPublishView, { task: whenPublish })) : null;
    const [cancelled, setCancelled] = useState(false);
    const [showLog, setShowLog] = useState(false);
    // TODO Incorporate also task.history, which has a list of status, maybe on Hover of the current status
    // which will then display the rest of them. Or by clicking and opening it in a large view.
    const [tryCancel, { pending: cancelling }] = useTasksServiceTryCancel({
        onSuccess: () => setCancelled(true)
    });
    const onCancel = useCallback(() => {
        tryCancel(task.id);
    }, []);
    const onToggleHistory = useCallback(() => {
        setShowLog(!showLog);
    }, [showLog]);
    return (React.createElement(Card, { elevation: 1, style: {
            padding: '10px 10px 0px 10px',
            margin: 5,
            display: 'flex',
            justifyContent: 'space-between'
        } },
        React.createElement("div", { style: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: '5px 1px'
            } },
            view,
            React.createElement(TaskStatusView, { status: task.status, cancelled: cancelled }),
            React.createElement("div", { style: {
                    display: 'flex',
                    alignItems: 'center'
                } },
                React.createElement(UserAvatar, { user: task.meta.initiator, display: UserAvatarDisplay.Line }),
                React.createElement(Tooltip, { content: 'Open log', openOnTargetFocus: false },
                    React.createElement(Button, { icon: 'history', onClick: onToggleHistory, minimal: true, style: { marginLeft: 5 } }))),
            React.createElement(TaskLog, { open: showLog, taskID: task.id })),
        task.meta.cancellable && !cancelled &&
            React.createElement(Tooltip, { content: 'Try cancelling the task', intent: 'danger', openOnTargetFocus: false },
                React.createElement(Button, { autoFocus: false, disabled: cancelling, loading: cancelling, onClick: onCancel, icon: 'cross', minimal: true, style: { marginLeft: 5 } }))));
});
