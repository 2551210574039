// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.models
import { AccountIdentity } from '../../../shared';
/**
  *
  *  Get details about a particular organization.
  *
  *  @id Org identity
  *
  * Class io.protoforce.auth.orgservice.models:GetOrgInput
  *
  * Defined at /auth/organization.service.model @ 229:5
  */
export class OrgServiceGetOrgInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return OrgServiceGetOrgInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OrgServiceGetOrgInput.RTTI_FQN;
    }
    toJSON() {
        return OrgServiceGetOrgInput.toJSON(this);
    }
}
OrgServiceGetOrgInput.RTTI_CLASS = 'GetOrgInput';
OrgServiceGetOrgInput.RTTI_FQN = 'io.protoforce.auth.orgservice.models:GetOrgInput';
OrgServiceGetOrgInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
OrgServiceGetOrgInput.fromJSON = (value) => {
    return new OrgServiceGetOrgInput({
        id: AccountIdentity.fromJSON(value.id)
    });
};
