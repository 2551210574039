// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.models
import { AccountIdentity } from '../../../shared';
/**
  *
  *  Brief information about a set of requested organizations.
  *
  *  @orgs List of orgs for which the details have to be returned
  *
  *  Note: returns no error if IDs are not found, just an empty array
  *
  * Class io.protoforce.auth.orgservice.models:GetOrgsBriefInput
  *
  * Defined at /auth/organization.service.model @ 222:5
  */
export class OrgServiceGetOrgsBriefInput {
    constructor(data) {
        this.orgs = data.orgs;
    }
    get RTTI_CLASS() {
        return OrgServiceGetOrgsBriefInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OrgServiceGetOrgsBriefInput.RTTI_FQN;
    }
    toJSON() {
        return OrgServiceGetOrgsBriefInput.toJSON(this);
    }
}
OrgServiceGetOrgsBriefInput.RTTI_CLASS = 'GetOrgsBriefInput';
OrgServiceGetOrgsBriefInput.RTTI_FQN = 'io.protoforce.auth.orgservice.models:GetOrgsBriefInput';
OrgServiceGetOrgsBriefInput.toJSON = (value) => {
    return {
        orgs: value.orgs.map((e) => e.toJSON())
    };
};
OrgServiceGetOrgsBriefInput.fromJSON = (value) => {
    return new OrgServiceGetOrgsBriefInput({
        orgs: value.orgs.map((e) => AccountIdentity.fromJSON(e))
    });
};
