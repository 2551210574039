// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { AccountID } from '../shared';
import { BgTaskId } from './BgTaskId';
import { BgTaskType } from './BgTaskType';
/**
  * Class io.protoforce.projects:BgTaskMetadata
  *
  * Defined at /projects/tasks.model @ 23:1
  */
export class BgTaskMetadata {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.cancellable = false;
                this.depends = [];
            }
            return;
        }
        this.kind = data.kind;
        this.cancellable = data.cancellable;
        this.initiator = data.initiator;
        this.depends = data.depends;
    }
    get RTTI_CLASS() {
        return BgTaskMetadata.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskMetadata.RTTI_FQN;
    }
    toJSON() {
        return BgTaskMetadata.toJSON(this);
    }
}
BgTaskMetadata.RTTI_CLASS = 'BgTaskMetadata';
BgTaskMetadata.RTTI_FQN = 'io.protoforce.projects:BgTaskMetadata';
BgTaskMetadata.toJSON = (value) => {
    return {
        kind: value.kind.toJSON(),
        cancellable: value.cancellable,
        initiator: value.initiator.toJSON(),
        depends: value.depends.map((e) => e.toJSON())
    };
};
BgTaskMetadata.fromJSON = (value) => {
    return new BgTaskMetadata({
        kind: BgTaskType.fromJSON(value.kind),
        cancellable: value.cancellable,
        initiator: AccountID.fromJSON(value.initiator),
        depends: value.depends.map((e) => BgTaskId.fromJSON(e))
    });
};
