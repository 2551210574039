// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.authservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { authServiceRTTI } from '../../authservice/methods/authServiceRTTI';
import { authServiceRefresh } from '../../authservice/methods/authServiceRefresh';
import { useCallback } from 'react';
/**
  *
  *  Refresh your access token, providing the refresh token.
  *  Returns an access token which is now valid again, or failure
  *  if something went wrong.
  *
  * Defined at /auth/auth.service.model @ 170:6
  */
export function useAuthServiceRefresh(options, context) {
    const clientTransport = useClientTransport(authServiceRTTI, options);
    const request = useCallback((tokens) => {
        return authServiceRefresh({ tokens }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
