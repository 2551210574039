// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { ProjectDataCodec } from './codecs/ProjectDataCodec';
import { ProjectDataImpl } from './ProjectDataImpl';
import { ProjectID } from '../shared';
/**
  * Class io.protoforce.projects:Project
  *
  * Defined at projects.domain @ 58:1
  */
export class Project {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.isPublic = false;
            }
            return;
        }
        this.name = data.name;
        this.isPublic = data.isPublic;
        this.repository = data.repository;
        this.website = data.website;
        this.description = data.description;
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return Project.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Project.RTTI_FQN;
    }
    static fromProjectData(from, id) {
        return new Project({
            id: id,
            name: from.name,
            isPublic: from.isPublic,
            repository: from.repository,
            website: from.website,
            description: from.description
        });
    }
    loadProjectData(from) {
        this.name = from.name;
        this.isPublic = from.isPublic;
        this.repository = from.repository;
        this.website = from.website;
        this.description = from.description;
    }
    toProjectData() {
        return new ProjectDataImpl({
            name: this.name,
            isPublic: this.isPublic,
            repository: this.repository,
            website: this.website,
            description: this.description
        });
    }
    toJSON() {
        return Project.toJSON(this);
    }
}
Project.RTTI_CLASS = 'Project';
Project.RTTI_FQN = 'io.protoforce.projects:Project';
Project.toJSON = (value) => {
    return {
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined,
        id: value.id.toJSON()
    };
};
Project.fromJSON = (value) => {
    return new Project({
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined,
        id: ProjectID.fromJSON(value.id)
    });
};
ProjectDataCodec.register(Project.RTTI_FQN, Project.fromJSON);
