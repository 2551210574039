// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Enum io.protoforce.projects:MavenProjectLayout
  *
  * Defined at /projects/manifests/java.model @ 9:1
  */
export var MavenProjectLayout;
(function (MavenProjectLayout) {
    /**
      * Defined at /projects/manifests/java.model @ 10:5
      */
    MavenProjectLayout[MavenProjectLayout["PLAIN"] = 0] = "PLAIN";
    /**
      * Defined at /projects/manifests/java.model @ 11:5
      */
    MavenProjectLayout[MavenProjectLayout["MAVEN"] = 1] = "MAVEN";
})(MavenProjectLayout || (MavenProjectLayout = {}));
