// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { SignupWithBaseCodec } from './codecs/SignupWithBaseCodec';
import { SignupWithBaseImpl } from './SignupWithBaseImpl';
/**
  *
  *  Sign up with an email
  *
  * Class io.protoforce.auth:SignupWithEmail
  *
  * Defined at /auth/signup.model @ 25:1
  */
export class SignupWithEmail {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.email = '';
                this.pass = '';
            }
            return;
        }
        this.displayName = data.displayName;
        this.name = data.name;
        this.location = data.location;
        this.code = data.code;
        this.email = data.email;
        this.pass = data.pass;
    }
    get RTTI_CLASS() {
        return SignupWithEmail.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWithEmail.RTTI_FQN;
    }
    static fromSignupWithBase(from, email, pass) {
        return new SignupWithEmail({
            email: email,
            pass: pass,
            displayName: from.displayName,
            name: from.name,
            location: from.location,
            code: from.code
        });
    }
    loadSignupWithBase(from) {
        this.displayName = from.displayName;
        this.name = from.name;
        this.location = from.location;
        this.code = from.code;
    }
    toSignupWithBase() {
        return new SignupWithBaseImpl({
            displayName: this.displayName,
            name: this.name,
            location: this.location,
            code: this.code
        });
    }
    static fromSignupWithPhone(from, email) {
        return new SignupWithEmail({
            email: email,
            displayName: from.displayName,
            name: from.name,
            location: from.location,
            code: from.code,
            pass: from.pass
        });
    }
    loadSignupWithPhone(from) {
        this.displayName = from.displayName;
        this.name = from.name;
        this.location = from.location;
        this.code = from.code;
        this.pass = from.pass;
    }
    toJSON() {
        return SignupWithEmail.toJSON(this);
    }
}
SignupWithEmail.RTTI_CLASS = 'SignupWithEmail';
SignupWithEmail.RTTI_FQN = 'io.protoforce.auth:SignupWithEmail';
SignupWithEmail.toJSON = (value) => {
    return {
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined,
        email: value.email,
        pass: value.pass
    };
};
SignupWithEmail.fromJSON = (value) => {
    return new SignupWithEmail({
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined,
        email: value.email,
        pass: value.pass
    });
};
SignupWithBaseCodec.register(SignupWithEmail.RTTI_FQN, SignupWithEmail.fromJSON);
