// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { AccountID, UserLevelHelper } from '../shared';
import { NotificationID } from './NotificationID';
import { NotificationbaseCodec } from './codecs/NotificationbaseCodec';
import { NotificationbaseImpl } from './NotificationbaseImpl';
import { IRTZonedDateTime } from '../../../irt';
/**
  *
  *  User's level has changed within the organization
  *
  * Class io.protoforce.notifications:LevelChangedNotification
  *
  * Defined at /notifications/org.model @ 36:1
  */
export class LevelChangedNotification {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
                this.level = UserLevelHelper.all[0];
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
        this.org = data.org;
        this.level = data.level;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return LevelChangedNotification.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LevelChangedNotification.RTTI_FQN;
    }
    static fromNotificationbase(from, org, level) {
        return new LevelChangedNotification({
            org: org,
            level: level,
            id: from.id,
            at: from.at
        });
    }
    loadNotificationbase(from) {
        this.id = from.id;
        this.at = from.at;
    }
    static fromAccessChangedNotification(from, org, level) {
        return new LevelChangedNotification({
            org: org,
            level: level,
            id: from.id,
            at: from.at
        });
    }
    loadAccessChangedNotification(from) {
        this.id = from.id;
        this.at = from.at;
    }
    static fromUserJoinedNotification(from, level) {
        return new LevelChangedNotification({
            level: level,
            id: from.id,
            at: from.at,
            org: from.org
        });
    }
    loadUserJoinedNotification(from) {
        this.id = from.id;
        this.at = from.at;
        this.org = from.org;
    }
    toNotificationbase() {
        return new NotificationbaseImpl({
            id: this.id,
            at: this.at
        });
    }
    static fromInviteAcceptedNotification(from, level) {
        return new LevelChangedNotification({
            level: level,
            id: from.id,
            at: from.at,
            org: from.org
        });
    }
    loadInviteAcceptedNotification(from) {
        this.id = from.id;
        this.at = from.at;
        this.org = from.org;
    }
    toJSON() {
        return LevelChangedNotification.toJSON(this);
    }
}
LevelChangedNotification.RTTI_CLASS = 'LevelChangedNotification';
LevelChangedNotification.RTTI_FQN = 'io.protoforce.notifications:LevelChangedNotification';
LevelChangedNotification.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON(),
        org: value.org.toJSON(),
        level: UserLevelHelper.toJSON(value.level)
    };
};
LevelChangedNotification.fromJSON = (value) => {
    return new LevelChangedNotification({
        id: NotificationID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at),
        org: AccountID.fromJSON(value.org),
        level: UserLevelHelper.fromJSON(value.level)
    });
};
NotificationbaseCodec.register(LevelChangedNotification.RTTI_FQN, LevelChangedNotification.fromJSON);
