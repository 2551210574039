// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:SBTOptions
  *
  * Defined at /projects/manifests/scala.model @ 39:1
  */
export class SBTOptions {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.useDotty = false;
            }
            return;
        }
        this.useDotty = data.useDotty;
    }
    get RTTI_CLASS() {
        return SBTOptions.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SBTOptions.RTTI_FQN;
    }
    toJSON() {
        return SBTOptions.toJSON(this);
    }
}
SBTOptions.RTTI_CLASS = 'SBTOptions';
SBTOptions.RTTI_FQN = 'io.protoforce.projects:SBTOptions';
SBTOptions.toJSON = (value) => {
    return {
        useDotty: value.useDotty
    };
};
SBTOptions.fromJSON = (value) => {
    return new SBTOptions({
        useDotty: value.useDotty
    });
};
