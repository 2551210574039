import React, { useMemo } from 'react';
import { Lang } from '@protoforce/shared';
import { FileIcon, LangFileIcons } from '~/components/general/FileIcon';
export const TaskValidateView = React.memo((props) => {
    const { target, style } = props;
    const lang = useMemo(() => {
        return target.match(whenParse => undefined, whenTypeScript => Lang.TypeScript, whenScala => Lang.Scala, whenJava => Lang.Java);
    }, [target]);
    return (React.createElement("div", { style: Object.assign({ display: 'flex', alignItems: 'center' }, (style || {})) },
        typeof lang !== 'undefined' && React.createElement(FileIcon, { key: lang, file: LangFileIcons[lang], style: { marginRight: 5, width: 16, height: 16 } }),
        "Validate ",
        typeof lang !== 'undefined' ? Lang[lang] : 'Sources'));
});
