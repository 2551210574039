// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.models
import { fromEitherJSON } from '../../../../../irt';
import { NotFoundFailure } from '../../../shared';
import { Organization } from '../../Organization';
export class OrgServicegetOrgMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return Organization.fromJSON(r);
        }, (l) => {
            return NotFoundFailure.fromJSON(l);
        });
    }
}
