// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/PublishingCredentials:MavenRepository (member of ADT io.protoforce.projects:PublishingCredentials)
  *
  * Defined at /projects/publishing.model @ 178:5
  */
export class PublishingCredentialsMavenRepository {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.realm = '';
                this.user = '';
                this.password = '';
            }
            return;
        }
        this.realm = data.realm;
        this.user = data.user;
        this.password = data.password;
    }
    get RTTI_CLASS() {
        return PublishingCredentialsMavenRepository.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingCredentialsMavenRepository.RTTI_FQN;
    }
    toJSON() {
        return PublishingCredentialsMavenRepository.toJSON(this);
    }
}
PublishingCredentialsMavenRepository.RTTI_CLASS = 'MavenRepository';
PublishingCredentialsMavenRepository.RTTI_FQN = 'io.protoforce.projects/PublishingCredentials:MavenRepository';
PublishingCredentialsMavenRepository.toJSON = (value) => {
    return {
        realm: value.realm,
        user: value.user,
        password: value.password
    };
};
PublishingCredentialsMavenRepository.fromJSON = (value) => {
    return new PublishingCredentialsMavenRepository({
        realm: value.realm,
        user: value.user,
        password: value.password
    });
};
