// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * npm config set //$name:always-auth true
  *  npm config set //$name:username $username
  *  npm config set //$name:email $email
  *  npm config set //$name:_password $passwordbase64
  *
  * Class io.protoforce.projects/PublishingCredentials.NPMCredentials:Password (member of ADT io.protoforce.projects/PublishingCredentials:NPMCredentials)
  *
  * Defined at /projects/publishing.model @ 191:9
  */
export class PublishingCredentialsNPMCredentialsPassword {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.user = '';
                this.password = '';
                this.email = '';
            }
            return;
        }
        this.scope = data.scope;
        this.user = data.user;
        this.password = data.password;
        this.email = data.email;
    }
    get RTTI_CLASS() {
        return PublishingCredentialsNPMCredentialsPassword.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingCredentialsNPMCredentialsPassword.RTTI_FQN;
    }
    toJSON() {
        return PublishingCredentialsNPMCredentialsPassword.toJSON(this);
    }
}
PublishingCredentialsNPMCredentialsPassword.RTTI_CLASS = 'Password';
PublishingCredentialsNPMCredentialsPassword.RTTI_FQN = 'io.protoforce.projects/PublishingCredentials.NPMCredentials:Password';
PublishingCredentialsNPMCredentialsPassword.toJSON = (value) => {
    return {
        scope: typeof value.scope !== 'undefined' && value.scope != null ? value.scope : undefined,
        user: value.user,
        password: value.password,
        email: value.email
    };
};
PublishingCredentialsNPMCredentialsPassword.fromJSON = (value) => {
    return new PublishingCredentialsNPMCredentialsPassword({
        scope: typeof value.scope !== 'undefined' && value.scope != null ? value.scope : undefined,
        user: value.user,
        password: value.password,
        email: value.email
    });
};
