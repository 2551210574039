import { EncoderProxy, DecoderProxy } from '../codec';
/**
 * Implementation of a JSON codec, with the final type being a string
 */
export class JSONCodec {
    constructor(pretty = false) {
        this.pretty = pretty;
    }
    /**
     * Encodes the object to a JSON format
     * @param data The datum to be encoded
     * @param classRef Class reference which is used for encoding for non scalar types
     * @param withProxy If true, allows to encode via proxy, which allows to use native JSON before emitting the final string
     */
    encode(data, classRef, withProxy) {
        const prepared = (classRef ? classRef.toJSON(data) : data);
        const finalizer = (data) => JSONCodec.encodeFinalizer(data, this.pretty);
        if (withProxy) {
            return new EncoderProxy(prepared, finalizer);
        }
        else {
            return finalizer(prepared);
        }
    }
    /**
     * Decodes the string into a final type
     * @param data String representation of an encoded object
     * @param classRef Class reference which is used for decoding for non scalar types
     * @param withProxy If true, the string will be decoded into an intermediate, native JSON type, before converting to an actual class when needed
     */
    decode(data, classRef, withProxy) {
        const parsed = JSON.parse(data);
        const finalizer = (data) => JSONCodec.decodeFinalizer(data, classRef);
        if (withProxy) {
            return new DecoderProxy(parsed, finalizer);
        }
        else {
            return finalizer(parsed);
        }
    }
}
/**
 * Helper method to finalize the encoding. Mostly used in websockets, where double encoding
 * is avoided by using intermediate - proxy state.
 * @param data Intermediate object state, e.g. JSON
 * @param pretty Whether the finalizer should use pretty print when formatting the text
 */
JSONCodec.encodeFinalizer = (data, pretty) => { return JSON.stringify(data, undefined, pretty ? 2 : undefined); };
/**
 * Helper method to finalize the decoding. Mostly used in websockets, where double decoding is avoided
 * by using intermediate - proxy state.
 * @param data Intermediate object state
 * @param classRef Clas reference which is used for decoding for non scalar types
 */
JSONCodec.decodeFinalizer = (data, classRef) => { return classRef ? classRef.fromJSON(data) : data; };
