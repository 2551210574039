// tslint:disable
// eslint-disable
// package io.protoforce.docs.imagesearchservice.methods
import { ImageSearchServiceUnsplashRandomInput } from '../models/ImageSearchServiceUnsplashRandomInput';
import { ImageSearchServiceunsplashRandomMethodOutputHelper } from '../models/ImageSearchServiceunsplashRandomMethodOutputHelper';
import { imageSearchServiceRTTI } from './imageSearchServiceRTTI';
import { imageSearchServiceUnsplashRandomName } from './imageSearchServiceUnsplashRandomName';
export function imageSearchServiceUnsplashRandom(transport, context) {
    return transport.send(imageSearchServiceRTTI, imageSearchServiceUnsplashRandomName, new ImageSearchServiceUnsplashRandomInput(), {
        in: ImageSearchServiceUnsplashRandomInput,
        out: ImageSearchServiceunsplashRandomMethodOutputHelper,
        alternative: true,
        context
    });
}
