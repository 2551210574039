var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useAccountServiceGetAccount } from '@protoforce/auth';
export function useAccount(account) {
    const _a = useAccountServiceGetAccount(account), { data } = _a, rest = __rest(_a, ["data"]);
    const res = data ? data.match(whenUser => ({
        user: whenUser.user,
        organization: undefined,
        pinned: whenUser.pinned.projects
    }), whenOrg => ({
        user: undefined,
        organization: whenOrg.organization,
        pinned: whenOrg.pinned.projects
    })) : {
        user: undefined,
        organization: undefined,
        pinned: undefined
    };
    return Object.assign(Object.assign(Object.assign({}, res), { data }), rest);
}
