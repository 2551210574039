// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { GenericFailureCode } from './GenericFailureCode';
export class GenericFailureCodeHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return GenericFailureCodeHelper.toString(this.value);
    }
    static toString(e) {
        return GenericFailureCode[e];
    }
    static fromString(e) {
        return GenericFailureCode[e];
    }
    get RTTI_CLASS() {
        return GenericFailureCodeHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GenericFailureCodeHelper.RTTI_FQN;
    }
    toJSON() {
        return GenericFailureCodeHelper.toJSON(this.value);
    }
}
GenericFailureCodeHelper.RTTI_CLASS = 'GenericFailureCode';
GenericFailureCodeHelper.RTTI_FQN = 'io.protoforce.shared:GenericFailureCode';
GenericFailureCodeHelper.all = [
    GenericFailureCode.NotFound,
    GenericFailureCode.AlreadyExists,
    GenericFailureCode.InvalidParams,
    GenericFailureCode.ConditionNotMet,
    GenericFailureCode.Expired,
    GenericFailureCode.Unauthorized,
    GenericFailureCode.UnexpectedException,
    GenericFailureCode.NotSupported,
    GenericFailureCode.Unknown
];
GenericFailureCodeHelper.toJSON = (value) => {
    return GenericFailureCode[value];
};
GenericFailureCodeHelper.fromJSON = (value) => {
    switch (value) {
        case 'NotFound': return GenericFailureCode.NotFound;
        case 'AlreadyExists': return GenericFailureCode.AlreadyExists;
        case 'InvalidParams': return GenericFailureCode.InvalidParams;
        case 'ConditionNotMet': return GenericFailureCode.ConditionNotMet;
        case 'Expired': return GenericFailureCode.Expired;
        case 'Unauthorized': return GenericFailureCode.Unauthorized;
        case 'UnexpectedException': return GenericFailureCode.UnexpectedException;
        case 'NotSupported': return GenericFailureCode.NotSupported;
        case 'Unknown': return GenericFailureCode.Unknown;
        default: throw new CodecError(`Unexpected value for enum GenericFailureCode: '${value}'`);
    }
};
