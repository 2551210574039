// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:License
  *
  * Defined at /projects/manifests/build.model @ 14:1
  */
export class License {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.url = '';
            }
            return;
        }
        this.name = data.name;
        this.url = data.url;
    }
    get RTTI_CLASS() {
        return License.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return License.RTTI_FQN;
    }
    toJSON() {
        return License.toJSON(this);
    }
}
License.RTTI_CLASS = 'License';
License.RTTI_FQN = 'io.protoforce.projects:License';
License.toJSON = (value) => {
    return {
        name: value.name,
        url: value.url
    };
};
License.fromJSON = (value) => {
    return new License({
        name: value.name,
        url: value.url
    });
};
