// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BuildManifestCodec } from './codecs/BuildManifestCodec';
import { BuildManifestImpl } from './BuildManifestImpl';
import { Common } from './Common';
import { ProjectNamingRule } from './ProjectNamingRule';
import { TypeScriptExtensionSpec } from './TypeScriptExtensionSpec';
import { TypeScriptPackagedOptions } from './TypeScriptPackagedOptions';
import { TypeScriptProjectLayoutHelper } from './TypeScriptProjectLayoutHelper';
/**
  * Class io.protoforce.projects:TypeScriptBuildManifest
  *
  * Defined at /projects/manifests/typescript.model @ 80:1
  */
export class TypeScriptBuildManifest {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.layout = TypeScriptProjectLayoutHelper.all[0];
            }
            return;
        }
        this.common = data.common;
        this.naming = data.naming;
        this.extensions = data.extensions;
        this.layout = data.layout;
        this.packaged = data.packaged;
    }
    get RTTI_CLASS() {
        return TypeScriptBuildManifest.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptBuildManifest.RTTI_FQN;
    }
    static fromBuildManifest(from, extensions, layout, packaged) {
        return new TypeScriptBuildManifest({
            extensions: extensions,
            layout: layout,
            packaged: packaged,
            common: from.common,
            naming: from.naming
        });
    }
    loadBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    static fromJavaBuildManifest(from, extensions, layout, packaged) {
        return new TypeScriptBuildManifest({
            extensions: extensions,
            layout: layout,
            packaged: packaged,
            common: from.common,
            naming: from.naming
        });
    }
    loadJavaBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toBuildManifest() {
        return new BuildManifestImpl({
            common: this.common,
            naming: this.naming
        });
    }
    static fromScalaBuildManifest(from, extensions, layout, packaged) {
        return new TypeScriptBuildManifest({
            extensions: extensions,
            layout: layout,
            packaged: packaged,
            common: from.common,
            naming: from.naming
        });
    }
    loadScalaBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toJSON() {
        return TypeScriptBuildManifest.toJSON(this);
    }
}
TypeScriptBuildManifest.RTTI_CLASS = 'TypeScriptBuildManifest';
TypeScriptBuildManifest.RTTI_FQN = 'io.protoforce.projects:TypeScriptBuildManifest';
TypeScriptBuildManifest.toJSON = (value) => {
    return {
        common: value.common.toJSON(),
        naming: value.naming.toJSON(),
        extensions: value.extensions.toJSON(),
        layout: TypeScriptProjectLayoutHelper.toJSON(value.layout),
        packaged: value.packaged.toJSON()
    };
};
TypeScriptBuildManifest.fromJSON = (value) => {
    return new TypeScriptBuildManifest({
        common: Common.fromJSON(value.common),
        naming: ProjectNamingRule.fromJSON(value.naming),
        extensions: TypeScriptExtensionSpec.fromJSON(value.extensions),
        layout: TypeScriptProjectLayoutHelper.fromJSON(value.layout),
        packaged: TypeScriptPackagedOptions.fromJSON(value.packaged)
    });
};
BuildManifestCodec.register(TypeScriptBuildManifest.RTTI_FQN, TypeScriptBuildManifest.fromJSON);
