// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.methods
import { AccountIdentity } from '../../../shared';
import { UserServiceGetUserInput } from '../models/UserServiceGetUserInput';
import { UserServicegetUserMethodOutputHelper } from '../models/UserServicegetUserMethodOutputHelper';
import { userServiceGetUserName } from './userServiceGetUserName';
import { userServiceRTTI } from './userServiceRTTI';
export function userServiceGetUser(input, transport, context) {
    return transport.send(userServiceRTTI, userServiceGetUserName, new UserServiceGetUserInput(Object.assign(Object.assign({}, input), { id: AccountIdentity.from(input.id) })), {
        in: UserServiceGetUserInput,
        out: UserServicegetUserMethodOutputHelper,
        alternative: true,
        context
    });
}
