// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.models
import { FileUpload } from '../../../shared';
/**
  * Class io.protoforce.docs.internaldocsservice.models:UploadDocumentationFileInput
  *
  * Defined at docs.domain @ 22:5
  */
export class InternalDocsServiceUploadDocumentationFileInput {
    constructor(data) {
        this.article = data.article;
        this.data = data.data;
    }
    get RTTI_CLASS() {
        return InternalDocsServiceUploadDocumentationFileInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return InternalDocsServiceUploadDocumentationFileInput.RTTI_FQN;
    }
    toJSON() {
        return InternalDocsServiceUploadDocumentationFileInput.toJSON(this);
    }
}
InternalDocsServiceUploadDocumentationFileInput.RTTI_CLASS = 'UploadDocumentationFileInput';
InternalDocsServiceUploadDocumentationFileInput.RTTI_FQN = 'io.protoforce.docs.internaldocsservice.models:UploadDocumentationFileInput';
InternalDocsServiceUploadDocumentationFileInput.toJSON = (value) => {
    return {
        article: value.article,
        data: value.data.toJSON()
    };
};
InternalDocsServiceUploadDocumentationFileInput.fromJSON = (value) => {
    return new InternalDocsServiceUploadDocumentationFileInput({
        article: value.article,
        data: FileUpload.fromJSON(value.data)
    });
};
