// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.methods
import { TasksServiceTryCancelInput } from '../models/TasksServiceTryCancelInput';
import { TasksServicetryCancelMethodOutputHelper } from '../models/TasksServicetryCancelMethodOutputHelper';
import { tasksServiceRTTI } from './tasksServiceRTTI';
import { tasksServiceTryCancelName } from './tasksServiceTryCancelName';
export function tasksServiceTryCancel(input, transport, context) {
    return transport.send(tasksServiceRTTI, tasksServiceTryCancelName, new TasksServiceTryCancelInput(input), {
        in: TasksServiceTryCancelInput,
        out: TasksServicetryCancelMethodOutputHelper,
        alternative: true,
        context
    });
}
