// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { ProjectID } from '../shared';
/**
  * Class io.protoforce.tasks/TasksFilter:MineInProject (member of ADT io.protoforce.tasks:TasksFilter)
  *
  * Defined at tasks.domain @ 32:5
  */
export class TasksFilterMineInProject {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.project = data.project;
    }
    get RTTI_CLASS() {
        return TasksFilterMineInProject.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksFilterMineInProject.RTTI_FQN;
    }
    toJSON() {
        return TasksFilterMineInProject.toJSON(this);
    }
}
TasksFilterMineInProject.RTTI_CLASS = 'MineInProject';
TasksFilterMineInProject.RTTI_FQN = 'io.protoforce.tasks/TasksFilter:MineInProject';
TasksFilterMineInProject.toJSON = (value) => {
    return {
        project: value.project.toJSON()
    };
};
TasksFilterMineInProject.fromJSON = (value) => {
    return new TasksFilterMineInProject({
        project: ProjectID.fromJSON(value.project)
    });
};
