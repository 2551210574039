// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.methods
import { AccountServiceGetAccountInput } from '../models/AccountServiceGetAccountInput';
import { AccountServicegetAccountMethodOutputHelper } from '../models/AccountServicegetAccountMethodOutputHelper';
import { AccountIdentity } from '../../../shared';
import { accountServiceGetAccountName } from './accountServiceGetAccountName';
import { accountServiceRTTI } from './accountServiceRTTI';
export function accountServiceGetAccount(input, transport, context) {
    return transport.send(accountServiceRTTI, accountServiceGetAccountName, new AccountServiceGetAccountInput(Object.assign(Object.assign({}, input), { id: AccountIdentity.from(input.id) })), {
        in: AccountServiceGetAccountInput,
        out: AccountServicegetAccountMethodOutputHelper,
        alternative: true,
        context
    });
}
