// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/ValidationTarget:Parse (member of ADT io.protoforce.projects:ValidationTarget)
  *
  * Defined at /projects/tasks.model @ 29:5
  */
export class ValidationTargetParse {
    get RTTI_CLASS() {
        return ValidationTargetParse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ValidationTargetParse.RTTI_FQN;
    }
    toJSON() {
        return ValidationTargetParse.toJSON(this);
    }
}
ValidationTargetParse.RTTI_CLASS = 'Parse';
ValidationTargetParse.RTTI_FQN = 'io.protoforce.projects/ValidationTarget:Parse';
ValidationTargetParse.toJSON = (value) => {
    return {};
};
ValidationTargetParse.fromJSON = (value) => {
    return new ValidationTargetParse();
};
