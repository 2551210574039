// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:PublishingTaskMeta
  *
  * Defined at /projects/publishing.model @ 13:1
  */
export class PublishingTaskMeta {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
    }
    get RTTI_CLASS() {
        return PublishingTaskMeta.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTaskMeta.RTTI_FQN;
    }
    toJSON() {
        return PublishingTaskMeta.toJSON(this);
    }
}
PublishingTaskMeta.RTTI_CLASS = 'PublishingTaskMeta';
PublishingTaskMeta.RTTI_FQN = 'io.protoforce.projects:PublishingTaskMeta';
PublishingTaskMeta.toJSON = (value) => {
    return {
        name: value.name
    };
};
PublishingTaskMeta.fromJSON = (value) => {
    return new PublishingTaskMeta({
        name: value.name
    });
};
