// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.models
import { OrganizationBrief } from '../../OrganizationBrief';
export class OrgServicegetOrgsBriefMethodOutputHelper {
    static toJSON(value) {
        return value.map((e) => e.toJSON());
    }
    static fromJSON(value) {
        return value.map((e) => OrganizationBrief.fromJSON(e));
    }
}
