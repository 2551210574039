import { useMemo, useContext } from 'react';
import CryptoJS from 'crypto-js';
import { FileUpload, Hash, HashAlgorithm } from '@protoforce/shared';
import { ServicesTransportContext } from '@protoforce/irt-react';
import { useFileUploadService } from '@protoforce/auth';
import { useInternalDocsService } from '@protoforce/docs';
import { uploadPresignedFile } from './shared';
import { formatError } from './utils';
function genericUpload(file, serviceCall) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (f) {
            // this == reader, get the loaded file "result"
            const result = this.result;
            // convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
            const file_wordArr = CryptoJS.lib.WordArray.create(result);
            // calculate SHA1 hash
            const sha1_hash = CryptoJS.SHA1(file_wordArr);
            const sha1 = sha1_hash.toString();
            const uploadData = new FileUpload();
            uploadData.name = file.name;
            uploadData.hash = new Hash();
            uploadData.hash.alg = HashAlgorithm.SHA1;
            uploadData.hash.value = sha1;
            uploadData.contentType = file.type;
            uploadData.size = file.size;
            serviceCall(uploadData)
                .then(res => res.match((success) => {
                uploadPresignedFile(success.uploadPath, file)
                    .then(() => {
                    resolve({ url: success.viewPath, data: uploadData });
                }, err => {
                    console.error(err);
                    reject(formatError(err));
                })
                    .catch(err => {
                    console.error(err);
                    reject(formatError(err));
                });
            }, (failure) => {
                console.error(failure.toJSON());
                reject(formatError(failure));
            }))
                .catch(err => {
                console.error(err);
                reject(formatError(err));
            });
        };
        reader.readAsArrayBuffer(file);
    });
}
// TODO Optimize those functions, use not service but separate functions
export function uploadForBlog(file, service, postID) {
    return genericUpload(file, (fu) => service.blogUpload(fu, postID));
}
export function useBlogUploader(postID, transport) {
    const contextTransport = useContext(ServicesTransportContext);
    const fileService = useFileUploadService(transport || contextTransport);
    return useMemo(() => {
        return (blockID, f) => {
            return new Promise((resolve, reject) => {
                uploadForBlog(f, fileService, postID)
                    .then((res) => {
                    resolve({ url: res.url, name: f.name });
                })
                    .catch((reason) => reject(reason));
            });
        };
    }, [fileService, postID]);
}
export function uploadForProject(file, service, projectID) {
    return genericUpload(file, (fu) => service.projectUpload(fu, projectID));
}
export function useProjectUploader(projectID, transport) {
    const contextTransport = useContext(ServicesTransportContext);
    const fileService = useFileUploadService(transport || contextTransport);
    // const fileService
    return useMemo(() => {
        return (blockID, f) => {
            return new Promise((resolve, reject) => {
                uploadForProject(f, fileService, projectID)
                    .then((res) => {
                    resolve({ url: res.url, name: f.name });
                })
                    .catch((reason) => reject(reason));
            });
        };
    }, [fileService, projectID]);
}
export function uploaderInternal(file, service, location, blockID) {
    const extension = file.name.substr(file.name.lastIndexOf('.'));
    return genericUpload(file, (fu) => {
        const fuo = FileUpload.fromJSON(fu.toJSON());
        fuo.name = blockID + extension;
        return service.upload(location, fuo);
    });
}
function docsUploaderInternal(file, service, article, blockID) {
    const extension = file.name.substr(file.name.lastIndexOf('.'));
    return genericUpload(file, (fu) => {
        const fuo = FileUpload.fromJSON(fu.toJSON());
        fuo.name = blockID + extension;
        return service.uploadDocumentationFile(article, fuo);
    });
}
function fileToBase64(f, custom, onDone) {
    const fr = new FileReader();
    fr.onload = function (ev) {
        if (!ev.target) {
            console.error('Loading failed?');
            onDone('');
            return;
        }
        const result = this.result;
        const res = result.split(',')[1]; // encoding is messed up here, so we fix it
        onDone(`data:${f.type};${custom};base64,${res}`);
    };
    fr.onerror = error => {
        console.error('Error: ', error);
        onDone('');
    };
    fr.readAsDataURL(f);
}
export function useInternalUploader(location, transport) {
    const service = useInternalDocsService(transport);
    return useMemo(() => {
        return (blockID, f) => {
            return new Promise((resolve, reject) => {
                uploaderInternal(f, service, location, blockID)
                    .then((res) => {
                    // For internal doc uploads, we just swap with base64 file, until it gets swapped
                    // with the actual dynamic import during saving.
                    fileToBase64(f, `pfleinject=${res.url}`, (content) => resolve({ url: content, name: f.name }));
                })
                    .catch((reason) => reject(reason));
            });
        };
    }, [service, location]);
}
export function useInternalDocsUploader(currentArticle, transport) {
    const service = useInternalDocsService(transport);
    return useMemo(() => {
        return (blockID, f) => {
            return new Promise((resolve, reject) => {
                docsUploaderInternal(f, service, currentArticle(), blockID)
                    .then((res) => {
                    // For internal doc uploads, we just swap with base64 file, until it gets swapped
                    // with the actual dynamic import during saving.
                    fileToBase64(f, `pfleinject=${res.url}`, (content) => resolve({ url: content, name: f.name }));
                })
                    .catch((reason) => reject(reason));
            });
        };
    }, [service, currentArticle]);
}
