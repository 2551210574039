import { sha256 } from 'js-sha256';
import { ConsoleLogger, LogLevel } from '@protoforce/irt';
import { CONFIG } from './config';
export const logger = new ConsoleLogger(LogLevel[CONFIG.services.loggingLevel]);
export function sanitizePassword(pass) {
    return sha256(pass);
}
export function sanitizeProjectName(name) {
    if (!name) {
        return '';
    }
    return name.replace(/[\W_]+/g, '-');
}
export function sanitizeOrganizationName(name) {
    if (!name) {
        return '';
    }
    return name.replace(/[\W_]+/g, '-');
}
export function sanitizeUserName(name) {
    if (!name) {
        return '';
    }
    return name.replace(/[\W_]+/g, '-');
}
export function sanitizeGroupName(name) {
    return name.toLowerCase().replace(/[^0-9a-zA-Z]/g, '-');
}
export function uploadPresignedFile(url, file) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    // xhr.setRequestHeader('Content-Length', file.size.toString());
    // xhr.setRequestHeader('Access-Control-Allow-Origin', 'Origin');
    // xhr.setRequestHeader('Access-Control-Allow-Method', 'PUT');
    const HTTP_SUCCESS = 200;
    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            if (xhr.status === HTTP_SUCCESS) {
                resolve(HTTP_SUCCESS);
            }
        };
        xhr.onerror = () => {
            reject(0);
        };
        xhr.send(file);
    });
}
// export const serializeInterfaceSafe = (o: SerializableInterface | undefined) => o ? ({
//     [o.RTTI_FQN]: o.toJSON(),
// }) : undefined;
// export const serializeInterface = (o: SerializableInterface) => ({
//   [o.RTTI_FQN]: o.toJSON(),
// });
