// tslint:disable
// eslint-disable
// package io.protoforce.auth.fileuploadservice.models
import { FileUpload, PostID } from '../../../shared';
/**
  *
  *  Upload a file related to a blog post.
  *
  *  @data File details
  *  @post Post ID which the file belongs to
  *
  * Class io.protoforce.auth.fileuploadservice.models:BlogUploadInput
  *
  * Defined at /auth/upload.service.model @ 23:5
  */
export class FileUploadServiceBlogUploadInput {
    constructor(data) {
        this.data = data.data;
        this.post = data.post;
    }
    get RTTI_CLASS() {
        return FileUploadServiceBlogUploadInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FileUploadServiceBlogUploadInput.RTTI_FQN;
    }
    toJSON() {
        return FileUploadServiceBlogUploadInput.toJSON(this);
    }
}
FileUploadServiceBlogUploadInput.RTTI_CLASS = 'BlogUploadInput';
FileUploadServiceBlogUploadInput.RTTI_FQN = 'io.protoforce.auth.fileuploadservice.models:BlogUploadInput';
FileUploadServiceBlogUploadInput.toJSON = (value) => {
    return {
        data: value.data.toJSON(),
        post: value.post.toJSON()
    };
};
FileUploadServiceBlogUploadInput.fromJSON = (value) => {
    return new FileUploadServiceBlogUploadInput({
        data: FileUpload.fromJSON(value.data),
        post: PostID.fromJSON(value.post)
    });
};
