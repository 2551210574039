// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { ScalaBuildManifest } from './ScalaBuildManifest';
/**
  * Class io.protoforce.projects/ValidationTarget:Scala (member of ADT io.protoforce.projects:ValidationTarget)
  *
  * Defined at /projects/tasks.model @ 34:5
  */
export class ValidationTargetScala {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
    }
    get RTTI_CLASS() {
        return ValidationTargetScala.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ValidationTargetScala.RTTI_FQN;
    }
    toJSON() {
        return ValidationTargetScala.toJSON(this);
    }
}
ValidationTargetScala.RTTI_CLASS = 'Scala';
ValidationTargetScala.RTTI_FQN = 'io.protoforce.projects/ValidationTarget:Scala';
ValidationTargetScala.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON()
    };
};
ValidationTargetScala.fromJSON = (value) => {
    return new ValidationTargetScala({
        manifest: ScalaBuildManifest.fromJSON(value.manifest)
    });
};
