// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
import { fromEitherJSON } from '../../../../../irt';
import { GenericFailure } from '../../../shared';
import { TaskLog } from '../../../projects';
export class TasksServicegetLogMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return typeof r === 'undefined' || r == null ? null : r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return typeof r !== 'undefined' && r != null ? TaskLog.fromJSON(r) : undefined;
        }, (l) => {
            return GenericFailure.fromJSON(l);
        });
    }
}
