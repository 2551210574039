// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ScalaExtensionSpecAll } from './ScalaExtensionSpecAll';
import { ScalaExtensionSpecExcluded } from './ScalaExtensionSpecExcluded';
/**
  * ADT io.protoforce.projects:ScalaExtensionSpec
  *
  * Defined at /projects/manifests/scala.model @ 29:1
  */
export class ScalaExtensionSpec {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ScalaExtensionSpec must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.all !== 'undefined') {
            this.value = value.all;
        }
        else if (typeof value.excluded !== 'undefined') {
            this.value = value.excluded;
        }
        else {
            throw new Error('ADT ScalaExtensionSpec constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenAll, whenExcluded) {
        const v = this.value;
        if (v instanceof ScalaExtensionSpecAll) {
            return whenAll(v);
        }
        else if (v instanceof ScalaExtensionSpecExcluded) {
            return whenExcluded(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenAll, whenExcluded) {
        const v = this.value;
        if (v instanceof ScalaExtensionSpecAll) {
            return whenAll(v);
        }
        else if (v instanceof ScalaExtensionSpecExcluded) {
            return whenExcluded(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromAll(value) {
        return new ScalaExtensionSpec({ all: value });
    }
    static fromExcluded(value) {
        return new ScalaExtensionSpec({ excluded: value });
    }
    static from(value) {
        return value instanceof ScalaExtensionSpec ? value : new ScalaExtensionSpec(undefined, value);
    }
    get RTTI_CLASS() {
        return ScalaExtensionSpec.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaExtensionSpec.RTTI_FQN;
    }
    toJSON() {
        return ScalaExtensionSpec.toJSON(this);
    }
}
ScalaExtensionSpec.RTTI_CLASS = 'ScalaExtensionSpec';
ScalaExtensionSpec.RTTI_FQN = 'io.protoforce.projects:ScalaExtensionSpec';
ScalaExtensionSpec.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaExtensionSpecAll) {
        return JSONConverter.withTypeNested(v, 'All');
    }
    else if (v instanceof ScalaExtensionSpecExcluded) {
        return JSONConverter.withTypeNested(v, 'Excluded');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ScalaExtensionSpec.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'All': {
            const vc = val;
            return ScalaExtensionSpec.fromAll(ScalaExtensionSpecAll.fromJSON(vc));
        }
        case 'Excluded': {
            const vc = val;
            return ScalaExtensionSpec.fromExcluded(ScalaExtensionSpecExcluded.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ScalaExtensionSpec'`);
    }
};
