// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.models
import { UserBrief } from '../../UserBrief';
export class UserServicegetUsersBriefMethodOutputHelper {
    static toJSON(value) {
        return value.map((e) => e.toJSON());
    }
    static fromJSON(value) {
        return value.map((e) => UserBrief.fromJSON(e));
    }
}
