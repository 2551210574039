// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
import { BgTaskId } from '../../../projects';
/**
  * Class io.protoforce.tasks.tasksservice.models:TryCancelInput
  *
  * Defined at tasks.domain @ 46:5
  */
export class TasksServiceTryCancelInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return TasksServiceTryCancelInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksServiceTryCancelInput.RTTI_FQN;
    }
    toJSON() {
        return TasksServiceTryCancelInput.toJSON(this);
    }
}
TasksServiceTryCancelInput.RTTI_CLASS = 'TryCancelInput';
TasksServiceTryCancelInput.RTTI_FQN = 'io.protoforce.tasks.tasksservice.models:TryCancelInput';
TasksServiceTryCancelInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
TasksServiceTryCancelInput.fromJSON = (value) => {
    return new TasksServiceTryCancelInput({
        id: BgTaskId.fromJSON(value.id)
    });
};
