// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.models
import { AccountIdentity } from '../../../shared';
/**
  *
  *  Brief information about a set of requested users.
  *
  *  @users List of users for which the details have to be returned
  *
  *  Note: returns no error if IDs are not found, just an empty array
  *
  * Class io.protoforce.auth.userservice.models:GetUsersBriefInput
  *
  * Defined at /auth/user.service.model @ 63:5
  */
export class UserServiceGetUsersBriefInput {
    constructor(data) {
        this.users = data.users;
    }
    get RTTI_CLASS() {
        return UserServiceGetUsersBriefInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserServiceGetUsersBriefInput.RTTI_FQN;
    }
    toJSON() {
        return UserServiceGetUsersBriefInput.toJSON(this);
    }
}
UserServiceGetUsersBriefInput.RTTI_CLASS = 'GetUsersBriefInput';
UserServiceGetUsersBriefInput.RTTI_FQN = 'io.protoforce.auth.userservice.models:GetUsersBriefInput';
UserServiceGetUsersBriefInput.toJSON = (value) => {
    return {
        users: value.users.map((e) => e.toJSON())
    };
};
UserServiceGetUsersBriefInput.fromJSON = (value) => {
    return new UserServiceGetUsersBriefInput({
        users: value.users.map((e) => AccountIdentity.fromJSON(e))
    });
};
