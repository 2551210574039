import { IRTError } from '../../types';
import { formatHTTPRequest } from './common';
import { formatHTTPResponse } from '.';
export class ClientTransportError extends IRTError {
    constructor(message) {
        super(message);
    }
}
export class ClientTransportRequestError extends ClientTransportError {
    constructor(message, req) {
        super(message);
        this.req = req;
    }
}
export class ClientTransportResponseError extends ClientTransportError {
    constructor(message, req, res) {
        super(message);
        this.req = req;
        this.res = res;
    }
}
export class SocketClientTransportError extends ClientTransportError {
    constructor(error) {
        super(`Socket client transport error: ${JSON.stringify(error, undefined, 2)}`);
    }
}
export class SocketClientCriticalError extends ClientTransportError {
    constructor(error) {
        super(`Socket client critical error: ${JSON.stringify(error, undefined, 2)}`);
    }
}
export function formatClientError(error, extended) {
    if (error instanceof ClientTransportRequestError) {
        if (extended) {
            let res = `${error.message}.\n${formatHTTPRequest(error.req)}`;
            if (error.stack) {
                res += `\n\nStack:\n${error.stack}`;
            }
            return res;
        }
        else {
            return error.message;
        }
    }
    if (error instanceof ClientTransportResponseError) {
        if (extended) {
            let res = `${error.message}.\nRequest:\n${formatHTTPRequest(error.req)}\n\nResponse:\n${formatHTTPResponse(error.res)}`;
            if (error.stack) {
                res += `\n\nStack:\n${error.stack}`;
            }
            return res;
        }
        else {
            return error.message;
        }
    }
    if (extended) {
        let res = error.message;
        if (error.stack) {
            res += `\n\nStack:\n${error.stack}`;
        }
        return res;
    }
    return error.message;
}
