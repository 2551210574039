// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { JSONConverter, CodecError } from '../../../irt';
import { SignupWithEmail } from './SignupWithEmail';
import { SignupWithGithub } from './SignupWithGithub';
import { SignupWithGoogle } from './SignupWithGoogle';
import { SignupWithPhone } from './SignupWithPhone';
/**
  * ADT io.protoforce.auth:SignupWith
  *
  * Defined at /auth/signup.model @ 53:1
  */
export class SignupWith {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT SignupWith must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.email !== 'undefined') {
            this.value = value.email;
        }
        else if (typeof value.google !== 'undefined') {
            this.value = value.google;
        }
        else if (typeof value.github !== 'undefined') {
            this.value = value.github;
        }
        else if (typeof value.phone !== 'undefined') {
            this.value = value.phone;
        }
        else {
            throw new Error('ADT SignupWith constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenEmail, whenGoogle, whenGithub, whenPhone) {
        const v = this.value;
        if (v instanceof SignupWithEmail) {
            return whenEmail(v);
        }
        else if (v instanceof SignupWithGoogle) {
            return whenGoogle(v);
        }
        else if (v instanceof SignupWithGithub) {
            return whenGithub(v);
        }
        else if (v instanceof SignupWithPhone) {
            return whenPhone(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenEmail, whenGoogle, whenGithub, whenPhone) {
        const v = this.value;
        if (v instanceof SignupWithEmail) {
            return whenEmail(v);
        }
        else if (v instanceof SignupWithGoogle) {
            return whenGoogle(v);
        }
        else if (v instanceof SignupWithGithub) {
            return whenGithub(v);
        }
        else if (v instanceof SignupWithPhone) {
            return whenPhone(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromEmail(value) {
        return new SignupWith({ email: value });
    }
    static fromGoogle(value) {
        return new SignupWith({ google: value });
    }
    static fromGithub(value) {
        return new SignupWith({ github: value });
    }
    static fromPhone(value) {
        return new SignupWith({ phone: value });
    }
    static from(value) {
        return value instanceof SignupWith ? value : new SignupWith(undefined, value);
    }
    get RTTI_CLASS() {
        return SignupWith.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWith.RTTI_FQN;
    }
    toJSON() {
        return SignupWith.toJSON(this);
    }
}
SignupWith.RTTI_CLASS = 'SignupWith';
SignupWith.RTTI_FQN = 'io.protoforce.auth:SignupWith';
SignupWith.toJSON = (value) => {
    const v = value.value;
    if (v instanceof SignupWithEmail) {
        return JSONConverter.withTypeField(v, '$type', 'email');
    }
    else if (v instanceof SignupWithGoogle) {
        return JSONConverter.withTypeField(v, '$type', 'google');
    }
    else if (v instanceof SignupWithGithub) {
        return JSONConverter.withTypeField(v, '$type', 'github');
    }
    else if (v instanceof SignupWithPhone) {
        return JSONConverter.withTypeField(v, '$type', 'phone');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
SignupWith.fromJSON = (value) => {
    const keyarr = Array.isArray(value.$type) ? value.$type : [value.$type];
    const key = keyarr[0];
    const val = keyarr.length > 1 ?
        Object.assign({}, value, { '$type': keyarr.length === 2 ? keyarr[1] : keyarr.slice(1) }) : value;
    switch (key) {
        case 'email': {
            const vc = val;
            return SignupWith.fromEmail(SignupWithEmail.fromJSON(vc));
        }
        case 'google': {
            const vc = val;
            return SignupWith.fromGoogle(SignupWithGoogle.fromJSON(vc));
        }
        case 'github': {
            const vc = val;
            return SignupWith.fromGithub(SignupWithGithub.fromJSON(vc));
        }
        case 'phone': {
            const vc = val;
            return SignupWith.fromPhone(SignupWithPhone.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'SignupWith'`);
    }
};
