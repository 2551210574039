import React, { useState } from 'react';
import { PaletteNineDual } from '~/core/colors';
import { MTRandom } from '~/core/random';
import { AvatarSize, useCapitalNameLetters, AvatarBorderRadius } from './common';
export const AccountAvatarOrg = React.memo((props) => {
    const dim = props.size || AvatarSize.Normal;
    const palette = PaletteNineDual;
    const [rnd] = useState(new MTRandom());
    rnd.setSeed(MTRandom.hash(props.id.id));
    const name = useCapitalNameLetters(props);
    const fontSize = 64;
    const scale = 1; // size / fontSize;
    const colors = palette[rnd.nextInt32([0, palette.length - 1])];
    return (React.createElement("div", { style: {
            overflow: 'hidden',
            borderRadius: AvatarBorderRadius,
            position: 'relative',
            width: dim,
            height: dim
        } },
        React.createElement("svg", { viewBox: '0 0 128 128', style: {
                borderRadius: AvatarBorderRadius
            }, width: dim, height: dim },
            React.createElement("rect", { x: '0', y: '0', width: '128', height: '128', style: {
                    fill: colors[0]
                } }),
            name && (React.createElement("text", { x: '64', y: '64', transform: `scale(${scale}, ${scale})`, fill: 'white', style: { cursor: 'default' }, fontSize: fontSize, textAnchor: 'middle', alignmentBaseline: 'central' }, name)))));
});
