// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ProtoforceTargetGit } from './ProtoforceTargetGit';
/**
  * ADT io.protoforce.projects:ProtoforceTarget
  *
  * Defined at /projects/publishing.model @ 91:1
  */
export class ProtoforceTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ProtoforceTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.git !== 'undefined') {
            this.value = value.git;
        }
        else {
            throw new Error('ADT ProtoforceTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenGit) {
        const v = this.value;
        if (v instanceof ProtoforceTargetGit) {
            return whenGit(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenGit) {
        const v = this.value;
        if (v instanceof ProtoforceTargetGit) {
            return whenGit(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromGit(value) {
        return new ProtoforceTarget({ git: value });
    }
    static from(value) {
        return value instanceof ProtoforceTarget ? value : new ProtoforceTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return ProtoforceTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ProtoforceTarget.RTTI_FQN;
    }
    toJSON() {
        return ProtoforceTarget.toJSON(this);
    }
}
ProtoforceTarget.RTTI_CLASS = 'ProtoforceTarget';
ProtoforceTarget.RTTI_FQN = 'io.protoforce.projects:ProtoforceTarget';
ProtoforceTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ProtoforceTargetGit) {
        return JSONConverter.withTypeNested(v, 'Git');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ProtoforceTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Git': {
            const vc = val;
            return ProtoforceTarget.fromGit(ProtoforceTargetGit.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ProtoforceTarget'`);
    }
};
