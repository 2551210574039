// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.methods
import { AuthServiceLoginInput } from '../models/AuthServiceLoginInput';
import { AuthServiceLoginOutput } from '../models/AuthServiceLoginOutput';
import { LoginWith } from '../../LoginWith';
import { authServiceLoginName } from './authServiceLoginName';
import { authServiceRTTI } from './authServiceRTTI';
export function authServiceLogin(input, transport, context) {
    return transport.send(authServiceRTTI, authServiceLoginName, new AuthServiceLoginInput(Object.assign(Object.assign({}, input), { data: LoginWith.from(input.data) })), {
        in: AuthServiceLoginInput,
        out: AuthServiceLoginOutput,
        context
    });
}
