// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.models
/**
  * Class io.protoforce.docs.internaldocsservice.models:SaveDocumentationInput
  *
  * Defined at docs.domain @ 21:5
  */
export class InternalDocsServiceSaveDocumentationInput {
    constructor(data) {
        this.structure = data.structure;
        this.articles = data.articles;
        this.searchIndex = data.searchIndex;
    }
    get RTTI_CLASS() {
        return InternalDocsServiceSaveDocumentationInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return InternalDocsServiceSaveDocumentationInput.RTTI_FQN;
    }
    toJSON() {
        return InternalDocsServiceSaveDocumentationInput.toJSON(this);
    }
}
InternalDocsServiceSaveDocumentationInput.RTTI_CLASS = 'SaveDocumentationInput';
InternalDocsServiceSaveDocumentationInput.RTTI_FQN = 'io.protoforce.docs.internaldocsservice.models:SaveDocumentationInput';
InternalDocsServiceSaveDocumentationInput.toJSON = (value) => {
    return {
        structure: value.structure,
        articles: Object.keys(value.articles)
            .reduce((previous, current) => {
            previous[current] = value.articles[current];
            return previous;
        }, {}),
        searchIndex: value.searchIndex
    };
};
InternalDocsServiceSaveDocumentationInput.fromJSON = (value) => {
    return new InternalDocsServiceSaveDocumentationInput({
        structure: value.structure,
        articles: Object.keys(value.articles)
            .reduce((previous, current) => {
            previous[current] = value.articles[current];
            return previous;
        }, {}),
        searchIndex: value.searchIndex
    });
};
