// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  ConfirmMFAData holds collected data according to the ConfirmMFAResponse.
  *
  * Class io.protoforce.auth:ConfirmMFAData
  *
  * Defined at /auth/auth.service.model @ 57:1
  */
export class ConfirmMFAData {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.codes = {};
                this.mfaToken = '';
            }
            return;
        }
        this.codes = data.codes;
        this.mfaToken = data.mfaToken;
    }
    get RTTI_CLASS() {
        return ConfirmMFAData.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ConfirmMFAData.RTTI_FQN;
    }
    toJSON() {
        return ConfirmMFAData.toJSON(this);
    }
}
ConfirmMFAData.RTTI_CLASS = 'ConfirmMFAData';
ConfirmMFAData.RTTI_FQN = 'io.protoforce.auth:ConfirmMFAData';
ConfirmMFAData.toJSON = (value) => {
    return {
        codes: Object.keys(value.codes)
            .reduce((previous, current) => {
            previous[current] = value.codes[current];
            return previous;
        }, {}),
        mfaToken: value.mfaToken
    };
};
ConfirmMFAData.fromJSON = (value) => {
    return new ConfirmMFAData({
        codes: Object.keys(value.codes)
            .reduce((previous, current) => {
            previous[current] = value.codes[current];
            return previous;
        }, {}),
        mfaToken: value.mfaToken
    });
};
