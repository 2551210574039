// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { FailureBase } from './FailureBase';
import { UnknownFailure } from './UnknownFailure';
import { RuntimeError } from '../../../irt';
/**
  * Class io.protoforce.shared:IRTErrorShared
  */
export class IRTErrorShared extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return IRTErrorShared.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return IRTErrorShared.RTTI_FQN;
    }
    toFailureBase() {
        return new FailureBase({
            message: this.message
        });
    }
    toUnknownFailure() {
        return new UnknownFailure({
            message: this.message
        });
    }
    toJSON() {
        return IRTErrorShared.toJSON(this);
    }
}
IRTErrorShared.RTTI_CLASS = 'IRTErrorShared';
IRTErrorShared.RTTI_FQN = 'io.protoforce.shared:IRTErrorShared';
IRTErrorShared.toJSON = (value) => {
    return {
        message: value.message
    };
};
IRTErrorShared.fromJSON = (value) => {
    return new IRTErrorShared({
        message: value.message
    });
};
