// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.models
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  Check account name availability
  *
  *  @name Name of the potential account
  *
  * Class io.protoforce.auth.accountservice.models:CheckAccountNameInput
  *
  * Defined at /auth/account.service.model @ 98:5
  */
export class AccountServiceCheckAccountNameInput {
    constructor(data) {
        this.name = data.name;
    }
    get RTTI_CLASS() {
        return AccountServiceCheckAccountNameInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountServiceCheckAccountNameInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.GET,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'account'
                    },
                    {
                        type: 'word',
                        value: 'check'
                    },
                    {
                        type: 'word',
                        value: 'name'
                    },
                    {
                        type: 'param',
                        field: {
                            name: 'name',
                            ref: {
                                id: 'str',
                                args: []
                            }
                        },
                        path: [],
                        onWire: {
                            type: 'scalar',
                            ref: {
                                id: 'str',
                                args: []
                            },
                        }
                    }
                ]
            },
            body: {
                fields: []
            }
        };
    }
    toJSON() {
        return AccountServiceCheckAccountNameInput.toJSON(this);
    }
}
AccountServiceCheckAccountNameInput.RTTI_CLASS = 'CheckAccountNameInput';
AccountServiceCheckAccountNameInput.RTTI_FQN = 'io.protoforce.auth.accountservice.models:CheckAccountNameInput';
AccountServiceCheckAccountNameInput.toJSON = (value) => {
    return {
        name: value.name
    };
};
AccountServiceCheckAccountNameInput.fromJSON = (value) => {
    return new AccountServiceCheckAccountNameInput({
        name: value.name
    });
};
