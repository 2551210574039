// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice
import { HTTPClientTransport } from '../../../../irt';
import { userServiceGetUser } from './methods/userServiceGetUser';
import { userServiceGetUsersBrief } from './methods/userServiceGetUsersBrief';
import { userServiceRTTI } from './methods/userServiceRTTI';
export class UserServiceClient {
    constructor(transport) {
        this.transport = transport;
    }
    get RTTI_CLASS() {
        return UserServiceClient.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserServiceClient.RTTI_FQN;
    }
    static fromHTTP(endpoint, options) {
        return new UserServiceClient(new HTTPClientTransport(endpoint, options));
    }
    /**
      *
      *  Brief information about a set of requested users.
      *
      *  @users List of users for which the details have to be returned
      *
      *  Note: returns no error if IDs are not found, just an empty array
      *
      * Defined at /auth/user.service.model @ 63:5
      */
    getUsersBrief(users, _context) {
        return userServiceGetUsersBrief({ users }, this.transport, _context);
    }
    /**
      *
      *  Get details about a particular user.
      *
      *  @id User identity
      *
      * Defined at /auth/user.service.model @ 70:5
      */
    getUser(id, _context) {
        return userServiceGetUser({ id }, this.transport, _context);
    }
}
UserServiceClient.RTTI_CLASS = userServiceRTTI.RTTI_CLASS;
UserServiceClient.RTTI_FQN = userServiceRTTI.RTTI_FQN;
