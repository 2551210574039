// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
/**
  * Class io.protoforce.projects/ProtoforceTargetPublic:Git (member of ADT io.protoforce.projects:ProtoforceTargetPublic)
  *
  * Defined at /projects/publishing.model @ 103:5
  */
export class ProtoforceTargetPublicGit {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.id = data.id;
        this.meta = data.meta;
    }
    get RTTI_CLASS() {
        return ProtoforceTargetPublicGit.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ProtoforceTargetPublicGit.RTTI_FQN;
    }
    static fromGitSourceTarget(from, id, meta) {
        return new ProtoforceTargetPublicGit({
            id: id,
            meta: meta,
            target: from.target,
            layout: from.layout
        });
    }
    loadGitSourceTarget(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    toJSON() {
        return ProtoforceTargetPublicGit.toJSON(this);
    }
}
ProtoforceTargetPublicGit.RTTI_CLASS = 'Git';
ProtoforceTargetPublicGit.RTTI_FQN = 'io.protoforce.projects/ProtoforceTargetPublic:Git';
ProtoforceTargetPublicGit.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON()
    };
};
ProtoforceTargetPublicGit.fromJSON = (value) => {
    return new ProtoforceTargetPublicGit({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta)
    });
};
