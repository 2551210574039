// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { IRTZonedDateTime } from '../../../irt';
import { NotificationID } from './NotificationID';
import { NotificationbaseCodec } from './codecs/NotificationbaseCodec';
export class NotificationbaseImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return NotificationbaseImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotificationbaseImpl.RTTI_FQN;
    }
    toNotificationbase() {
        return new NotificationbaseImpl({
            id: this.id,
            at: this.at
        });
    }
    toJSON() {
        return NotificationbaseImpl.toJSON(this);
    }
}
NotificationbaseImpl.RTTI_CLASS = 'Notificationbase';
NotificationbaseImpl.RTTI_FQN = 'io.protoforce.notifications:Notificationbase';
NotificationbaseImpl.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON()
    };
};
NotificationbaseImpl.fromJSON = (value) => {
    return new NotificationbaseImpl({
        id: NotificationID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at)
    });
};
NotificationbaseCodec.register(NotificationbaseImpl.RTTI_FQN, NotificationbaseImpl.fromJSON);
