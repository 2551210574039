// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { ProjectDataCodec } from './codecs/ProjectDataCodec';
export class ProjectDataImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.isPublic = false;
            }
            return;
        }
        this.name = data.name;
        this.isPublic = data.isPublic;
        this.repository = data.repository;
        this.website = data.website;
        this.description = data.description;
    }
    get RTTI_CLASS() {
        return ProjectDataImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ProjectDataImpl.RTTI_FQN;
    }
    toProjectData() {
        return new ProjectDataImpl({
            name: this.name,
            isPublic: this.isPublic,
            repository: this.repository,
            website: this.website,
            description: this.description
        });
    }
    toJSON() {
        return ProjectDataImpl.toJSON(this);
    }
}
ProjectDataImpl.RTTI_CLASS = 'ProjectData';
ProjectDataImpl.RTTI_FQN = 'io.protoforce.projects:ProjectData';
ProjectDataImpl.toJSON = (value) => {
    return {
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined
    };
};
ProjectDataImpl.fromJSON = (value) => {
    return new ProjectDataImpl({
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined
    });
};
ProjectDataCodec.register(ProjectDataImpl.RTTI_FQN, ProjectDataImpl.fromJSON);
