/**
 * Generic IRTError class which is used as a basis for all other IRT errors
 */
export class IRTError extends Error {
    constructor(message) {
        // 'Error' breaks prototype chain here
        super(message);
        // restore prototype chain
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            // @ts-ignore
            this.__proto__ = actualProto;
        }
    }
}
/**
 * Abstract error class for all errors occurring in runtime
 */
export class RuntimeError extends IRTError {
    constructor(value) {
        super(value.message);
        this.message = value.message;
    }
    throw() {
        throw this;
    }
}
/**
 * Invalid parameters error
 */
export class InvalidParamsError extends IRTError {
    constructor(message) {
        super(`Invalid params error: ${message}`);
    }
}
/**
 * Codec error
 */
export class CodecError extends IRTError {
    constructor(message) {
        super(`Codec error: ${message}`);
    }
}
/**
 * Network operations error
 */
export class NetworkError extends IRTError {
    constructor(message) {
        super(`Network error: ${message}`);
    }
}
