import React, { useMemo, useContext, useCallback } from 'react';
import { useImageSearchService } from '@protoforce/docs';
import { ServicesTransportContext } from '@protoforce/irt-react';
import { ProjectID } from '@protoforce/shared';
import { defaultAllowedExtensions } from '~/components/project/CodeSandbox';
import { createEditorOptions, RIDEditor } from '~/components/docs/RIDEditor';
import { CONFIG } from './config';
import { useProjectUploader } from './uploader';
import { showSuccessToast } from '~/core';
function getDocumentExtension(projectID) {
    const pid = projectID ? projectID : new ProjectID();
    return (props) => {
        const { file, readonly, onChange } = props;
        const uploader = useProjectUploader(pid);
        const options = useDocEditorOptions(readonly, uploader);
        const content = file.content;
        const doc = useMemo(() => {
            if (content.type !== 'local' || !content.data) {
                return undefined;
            }
            try {
                return JSON.parse(content.data);
            }
            catch (_a) {
                console.error(`Document is not in valid format: `, content);
                return undefined;
            }
        }, [content.type, content.type === 'local' ? content.data : undefined]);
        const onDocChanged = useCallback((d) => {
            if (content.type !== 'local') {
                console.warn('Trying to edit file where content is not local');
                return;
            }
            content.data = JSON.stringify(d);
            onChange(file);
        }, [onChange, content]);
        if (file.content.type !== 'local') {
            return React.createElement("span", null, "Non local content is not supported for document extension.");
        }
        return React.createElement(RIDEditor, { doc: doc, options: options, onUpdated: onDocChanged });
    };
}
export function useSandboxOptions(templateName, readonly, projectID, other) {
    return useMemo(() => {
        return [
            Object.assign({ allowCopyContent: CONFIG.wysiwig, allowedExtensions: CONFIG.wysiwig
                    ? [...defaultAllowedExtensions, '.js', '.ts', '.scala', '.gql', '.rid']
                    : undefined, extensionsEditor: {
                    ['rid']: getDocumentExtension(projectID)
                }, readonly }, (other || {})),
            templateName
        ];
    }, [templateName, readonly, projectID, other]);
}
export const codePluginConfig = {
    onCopy: (content) => showSuccessToast(`Copied`)
};
export function useDocEditorOptions(readonly, uploader, debug, observe, textConfig, codeConfig) {
    const transport = useContext(ServicesTransportContext);
    const service = useImageSearchService(transport);
    const unsplashSearch = useCallback((filter, page) => {
        return new Promise((resolve, reject) => {
            service.unsplash(filter, page).then(res => res.match(whenRight => {
                resolve(whenRight);
            }, whenLeft => {
                console.error(whenLeft);
                reject(whenLeft.message);
            }), err => {
                console.error(err);
                reject('' + err);
            }).catch(err => {
                console.error(err);
                reject('' + err);
            });
        });
    }, [service]);
    const unsplashRandom = useCallback(() => {
        return new Promise((resolve, reject) => {
            service.unsplashRandom().then(res => res.match(whenRight => {
                resolve(whenRight);
            }, whenLeft => {
                console.error(whenLeft);
                reject(whenLeft.message);
            }), err => {
                console.error(err);
                reject('' + err);
            }).catch(err => {
                console.error(err);
                reject('' + err);
            });
        });
    }, [service]);
    return useMemo(() => {
        return createEditorOptions({
            text: textConfig,
            code: codeConfig || codePluginConfig || true,
            delimiter: true,
            embed: true,
            callout: true,
            previewBreak: true,
            list: true,
            audio: {
                uploader
            },
            image: {
                uploader,
                unsplash: {
                    search: unsplashSearch,
                    random: unsplashRandom
                }
            },
            video: true,
            file: { uploader },
            // https://developers.google.com/maps/documentation/embed/guide
            googleMaps: {
                // TODO Replace this KEY: https://github.com/ProtoForce/protoforce-portal-webui/issues/41
                // https://www.google.com/maps/embed/v1/view?center=-33.8314576%2C151.2074262&
                //    key=AIzaSyD9HrlRuI1Ani0-MTZ7pvzxwxi4pgW0BCY&zoom=15.36
                apiKey: CONFIG.googleAPIKey
            },
            other: [],
        }, debug, readonly, observe);
    }, [uploader, unsplashSearch, unsplashRandom, readonly, debug, textConfig, codeConfig, observe]);
}
