import React, { useState, useEffect } from 'react';
import { Lang } from '@protoforce/shared';
import { findFileIcon } from '../../icons';
export const LangFileIcons = {
    [Lang.CSharp]: '.cs',
    [Lang.Golang]: '.go',
    [Lang.Scala]: '.scala',
    [Lang.TypeScript]: '.js',
    [Lang.Curl]: '.sh',
    [Lang.Java]: '.java',
    [Lang.Python]: '.py',
    [Lang.PHP]: '.php',
    [Lang.Ruby]: '.ruby',
    [Lang.Swift]: '.swift',
    [Lang.Kotlin]: '.kt',
    [Lang.Rust]: '.rs',
    [Lang.CPP]: '.cpp',
    [Lang.Protobuf]: '.proto'
};
export const FileIcon = React.memo((props) => {
    const { file, width, height, style } = props;
    const [icon, setIcon] = useState(findFileIcon(file));
    useEffect(() => {
        setIcon(findFileIcon(file));
    }, [file]);
    return (React.createElement("img", { src: icon, alt: file, style: Object.assign({ width: width || 18, height: height || 18 }, (style || {})) }));
});
