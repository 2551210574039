// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.userservice
import { UserServiceClient } from '../../userservice/UserServiceClient';
import { useClientTransport } from '../../../../../irt-react';
import { useMemo } from 'react';
/**
  *
  *  Service provides public details of users
  *
  * Defined at /auth/user.service.model @ 55:1
  */
export function useUserService(transport) {
    const clientTransport = useClientTransport(UserServiceClient, { transport });
    return useMemo(() => {
        return new UserServiceClient(clientTransport);
    }, [clientTransport]);
}
