import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { Router } from 'react-router';
import { loadableReady } from '@loadable/component';
import App from './app';
import registerServiceWorker from './registerServiceWorker';
import { CONFIG as config, analytics, FetcherServerConfig } from './core';
// tslint:disable:origin-ordered-imports
// tslint:disable-next-line:no-implicit-dependencies
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
//
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// tslint:enable:origin-ordered-imports
// Import ours last to override stuff
import './styles/index.scss';
import './components/docs/RIDEditor/plugins/code/prism.scss';
import { ClientServerStorage } from './core/storage/useServerStorage';
const root = document.getElementById('root');
const ssr = root && root.innerHTML !== '';
// const ssr = config.ssr;
if (!config.testing && !ssr) {
    Sentry.init({ dsn: config.sentry });
}
const { history, domFunc } = ssr ? {
    history: createBrowserHistory(),
    domFunc: ReactDOM.hydrate
} : {
    history: createBrowserHistory(),
    domFunc: ReactDOM.render
};
loadableReady(() => {
    domFunc(React.createElement(ClientServerStorage, null,
        React.createElement(FetcherServerConfig, null,
            React.createElement(Router, { history: history },
                React.createElement(App, { config: config.services, analytics: analytics })))), document.getElementById('root'));
});
registerServiceWorker();
