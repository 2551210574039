import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AccountName } from '@protoforce/shared';
import { useAuthContext } from '../services/hooks/contexts/useAuth/useAuth';
export function useRouteOrg() {
    const params = useParams();
    if (!params.org || params.org === '') {
        throw new Error(`There must be an :org param in the route for the useRouteOrg hook to work.`);
    }
    const accountID = useMemo(() => {
        return new AccountName({
            name: params.org
        });
    }, [params.org]);
    const { orgs } = useAuthContext();
    const userOrg = useMemo(() => {
        return orgs.find((o) => o.name === accountID.name);
    }, [accountID]);
    if (!userOrg) {
        throw new Error(`Organization ${params.org} is not found in auth context.`);
    }
    return userOrg;
}
