// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  MFASourceKind Sources an MFA can be obtained from.
  *
  * Enum io.protoforce.auth:MFASourceKind
  *
  * Defined at /auth/mfa.model @ 5:1
  */
export var MFASourceKind;
(function (MFASourceKind) {
    /**
      *
      *  Phone SMS.
      *
      * Defined at /auth/mfa.model @ 9:5
      */
    MFASourceKind[MFASourceKind["Phone"] = 0] = "Phone";
    /**
      *
      *  Authenticator App.
      *
      * Defined at /auth/mfa.model @ 13:5
      */
    MFASourceKind[MFASourceKind["App"] = 1] = "App";
    /**
      *
      *  FIDO device.
      *
      * Defined at /auth/mfa.model @ 17:5
      */
    MFASourceKind[MFASourceKind["FIDO"] = 2] = "FIDO";
})(MFASourceKind || (MFASourceKind = {}));
