// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { ActionBaseCodec } from './codecs/ActionBaseCodec';
import { ActionID } from './ActionID';
import { ConfirmActionImpl } from './ConfirmActionImpl';
import { IRTZonedDateTime } from '../../../irt';
export class ActionBaseImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return ActionBaseImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ActionBaseImpl.RTTI_FQN;
    }
    toConfirmAction() {
        return new ConfirmActionImpl({
            id: this.id,
            at: this.at
        });
    }
    toActionBase() {
        return new ActionBaseImpl({
            id: this.id,
            at: this.at
        });
    }
    toJSON() {
        return ActionBaseImpl.toJSON(this);
    }
}
ActionBaseImpl.RTTI_CLASS = 'ActionBase';
ActionBaseImpl.RTTI_FQN = 'io.protoforce.notifications:ActionBase';
ActionBaseImpl.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON()
    };
};
ActionBaseImpl.fromJSON = (value) => {
    return new ActionBaseImpl({
        id: ActionID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at)
    });
};
ActionBaseCodec.register(ActionBaseImpl.RTTI_FQN, ActionBaseImpl.fromJSON);
