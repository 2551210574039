import React, { useCallback } from 'react';
import { Popover, Menu, MenuDivider, MenuItem, Position, Button, Icon, Classes } from '@blueprintjs/core';
import { Link, useHistory } from 'react-router-dom';
import { useAuthServiceLogout } from '@protoforce/auth';
import { AccountAvatar, AvatarSize } from '~/components/account/AccountAvatar';
import { useAuthContext } from '~/core/services/hooks/contexts/useAuth/useAuth';
import { asyncSettings, asyncOrg } from '../../../async';
import { asyncProfile } from '../../../routes/Settings/async';
import { asyncAccount } from '../../../routes/org/async';
export const UserComponent = React.memo((props) => {
    const { user, className } = props;
    const name = user.displayName || user.name;
    const history = useHistory();
    const { logout: logoutLocal } = useAuthContext();
    const [logoutRemote] = useAuthServiceLogout();
    function logout() {
        logoutRemote();
        logoutLocal();
        history.push('/');
    }
    const handleLinkClick = useCallback((e) => e.target.parentElement.parentElement.click(), []);
    function renderMenu() {
        return (React.createElement(Menu, { className: className, "data-testid": 'header-user-menu' },
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: `/${user.name}`, className: 'bp4-menu-item pt-popover-dismiss', onMouseEnter: () => {
                        asyncOrg.preload();
                        asyncAccount.preload();
                    } },
                    React.createElement(Icon, { icon: 'person' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "Your profile"))),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: `/${user.name}?tab=projects`, className: 'bp4-menu-item pt-popover-dismiss', onMouseEnter: () => {
                        asyncOrg.preload();
                        asyncAccount.preload();
                    } },
                    React.createElement(Icon, { icon: 'projects' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "Your projects"))),
            React.createElement(MenuDivider, null),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: `/settings`, className: 'bp4-menu-item pt-popover-dismiss', onMouseEnter: () => {
                        asyncSettings.preload();
                        asyncProfile.preload();
                    } },
                    React.createElement(Icon, { icon: 'cog' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "Settings"))),
            React.createElement(MenuItem, { text: 'Sign out', icon: 'log-out', onClick: logout })));
    }
    return (React.createElement(Popover, { content: renderMenu(), position: Position.BOTTOM_RIGHT },
        React.createElement(Button, { "data-testid": 'header-user-menu-button', minimal: true, icon: React.createElement(AccountAvatar, { id: user.id, account: user, size: AvatarSize.Mini }), rightIcon: 'caret-down', text: name })));
});
