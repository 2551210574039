// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.accountservice
import { AccountServiceGetAccountOutput } from '../../accountservice/models/AccountServiceGetAccountOutput';
import { useClientTransport, useEitherQuery, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { accountServiceGetAccount } from '../../accountservice/methods/accountServiceGetAccount';
import { accountServiceGetAccountName } from '../../accountservice/methods/accountServiceGetAccountName';
import { accountServiceRTTI } from '../../accountservice/methods/accountServiceRTTI';
import { useCallback } from 'react';
export const accountServiceGetAccountTags = [
    '$pf',
    accountServiceRTTI.RTTI_FQN,
    accountServiceGetAccountName
];
/**
  *
  *  Get account public details
  *
  *  @id Account identity
  *
  * Defined at /auth/account.service.model @ 146:5
  */
export function useAccountServiceGetAccount(id, $options, $context) {
    const clientTransport = useClientTransport(accountServiceRTTI, $options);
    return useEitherQuery(useServiceKey(accountServiceRTTI.RTTI_FQN, accountServiceGetAccountName, id)[0], useCallback((id) => accountServiceGetAccount({ id }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: accountServiceGetAccountTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.toJSON(), []),
            fromCache: useCallback(v => AccountServiceGetAccountOutput.fromJSON(v), [])
        } }, ($options || {})), { tags: useMergedTags(accountServiceGetAccountTags) }), id);
}
