// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { GitSourceTargetImpl } from './GitSourceTargetImpl';
import { ProtoforceTargetPublicGit } from './ProtoforceTargetPublicGit';
import { PublishingCredentialsGitCreds } from './PublishingCredentialsGitCreds';
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
/**
  * Class io.protoforce.projects/ProtoforceTarget:Git (member of ADT io.protoforce.projects:ProtoforceTarget)
  *
  * Defined at /projects/publishing.model @ 95:5
  */
export class ProtoforceTargetGit {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.credentials = data.credentials;
        this.id = data.id;
        this.meta = data.meta;
    }
    get RTTI_CLASS() {
        return ProtoforceTargetGit.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ProtoforceTargetGit.RTTI_FQN;
    }
    static fromGitSourceTarget(from, id, meta) {
        return new ProtoforceTargetGit({
            id: id,
            meta: meta,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials
        });
    }
    loadGitSourceTarget(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
    }
    toGitSourceTarget() {
        return new GitSourceTargetImpl({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials
        });
    }
    static fromTypescriptSourceTargetPublicGit(from, credentials) {
        return new ProtoforceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadTypescriptSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromScalaSourceTargetPublicGit(from, credentials) {
        return new ProtoforceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadScalaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromProtoforceTargetPublicGit(from, credentials) {
        return new ProtoforceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromJavaSourceTargetPublicGit(from, credentials) {
        return new ProtoforceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadJavaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toProtoforceTargetPublicGit() {
        return new ProtoforceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            meta: this.meta
        });
    }
    toJSON() {
        return ProtoforceTargetGit.toJSON(this);
    }
}
ProtoforceTargetGit.RTTI_CLASS = 'Git';
ProtoforceTargetGit.RTTI_FQN = 'io.protoforce.projects/ProtoforceTarget:Git';
ProtoforceTargetGit.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        credentials: value.credentials.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON()
    };
};
ProtoforceTargetGit.fromJSON = (value) => {
    return new ProtoforceTargetGit({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        credentials: PublishingCredentialsGitCreds.fromJSON(value.credentials),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta)
    });
};
