// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:MavenOptions
  *
  * Defined at /projects/manifests/java.model @ 14:1
  */
export class MavenOptions {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.encoding = '';
            }
            return;
        }
        this.encoding = data.encoding;
    }
    get RTTI_CLASS() {
        return MavenOptions.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return MavenOptions.RTTI_FQN;
    }
    toJSON() {
        return MavenOptions.toJSON(this);
    }
}
MavenOptions.RTTI_CLASS = 'MavenOptions';
MavenOptions.RTTI_FQN = 'io.protoforce.projects:MavenOptions';
MavenOptions.toJSON = (value) => {
    return {
        encoding: value.encoding
    };
};
MavenOptions.fromJSON = (value) => {
    return new MavenOptions({
        encoding: value.encoding
    });
};
