// tslint:disable
// eslint-disable
// package io.protoforce.notifications.notificationsservice.methods
import { NotificationsServiceGetUpdatesInput } from '../models/NotificationsServiceGetUpdatesInput';
import { NotificationsServicegetUpdatesMethodOutputHelper } from '../models/NotificationsServicegetUpdatesMethodOutputHelper';
import { notificationsServiceGetUpdatesName } from './notificationsServiceGetUpdatesName';
import { notificationsServiceRTTI } from './notificationsServiceRTTI';
export function notificationsServiceGetUpdates(transport, context) {
    return transport.send(notificationsServiceRTTI, notificationsServiceGetUpdatesName, new NotificationsServiceGetUpdatesInput(), {
        in: NotificationsServiceGetUpdatesInput,
        out: NotificationsServicegetUpdatesMethodOutputHelper,
        alternative: true,
        context
    });
}
