// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  LoginWithPhone is a two step login, once tried to login with a phone number
  *  a code will be sent to the phone. Then LoginWithPhone can be used to login.
  *
  * Class io.protoforce.auth:LoginWithPhone
  *
  * Defined at /auth/login.model @ 20:1
  */
export class LoginWithPhone {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.phone = '';
            }
            return;
        }
        this.phone = data.phone;
        this.code = data.code;
    }
    get RTTI_CLASS() {
        return LoginWithPhone.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginWithPhone.RTTI_FQN;
    }
    toJSON() {
        return LoginWithPhone.toJSON(this);
    }
}
LoginWithPhone.RTTI_CLASS = 'LoginWithPhone';
LoginWithPhone.RTTI_FQN = 'io.protoforce.auth:LoginWithPhone';
LoginWithPhone.toJSON = (value) => {
    return {
        phone: value.phone,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    };
};
LoginWithPhone.fromJSON = (value) => {
    return new LoginWithPhone({
        phone: value.phone,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    });
};
