// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BgTaskData } from './BgTaskData';
import { BgTaskId } from './BgTaskId';
import { BgTaskMetadata } from './BgTaskMetadata';
import { BgTaskStatus } from './BgTaskStatus';
import { IRTZonedDateTime } from '../../../irt';
/**
  * Class io.protoforce.projects:BgTask
  *
  * Defined at /projects/tasks.model @ 7:1
  */
export class BgTask {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.created = new IRTZonedDateTime();
                this.history = [];
            }
            return;
        }
        this.created = data.created;
        this.history = data.history;
        this.status = data.status;
        this.id = data.id;
        this.meta = data.meta;
    }
    get createdAsDate() {
        return this.created.toDate();
    }
    set createdAsDate(value) {
        this.created = IRTZonedDateTime.fromDate(value);
    }
    get createdAsString() {
        return this.created.toJSON();
    }
    set createdAsString(value) {
        this.created = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return BgTask.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTask.RTTI_FQN;
    }
    static fromBgTaskData(from, id, meta) {
        return new BgTask({
            id: id,
            meta: meta,
            created: from.created,
            history: from.history,
            status: from.status
        });
    }
    loadBgTaskData(from) {
        this.created = from.created;
        this.history = from.history;
        this.status = from.status;
    }
    toBgTaskData() {
        return new BgTaskData({
            created: this.created,
            history: this.history,
            status: this.status
        });
    }
    static fromBgTaskPublic(from, meta) {
        return new BgTask({
            meta: meta,
            created: from.created,
            history: from.history,
            status: from.status,
            id: from.id
        });
    }
    loadBgTaskPublic(from) {
        this.created = from.created;
        this.history = from.history;
        this.status = from.status;
        this.id = from.id;
    }
    toJSON() {
        return BgTask.toJSON(this);
    }
}
BgTask.RTTI_CLASS = 'BgTask';
BgTask.RTTI_FQN = 'io.protoforce.projects:BgTask';
BgTask.toJSON = (value) => {
    return {
        created: value.created.toJSON(),
        history: value.history.map((e) => e.toJSON()),
        status: value.status.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON()
    };
};
BgTask.fromJSON = (value) => {
    return new BgTask({
        created: IRTZonedDateTime.fromJSON(value.created),
        history: value.history.map((e) => BgTaskStatus.fromJSON(e)),
        status: BgTaskStatus.fromJSON(value.status),
        id: BgTaskId.fromJSON(value.id),
        meta: BgTaskMetadata.fromJSON(value.meta)
    });
};
