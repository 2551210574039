// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { GenericSuccess } from './GenericSuccess';
import { SuccessBaseCodec } from './codecs/SuccessBaseCodec';
export class SuccessBaseImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return SuccessBaseImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SuccessBaseImpl.RTTI_FQN;
    }
    toGenericSuccess() {
        return new GenericSuccess({
            message: this.message
        });
    }
    toSuccessBase() {
        return new SuccessBaseImpl({
            message: this.message
        });
    }
    toJSON() {
        return SuccessBaseImpl.toJSON(this);
    }
}
SuccessBaseImpl.RTTI_CLASS = 'SuccessBase';
SuccessBaseImpl.RTTI_FQN = 'io.protoforce.shared:SuccessBase';
SuccessBaseImpl.toJSON = (value) => {
    return {
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined
    };
};
SuccessBaseImpl.fromJSON = (value) => {
    return new SuccessBaseImpl({
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined
    });
};
SuccessBaseCodec.register(SuccessBaseImpl.RTTI_FQN, SuccessBaseImpl.fromJSON);
