import React from 'react';
export var UniSearchMode;
(function (UniSearchMode) {
    UniSearchMode["All"] = "all";
    UniSearchMode["Users"] = "users";
    UniSearchMode["Projects"] = "projects";
    UniSearchMode["Orgs"] = "orgs";
})(UniSearchMode || (UniSearchMode = {}));
// @ts-ignore
export const UniSearchContext = React.createContext({});
