import React from 'react';
import { ConfirmInviteAction, InviteAcceptedNotification, UserJoinedNotification, LevelChangedNotification, AccessChangedNotification } from '@protoforce/notifications';
import { ActionConfirmInvite } from './ActionConfirmInvite';
import { NotificationAcceptedInvite } from './NotificationAcceptedInvite';
import { NotificationUserJoined } from './NotificationUserJoined';
import { NotificationLevelChanged } from './NotificationLevelChanged';
import { NotificationAccessChanged } from './NotificationAccessChanged';
const notificationRenderers = {
    [ConfirmInviteAction.RTTI_FQN]: ActionConfirmInvite,
    [InviteAcceptedNotification.RTTI_FQN]: NotificationAcceptedInvite,
    [UserJoinedNotification.RTTI_FQN]: NotificationUserJoined,
    [LevelChangedNotification.RTTI_FQN]: NotificationLevelChanged,
    [AccessChangedNotification.RTTI_FQN]: NotificationAccessChanged
};
export function NotificationView(props) {
    const { notif, remove } = props;
    const Renderer = notificationRenderers[notif.value.RTTI_FQN];
    if (Renderer) {
        return React.createElement(Renderer, { notif: notif.value, remove: remove });
    }
    return React.createElement("span", null,
        "Unsupport notification: ",
        notif.RTTI_FQN);
}
