// tslint:disable
// eslint-disable
// package io.protoforce.auth.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class OrganizationDataCodec {
    static register(fqn, ctor) {
        OrganizationDataCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete OrganizationDataCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in OrganizationDataCodec.registry;
    }
    static getRegistered() {
        return Object.keys(OrganizationDataCodec.registry);
    }
}
OrganizationDataCodec.registry = {};
OrganizationDataCodec.fromFQN = (fqn, value) => {
    const ctor = OrganizationDataCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface OrganizationData. Register at OrganizationDataCodec before it can be used.`);
    }
    return ctor(value);
};
OrganizationDataCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return OrganizationDataCodec.fromFQN(key, val);
};
OrganizationDataCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return OrganizationDataCodec.fromFQN(key, val);
};
OrganizationDataCodec.fromJSON = (value) => {
    return OrganizationDataCodec.fromTypeNestedJSON(value);
};
OrganizationDataCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
OrganizationDataCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
OrganizationDataCodec.toJSON = (value) => {
    return OrganizationDataCodec.toTypeNestedJSON(value);
};
