// tslint:disable
// eslint-disable
// package io.protoforce.notifications.notificationsservice.methods
import { NotificationsServiceRejectInput } from '../models/NotificationsServiceRejectInput';
import { NotificationsServicerejectMethodOutputHelper } from '../models/NotificationsServicerejectMethodOutputHelper';
import { notificationsServiceRTTI } from './notificationsServiceRTTI';
import { notificationsServiceRejectName } from './notificationsServiceRejectName';
export function notificationsServiceReject(input, transport, context) {
    return transport.send(notificationsServiceRTTI, notificationsServiceRejectName, new NotificationsServiceRejectInput(input), {
        in: NotificationsServiceRejectInput,
        out: NotificationsServicerejectMethodOutputHelper,
        alternative: true,
        context
    });
}
