// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { UniqueNameStatusHelper } from '../shared';
/**
  * Class io.protoforce.auth:CheckUniqueNameResponse
  *
  * Defined at /auth/account.service.model @ 2:1
  */
export class CheckUniqueNameResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.status = UniqueNameStatusHelper.all[0];
            }
            return;
        }
        this.name = data.name;
        this.status = data.status;
    }
    get RTTI_CLASS() {
        return CheckUniqueNameResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return CheckUniqueNameResponse.RTTI_FQN;
    }
    toJSON() {
        return CheckUniqueNameResponse.toJSON(this);
    }
}
CheckUniqueNameResponse.RTTI_CLASS = 'CheckUniqueNameResponse';
CheckUniqueNameResponse.RTTI_FQN = 'io.protoforce.auth:CheckUniqueNameResponse';
CheckUniqueNameResponse.toJSON = (value) => {
    return {
        name: value.name,
        status: UniqueNameStatusHelper.toJSON(value.status)
    };
};
CheckUniqueNameResponse.fromJSON = (value) => {
    return new CheckUniqueNameResponse({
        name: value.name,
        status: UniqueNameStatusHelper.fromJSON(value.status)
    });
};
