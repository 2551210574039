// tslint:disable
// eslint-disable
// package io.protoforce.auth.fileuploadservice
import { HTTPClientTransport } from '../../../../irt';
import { fileUploadServiceBlogUpload } from './methods/fileUploadServiceBlogUpload';
import { fileUploadServiceProjectUpload } from './methods/fileUploadServiceProjectUpload';
import { fileUploadServiceRTTI } from './methods/fileUploadServiceRTTI';
export class FileUploadServiceClient {
    constructor(transport) {
        this.transport = transport;
    }
    get RTTI_CLASS() {
        return FileUploadServiceClient.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FileUploadServiceClient.RTTI_FQN;
    }
    static fromHTTP(endpoint, options) {
        return new FileUploadServiceClient(new HTTPClientTransport(endpoint, options));
    }
    /**
      *
      *  Upload a file related to a prokect scope.
      *
      *  @data File details
      *  @project Project ID which the file belongs to
      *
      * Defined at /auth/upload.service.model @ 16:5
      */
    projectUpload(data, project, _context) {
        return fileUploadServiceProjectUpload({ data, project }, this.transport, _context);
    }
    /**
      *
      *  Upload a file related to a blog post.
      *
      *  @data File details
      *  @post Post ID which the file belongs to
      *
      * Defined at /auth/upload.service.model @ 23:5
      */
    blogUpload(data, post, _context) {
        return fileUploadServiceBlogUpload({ data, post }, this.transport, _context);
    }
}
FileUploadServiceClient.RTTI_CLASS = fileUploadServiceRTTI.RTTI_CLASS;
FileUploadServiceClient.RTTI_FQN = fileUploadServiceRTTI.RTTI_FQN;
