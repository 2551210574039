// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { Validator } from '../../../irt';
/**
  * Class io.protoforce.shared:Iterator
  *
  * Defined at shared.domain @ 13:1
  */
export class Iterator {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.offset = 0;
                this.limit = 0;
            }
            return;
        }
        this.offset = data.offset;
        this.limit = data.limit;
    }
    get offset() {
        return this._offset;
    }
    set offset(value) {
        Validator.integer(value, 'offset');
        this._offset = value;
    }
    get limit() {
        return this._limit;
    }
    set limit(value) {
        Validator.min(value, 0, 'limit');
        Validator.max(value, 65535, 'limit');
        Validator.integer(value, 'limit');
        this._limit = value;
    }
    get RTTI_CLASS() {
        return Iterator.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Iterator.RTTI_FQN;
    }
    toJSON() {
        return Iterator.toJSON(this);
    }
}
Iterator.RTTI_CLASS = 'Iterator';
Iterator.RTTI_FQN = 'io.protoforce.shared:Iterator';
Iterator.toJSON = (value) => {
    return {
        offset: value.offset,
        limit: value.limit
    };
};
Iterator.fromJSON = (value) => {
    return new Iterator({
        offset: value.offset,
        limit: value.limit
    });
};
