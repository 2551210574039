export const PaletteNineDual = [
    ['#185b8a', '#1f77b4'],
    ['#c3610b', '#ff7f0e'],
    ['#227a22', '#2ca02c'],
    ['#a41e1f', '#d62728'],
    ['#714f91', '#9467bd'],
    ['#6b4239', '#8c564b'],
    ['#ae5b94', '#e377c2'],
    ['#90911a', '#bcbd22'],
    ['#12919e', '#17becf']
];
