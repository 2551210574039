// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  OAuthAccessToken holds a token obtained during the OAuth process
  *
  * Class io.protoforce.auth:OAuthAccessToken
  *
  * Defined at /auth/oauth.model @ 13:1
  */
export class OAuthAccessToken {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.token = '';
            }
            return;
        }
        this.token = data.token;
    }
    get RTTI_CLASS() {
        return OAuthAccessToken.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OAuthAccessToken.RTTI_FQN;
    }
    toJSON() {
        return OAuthAccessToken.toJSON(this);
    }
}
OAuthAccessToken.RTTI_CLASS = 'OAuthAccessToken';
OAuthAccessToken.RTTI_FQN = 'io.protoforce.auth:OAuthAccessToken';
OAuthAccessToken.toJSON = (value) => {
    return {
        token: value.token
    };
};
OAuthAccessToken.fromJSON = (value) => {
    return new OAuthAccessToken({
        token: value.token
    });
};
