export const CONFIG = __CONFIG__;
// export const CONFIG: Config = {
//   services: {
//     restEndpoint: '',
//     wsEndpoint: '',
//     loggingLevel: '',
//   },
//   ssr: true,
// };
export const SDK_VERSION = __SDK_VERSION__;
export const CI_BUILD = __CI_BUILD__;
export const ASSETS_PATH = __ASSETS_PATH__;
