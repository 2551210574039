// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/PublishingTarget:Snapshots (member of ADT io.protoforce.projects:PublishingTarget)
  *
  * Defined at /projects/publishing.model @ 198:5
  */
export class PublishingTargetSnapshots {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.url = '';
            }
            return;
        }
        this.url = data.url;
    }
    get RTTI_CLASS() {
        return PublishingTargetSnapshots.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTargetSnapshots.RTTI_FQN;
    }
    toJSON() {
        return PublishingTargetSnapshots.toJSON(this);
    }
}
PublishingTargetSnapshots.RTTI_CLASS = 'Snapshots';
PublishingTargetSnapshots.RTTI_FQN = 'io.protoforce.projects/PublishingTarget:Snapshots';
PublishingTargetSnapshots.toJSON = (value) => {
    return {
        url: value.url
    };
};
PublishingTargetSnapshots.fromJSON = (value) => {
    return new PublishingTargetSnapshots({
        url: value.url
    });
};
