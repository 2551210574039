// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { AccessLevel } from './AccessLevel';
import { CodecError } from '../../../irt';
export class AccessLevelHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return AccessLevelHelper.toString(this.value);
    }
    static toString(e) {
        return AccessLevel[e];
    }
    static fromString(e) {
        return AccessLevel[e];
    }
    get RTTI_CLASS() {
        return AccessLevelHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccessLevelHelper.RTTI_FQN;
    }
    toJSON() {
        return AccessLevelHelper.toJSON(this.value);
    }
}
AccessLevelHelper.RTTI_CLASS = 'AccessLevel';
AccessLevelHelper.RTTI_FQN = 'io.protoforce.shared:AccessLevel';
AccessLevelHelper.all = [
    AccessLevel.Read,
    AccessLevel.Write,
    AccessLevel.Admin
];
AccessLevelHelper.toJSON = (value) => {
    return AccessLevel[value];
};
AccessLevelHelper.fromJSON = (value) => {
    switch (value) {
        case 'Read': return AccessLevel.Read;
        case 'Write': return AccessLevel.Write;
        case 'Admin': return AccessLevel.Admin;
        default: throw new CodecError(`Unexpected value for enum AccessLevel: '${value}'`);
    }
};
