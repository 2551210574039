import React, { useContext, useCallback } from 'react';
import { InputGroup, KeyCombo } from '@blueprintjs/core';
import { MediaQuerySize, useHotKey } from '~/core/hooks';
import { useMediaQuerySize } from '~/core';
import { UniSearchContext, UniSearchMode } from '../../search/UniSearch';
export const SearchComponent = React.memo(() => {
    const context = useContext(UniSearchContext);
    const onStartSearch = useCallback(() => context.startSearch(UniSearchMode.All), [context]);
    useHotKey({
        hotkey: '/',
        preventDefault: true,
        onKeyDown: onStartSearch
    });
    const mqs = useMediaQuerySize();
    const placeholder = mqs >= MediaQuerySize.xl || (mqs < MediaQuerySize.md && mqs >= MediaQuerySize.xs) ? 'Search APIs, Companies, ...' : 'Search...';
    const minWidth = mqs >= MediaQuerySize.xl || (mqs < MediaQuerySize.md && mqs >= MediaQuerySize.xs) ? 220 : undefined;
    const maxWidth = mqs >= MediaQuerySize.xl || (mqs < MediaQuerySize.md && mqs >= MediaQuerySize.xs) ? undefined : 100;
    return (React.createElement("div", null,
        React.createElement(InputGroup, { id: 'portal-search', style: { maxWidth, minWidth }, placeholder: placeholder, onClick: onStartSearch, readOnly: true, "data-testid": 'input-unisearch', rightElement: React.createElement("div", { style: { padding: 3 } },
                React.createElement(KeyCombo, { combo: '/' })) })));
});
