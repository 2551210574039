import React from 'react';
import { MenuItem, Tag } from '@blueprintjs/core';
import { AccountID } from '@protoforce/shared';
import { useUserBrief } from '~/core/services/hooks/userService/useUserBrief';
import { AccountAvatar, AvatarSize } from '../AccountAvatar';
export const UserMenuItem = React.memo((props) => {
    const { active, disabled, user, onClick, tag } = props;
    const { data, pending } = useUserBrief(user instanceof AccountID ? user : undefined, {
        onError: err => console.error(err)
    });
    const userData = user instanceof AccountID ? data : user;
    return (React.createElement(MenuItem, { className: pending ? 'bp4-skeleton' : undefined, active: active, disabled: disabled, onClick: onClick, text: userData ? userData.displayName || userData.name : '', labelElement: tag ? React.createElement(Tag, { minimal: true }, "User") : undefined, icon: React.createElement(AccountAvatar, { id: user instanceof AccountID ? user : user.id, account: userData, size: AvatarSize.Small }) }));
});
