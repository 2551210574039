// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { PublishingTargetBoth } from './PublishingTargetBoth';
import { PublishingTargetReleases } from './PublishingTargetReleases';
import { PublishingTargetSnapshots } from './PublishingTargetSnapshots';
/**
  * ADT io.protoforce.projects:PublishingTarget
  *
  * Defined at /projects/publishing.model @ 197:1
  */
export class PublishingTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT PublishingTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.snapshots !== 'undefined') {
            this.value = value.snapshots;
        }
        else if (typeof value.releases !== 'undefined') {
            this.value = value.releases;
        }
        else if (typeof value.both !== 'undefined') {
            this.value = value.both;
        }
        else {
            throw new Error('ADT PublishingTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenSnapshots, whenReleases, whenBoth) {
        const v = this.value;
        if (v instanceof PublishingTargetSnapshots) {
            return whenSnapshots(v);
        }
        else if (v instanceof PublishingTargetReleases) {
            return whenReleases(v);
        }
        else if (v instanceof PublishingTargetBoth) {
            return whenBoth(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenSnapshots, whenReleases, whenBoth) {
        const v = this.value;
        if (v instanceof PublishingTargetSnapshots) {
            return whenSnapshots(v);
        }
        else if (v instanceof PublishingTargetReleases) {
            return whenReleases(v);
        }
        else if (v instanceof PublishingTargetBoth) {
            return whenBoth(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromSnapshots(value) {
        return new PublishingTarget({ snapshots: value });
    }
    static fromReleases(value) {
        return new PublishingTarget({ releases: value });
    }
    static fromBoth(value) {
        return new PublishingTarget({ both: value });
    }
    static from(value) {
        return value instanceof PublishingTarget ? value : new PublishingTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return PublishingTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTarget.RTTI_FQN;
    }
    toJSON() {
        return PublishingTarget.toJSON(this);
    }
}
PublishingTarget.RTTI_CLASS = 'PublishingTarget';
PublishingTarget.RTTI_FQN = 'io.protoforce.projects:PublishingTarget';
PublishingTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof PublishingTargetSnapshots) {
        return JSONConverter.withTypeNested(v, 'Snapshots');
    }
    else if (v instanceof PublishingTargetReleases) {
        return JSONConverter.withTypeNested(v, 'Releases');
    }
    else if (v instanceof PublishingTargetBoth) {
        return JSONConverter.withTypeNested(v, 'Both');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
PublishingTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Snapshots': {
            const vc = val;
            return PublishingTarget.fromSnapshots(PublishingTargetSnapshots.fromJSON(vc));
        }
        case 'Releases': {
            const vc = val;
            return PublishingTarget.fromReleases(PublishingTargetReleases.fromJSON(vc));
        }
        case 'Both': {
            const vc = val;
            return PublishingTarget.fromBoth(PublishingTargetBoth.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'PublishingTarget'`);
    }
};
