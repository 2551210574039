// tslint:disable
// eslint-disable
// package io.protoforce.tasks.react.tasksservice
import { TaskLog } from '../../../projects';
import { useClientTransport, useEitherQuery, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { tasksServiceGetLog } from '../../tasksservice/methods/tasksServiceGetLog';
import { tasksServiceGetLogName } from '../../tasksservice/methods/tasksServiceGetLogName';
import { tasksServiceRTTI } from '../../tasksservice/methods/tasksServiceRTTI';
import { useCallback } from 'react';
export const tasksServiceGetLogTags = [
    '$pf',
    tasksServiceRTTI.RTTI_FQN,
    tasksServiceGetLogName
];
/**
  * Defined at tasks.domain @ 45:5
  */
export function useTasksServiceGetLog(id, $options, $context) {
    const clientTransport = useClientTransport(tasksServiceRTTI, $options);
    return useEitherQuery(useServiceKey(tasksServiceRTTI.RTTI_FQN, tasksServiceGetLogName, id)[0], useCallback((id) => tasksServiceGetLog({ id }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: tasksServiceGetLogTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => typeof v !== 'undefined' && v != null ? v.toJSON() : undefined, []),
            fromCache: useCallback(v => typeof v !== 'undefined' && v != null ? TaskLog.fromJSON(v) : undefined, [])
        } }, ($options || {})), { tags: useMergedTags(tasksServiceGetLogTags) }), id);
}
