// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
import { BgTaskId } from '../../../projects';
/**
  * Class io.protoforce.tasks.tasksservice.models:GetLogInput
  *
  * Defined at tasks.domain @ 45:5
  */
export class TasksServiceGetLogInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return TasksServiceGetLogInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksServiceGetLogInput.RTTI_FQN;
    }
    toJSON() {
        return TasksServiceGetLogInput.toJSON(this);
    }
}
TasksServiceGetLogInput.RTTI_CLASS = 'GetLogInput';
TasksServiceGetLogInput.RTTI_FQN = 'io.protoforce.tasks.tasksservice.models:GetLogInput';
TasksServiceGetLogInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
TasksServiceGetLogInput.fromJSON = (value) => {
    return new TasksServiceGetLogInput({
        id: BgTaskId.fromJSON(value.id)
    });
};
