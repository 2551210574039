import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Navbar, Icon, Button, Drawer, MenuItem, Menu, MenuDivider } from '@blueprintjs/core';
import { useAuthContext } from '~/core/services/hooks/contexts';
import { MediaQuerySize, useMediaQuerySize } from '~/core';
import { UserComponent } from './components/user';
import { SearchComponent } from './components/search';
import { ProductComponent, ProductMenuComponent } from './components/product';
import { NewComponent } from './components/new';
import { asyncLogin, asyncSignup } from '../../async';
import PROTOFORCE_LOGO from './protoforce-dark.svg';
import PROTOFORCE_LOGO_ONLY from './protoforce-dark-logo.svg';
import { NotificationsComponent } from './components/notifications';
import { TasksComponent } from './components/tasks';
const RoadmapButton = React.memo((props) => {
    const history = useHistory();
    const onClick = () => {
        history.push(`/roadmap`);
    };
    return (React.createElement(Button, { style: props.style, icon: 'route', text: 'Roadmap', minimal: true, onClick: onClick }));
});
export const Header = React.memo(() => {
    const { user } = useAuthContext();
    const mqs = useMediaQuerySize();
    const logoImage = mqs >= MediaQuerySize.lg || (mqs < MediaQuerySize.md && mqs >= MediaQuerySize.xs) ? PROTOFORCE_LOGO : PROTOFORCE_LOGO_ONLY;
    const [menuOpen, setMenuOpen] = useState(false);
    const hideProduct = mqs < MediaQuerySize.md ? true : false;
    function renderAuthButtons(asMenu = false) {
        if (asMenu) {
            return [
                React.createElement(MenuItem, { key: 'signin', icon: 'log-in', text: React.createElement(NavLink, { to: '/signin', activeClassName: 'bp4-active bp4-intent-primary', onMouseEnter: () => asyncLogin.preload() },
                        React.createElement("span", null, "Sign In")), onClick: () => setMenuOpen(false) }),
                React.createElement(MenuItem, { key: 'signup', icon: 'new-person', text: React.createElement(NavLink, { to: '/signup', activeClassName: 'bp4-active bp4-intent-primary', onMouseEnter: () => asyncSignup.preload() },
                        React.createElement("span", null, "Sign Up")), onClick: () => setMenuOpen(false) })
            ];
        }
        return (React.createElement("div", { className: 'bp4-navbar-group bp4-align-right' },
            React.createElement(NavLink, { className: 'bp4-button bp4-minimal bp4-large', to: '/signin', activeClassName: 'bp4-intent-primary', onMouseEnter: () => asyncLogin.preload() },
                React.createElement(Icon, { icon: 'log-in' }),
                React.createElement("span", null, "Sign In")),
            React.createElement("span", { className: 'bp4-navbar-divider' }),
            React.createElement(NavLink, { className: 'bp4-button bp4-minimal bp4-large', to: '/signup', activeClassName: 'bp4-intent-primary', onMouseEnter: () => asyncSignup.preload() },
                React.createElement(Icon, { icon: 'new-person' }),
                React.createElement("span", null, "Sign Up"))));
    }
    function renderDrawerMenu() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'bp4-navbar-group bp4-align-right' },
                React.createElement(Button, { icon: 'menu', onClick: () => setMenuOpen(!menuOpen) })),
            React.createElement(Drawer, { isOpen: menuOpen, position: 'right', className: 'bp4-dark', size: mqs <= MediaQuerySize.xs ? Drawer.SIZE_LARGE : Drawer.SIZE_SMALL, style: { padding: 10 }, onClose: () => setMenuOpen(false) },
                React.createElement("img", { src: PROTOFORCE_LOGO, style: { maxHeight: 32 }, alt: 'ProtoForce Logo' }),
                React.createElement("div", { style: {
                        position: 'relative',
                        overflowY: 'scroll',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    } },
                    React.createElement(Menu, null,
                        React.createElement(MenuDivider, { title: 'Account' }),
                        renderAuthButtons(true),
                        hideProduct &&
                            React.createElement(ProductMenuComponent, { onClose: () => setMenuOpen(false) }))))));
    }
    const rightMenu = mqs < MediaQuerySize.lg ?
        renderDrawerMenu() : renderAuthButtons();
    return (React.createElement("div", { style: { padding: 0 } },
        React.createElement(Navbar, { fixedToTop: true },
            React.createElement("div", null,
                React.createElement("div", { className: 'bp4-navbar-group bp4-align-left' },
                    React.createElement(Link, { className: 'bp4-navbar-heading', to: '/' },
                        React.createElement("img", { src: logoImage, style: { maxHeight: 32 }, alt: 'ProtoForce Logo' })),
                    React.createElement(SearchComponent, null),
                    !user ? (hideProduct ? null : React.createElement(ProductComponent, null)) : React.createElement(RoadmapButton, { style: { marginLeft: 15 } })),
                !user ? rightMenu : (React.createElement("div", { className: 'bp4-navbar-group bp4-align-right' },
                    React.createElement(NewComponent, null),
                    React.createElement("span", { className: 'bp4-navbar-divider' }),
                    React.createElement(TasksComponent, null),
                    React.createElement(NotificationsComponent, null),
                    React.createElement(UserComponent, { user: user })))))));
});
