// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { HashAlgorithmHelper } from './HashAlgorithmHelper';
/**
  *
  *  Has holds a hash algo and a value
  *
  * Identifier io.protoforce.shared:Hash
  *
  * Defined at /shared/upload.model @ 10:1
  */
export class Hash {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.alg = HashAlgorithmHelper.all[0];
                this.value = '';
            }
            return;
        }
        this.alg = data.alg;
        this.value = data.value;
    }
    get RTTI_CLASS() {
        return Hash.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Hash.RTTI_FQN;
    }
    toJSON() {
        return Hash.toJSON(this);
    }
}
Hash.RTTI_CLASS = 'Hash';
Hash.RTTI_FQN = 'io.protoforce.shared:Hash';
Hash.toJSON = (value) => {
    const kvs = [
        `alg=${encodeURIComponent(HashAlgorithmHelper.toJSON(value.alg))}`,
        `value=${encodeURIComponent(value.value)}`
    ];
    return `${Hash.RTTI_CLASS}#${kvs.join(';')}`;
};
Hash.fromJSON = (value) => {
    const err = `Identifier ${Hash.RTTI_CLASS}.fromJSON input (${value}) is malformed.`;
    const chunks = value.split('#');
    if (chunks.length !== 2) {
        throw new CodecError(err);
    }
    if (chunks[0] !== Hash.RTTI_CLASS) {
        throw new CodecError(`${err} Expected to start with '${Hash.RTTI_CLASS}#'`);
    }
    const values = chunks[1].split(';');
    if (values.length !== 2) {
        throw new CodecError(`${err} Expects 2 key values, got ${values.length}`);
    }
    const valueMap = {};
    for (let i = 0; i < values.length; i++) {
        const kv = values[i].split('=');
        if (kv.length !== 2) {
            throw new CodecError(`${err} Must have key=value pairs comma separated.`);
        }
        valueMap[kv[0]] = kv[1];
    }
    const data = {
        alg: HashAlgorithmHelper.fromJSON(decodeURIComponent(valueMap['alg'])),
        value: decodeURIComponent(valueMap['value'])
    };
    return new Hash(data);
};
