// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.methods
import { OrgServiceGetOrgsBriefInput } from '../models/OrgServiceGetOrgsBriefInput';
import { OrgServicegetOrgsBriefMethodOutputHelper } from '../models/OrgServicegetOrgsBriefMethodOutputHelper';
import { orgServiceGetOrgsBriefName } from './orgServiceGetOrgsBriefName';
import { orgServiceRTTI } from './orgServiceRTTI';
export function orgServiceGetOrgsBrief(input, transport, context) {
    return transport.send(orgServiceRTTI, orgServiceGetOrgsBriefName, new OrgServiceGetOrgsBriefInput(input), {
        in: OrgServiceGetOrgsBriefInput,
        out: OrgServicegetOrgsBriefMethodOutputHelper,
        context
    });
}
