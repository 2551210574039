// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { SuccessBaseCodec } from './codecs/SuccessBaseCodec';
import { SuccessBaseImpl } from './SuccessBaseImpl';
/**
  *
  *  Generic success, can be used for any kind
  *  of operation which does not require extra
  *  details to be returned.
  *
  * Class io.protoforce.shared:GenericSuccess
  *
  * Defined at /shared/success.model @ 11:1
  */
export class GenericSuccess {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return GenericSuccess.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GenericSuccess.RTTI_FQN;
    }
    toSuccessBase() {
        return new SuccessBaseImpl({
            message: this.message
        });
    }
    toJSON() {
        return GenericSuccess.toJSON(this);
    }
}
GenericSuccess.RTTI_CLASS = 'GenericSuccess';
GenericSuccess.RTTI_FQN = 'io.protoforce.shared:GenericSuccess';
GenericSuccess.toJSON = (value) => {
    return {
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined
    };
};
GenericSuccess.fromJSON = (value) => {
    return new GenericSuccess({
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined
    });
};
SuccessBaseCodec.register(GenericSuccess.RTTI_FQN, GenericSuccess.fromJSON);
