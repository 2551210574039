// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  GenericFailureCode is a basic set of failure
  *  codes which are slightly different depending
  *  on the context.
  *
  * Enum io.protoforce.shared:GenericFailureCode
  *
  * Defined at /shared/error.model @ 21:1
  */
export var GenericFailureCode;
(function (GenericFailureCode) {
    /**
      * Defined at /shared/error.model @ 22:5
      */
    GenericFailureCode[GenericFailureCode["NotFound"] = 0] = "NotFound";
    /**
      * Defined at /shared/error.model @ 23:5
      */
    GenericFailureCode[GenericFailureCode["AlreadyExists"] = 1] = "AlreadyExists";
    /**
      * Defined at /shared/error.model @ 24:5
      */
    GenericFailureCode[GenericFailureCode["InvalidParams"] = 2] = "InvalidParams";
    /**
      * Defined at /shared/error.model @ 25:5
      */
    GenericFailureCode[GenericFailureCode["ConditionNotMet"] = 3] = "ConditionNotMet";
    /**
      * Defined at /shared/error.model @ 26:5
      */
    GenericFailureCode[GenericFailureCode["Expired"] = 4] = "Expired";
    /**
      * Defined at /shared/error.model @ 27:5
      */
    GenericFailureCode[GenericFailureCode["Unauthorized"] = 5] = "Unauthorized";
    /**
      * Defined at /shared/error.model @ 28:5
      */
    GenericFailureCode[GenericFailureCode["UnexpectedException"] = 6] = "UnexpectedException";
    /**
      * Defined at /shared/error.model @ 29:5
      */
    GenericFailureCode[GenericFailureCode["NotSupported"] = 7] = "NotSupported";
    /**
      * Defined at /shared/error.model @ 30:5
      */
    GenericFailureCode[GenericFailureCode["Unknown"] = 8] = "Unknown";
})(GenericFailureCode || (GenericFailureCode = {}));
