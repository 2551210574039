// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.models
import { GetAccountOrganizationResponse } from '../../GetAccountOrganizationResponse';
import { GetAccountUserResponse } from '../../GetAccountUserResponse';
import { JSONConverter, CodecError } from '../../../../../irt';
/**
  *
  *  Get account public details
  *
  *  @id Account identity
  *
  * ADT io.protoforce.auth.accountservice.models:GetAccountOutput
  *
  * Defined at /auth/account.service.model @ 146:5
  */
export class AccountServiceGetAccountOutput {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT AccountServiceGetAccountOutput must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.getAccountUserResponse !== 'undefined') {
            this.value = value.getAccountUserResponse;
        }
        else if (typeof value.getAccountOrganizationResponse !== 'undefined') {
            this.value = value.getAccountOrganizationResponse;
        }
        else {
            throw new Error('ADT AccountServiceGetAccountOutput constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenGetAccountUserResponse, whenGetAccountOrganizationResponse) {
        const v = this.value;
        if (v instanceof GetAccountUserResponse) {
            return whenGetAccountUserResponse(v);
        }
        else if (v instanceof GetAccountOrganizationResponse) {
            return whenGetAccountOrganizationResponse(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenGetAccountUserResponse, whenGetAccountOrganizationResponse) {
        const v = this.value;
        if (v instanceof GetAccountUserResponse) {
            return whenGetAccountUserResponse(v);
        }
        else if (v instanceof GetAccountOrganizationResponse) {
            return whenGetAccountOrganizationResponse(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromGetAccountUserResponse(value) {
        return new AccountServiceGetAccountOutput({ getAccountUserResponse: value });
    }
    static fromGetAccountOrganizationResponse(value) {
        return new AccountServiceGetAccountOutput({ getAccountOrganizationResponse: value });
    }
    static from(value) {
        return value instanceof AccountServiceGetAccountOutput ? value : new AccountServiceGetAccountOutput(undefined, value);
    }
    get RTTI_CLASS() {
        return AccountServiceGetAccountOutput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountServiceGetAccountOutput.RTTI_FQN;
    }
    toJSON() {
        return AccountServiceGetAccountOutput.toJSON(this);
    }
}
AccountServiceGetAccountOutput.RTTI_CLASS = 'GetAccountOutput';
AccountServiceGetAccountOutput.RTTI_FQN = 'io.protoforce.auth.accountservice.models:GetAccountOutput';
AccountServiceGetAccountOutput.toJSON = (value) => {
    const v = value.value;
    if (v instanceof GetAccountUserResponse) {
        return JSONConverter.withTypeNested(v, 'GetAccountUserResponse');
    }
    else if (v instanceof GetAccountOrganizationResponse) {
        return JSONConverter.withTypeNested(v, 'GetAccountOrganizationResponse');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
AccountServiceGetAccountOutput.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'GetAccountUserResponse': {
            const vc = val;
            return AccountServiceGetAccountOutput.fromGetAccountUserResponse(GetAccountUserResponse.fromJSON(vc));
        }
        case 'GetAccountOrganizationResponse': {
            const vc = val;
            return AccountServiceGetAccountOutput.fromGetAccountOrganizationResponse(GetAccountOrganizationResponse.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'GetAccountOutput'`);
    }
};
