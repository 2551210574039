// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { UserLevel } from './UserLevel';
export class UserLevelHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return UserLevelHelper.toString(this.value);
    }
    static toString(e) {
        return UserLevel[e];
    }
    static fromString(e) {
        return UserLevel[e];
    }
    get RTTI_CLASS() {
        return UserLevelHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserLevelHelper.RTTI_FQN;
    }
    toJSON() {
        return UserLevelHelper.toJSON(this.value);
    }
}
UserLevelHelper.RTTI_CLASS = 'UserLevel';
UserLevelHelper.RTTI_FQN = 'io.protoforce.shared:UserLevel';
UserLevelHelper.all = [
    UserLevel.Owner,
    UserLevel.Admin,
    UserLevel.Member
];
UserLevelHelper.toJSON = (value) => {
    return UserLevel[value];
};
UserLevelHelper.fromJSON = (value) => {
    switch (value) {
        case 'Owner': return UserLevel.Owner;
        case 'Admin': return UserLevel.Admin;
        case 'Member': return UserLevel.Member;
        default: throw new CodecError(`Unexpected value for enum UserLevel: '${value}'`);
    }
};
