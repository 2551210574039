// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { PublishingCredentialsNPMCredentialsPassword } from './PublishingCredentialsNPMCredentialsPassword';
import { PublishingCredentialsNPMCredentialsToken } from './PublishingCredentialsNPMCredentialsToken';
/**
  * ADT io.protoforce.projects/PublishingCredentials:NPMCredentials
  *
  * Defined at /projects/publishing.model @ 180:5
  */
export class PublishingCredentialsNPMCredentials {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT PublishingCredentialsNPMCredentials must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.token !== 'undefined') {
            this.value = value.token;
        }
        else if (typeof value.password !== 'undefined') {
            this.value = value.password;
        }
        else {
            throw new Error('ADT PublishingCredentialsNPMCredentials constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenToken, whenPassword) {
        const v = this.value;
        if (v instanceof PublishingCredentialsNPMCredentialsToken) {
            return whenToken(v);
        }
        else if (v instanceof PublishingCredentialsNPMCredentialsPassword) {
            return whenPassword(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenToken, whenPassword) {
        const v = this.value;
        if (v instanceof PublishingCredentialsNPMCredentialsToken) {
            return whenToken(v);
        }
        else if (v instanceof PublishingCredentialsNPMCredentialsPassword) {
            return whenPassword(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromToken(value) {
        return new PublishingCredentialsNPMCredentials({ token: value });
    }
    static fromPassword(value) {
        return new PublishingCredentialsNPMCredentials({ password: value });
    }
    static from(value) {
        return value instanceof PublishingCredentialsNPMCredentials ? value : new PublishingCredentialsNPMCredentials(undefined, value);
    }
    get RTTI_CLASS() {
        return PublishingCredentialsNPMCredentials.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingCredentialsNPMCredentials.RTTI_FQN;
    }
    toJSON() {
        return PublishingCredentialsNPMCredentials.toJSON(this);
    }
}
PublishingCredentialsNPMCredentials.RTTI_CLASS = 'NPMCredentials';
PublishingCredentialsNPMCredentials.RTTI_FQN = 'io.protoforce.projects/PublishingCredentials:NPMCredentials';
PublishingCredentialsNPMCredentials.toJSON = (value) => {
    const v = value.value;
    if (v instanceof PublishingCredentialsNPMCredentialsToken) {
        return JSONConverter.withTypeNested(v, 'Token');
    }
    else if (v instanceof PublishingCredentialsNPMCredentialsPassword) {
        return JSONConverter.withTypeNested(v, 'Password');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
PublishingCredentialsNPMCredentials.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Token': {
            const vc = val;
            return PublishingCredentialsNPMCredentials.fromToken(PublishingCredentialsNPMCredentialsToken.fromJSON(vc));
        }
        case 'Password': {
            const vc = val;
            return PublishingCredentialsNPMCredentials.fromPassword(PublishingCredentialsNPMCredentialsPassword.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'NPMCredentials'`);
    }
};
