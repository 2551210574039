// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { UniqueNameStatus } from './UniqueNameStatus';
export class UniqueNameStatusHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return UniqueNameStatusHelper.toString(this.value);
    }
    static toString(e) {
        return UniqueNameStatus[e];
    }
    static fromString(e) {
        return UniqueNameStatus[e];
    }
    get RTTI_CLASS() {
        return UniqueNameStatusHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UniqueNameStatusHelper.RTTI_FQN;
    }
    toJSON() {
        return UniqueNameStatusHelper.toJSON(this.value);
    }
}
UniqueNameStatusHelper.RTTI_CLASS = 'UniqueNameStatus';
UniqueNameStatusHelper.RTTI_FQN = 'io.protoforce.shared:UniqueNameStatus';
UniqueNameStatusHelper.all = [
    UniqueNameStatus.Available,
    UniqueNameStatus.Taken,
    UniqueNameStatus.Reserved
];
UniqueNameStatusHelper.toJSON = (value) => {
    return UniqueNameStatus[value];
};
UniqueNameStatusHelper.fromJSON = (value) => {
    switch (value) {
        case 'Available': return UniqueNameStatus.Available;
        case 'Taken': return UniqueNameStatus.Taken;
        case 'Reserved': return UniqueNameStatus.Reserved;
        default: throw new CodecError(`Unexpected value for enum UniqueNameStatus: '${value}'`);
    }
};
