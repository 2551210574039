var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LogLevel } from '../../logger';
import { EncoderProxy, DecoderProxy } from '../../codec';
import { ContentType, HTTPMethod, getHTTPHeader, XHeaders, XResponseType, XSuccessHeader, isContentType } from '../http';
import { restify } from '../rest';
import { sanitizeEndpoint, defaultHTTPClientTransportOptions } from './common';
import { ClientTransportError, ClientTransportRequestError, ClientTransportResponseError } from './error';
import { mergeHeaders } from './http';
export class HTTPClientTransport {
    /** Create a new instance of HTTPClientTransport
     *  @param {endpoint}           The endpoint that will be used for all requests
     *  @param {options}            All options are optional, can override default behavior
     */
    constructor(endpoint, options = {}) {
        this.setEndpoint(endpoint);
        this.options = Object.assign(Object.assign({}, defaultHTTPClientTransportOptions), options);
    }
    getCodec(contentType) {
        const { codecs, logger } = this.options;
        const codec = codecs[contentType];
        if (!codec) {
            const msg = `Can't find codec for content type '${contentType}', available: ${Object.keys(codecs).join(', ')}`;
            logger.logf(LogLevel.Error, msg);
            throw new ClientTransportError(msg);
        }
        return codec;
    }
    setEndpoint(endpoint) {
        this.endpoint = sanitizeEndpoint(endpoint);
    }
    getEndpoint() {
        return this.endpoint;
    }
    setHeaders(headers) {
        this.options.headers = headers || {};
    }
    getHeaders() {
        return this.options.headers || {};
    }
    setTimeout(timeout) {
        this.options.timeout = timeout;
    }
    getTimeout() {
        return this.options.timeout;
    }
    send(service, method, data, meta) {
        const { hooks, headers, defaultContentType, requestFunction, useServiceFQN } = this.options;
        const serviceID = useServiceFQN ? service.RTTI_FQN : service.RTTI_CLASS;
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            const logger = this.options.logger.withContext(`${serviceID}.${method}`);
            for (const hook of hooks) {
                const hookData = {
                    service: serviceID,
                    method,
                    in: data,
                    meta
                };
                if (hook.onSend) {
                    yield hook.onSend(hookData);
                }
            }
            // We only support defaultContentType at the moment
            const inContentType = defaultContentType;
            const inCodec = this.getCodec(inContentType);
            let path = `/${serviceID}/${method}`;
            let httpMethod = HTTPMethod.POST;
            const restSpec = meta.in && meta.in.restSpec;
            const inMaybeProxy = inCodec.encode(data, meta.in, !!restSpec);
            if (restSpec && inMaybeProxy instanceof EncoderProxy) {
                if (typeof inMaybeProxy.data !== 'object') {
                    throw new ClientTransportError(`REST spec expects an object from the encoder proxy. ${serviceID}.${method}`);
                }
                // @ts-ignore
                path = restify(inMaybeProxy.data, restSpec);
                if (!path.startsWith('/')) {
                    path = '/' + path;
                }
                httpMethod = restSpec.method;
            }
            const url = `${this.endpoint}${path}`;
            const payload = inMaybeProxy instanceof EncoderProxy ?
                inMaybeProxy.finalize(inMaybeProxy.data) :
                inMaybeProxy;
            const req = {
                method: httpMethod,
                headers: mergeHeaders(headers, { [ContentType.Header]: inContentType }),
                url,
                payload
            };
            for (const hook of hooks) {
                const hookData = {
                    service: serviceID,
                    method,
                    in: data,
                    meta,
                    request: req
                };
                if (hook.onRequest) {
                    yield hook.onRequest(hookData);
                }
            }
            logger.logf(LogLevel.Debug, `Request: \n${JSON.stringify(req, undefined, 2)}`);
            requestFunction(req, this.options).then((res) => __awaiter(this, void 0, void 0, function* () {
                logger.logf(LogLevel.Debug, `Response: \n${JSON.stringify(res, undefined, 2)}`);
                for (const hook of hooks) {
                    const hookData = {
                        service: serviceID,
                        method,
                        in: data,
                        meta,
                        response: res
                    };
                    if (hook.onResponse) {
                        yield hook.onResponse(hookData);
                    }
                }
                try {
                    const outContentType = getHTTPHeader(res.headers, ContentType.Header) || defaultContentType;
                    const outCodec = this.getCodec(outContentType);
                    const outMaybeProxy = outCodec.decode(res.payload, meta.out, meta.alternative);
                    if (meta.alternative) {
                        // We need to check headers for alternatives, in case
                        // we need to convert the payload back to left / right type of answer
                        if (isContentType(outContentType, [ContentType.ApplicationJson, ContentType.TextJson]) && outMaybeProxy instanceof DecoderProxy) {
                            const responseType = getHTTPHeader(res.headers, XHeaders.ResponseType);
                            switch (responseType) {
                                case XResponseType.Success:
                                    {
                                        const successType = getHTTPHeader(res.headers, XHeaders.Success);
                                        if (typeof successType !== 'undefined') {
                                            if (successType === XSuccessHeader.Left) {
                                                outMaybeProxy.data = { left: outMaybeProxy.data };
                                            }
                                            else if (successType === XSuccessHeader.Right) {
                                                outMaybeProxy.data = { right: outMaybeProxy.data };
                                            }
                                            else {
                                                reject(new ClientTransportResponseError(`Unexpected success type ${successType} for alternative response.`, req, res));
                                                return;
                                            }
                                        }
                                        else {
                                            reject(new ClientTransportResponseError(`No success ${XSuccessHeader} header is found.`, req, res));
                                            return;
                                        }
                                    }
                                    break;
                                case XResponseType.Failure: {
                                    const failureType = getHTTPHeader(res.headers, XHeaders.Failure);
                                    reject(new ClientTransportResponseError(`Failure ${failureType}.`, req, res));
                                    return;
                                }
                                default:
                                    reject(new ClientTransportResponseError(`No alternative response information provided.`, req, res));
                                    return;
                            }
                        }
                        else {
                            logger.logf(LogLevel.Warning, `No support for alternative encoding for codec ${outContentType}`);
                        }
                    }
                    const outPayload = outMaybeProxy instanceof DecoderProxy ?
                        outMaybeProxy.finalize(outMaybeProxy.data) :
                        outMaybeProxy;
                    for (const hook of hooks) {
                        const hookData = {
                            service: serviceID,
                            method,
                            in: data,
                            out: outPayload,
                            meta
                        };
                        if (hook.onResolve) {
                            yield hook.onResolve(hookData);
                        }
                    }
                    resolve(outPayload);
                }
                catch (err) {
                    reject(new ClientTransportResponseError(`Error '${err}' while processing response`, req, res));
                }
            }), (err) => {
                reject(new ClientTransportRequestError(`Error '${err}' while processing request`, req));
            });
        }));
    }
}
