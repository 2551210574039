import React, { useContext, useMemo } from 'react';
function loadClientStorage() {
    if (typeof window === 'undefined') {
        throw new Error(`loadClientStorage should not be loaded on the backend, use ServerServerStorage instead of ClientServerStorage`);
    }
    // @ts-ignore
    if (typeof window.__SERVER_STORAGE__ !== 'undefined') {
        // @ts-ignore
        const serverStorage = window.__SERVER_STORAGE__;
        // @ts-ignore
        delete window.__SERVER_STORAGE__;
        return serverStorage;
    }
    return {};
}
export const SERVER_STORAGE_VAR = '__SERVER_STORAGE__';
// @ts-ignore
const ServerStorageContext = React.createContext();
export function getHtmlStorageScript(storage) {
    const contents = JSON.stringify(storage);
    return `<script id="${SERVER_STORAGE_VAR}" type="text/javascript">//<![CDATA[\nwindow.${SERVER_STORAGE_VAR} = ${contents.replace(/</g, '\\u003c')};\n//]]></script>`;
}
function buildStorage(storage) {
    function get(key, orElse) {
        const value = storage[key];
        if (typeof value === 'undefined') {
            return orElse;
        }
        return value;
    }
    function put(key, value) {
        storage[key] = value;
    }
    function clear(key) {
        if (!(key in storage)) {
            return false;
        }
        delete storage[key];
        return true;
    }
    function load(saved) {
        Object.keys(storage).forEach(k => delete storage[k]);
        const from = typeof saved === 'string' ? JSON.parse(saved) : saved;
        Object.keys(from).forEach(k => storage[k] = from[k]);
    }
    function save(ssr) {
        return ssr ? getHtmlStorageScript(storage) : JSON.stringify(storage);
    }
    return {
        get,
        put,
        clear,
        load,
        save
    };
}
export const ClientServerStorage = React.memo((props) => {
    const { children } = props;
    const storage = useMemo(() => {
        return buildStorage(loadClientStorage());
    }, []);
    return (React.createElement(ServerStorageContext.Provider, { value: storage }, children));
});
export const ServerServerStorage = React.memo((props) => {
    const { children } = props;
    const storage = useMemo(() => {
        return buildStorage(props.storage);
    }, []);
    return (React.createElement(ServerStorageContext.Provider, { value: storage }, children));
});
export function useServerStorage() {
    return useContext(ServerStorageContext);
}
