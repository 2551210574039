import React, { useState, useCallback, useMemo } from 'react';
import { MenuItem, Spinner } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import { UserBrief, OrganizationBrief } from '@protoforce/auth';
import { SearchProject } from '@protoforce/search';
import { useSearch } from '~/core/services/hooks/searchService/useSearch';
import { UserMenuItem } from '../../account/UserMenuItem';
import { OrgMenuItem } from '../../account/OrgMenuItem';
import { ProjectMenuItem } from '../../project/ProjectMenuItem';
import { UniSearchHelp } from './UniSearchHelp';
import { UniSearchNoResult } from './UniSearchNoResult';
import { UniSearchMode } from './context';
const UniSearchOmnibar = Omnibar.ofType();
const LARGE_PAGE_SIZE = 20;
const DEFAULT_PAGE_SIZE = 10;
export const UniSearchView = React.memo((props) => {
    const { isOpen, placeholder, searchMode, onClose } = props;
    const [search, setSearch] = useState('');
    const query = useMemo(() => {
        if (searchMode === UniSearchMode.All) {
            // In universal mode we support prefix, to know if user is looking for a project, or organization
            const ql = search.toLowerCase();
            const isUser = ql.startsWith('user:');
            const isProject = ql.startsWith('project:');
            const isOrg = ql.startsWith('org:');
            const q = isUser || isProject || isOrg ? search.substr(search.indexOf(':') + 1) : search;
            return {
                search: q.trim(),
                usersLimit: !isProject && !isOrg ? (isUser ? LARGE_PAGE_SIZE : DEFAULT_PAGE_SIZE) : 0,
                orgsLimit: !isProject && !isUser ? (isOrg ? LARGE_PAGE_SIZE : DEFAULT_PAGE_SIZE) : 0,
                projectsLimit: !isOrg && !isUser ? (isProject ? LARGE_PAGE_SIZE : DEFAULT_PAGE_SIZE) : 0
            };
        }
        else {
            return {
                search,
                usersLimit: searchMode === UniSearchMode.Users ? LARGE_PAGE_SIZE : 0,
                orgsLimit: searchMode === UniSearchMode.Orgs ? LARGE_PAGE_SIZE : 0,
                projectsLimit: searchMode === UniSearchMode.Projects ? LARGE_PAGE_SIZE : 0
            };
        }
    }, [search, searchMode]);
    const { data, pending, error } = useSearch(query === null || query === void 0 ? void 0 : query.search, query.usersLimit, query.orgsLimit, query.projectsLimit, {
        delay: 250,
        debounce: true
    });
    const entities = useMemo(() => {
        if (!data) {
            return [];
        }
        return [
            ...data.users.results,
            ...data.orgs.results,
            ...data.projects.results
        ];
    }, [data === null || data === void 0 ? void 0 : data.users, data === null || data === void 0 ? void 0 : data.orgs, data === null || data === void 0 ? void 0 : data.projects]);
    const renderItem = useCallback((item, { handleClick, modifiers, query: q }) => {
        if (item instanceof UserBrief) {
            return (React.createElement(UserMenuItem, { key: item.id.id, tag: true, user: item, active: modifiers.active, disabled: modifiers.disabled, onClick: handleClick }));
        }
        if (item instanceof OrganizationBrief) {
            return (React.createElement(OrgMenuItem, { key: item.id.id, tag: true, org: item, active: modifiers.active, disabled: modifiers.disabled, onClick: handleClick }));
        }
        if (item instanceof SearchProject) {
            return (React.createElement(ProjectMenuItem, { key: item.id.account + '/' + item.id.name, tag: true, project: item, active: modifiers.active, disabled: modifiers.disabled, onClick: handleClick }));
        }
        return React.createElement(MenuItem, { active: modifiers.active, disabled: true, text: 'Unsupported entity.' });
    }, []);
    const onCloseSearch = useCallback(() => {
        onClose();
    }, [onClose]);
    const onItemSelect = useCallback((item) => {
        onClose(item);
    }, [onClose]);
    const SPINNER_SIZE = 18;
    return (React.createElement(UniSearchOmnibar, { className: 'bp4-dark', inputProps: {
            placeholder,
            rightElement: pending ? React.createElement(Spinner, { size: SPINNER_SIZE }) : undefined
        }, isOpen: isOpen, query: search, items: entities, noResults: error ? React.createElement("span", { className: 'bp4-danger' },
            "Something went wrong: ",
            error.message) : React.createElement(UniSearchNoResult, { searchMode: searchMode }), itemRenderer: renderItem, onItemSelect: onItemSelect, onQueryChange: setSearch, onClose: onCloseSearch, initialContent: React.createElement(UniSearchHelp, { searchMode: searchMode }) }));
});
