// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { GitSourceTargetImpl } from './GitSourceTargetImpl';
import { JavaBuildManifest } from './JavaBuildManifest';
import { JavaSourceTargetPublicGit } from './JavaSourceTargetPublicGit';
import { ProtoforceTargetGit } from './ProtoforceTargetGit';
import { ProtoforceTargetPublicGit } from './ProtoforceTargetPublicGit';
import { PublishingCredentialsGitCreds } from './PublishingCredentialsGitCreds';
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
/**
  * Class io.protoforce.projects/JavaSourceTarget:Git (member of ADT io.protoforce.projects:JavaSourceTarget)
  *
  * Defined at /projects/publishing.model @ 57:5
  */
export class JavaSourceTargetGit {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.credentials = data.credentials;
        this.id = data.id;
        this.manifest = data.manifest;
        this.meta = data.meta;
    }
    get RTTI_CLASS() {
        return JavaSourceTargetGit.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return JavaSourceTargetGit.RTTI_FQN;
    }
    static fromGitSourceTarget(from, id, manifest, meta) {
        return new JavaSourceTargetGit({
            id: id,
            manifest: manifest,
            meta: meta,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials
        });
    }
    loadGitSourceTarget(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
    }
    static fromScalaSourceTargetPublicGit(from, credentials, manifest) {
        return new JavaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadScalaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromTypescriptSourceTargetGit(from, manifest) {
        return new JavaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadTypescriptSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    toProtoforceTargetGit() {
        return new ProtoforceTargetGit({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials,
            id: this.id,
            meta: this.meta
        });
    }
    static fromTypescriptSourceTargetPublicGit(from, credentials, manifest) {
        return new JavaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadTypescriptSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toGitSourceTarget() {
        return new GitSourceTargetImpl({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials
        });
    }
    static fromProtoforceTargetGit(from, manifest) {
        return new JavaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromScalaSourceTargetGit(from, manifest) {
        return new JavaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadScalaSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromProtoforceTargetPublicGit(from, credentials, manifest) {
        return new JavaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toJavaSourceTargetPublicGit() {
        return new JavaSourceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            manifest: this.manifest,
            meta: this.meta
        });
    }
    toProtoforceTargetPublicGit() {
        return new ProtoforceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            meta: this.meta
        });
    }
    static fromJavaSourceTargetPublicGit(from, credentials) {
        return new JavaSourceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            manifest: from.manifest,
            meta: from.meta
        });
    }
    loadJavaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.manifest = from.manifest;
        this.meta = from.meta;
    }
    toJSON() {
        return JavaSourceTargetGit.toJSON(this);
    }
}
JavaSourceTargetGit.RTTI_CLASS = 'Git';
JavaSourceTargetGit.RTTI_FQN = 'io.protoforce.projects/JavaSourceTarget:Git';
JavaSourceTargetGit.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        credentials: value.credentials.toJSON(),
        id: value.id.toJSON(),
        manifest: value.manifest.toJSON(),
        meta: value.meta.toJSON()
    };
};
JavaSourceTargetGit.fromJSON = (value) => {
    return new JavaSourceTargetGit({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        credentials: PublishingCredentialsGitCreds.fromJSON(value.credentials),
        id: PublishingTaskId.fromJSON(value.id),
        manifest: JavaBuildManifest.fromJSON(value.manifest),
        meta: PublishingTaskMeta.fromJSON(value.meta)
    });
};
