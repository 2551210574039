// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:TaskLog
  *
  * Defined at /projects/tasks.model @ 79:1
  */
export class TaskLog {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.text = data.text;
    }
    get RTTI_CLASS() {
        return TaskLog.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TaskLog.RTTI_FQN;
    }
    toJSON() {
        return TaskLog.toJSON(this);
    }
}
TaskLog.RTTI_CLASS = 'TaskLog';
TaskLog.RTTI_FQN = 'io.protoforce.projects:TaskLog';
TaskLog.toJSON = (value) => {
    return {
        text: typeof value.text !== 'undefined' && value.text != null ? value.text : undefined
    };
};
TaskLog.fromJSON = (value) => {
    return new TaskLog({
        text: typeof value.text !== 'undefined' && value.text != null ? value.text : undefined
    });
};
