// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { JavaTargetPublic } from './JavaTargetPublic';
import { ProtoforceTargetPublic } from './ProtoforceTargetPublic';
import { ScalaTargetPublic } from './ScalaTargetPublic';
import { TypescriptTargetPublic } from './TypescriptTargetPublic';
/**
  * ADT io.protoforce.projects:PublishingTaskPublic
  *
  * Defined at /projects/publishing.model @ 24:1
  */
export class PublishingTaskPublic {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT PublishingTaskPublic must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.scalaTargetPublic !== 'undefined') {
            this.value = value.scalaTargetPublic;
        }
        else if (typeof value.javaTargetPublic !== 'undefined') {
            this.value = value.javaTargetPublic;
        }
        else if (typeof value.typescriptTargetPublic !== 'undefined') {
            this.value = value.typescriptTargetPublic;
        }
        else if (typeof value.protoforceTargetPublic !== 'undefined') {
            this.value = value.protoforceTargetPublic;
        }
        else {
            throw new Error('ADT PublishingTaskPublic constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenScalaTargetPublic, whenJavaTargetPublic, whenTypescriptTargetPublic, whenProtoforceTargetPublic) {
        const v = this.value;
        if (v instanceof ScalaTargetPublic) {
            return whenScalaTargetPublic(v);
        }
        else if (v instanceof JavaTargetPublic) {
            return whenJavaTargetPublic(v);
        }
        else if (v instanceof TypescriptTargetPublic) {
            return whenTypescriptTargetPublic(v);
        }
        else if (v instanceof ProtoforceTargetPublic) {
            return whenProtoforceTargetPublic(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenScalaTargetPublic, whenJavaTargetPublic, whenTypescriptTargetPublic, whenProtoforceTargetPublic) {
        const v = this.value;
        if (v instanceof ScalaTargetPublic) {
            return whenScalaTargetPublic(v);
        }
        else if (v instanceof JavaTargetPublic) {
            return whenJavaTargetPublic(v);
        }
        else if (v instanceof TypescriptTargetPublic) {
            return whenTypescriptTargetPublic(v);
        }
        else if (v instanceof ProtoforceTargetPublic) {
            return whenProtoforceTargetPublic(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromScalaTargetPublic(value) {
        return new PublishingTaskPublic({ scalaTargetPublic: value });
    }
    static fromJavaTargetPublic(value) {
        return new PublishingTaskPublic({ javaTargetPublic: value });
    }
    static fromTypescriptTargetPublic(value) {
        return new PublishingTaskPublic({ typescriptTargetPublic: value });
    }
    static fromProtoforceTargetPublic(value) {
        return new PublishingTaskPublic({ protoforceTargetPublic: value });
    }
    static from(value) {
        return value instanceof PublishingTaskPublic ? value : new PublishingTaskPublic(undefined, value);
    }
    get RTTI_CLASS() {
        return PublishingTaskPublic.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTaskPublic.RTTI_FQN;
    }
    toJSON() {
        return PublishingTaskPublic.toJSON(this);
    }
}
PublishingTaskPublic.RTTI_CLASS = 'PublishingTaskPublic';
PublishingTaskPublic.RTTI_FQN = 'io.protoforce.projects:PublishingTaskPublic';
PublishingTaskPublic.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaTargetPublic) {
        return JSONConverter.withTypeNested(v, 'ScalaTargetPublic');
    }
    else if (v instanceof JavaTargetPublic) {
        return JSONConverter.withTypeNested(v, 'JavaTargetPublic');
    }
    else if (v instanceof TypescriptTargetPublic) {
        return JSONConverter.withTypeNested(v, 'TypescriptTargetPublic');
    }
    else if (v instanceof ProtoforceTargetPublic) {
        return JSONConverter.withTypeNested(v, 'ProtoforceTargetPublic');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
PublishingTaskPublic.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'ScalaTargetPublic': {
            const vc = val;
            return PublishingTaskPublic.fromScalaTargetPublic(ScalaTargetPublic.fromJSON(vc));
        }
        case 'JavaTargetPublic': {
            const vc = val;
            return PublishingTaskPublic.fromJavaTargetPublic(JavaTargetPublic.fromJSON(vc));
        }
        case 'TypescriptTargetPublic': {
            const vc = val;
            return PublishingTaskPublic.fromTypescriptTargetPublic(TypescriptTargetPublic.fromJSON(vc));
        }
        case 'ProtoforceTargetPublic': {
            const vc = val;
            return PublishingTaskPublic.fromProtoforceTargetPublic(ProtoforceTargetPublic.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'PublishingTaskPublic'`);
    }
};
