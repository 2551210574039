// tslint:disable
// eslint-disable
// package io.protoforce.docs
import { UnsplashImage } from './UnsplashImage';
import { Validator } from '../../../irt';
/**
  * Class io.protoforce.docs:UnsplashResponse
  *
  * Defined at /docs/doc.unsplash.model @ 11:1
  */
export class UnsplashResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.content = [];
                this.pages = 0;
            }
            return;
        }
        this.content = data.content;
        this.pages = data.pages;
    }
    get pages() {
        return this._pages;
    }
    set pages(value) {
        Validator.min(value, 0, 'pages');
        Validator.max(value, 4294967295, 'pages');
        Validator.integer(value, 'pages');
        this._pages = value;
    }
    get RTTI_CLASS() {
        return UnsplashResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UnsplashResponse.RTTI_FQN;
    }
    toJSON() {
        return UnsplashResponse.toJSON(this);
    }
}
UnsplashResponse.RTTI_CLASS = 'UnsplashResponse';
UnsplashResponse.RTTI_FQN = 'io.protoforce.docs:UnsplashResponse';
UnsplashResponse.toJSON = (value) => {
    return {
        content: value.content.map((e) => e.toJSON()),
        pages: value.pages
    };
};
UnsplashResponse.fromJSON = (value) => {
    return new UnsplashResponse({
        content: value.content.map((e) => UnsplashImage.fromJSON(e)),
        pages: value.pages
    });
};
