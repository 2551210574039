// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { FailureBase } from './FailureBase';
import { IRTErrorShared } from './IRTErrorShared';
import { NotFoundEntityHelper } from './NotFoundEntityHelper';
import { UnknownFailure } from './UnknownFailure';
import { RuntimeError } from '../../../irt';
/**
  *
  *  Specialized failure to describe an unknown entity.
  *
  * Class io.protoforce.shared:NotFoundFailure
  *
  * Defined at /shared/error.model @ 79:1
  */
export class NotFoundFailure extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
        this.entity = data.entity;
    }
    get RTTI_CLASS() {
        return NotFoundFailure.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotFoundFailure.RTTI_FQN;
    }
    static fromFailureBase(from, entity) {
        return new NotFoundFailure({
            entity: entity,
            message: from.message
        });
    }
    loadFailureBase(from) {
        this.message = from.message;
    }
    static fromIRTErrorShared(from, entity) {
        return new NotFoundFailure({
            entity: entity,
            message: from.message
        });
    }
    loadIRTErrorShared(from) {
        this.message = from.message;
    }
    toUnknownFailure() {
        return new UnknownFailure({
            message: this.message
        });
    }
    static fromGenericFailure(from, entity) {
        return new NotFoundFailure({
            entity: entity,
            message: from.message
        });
    }
    loadGenericFailure(from) {
        this.message = from.message;
    }
    toIRTErrorShared() {
        return new IRTErrorShared({
            message: this.message
        });
    }
    static fromUnknownFailure(from, entity) {
        return new NotFoundFailure({
            entity: entity,
            message: from.message
        });
    }
    loadUnknownFailure(from) {
        this.message = from.message;
    }
    toFailureBase() {
        return new FailureBase({
            message: this.message
        });
    }
    toJSON() {
        return NotFoundFailure.toJSON(this);
    }
}
NotFoundFailure.RTTI_CLASS = 'NotFoundFailure';
NotFoundFailure.RTTI_FQN = 'io.protoforce.shared:NotFoundFailure';
NotFoundFailure.toJSON = (value) => {
    return {
        message: value.message,
        entity: NotFoundEntityHelper.toJSON(value.entity)
    };
};
NotFoundFailure.fromJSON = (value) => {
    return new NotFoundFailure({
        message: value.message,
        entity: NotFoundEntityHelper.fromJSON(value.entity)
    });
};
