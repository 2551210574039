// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:Publisher
  *
  * Defined at /projects/manifests/build.model @ 2:1
  */
export class Publisher {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.domain = '';
            }
            return;
        }
        this.name = data.name;
        this.domain = data.domain;
    }
    get RTTI_CLASS() {
        return Publisher.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Publisher.RTTI_FQN;
    }
    toJSON() {
        return Publisher.toJSON(this);
    }
}
Publisher.RTTI_CLASS = 'Publisher';
Publisher.RTTI_FQN = 'io.protoforce.projects:Publisher';
Publisher.toJSON = (value) => {
    return {
        name: value.name,
        domain: value.domain
    };
};
Publisher.fromJSON = (value) => {
    return new Publisher({
        name: value.name,
        domain: value.domain
    });
};
