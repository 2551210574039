// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.methods
import { AccountServiceCheckAccountNameInput } from '../models/AccountServiceCheckAccountNameInput';
import { CheckUniqueNameResponse } from '../../CheckUniqueNameResponse';
import { accountServiceCheckAccountNameName } from './accountServiceCheckAccountNameName';
import { accountServiceRTTI } from './accountServiceRTTI';
export function accountServiceCheckAccountName(input, transport, context) {
    return transport.send(accountServiceRTTI, accountServiceCheckAccountNameName, new AccountServiceCheckAccountNameInput(input), {
        in: AccountServiceCheckAccountNameInput,
        out: CheckUniqueNameResponse,
        context
    });
}
