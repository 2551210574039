// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { FailureBase } from './FailureBase';
import { GenericFailureCodeHelper } from './GenericFailureCodeHelper';
import { IRTErrorShared } from './IRTErrorShared';
import { UnknownFailure } from './UnknownFailure';
import { RuntimeError } from '../../../irt';
/**
  *
  *  GenericFailure is a basic failure that can be used in the majority
  *  of responses, unless extra information is required.
  *
  * Class io.protoforce.shared:GenericFailure
  *
  * Defined at /shared/error.model @ 38:1
  */
export class GenericFailure extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
        this.code = data.code;
        this.diagnostics = data.diagnostics;
        this.reserved = data.reserved;
    }
    get RTTI_CLASS() {
        return GenericFailure.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GenericFailure.RTTI_FQN;
    }
    static fromIRTErrorShared(from, code, diagnostics, reserved) {
        return new GenericFailure({
            code: code,
            diagnostics: diagnostics,
            reserved: reserved,
            message: from.message
        });
    }
    loadIRTErrorShared(from) {
        this.message = from.message;
    }
    toFailureBase() {
        return new FailureBase({
            message: this.message
        });
    }
    toIRTErrorShared() {
        return new IRTErrorShared({
            message: this.message
        });
    }
    static fromUnknownFailure(from, code, diagnostics, reserved) {
        return new GenericFailure({
            code: code,
            diagnostics: diagnostics,
            reserved: reserved,
            message: from.message
        });
    }
    loadUnknownFailure(from) {
        this.message = from.message;
    }
    static fromFailureBase(from, code, diagnostics, reserved) {
        return new GenericFailure({
            code: code,
            diagnostics: diagnostics,
            reserved: reserved,
            message: from.message
        });
    }
    loadFailureBase(from) {
        this.message = from.message;
    }
    toUnknownFailure() {
        return new UnknownFailure({
            message: this.message
        });
    }
    static fromNotFoundFailure(from, code, diagnostics, reserved) {
        return new GenericFailure({
            code: code,
            diagnostics: diagnostics,
            reserved: reserved,
            message: from.message
        });
    }
    loadNotFoundFailure(from) {
        this.message = from.message;
    }
    toJSON() {
        return GenericFailure.toJSON(this);
    }
}
GenericFailure.RTTI_CLASS = 'GenericFailure';
GenericFailure.RTTI_FQN = 'io.protoforce.shared:GenericFailure';
GenericFailure.toJSON = (value) => {
    return {
        message: value.message,
        code: GenericFailureCodeHelper.toJSON(value.code),
        diagnostics: typeof value.diagnostics !== 'undefined' && value.diagnostics != null ? value.diagnostics : undefined,
        reserved: typeof value.reserved !== 'undefined' && value.reserved != null ? Object.keys(value.reserved)
            .reduce((previous, current) => {
            previous[current] = value.reserved[current];
            return previous;
        }, {}) : undefined
    };
};
GenericFailure.fromJSON = (value) => {
    return new GenericFailure({
        message: value.message,
        code: GenericFailureCodeHelper.fromJSON(value.code),
        diagnostics: typeof value.diagnostics !== 'undefined' && value.diagnostics != null ? value.diagnostics : undefined,
        reserved: typeof value.reserved !== 'undefined' && value.reserved != null ? Object.keys(value.reserved)
            .reduce((previous, current) => {
            previous[current] = value.reserved[current];
            return previous;
        }, {}) : undefined
    });
};
