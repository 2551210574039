import moment from 'moment';
export function timeToTimeline(date) {
    return moment(date instanceof Date ? date : date.toDate()).calendar(undefined, {
        lastWeek: 'On MMM Do',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        sameElse: 'On ll'
    });
}
export function timeToPoint(date) {
    return moment(date instanceof Date ? date : date.toDate()).format('lll');
}
export function timeToDate(date) {
    return moment(date instanceof Date ? date : date.toDate()).format('ll');
}
