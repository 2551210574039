// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Enum io.protoforce.projects:ScalaProjectLayout
  *
  * Defined at /projects/manifests/scala.model @ 2:1
  */
export var ScalaProjectLayout;
(function (ScalaProjectLayout) {
    /**
      * Defined at /projects/manifests/scala.model @ 3:5
      */
    ScalaProjectLayout[ScalaProjectLayout["PLAIN"] = 0] = "PLAIN";
    /**
      * Defined at /projects/manifests/scala.model @ 4:5
      */
    ScalaProjectLayout[ScalaProjectLayout["SBT"] = 1] = "SBT";
})(ScalaProjectLayout || (ScalaProjectLayout = {}));
