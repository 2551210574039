// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { TypescriptCompiledPublicNPM } from './TypescriptCompiledPublicNPM';
/**
  * ADT io.protoforce.projects:TypescriptCompiledPublic
  *
  * Defined at /projects/publishing.model @ 165:1
  */
export class TypescriptCompiledPublic {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT TypescriptCompiledPublic must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.nPM !== 'undefined') {
            this.value = value.nPM;
        }
        else {
            throw new Error('ADT TypescriptCompiledPublic constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenNPM) {
        const v = this.value;
        if (v instanceof TypescriptCompiledPublicNPM) {
            return whenNPM(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenNPM) {
        const v = this.value;
        if (v instanceof TypescriptCompiledPublicNPM) {
            return whenNPM(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromNPM(value) {
        return new TypescriptCompiledPublic({ nPM: value });
    }
    static from(value) {
        return value instanceof TypescriptCompiledPublic ? value : new TypescriptCompiledPublic(undefined, value);
    }
    get RTTI_CLASS() {
        return TypescriptCompiledPublic.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptCompiledPublic.RTTI_FQN;
    }
    toJSON() {
        return TypescriptCompiledPublic.toJSON(this);
    }
}
TypescriptCompiledPublic.RTTI_CLASS = 'TypescriptCompiledPublic';
TypescriptCompiledPublic.RTTI_FQN = 'io.protoforce.projects:TypescriptCompiledPublic';
TypescriptCompiledPublic.toJSON = (value) => {
    const v = value.value;
    if (v instanceof TypescriptCompiledPublicNPM) {
        return JSONConverter.withTypeNested(v, 'NPM');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
TypescriptCompiledPublic.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'NPM': {
            const vc = val;
            return TypescriptCompiledPublic.fromNPM(TypescriptCompiledPublicNPM.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'TypescriptCompiledPublic'`);
    }
};
