// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.methods
import { InternalDocsServiceUploadInput } from '../models/InternalDocsServiceUploadInput';
import { InternalDocsServiceuploadMethodOutputHelper } from '../models/InternalDocsServiceuploadMethodOutputHelper';
import { internalDocsServiceRTTI } from './internalDocsServiceRTTI';
import { internalDocsServiceUploadName } from './internalDocsServiceUploadName';
export function internalDocsServiceUpload(input, transport, context) {
    return transport.send(internalDocsServiceRTTI, internalDocsServiceUploadName, new InternalDocsServiceUploadInput(input), {
        in: InternalDocsServiceUploadInput,
        out: InternalDocsServiceuploadMethodOutputHelper,
        alternative: true,
        context
    });
}
