// tslint:disable
// eslint-disable
// package io.protoforce.tasks
/**
  * Class io.protoforce.tasks/TasksFilter:Mine (member of ADT io.protoforce.tasks:TasksFilter)
  *
  * Defined at tasks.domain @ 28:5
  */
export class TasksFilterMine {
    get RTTI_CLASS() {
        return TasksFilterMine.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksFilterMine.RTTI_FQN;
    }
    toJSON() {
        return TasksFilterMine.toJSON(this);
    }
}
TasksFilterMine.RTTI_CLASS = 'Mine';
TasksFilterMine.RTTI_FQN = 'io.protoforce.tasks/TasksFilter:Mine';
TasksFilterMine.toJSON = (value) => {
    return {};
};
TasksFilterMine.fromJSON = (value) => {
    return new TasksFilterMine();
};
