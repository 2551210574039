// tslint:disable
// eslint-disable
// package io.protoforce.docs.react.imagesearchservice
import { ImageSearchServiceClient } from '../../imagesearchservice/ImageSearchServiceClient';
import { useClientTransport } from '../../../../../irt-react';
import { useMemo } from 'react';
/**
  * Defined at /docs/doc.unsplash.model @ 17:1
  */
export function useImageSearchService(transport) {
    const clientTransport = useClientTransport(ImageSearchServiceClient, { transport });
    return useMemo(() => {
        return new ImageSearchServiceClient(clientTransport);
    }, [clientTransport]);
}
