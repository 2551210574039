// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { SignupWithBaseCodec } from './codecs/SignupWithBaseCodec';
export class SignupWithBaseImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.displayName = data.displayName;
        this.name = data.name;
        this.location = data.location;
        this.code = data.code;
    }
    get RTTI_CLASS() {
        return SignupWithBaseImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWithBaseImpl.RTTI_FQN;
    }
    toSignupWithBase() {
        return new SignupWithBaseImpl({
            displayName: this.displayName,
            name: this.name,
            location: this.location,
            code: this.code
        });
    }
    toJSON() {
        return SignupWithBaseImpl.toJSON(this);
    }
}
SignupWithBaseImpl.RTTI_CLASS = 'SignupWithBase';
SignupWithBaseImpl.RTTI_FQN = 'io.protoforce.auth:SignupWithBase';
SignupWithBaseImpl.toJSON = (value) => {
    return {
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    };
};
SignupWithBaseImpl.fromJSON = (value) => {
    return new SignupWithBaseImpl({
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    });
};
SignupWithBaseCodec.register(SignupWithBaseImpl.RTTI_FQN, SignupWithBaseImpl.fromJSON);
