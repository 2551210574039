// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BgTaskStatus } from './BgTaskStatus';
import { IRTZonedDateTime } from '../../../irt';
/**
  * Class io.protoforce.projects:BgTaskData
  *
  * Defined at /projects/tasks.model @ 10:1
  */
export class BgTaskData {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.created = new IRTZonedDateTime();
                this.history = [];
            }
            return;
        }
        this.created = data.created;
        this.history = data.history;
        this.status = data.status;
    }
    get createdAsDate() {
        return this.created.toDate();
    }
    set createdAsDate(value) {
        this.created = IRTZonedDateTime.fromDate(value);
    }
    get createdAsString() {
        return this.created.toJSON();
    }
    set createdAsString(value) {
        this.created = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return BgTaskData.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskData.RTTI_FQN;
    }
    toJSON() {
        return BgTaskData.toJSON(this);
    }
}
BgTaskData.RTTI_CLASS = 'BgTaskData';
BgTaskData.RTTI_FQN = 'io.protoforce.projects:BgTaskData';
BgTaskData.toJSON = (value) => {
    return {
        created: value.created.toJSON(),
        history: value.history.map((e) => e.toJSON()),
        status: value.status.toJSON()
    };
};
BgTaskData.fromJSON = (value) => {
    return new BgTaskData({
        created: IRTZonedDateTime.fromJSON(value.created),
        history: value.history.map((e) => BgTaskStatus.fromJSON(e)),
        status: BgTaskStatus.fromJSON(value.status)
    });
};
