// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/FQNRule:Keep (member of ADT io.protoforce.projects:FQNRule)
  *
  * Defined at /projects/manifests/naming.model @ 11:5
  */
export class FQNRuleKeep {
    get RTTI_CLASS() {
        return FQNRuleKeep.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FQNRuleKeep.RTTI_FQN;
    }
    toJSON() {
        return FQNRuleKeep.toJSON(this);
    }
}
FQNRuleKeep.RTTI_CLASS = 'Keep';
FQNRuleKeep.RTTI_FQN = 'io.protoforce.projects/FQNRule:Keep';
FQNRuleKeep.toJSON = (value) => {
    return {};
};
FQNRuleKeep.fromJSON = (value) => {
    return new FQNRuleKeep();
};
