// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.methods
import { AuthServiceLogoutInput } from '../models/AuthServiceLogoutInput';
import { AuthServicelogoutMethodOutputHelper } from '../models/AuthServicelogoutMethodOutputHelper';
import { authServiceLogoutName } from './authServiceLogoutName';
import { authServiceRTTI } from './authServiceRTTI';
export function authServiceLogout(transport, context) {
    return transport.send(authServiceRTTI, authServiceLogoutName, new AuthServiceLogoutInput(), {
        in: AuthServiceLogoutInput,
        out: AuthServicelogoutMethodOutputHelper,
        alternative: true,
        context
    });
}
