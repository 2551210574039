// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { GenericFailure } from '../shared';
import { JSONConverter, CodecError } from '../../../irt';
import { TaskOperationFailureCannotCancel } from './TaskOperationFailureCannotCancel';
/**
  * ADT io.protoforce.tasks:TaskOperationFailure
  *
  * Defined at tasks.domain @ 19:1
  */
export class TaskOperationFailure {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT TaskOperationFailure must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.genericFailure !== 'undefined') {
            this.value = value.genericFailure;
        }
        else if (typeof value.cannotCancel !== 'undefined') {
            this.value = value.cannotCancel;
        }
        else {
            throw new Error('ADT TaskOperationFailure constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenGenericFailure, whenCannotCancel) {
        const v = this.value;
        if (v instanceof GenericFailure) {
            return whenGenericFailure(v);
        }
        else if (v instanceof TaskOperationFailureCannotCancel) {
            return whenCannotCancel(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenGenericFailure, whenCannotCancel) {
        const v = this.value;
        if (v instanceof GenericFailure) {
            return whenGenericFailure(v);
        }
        else if (v instanceof TaskOperationFailureCannotCancel) {
            return whenCannotCancel(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromGenericFailure(value) {
        return new TaskOperationFailure({ genericFailure: value });
    }
    static fromCannotCancel(value) {
        return new TaskOperationFailure({ cannotCancel: value });
    }
    static from(value) {
        return value instanceof TaskOperationFailure ? value : new TaskOperationFailure(undefined, value);
    }
    get RTTI_CLASS() {
        return TaskOperationFailure.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TaskOperationFailure.RTTI_FQN;
    }
    toJSON() {
        return TaskOperationFailure.toJSON(this);
    }
}
TaskOperationFailure.RTTI_CLASS = 'TaskOperationFailure';
TaskOperationFailure.RTTI_FQN = 'io.protoforce.tasks:TaskOperationFailure';
TaskOperationFailure.toJSON = (value) => {
    const v = value.value;
    if (v instanceof GenericFailure) {
        return JSONConverter.withTypeNested(v, 'GenericFailure');
    }
    else if (v instanceof TaskOperationFailureCannotCancel) {
        return JSONConverter.withTypeNested(v, 'CannotCancel');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
TaskOperationFailure.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'GenericFailure': {
            const vc = val;
            return TaskOperationFailure.fromGenericFailure(GenericFailure.fromJSON(vc));
        }
        case 'CannotCancel': {
            const vc = val;
            return TaskOperationFailure.fromCannotCancel(TaskOperationFailureCannotCancel.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'TaskOperationFailure'`);
    }
};
