// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingTaskPublic } from './PublishingTaskPublic';
/**
  * Class io.protoforce.projects/BgTaskTypePublic:Publish (member of ADT io.protoforce.projects:BgTaskTypePublic)
  *
  * Defined at /projects/tasks.model @ 53:5
  */
export class BgTaskTypePublicPublish {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.release = false;
            }
            return;
        }
        this.task = data.task;
        this.release = data.release;
    }
    get RTTI_CLASS() {
        return BgTaskTypePublicPublish.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskTypePublicPublish.RTTI_FQN;
    }
    toJSON() {
        return BgTaskTypePublicPublish.toJSON(this);
    }
}
BgTaskTypePublicPublish.RTTI_CLASS = 'Publish';
BgTaskTypePublicPublish.RTTI_FQN = 'io.protoforce.projects/BgTaskTypePublic:Publish';
BgTaskTypePublicPublish.toJSON = (value) => {
    return {
        task: value.task.toJSON(),
        release: value.release
    };
};
BgTaskTypePublicPublish.fromJSON = (value) => {
    return new BgTaskTypePublicPublish({
        task: PublishingTaskPublic.fromJSON(value.task),
        release: value.release
    });
};
