// tslint:disable
// eslint-disable
// package io.protoforce.notifications.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class ActionBaseCodec {
    static register(fqn, ctor) {
        ActionBaseCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete ActionBaseCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in ActionBaseCodec.registry;
    }
    static getRegistered() {
        return Object.keys(ActionBaseCodec.registry);
    }
}
ActionBaseCodec.registry = {};
ActionBaseCodec.fromFQN = (fqn, value) => {
    const ctor = ActionBaseCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface ActionBase. Register at ActionBaseCodec before it can be used.`);
    }
    return ctor(value);
};
ActionBaseCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return ActionBaseCodec.fromFQN(key, val);
};
ActionBaseCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return ActionBaseCodec.fromFQN(key, val);
};
ActionBaseCodec.fromJSON = (value) => {
    return ActionBaseCodec.fromTypeNestedJSON(value);
};
ActionBaseCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
ActionBaseCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
ActionBaseCodec.toJSON = (value) => {
    return ActionBaseCodec.toTypeNestedJSON(value);
};
