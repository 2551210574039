// tslint:disable
// eslint-disable
// package io.protoforce.notifications.notificationsservice.models
import { ActionID } from '../../ActionID';
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  Reject an action
  *
  *  @id Action ID
  *
  * Class io.protoforce.notifications.notificationsservice.models:RejectInput
  *
  * Defined at /notifications/notifications.service.model @ 65:5
  */
export class NotificationsServiceRejectInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return NotificationsServiceRejectInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotificationsServiceRejectInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.POST,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'notifs'
                    },
                    {
                        type: 'word',
                        value: 'actions'
                    },
                    {
                        type: 'word',
                        value: 'reject'
                    }
                ]
            },
            body: {
                fields: [
                    {
                        field: {
                            name: 'id',
                            ref: {
                                id: 'ActionID',
                                args: []
                            }
                        },
                        path: []
                    }
                ]
            }
        };
    }
    toJSON() {
        return NotificationsServiceRejectInput.toJSON(this);
    }
}
NotificationsServiceRejectInput.RTTI_CLASS = 'RejectInput';
NotificationsServiceRejectInput.RTTI_FQN = 'io.protoforce.notifications.notificationsservice.models:RejectInput';
NotificationsServiceRejectInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
NotificationsServiceRejectInput.fromJSON = (value) => {
    return new NotificationsServiceRejectInput({
        id: ActionID.fromJSON(value.id)
    });
};
