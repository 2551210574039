// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BuildManifestCodec } from './codecs/BuildManifestCodec';
import { BuildManifestImpl } from './BuildManifestImpl';
import { Common } from './Common';
import { MavenOptions } from './MavenOptions';
import { MavenProjectLayoutHelper } from './MavenProjectLayoutHelper';
import { ProjectNamingRule } from './ProjectNamingRule';
/**
  * Class io.protoforce.projects:JavaBuildManifest
  *
  * Defined at /projects/manifests/java.model @ 18:1
  */
export class JavaBuildManifest {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.layout = MavenProjectLayoutHelper.all[0];
            }
            return;
        }
        this.common = data.common;
        this.naming = data.naming;
        this.layout = data.layout;
        this.mvn = data.mvn;
    }
    get RTTI_CLASS() {
        return JavaBuildManifest.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return JavaBuildManifest.RTTI_FQN;
    }
    static fromBuildManifest(from, layout, mvn) {
        return new JavaBuildManifest({
            layout: layout,
            mvn: mvn,
            common: from.common,
            naming: from.naming
        });
    }
    loadBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    static fromTypeScriptBuildManifest(from, layout, mvn) {
        return new JavaBuildManifest({
            layout: layout,
            mvn: mvn,
            common: from.common,
            naming: from.naming
        });
    }
    loadTypeScriptBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toBuildManifest() {
        return new BuildManifestImpl({
            common: this.common,
            naming: this.naming
        });
    }
    static fromScalaBuildManifest(from, layout, mvn) {
        return new JavaBuildManifest({
            layout: layout,
            mvn: mvn,
            common: from.common,
            naming: from.naming
        });
    }
    loadScalaBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toJSON() {
        return JavaBuildManifest.toJSON(this);
    }
}
JavaBuildManifest.RTTI_CLASS = 'JavaBuildManifest';
JavaBuildManifest.RTTI_FQN = 'io.protoforce.projects:JavaBuildManifest';
JavaBuildManifest.toJSON = (value) => {
    return {
        common: value.common.toJSON(),
        naming: value.naming.toJSON(),
        layout: MavenProjectLayoutHelper.toJSON(value.layout),
        mvn: value.mvn.toJSON()
    };
};
JavaBuildManifest.fromJSON = (value) => {
    return new JavaBuildManifest({
        common: Common.fromJSON(value.common),
        naming: ProjectNamingRule.fromJSON(value.naming),
        layout: MavenProjectLayoutHelper.fromJSON(value.layout),
        mvn: MavenOptions.fromJSON(value.mvn)
    });
};
BuildManifestCodec.register(JavaBuildManifest.RTTI_FQN, JavaBuildManifest.fromJSON);
