// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice.methods
import { AccountIdentity } from '../../../shared';
import { OrgServiceGetOrgInput } from '../models/OrgServiceGetOrgInput';
import { OrgServicegetOrgMethodOutputHelper } from '../models/OrgServicegetOrgMethodOutputHelper';
import { orgServiceGetOrgName } from './orgServiceGetOrgName';
import { orgServiceRTTI } from './orgServiceRTTI';
export function orgServiceGetOrg(input, transport, context) {
    return transport.send(orgServiceRTTI, orgServiceGetOrgName, new OrgServiceGetOrgInput(Object.assign(Object.assign({}, input), { id: AccountIdentity.from(input.id) })), {
        in: OrgServiceGetOrgInput,
        out: OrgServicegetOrgMethodOutputHelper,
        alternative: true,
        context
    });
}
