// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/TypeScriptExtensionSpec:All (member of ADT io.protoforce.projects:TypeScriptExtensionSpec)
  *
  * Defined at /projects/manifests/build.model @ 81:5
  */
export class TypeScriptExtensionSpecAll {
    get RTTI_CLASS() {
        return TypeScriptExtensionSpecAll.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptExtensionSpecAll.RTTI_FQN;
    }
    toJSON() {
        return TypeScriptExtensionSpecAll.toJSON(this);
    }
}
TypeScriptExtensionSpecAll.RTTI_CLASS = 'All';
TypeScriptExtensionSpecAll.RTTI_FQN = 'io.protoforce.projects/TypeScriptExtensionSpec:All';
TypeScriptExtensionSpecAll.toJSON = (value) => {
    return {};
};
TypeScriptExtensionSpecAll.fromJSON = (value) => {
    return new TypeScriptExtensionSpecAll();
};
