// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { TypescriptSourceTargetGit } from './TypescriptSourceTargetGit';
/**
  * ADT io.protoforce.projects:TypescriptSourceTarget
  *
  * Defined at /projects/publishing.model @ 80:1
  */
export class TypescriptSourceTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT TypescriptSourceTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.git !== 'undefined') {
            this.value = value.git;
        }
        else {
            throw new Error('ADT TypescriptSourceTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenGit) {
        const v = this.value;
        if (v instanceof TypescriptSourceTargetGit) {
            return whenGit(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenGit) {
        const v = this.value;
        if (v instanceof TypescriptSourceTargetGit) {
            return whenGit(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromGit(value) {
        return new TypescriptSourceTarget({ git: value });
    }
    static from(value) {
        return value instanceof TypescriptSourceTarget ? value : new TypescriptSourceTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return TypescriptSourceTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptSourceTarget.RTTI_FQN;
    }
    toJSON() {
        return TypescriptSourceTarget.toJSON(this);
    }
}
TypescriptSourceTarget.RTTI_CLASS = 'TypescriptSourceTarget';
TypescriptSourceTarget.RTTI_FQN = 'io.protoforce.projects:TypescriptSourceTarget';
TypescriptSourceTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof TypescriptSourceTargetGit) {
        return JSONConverter.withTypeNested(v, 'Git');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
TypescriptSourceTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Git': {
            const vc = val;
            return TypescriptSourceTarget.fromGit(TypescriptSourceTargetGit.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'TypescriptSourceTarget'`);
    }
};
