// tslint:disable
// eslint-disable
// package io.protoforce.notifications.react.notificationsservice
import { useClientTransport, useEitherQuery, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { GetUpdatesResponse } from '../../GetUpdatesResponse';
import { notificationsServiceGetUpdates } from '../../notificationsservice/methods/notificationsServiceGetUpdates';
import { notificationsServiceGetUpdatesName } from '../../notificationsservice/methods/notificationsServiceGetUpdatesName';
import { notificationsServiceRTTI } from '../../notificationsservice/methods/notificationsServiceRTTI';
import { useCallback } from 'react';
export const notificationsServiceGetUpdatesTags = [
    '$pf',
    notificationsServiceRTTI.RTTI_FQN,
    notificationsServiceGetUpdatesName
];
/**
  *
  *  Get the latest updates
  *
  * Defined at /notifications/notifications.service.model @ 27:5
  */
export function useNotificationsServiceGetUpdates($options, $context) {
    const clientTransport = useClientTransport(notificationsServiceRTTI, $options);
    return useEitherQuery(useServiceKey(notificationsServiceRTTI.RTTI_FQN, notificationsServiceGetUpdatesName)[0], useCallback(() => notificationsServiceGetUpdates(clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: notificationsServiceGetUpdatesTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.toJSON(), []),
            fromCache: useCallback(v => GetUpdatesResponse.fromJSON(v), [])
        } }, ($options || {})), { tags: useMergedTags(notificationsServiceGetUpdatesTags) }));
}
