// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  OAuthAccessCode holds a code obtained during the OAuth process,
  *  which can then be used to exchange for a token.
  *
  * Class io.protoforce.auth:OAuthAccessCode
  *
  * Defined at /auth/oauth.model @ 21:1
  */
export class OAuthAccessCode {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.code = '';
            }
            return;
        }
        this.code = data.code;
    }
    get RTTI_CLASS() {
        return OAuthAccessCode.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OAuthAccessCode.RTTI_FQN;
    }
    toJSON() {
        return OAuthAccessCode.toJSON(this);
    }
}
OAuthAccessCode.RTTI_CLASS = 'OAuthAccessCode';
OAuthAccessCode.RTTI_FQN = 'io.protoforce.auth:OAuthAccessCode';
OAuthAccessCode.toJSON = (value) => {
    return {
        code: value.code
    };
};
OAuthAccessCode.fromJSON = (value) => {
    return new OAuthAccessCode({
        code: value.code
    });
};
