// tslint:disable
// eslint-disable
// package io.protoforce.search
import { Iterator } from '../shared';
import { SearchProject } from './SearchProject';
/**
  * Class io.protoforce.search:SearchProjectsResponse
  *
  * Defined at search.domain @ 33:1
  */
export class SearchProjectsResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.results = [];
            }
            return;
        }
        this.results = data.results;
        this.iterator = data.iterator;
    }
    get RTTI_CLASS() {
        return SearchProjectsResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SearchProjectsResponse.RTTI_FQN;
    }
    toJSON() {
        return SearchProjectsResponse.toJSON(this);
    }
}
SearchProjectsResponse.RTTI_CLASS = 'SearchProjectsResponse';
SearchProjectsResponse.RTTI_FQN = 'io.protoforce.search:SearchProjectsResponse';
SearchProjectsResponse.toJSON = (value) => {
    return {
        results: value.results.map((e) => e.toJSON()),
        iterator: value.iterator.toJSON()
    };
};
SearchProjectsResponse.fromJSON = (value) => {
    return new SearchProjectsResponse({
        results: value.results.map((e) => SearchProject.fromJSON(e)),
        iterator: Iterator.fromJSON(value.iterator)
    });
};
