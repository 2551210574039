// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { PinnedProjects } from './PinnedProjects';
import { User } from './User';
/**
  * Class io.protoforce.auth:GetAccountUserResponse
  *
  * Defined at /auth/account.service.model @ 11:1
  */
export class GetAccountUserResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.user = data.user;
        this.pinned = data.pinned;
    }
    get RTTI_CLASS() {
        return GetAccountUserResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GetAccountUserResponse.RTTI_FQN;
    }
    toJSON() {
        return GetAccountUserResponse.toJSON(this);
    }
}
GetAccountUserResponse.RTTI_CLASS = 'GetAccountUserResponse';
GetAccountUserResponse.RTTI_FQN = 'io.protoforce.auth:GetAccountUserResponse';
GetAccountUserResponse.toJSON = (value) => {
    return {
        user: value.user.toJSON(),
        pinned: value.pinned.toJSON()
    };
};
GetAccountUserResponse.fromJSON = (value) => {
    return new GetAccountUserResponse({
        user: User.fromJSON(value.user),
        pinned: PinnedProjects.fromJSON(value.pinned)
    });
};
