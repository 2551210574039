import { useMemo } from 'react';
export const AvatarBorderRadius = 5;
export var AvatarSize;
(function (AvatarSize) {
    AvatarSize[AvatarSize["Mini"] = 16] = "Mini";
    AvatarSize[AvatarSize["Minor"] = 24] = "Minor";
    AvatarSize[AvatarSize["Small"] = 32] = "Small";
    AvatarSize[AvatarSize["Normal"] = 64] = "Normal";
    AvatarSize[AvatarSize["Large"] = 128] = "Large";
    AvatarSize[AvatarSize["Huge"] = 164] = "Huge";
})(AvatarSize || (AvatarSize = {}));
export function capitalLetters(id) {
    if (!id) {
        return undefined;
    }
    // Trim whitespace
    id = id.replace(/^\s+|\s+$/g, '');
    if (id.length === 0) {
        return undefined;
    }
    const pieces = id.toLocaleUpperCase().split(' ');
    if (pieces.length === 0) {
        return undefined;
    }
    return pieces.length === 1 ? pieces[0][0] : pieces[0][0] + pieces[1][0];
}
export function deterministicCircle(rnd, wMin = 64, wMax = 96, x = 127, y = 127) {
    return {
        x: rnd.nextInt32([0, x]),
        y: rnd.nextInt32([0, y]),
        radius: rnd.nextInt32([wMin, wMax])
    };
}
export function useCapitalNameLetters(props) {
    const nameLetters = useMemo(() => {
        const name = props.name ? props.name : props.account ? props.account.displayName || props.account.name : undefined;
        return capitalLetters(name);
    }, [props.name, props.account]);
    return nameLetters;
}
