// tslint:disable
// eslint-disable
// package io.protoforce.auth.orgservice
import { HTTPClientTransport } from '../../../../irt';
import { orgServiceGetOrg } from './methods/orgServiceGetOrg';
import { orgServiceGetOrgsBrief } from './methods/orgServiceGetOrgsBrief';
import { orgServiceRTTI } from './methods/orgServiceRTTI';
export class OrgServiceClient {
    constructor(transport) {
        this.transport = transport;
    }
    get RTTI_CLASS() {
        return OrgServiceClient.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OrgServiceClient.RTTI_FQN;
    }
    static fromHTTP(endpoint, options) {
        return new OrgServiceClient(new HTTPClientTransport(endpoint, options));
    }
    /**
      *
      *  Brief information about a set of requested organizations.
      *
      *  @orgs List of orgs for which the details have to be returned
      *
      *  Note: returns no error if IDs are not found, just an empty array
      *
      * Defined at /auth/organization.service.model @ 222:5
      */
    getOrgsBrief(orgs, _context) {
        return orgServiceGetOrgsBrief({ orgs }, this.transport, _context);
    }
    /**
      *
      *  Get details about a particular organization.
      *
      *  @id Org identity
      *
      * Defined at /auth/organization.service.model @ 229:5
      */
    getOrg(id, _context) {
        return orgServiceGetOrg({ id }, this.transport, _context);
    }
}
OrgServiceClient.RTTI_CLASS = orgServiceRTTI.RTTI_CLASS;
OrgServiceClient.RTTI_FQN = orgServiceRTTI.RTTI_FQN;
