// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BuildManifestCodec } from './codecs/BuildManifestCodec';
import { BuildManifestImpl } from './BuildManifestImpl';
import { Common } from './Common';
import { ProjectNamingRule } from './ProjectNamingRule';
import { SBTOptions } from './SBTOptions';
import { ScalaExtensionSpec } from './ScalaExtensionSpec';
import { ScalaProjectLayoutHelper } from './ScalaProjectLayoutHelper';
/**
  * Class io.protoforce.projects:ScalaBuildManifest
  *
  * Defined at /projects/manifests/scala.model @ 44:1
  */
export class ScalaBuildManifest {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.layout = ScalaProjectLayoutHelper.all[0];
            }
            return;
        }
        this.common = data.common;
        this.naming = data.naming;
        this.extensions = data.extensions;
        this.layout = data.layout;
        this.sbt = data.sbt;
    }
    get RTTI_CLASS() {
        return ScalaBuildManifest.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaBuildManifest.RTTI_FQN;
    }
    static fromBuildManifest(from, extensions, layout, sbt) {
        return new ScalaBuildManifest({
            extensions: extensions,
            layout: layout,
            sbt: sbt,
            common: from.common,
            naming: from.naming
        });
    }
    loadBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toBuildManifest() {
        return new BuildManifestImpl({
            common: this.common,
            naming: this.naming
        });
    }
    static fromJavaBuildManifest(from, extensions, layout, sbt) {
        return new ScalaBuildManifest({
            extensions: extensions,
            layout: layout,
            sbt: sbt,
            common: from.common,
            naming: from.naming
        });
    }
    loadJavaBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    static fromTypeScriptBuildManifest(from, extensions, layout, sbt) {
        return new ScalaBuildManifest({
            extensions: extensions,
            layout: layout,
            sbt: sbt,
            common: from.common,
            naming: from.naming
        });
    }
    loadTypeScriptBuildManifest(from) {
        this.common = from.common;
        this.naming = from.naming;
    }
    toJSON() {
        return ScalaBuildManifest.toJSON(this);
    }
}
ScalaBuildManifest.RTTI_CLASS = 'ScalaBuildManifest';
ScalaBuildManifest.RTTI_FQN = 'io.protoforce.projects:ScalaBuildManifest';
ScalaBuildManifest.toJSON = (value) => {
    return {
        common: value.common.toJSON(),
        naming: value.naming.toJSON(),
        extensions: value.extensions.toJSON(),
        layout: ScalaProjectLayoutHelper.toJSON(value.layout),
        sbt: value.sbt.toJSON()
    };
};
ScalaBuildManifest.fromJSON = (value) => {
    return new ScalaBuildManifest({
        common: Common.fromJSON(value.common),
        naming: ProjectNamingRule.fromJSON(value.naming),
        extensions: ScalaExtensionSpec.fromJSON(value.extensions),
        layout: ScalaProjectLayoutHelper.fromJSON(value.layout),
        sbt: SBTOptions.fromJSON(value.sbt)
    });
};
BuildManifestCodec.register(ScalaBuildManifest.RTTI_FQN, ScalaBuildManifest.fromJSON);
