// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { TypescriptCompiled } from './TypescriptCompiled';
import { TypescriptSourceTarget } from './TypescriptSourceTarget';
/**
  * ADT io.protoforce.projects:TypescriptTarget
  *
  * Defined at /projects/publishing.model @ 38:1
  */
export class TypescriptTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT TypescriptTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.typescriptCompiled !== 'undefined') {
            this.value = value.typescriptCompiled;
        }
        else if (typeof value.typescriptSourceTarget !== 'undefined') {
            this.value = value.typescriptSourceTarget;
        }
        else {
            throw new Error('ADT TypescriptTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenTypescriptCompiled, whenTypescriptSourceTarget) {
        const v = this.value;
        if (v instanceof TypescriptCompiled) {
            return whenTypescriptCompiled(v);
        }
        else if (v instanceof TypescriptSourceTarget) {
            return whenTypescriptSourceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenTypescriptCompiled, whenTypescriptSourceTarget) {
        const v = this.value;
        if (v instanceof TypescriptCompiled) {
            return whenTypescriptCompiled(v);
        }
        else if (v instanceof TypescriptSourceTarget) {
            return whenTypescriptSourceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromTypescriptCompiled(value) {
        return new TypescriptTarget({ typescriptCompiled: value });
    }
    static fromTypescriptSourceTarget(value) {
        return new TypescriptTarget({ typescriptSourceTarget: value });
    }
    static from(value) {
        return value instanceof TypescriptTarget ? value : new TypescriptTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return TypescriptTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptTarget.RTTI_FQN;
    }
    toJSON() {
        return TypescriptTarget.toJSON(this);
    }
}
TypescriptTarget.RTTI_CLASS = 'TypescriptTarget';
TypescriptTarget.RTTI_FQN = 'io.protoforce.projects:TypescriptTarget';
TypescriptTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof TypescriptCompiled) {
        return JSONConverter.withTypeNested(v, 'TypescriptCompiled');
    }
    else if (v instanceof TypescriptSourceTarget) {
        return JSONConverter.withTypeNested(v, 'TypescriptSourceTarget');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
TypescriptTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'TypescriptCompiled': {
            const vc = val;
            return TypescriptTarget.fromTypescriptCompiled(TypescriptCompiled.fromJSON(vc));
        }
        case 'TypescriptSourceTarget': {
            const vc = val;
            return TypescriptTarget.fromTypescriptSourceTarget(TypescriptSourceTarget.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'TypescriptTarget'`);
    }
};
