// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { GenericSuccess } from './GenericSuccess';
import { SuccessBaseCodec } from './codecs/SuccessBaseCodec';
import { SuccessBaseImpl } from './SuccessBaseImpl';
/**
  *
  *  Success and Upload is used to denote a successful
  *  execution of an operation, which requires a post
  *  upload of a file.
  *
  * Class io.protoforce.shared:SuccessAndUpload
  *
  * Defined at /shared/upload.model @ 48:1
  */
export class SuccessAndUpload {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.uploadPath = '';
                this.viewPath = '';
            }
            return;
        }
        this.message = data.message;
        this.uploadPath = data.uploadPath;
        this.viewPath = data.viewPath;
    }
    get RTTI_CLASS() {
        return SuccessAndUpload.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SuccessAndUpload.RTTI_FQN;
    }
    static fromSuccessBase(from, uploadPath, viewPath) {
        return new SuccessAndUpload({
            uploadPath: uploadPath,
            viewPath: viewPath,
            message: from.message
        });
    }
    loadSuccessBase(from) {
        this.message = from.message;
    }
    toSuccessBase() {
        return new SuccessBaseImpl({
            message: this.message
        });
    }
    static fromGenericSuccess(from, uploadPath, viewPath) {
        return new SuccessAndUpload({
            uploadPath: uploadPath,
            viewPath: viewPath,
            message: from.message
        });
    }
    loadGenericSuccess(from) {
        this.message = from.message;
    }
    toGenericSuccess() {
        return new GenericSuccess({
            message: this.message
        });
    }
    toJSON() {
        return SuccessAndUpload.toJSON(this);
    }
}
SuccessAndUpload.RTTI_CLASS = 'SuccessAndUpload';
SuccessAndUpload.RTTI_FQN = 'io.protoforce.shared:SuccessAndUpload';
SuccessAndUpload.toJSON = (value) => {
    return {
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined,
        uploadPath: value.uploadPath,
        viewPath: value.viewPath
    };
};
SuccessAndUpload.fromJSON = (value) => {
    return new SuccessAndUpload({
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined,
        uploadPath: value.uploadPath,
        viewPath: value.viewPath
    });
};
SuccessBaseCodec.register(SuccessAndUpload.RTTI_FQN, SuccessAndUpload.fromJSON);
