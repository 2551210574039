// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  A couple of common entities across the service.
  *
  * Enum io.protoforce.shared:NotFoundEntity
  *
  * Defined at /shared/error.model @ 61:1
  */
export var NotFoundEntity;
(function (NotFoundEntity) {
    /**
      * Defined at /shared/error.model @ 62:5
      */
    NotFoundEntity[NotFoundEntity["Organization"] = 0] = "Organization";
    /**
      * Defined at /shared/error.model @ 63:5
      */
    NotFoundEntity[NotFoundEntity["Project"] = 1] = "Project";
    /**
      * Defined at /shared/error.model @ 64:5
      */
    NotFoundEntity[NotFoundEntity["Revision"] = 2] = "Revision";
    /**
      * Defined at /shared/error.model @ 65:5
      */
    NotFoundEntity[NotFoundEntity["Revisions"] = 3] = "Revisions";
    /**
      * Defined at /shared/error.model @ 66:5
      */
    NotFoundEntity[NotFoundEntity["User"] = 4] = "User";
    /**
      * Defined at /shared/error.model @ 67:5
      */
    NotFoundEntity[NotFoundEntity["Task"] = 5] = "Task";
    /**
      * Defined at /shared/error.model @ 68:5
      */
    NotFoundEntity[NotFoundEntity["ForumTopic"] = 6] = "ForumTopic";
    /**
      * Defined at /shared/error.model @ 69:5
      */
    NotFoundEntity[NotFoundEntity["ForumComment"] = 7] = "ForumComment";
    /**
      * Defined at /shared/error.model @ 70:5
      */
    NotFoundEntity[NotFoundEntity["Tag"] = 8] = "Tag";
    /**
      * Defined at /shared/error.model @ 71:5
      */
    NotFoundEntity[NotFoundEntity["Release"] = 9] = "Release";
    /**
      * Defined at /shared/error.model @ 72:5
      */
    NotFoundEntity[NotFoundEntity["Account"] = 10] = "Account";
})(NotFoundEntity || (NotFoundEntity = {}));
