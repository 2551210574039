// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  Unique name status
  *
  * Enum io.protoforce.shared:UniqueNameStatus
  *
  * Defined at shared.domain @ 32:1
  */
export var UniqueNameStatus;
(function (UniqueNameStatus) {
    /**
      *
      *  Name is available
      *
      * Defined at shared.domain @ 36:5
      */
    UniqueNameStatus[UniqueNameStatus["Available"] = 0] = "Available";
    /**
      *
      *  Name is taken by someone else
      *
      * Defined at shared.domain @ 40:5
      */
    UniqueNameStatus[UniqueNameStatus["Taken"] = 1] = "Taken";
    /**
      *
      *  Name is reserved and can not be used
      *
      * Defined at shared.domain @ 44:5
      */
    UniqueNameStatus[UniqueNameStatus["Reserved"] = 2] = "Reserved";
})(UniqueNameStatus || (UniqueNameStatus = {}));
