// tslint:disable
// eslint-disable
// package io.protoforce.search
import { SearchOrgsResponse } from './SearchOrgsResponse';
import { SearchProjectsResponse } from './SearchProjectsResponse';
import { SearchUsersResponse } from './SearchUsersResponse';
/**
  * Class io.protoforce.search:SearchResponse
  *
  * Defined at search.domain @ 38:1
  */
export class SearchResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.users = data.users;
        this.orgs = data.orgs;
        this.projects = data.projects;
    }
    get RTTI_CLASS() {
        return SearchResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SearchResponse.RTTI_FQN;
    }
    toJSON() {
        return SearchResponse.toJSON(this);
    }
}
SearchResponse.RTTI_CLASS = 'SearchResponse';
SearchResponse.RTTI_FQN = 'io.protoforce.search:SearchResponse';
SearchResponse.toJSON = (value) => {
    return {
        users: value.users.toJSON(),
        orgs: value.orgs.toJSON(),
        projects: value.projects.toJSON()
    };
};
SearchResponse.fromJSON = (value) => {
    return new SearchResponse({
        users: SearchUsersResponse.fromJSON(value.users),
        orgs: SearchOrgsResponse.fromJSON(value.orgs),
        projects: SearchProjectsResponse.fromJSON(value.projects)
    });
};
