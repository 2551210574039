// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { GitSourceTargetCodec } from './codecs/GitSourceTargetCodec';
import { PublishingCredentialsGitCreds } from './PublishingCredentialsGitCreds';
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
export class GitSourceTargetImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.credentials = data.credentials;
    }
    get RTTI_CLASS() {
        return GitSourceTargetImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return GitSourceTargetImpl.RTTI_FQN;
    }
    toGitSourceTarget() {
        return new GitSourceTargetImpl({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials
        });
    }
    static fromScalaSourceTargetPublicGit(from, credentials) {
        return new GitSourceTargetImpl({
            credentials: credentials,
            target: from.target,
            layout: from.layout
        });
    }
    loadScalaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    static fromJavaSourceTargetPublicGit(from, credentials) {
        return new GitSourceTargetImpl({
            credentials: credentials,
            target: from.target,
            layout: from.layout
        });
    }
    loadJavaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    static fromTypescriptSourceTargetPublicGit(from, credentials) {
        return new GitSourceTargetImpl({
            credentials: credentials,
            target: from.target,
            layout: from.layout
        });
    }
    loadTypescriptSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    static fromProtoforceTargetPublicGit(from, credentials) {
        return new GitSourceTargetImpl({
            credentials: credentials,
            target: from.target,
            layout: from.layout
        });
    }
    loadProtoforceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    toJSON() {
        return GitSourceTargetImpl.toJSON(this);
    }
}
GitSourceTargetImpl.RTTI_CLASS = 'GitSourceTarget';
GitSourceTargetImpl.RTTI_FQN = 'io.protoforce.projects:GitSourceTarget';
GitSourceTargetImpl.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        credentials: value.credentials.toJSON()
    };
};
GitSourceTargetImpl.fromJSON = (value) => {
    return new GitSourceTargetImpl({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        credentials: PublishingCredentialsGitCreds.fromJSON(value.credentials)
    });
};
GitSourceTargetCodec.register(GitSourceTargetImpl.RTTI_FQN, GitSourceTargetImpl.fromJSON);
