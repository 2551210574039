// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.methods
import { TasksServiceGetLogInput } from '../models/TasksServiceGetLogInput';
import { TasksServicegetLogMethodOutputHelper } from '../models/TasksServicegetLogMethodOutputHelper';
import { tasksServiceGetLogName } from './tasksServiceGetLogName';
import { tasksServiceRTTI } from './tasksServiceRTTI';
export function tasksServiceGetLog(input, transport, context) {
    return transport.send(tasksServiceRTTI, tasksServiceGetLogName, new TasksServiceGetLogInput(input), {
        in: TasksServiceGetLogInput,
        out: TasksServicegetLogMethodOutputHelper,
        alternative: true,
        context
    });
}
