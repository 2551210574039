// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  AuthTokens is a pair of token that are commonly
  *  returned during authentication.
  *
  * Class io.protoforce.auth:AuthTokens
  *
  * Defined at /auth/auth.service.model @ 6:1
  */
export class AuthTokens {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.access = '';
                this.refresh = '';
            }
            return;
        }
        this.access = data.access;
        this.refresh = data.refresh;
    }
    get RTTI_CLASS() {
        return AuthTokens.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthTokens.RTTI_FQN;
    }
    toJSON() {
        return AuthTokens.toJSON(this);
    }
}
AuthTokens.RTTI_CLASS = 'AuthTokens';
AuthTokens.RTTI_FQN = 'io.protoforce.auth:AuthTokens';
AuthTokens.toJSON = (value) => {
    return {
        access: value.access,
        refresh: value.refresh
    };
};
AuthTokens.fromJSON = (value) => {
    return new AuthTokens({
        access: value.access,
        refresh: value.refresh
    });
};
