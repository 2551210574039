var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useMemo } from 'react';
import { AccountIdentity } from '@protoforce/shared';
import { useOrgServiceGetOrgsBrief, useOrgService } from '@protoforce/auth';
export function useOrgBrief(id, options = {}) {
    const identity = useMemo(() => {
        if (!id) {
            return undefined;
        }
        if (id instanceof AccountIdentity) {
            return [id];
        }
        return [AccountIdentity.fromAccountID(id)];
    }, [id]);
    const service = useOrgService();
    const batcher = useCallback((args) => {
        const flattened = args.map(a => a[0]).filter(a => a ? true : false).map(a => a[0]);
        return new Promise((resolve, reject) => {
            service.getOrgsBrief(flattened).then(briefs => {
                const remapped = flattened.map(uf => {
                    const brief = briefs.find(u => uf.match(whenAccountID => u.id.id === whenAccountID.id, whenAccountName => u.name === whenAccountName.name));
                    return brief ? {
                        data: [brief]
                    } : {
                        error: new Error(`Organization with ID ${uf.value.toJSON()} is not found`)
                    };
                });
                resolve({
                    shape: 'direct',
                    data: remapped
                });
            }, err => reject(err)).catch(err => reject(err));
        });
    }, [service]);
    const _a = useOrgServiceGetOrgsBrief(identity, Object.assign(Object.assign({}, options), { batcher, delay: 50 })), { data } = _a, rest = __rest(_a, ["data"]);
    return Object.assign({ data: data ? data[0] : undefined }, rest);
}
