export const STATE_LENGTH = 32;
export function randomKey(len) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // TODO Consider more crypto stable generator here
    for (let i = 0; i < len; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
// copied almost directly from Mersenne Twister implementation found in
// https://gist.github.com/banksean/300494 all rights reserved to him.
/* tslint:disable */
export class MTRandom {
    constructor(seed = null) {
        /* least significant r bits */
        this.mt = new Array(MTRandom.N);
        /* the array for the state vector */
        this.mti = MTRandom.N + 1;
        this.setSeed(seed);
    }
    /* mti==N+1 means mt[N] is not initialized */
    static hash(str, min = 0, max = 4294967295) {
        if (!str) {
            return min;
        }
        let hash = 5381, i = str.length;
        while (i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }
        /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
         * integers. Since we want the results to be always positive, convert the
         * signed int to an unsigned by doing an unsigned bitshift. */
        hash = hash >>> 0;
        return (hash % (max - min)) + min;
    }
    setSeed(seed = null) {
        if (seed == null) {
            seed = new Date().getTime();
        }
        this.init_genrand(seed);
    }
    init_genrand(s) {
        this.mt[0] = s >>> 0;
        for (this.mti = 1; this.mti < MTRandom.N; this.mti++) {
            var s = this.mt[this.mti - 1] ^ (this.mt[this.mti - 1] >>> 30);
            this.mt[this.mti] = ((((s & 0xffff0000) >>> 16) * 1812433253) << 16) + (s & 0x0000ffff) * 1812433253 + this.mti;
            /* See Knuth TAOCP Vol2. 3rd Ed. P.106 for multiplier. */
            /* In the previous versions, MSBs of the seed affect   */
            /* only MSBs of the array mt[].                        */
            /* 2002/01/09 modified by Makoto Matsumoto             */
            this.mt[this.mti] >>>= 0;
            /* for >32 bit machines */
        }
    }
    /**
     * generates a random number on [0,0xffffffff]-interval
     * @private
     */
    _nextInt32() {
        let y;
        let mag01 = new Array(0x0, MTRandom.MATRIX_A);
        /* mag01[x] = x * MATRIX_A  for x=0,1 */
        if (this.mti >= MTRandom.N) {
            /* generate N words at one time */
            let kk;
            if (this.mti == MTRandom.N + 1)
                /* if init_genrand() has not been called, */
                this.init_genrand(5489);
            /* a default initial seed is used */
            for (kk = 0; kk < MTRandom.N - MTRandom.M; kk++) {
                y = (this.mt[kk] & MTRandom.UPPER_MASK) | (this.mt[kk + 1] & MTRandom.LOWER_MASK);
                this.mt[kk] = this.mt[kk + MTRandom.M] ^ (y >>> 1) ^ mag01[y & 0x1];
            }
            for (; kk < MTRandom.N - 1; kk++) {
                y = (this.mt[kk] & MTRandom.UPPER_MASK) | (this.mt[kk + 1] & MTRandom.LOWER_MASK);
                this.mt[kk] = this.mt[kk + (MTRandom.M - MTRandom.N)] ^ (y >>> 1) ^ mag01[y & 0x1];
            }
            y = (this.mt[MTRandom.N - 1] & MTRandom.UPPER_MASK) | (this.mt[0] & MTRandom.LOWER_MASK);
            this.mt[MTRandom.N - 1] = this.mt[MTRandom.M - 1] ^ (y >>> 1) ^ mag01[y & 0x1];
            this.mti = 0;
        }
        y = this.mt[this.mti++];
        /* Tempering */
        y ^= y >>> 11;
        y ^= (y << 7) & 0x9d2c5680;
        y ^= (y << 15) & 0xefc60000;
        y ^= y >>> 18;
        return y >>> 0;
    }
    /**
     * generates an int32 pseudo random number
     * @param range: an optional [from, to] range, if not specified the result will be in range [0,0xffffffff]
     * @return {number}
     */
    nextInt32(range = null) {
        var result = this._nextInt32();
        if (range == null) {
            return result;
        }
        return (result % (range[1] - range[0])) + range[0];
    }
    /**
     * generates a random number on [0,0x7fffffff]-interval
     */
    nextInt31() {
        return this._nextInt32() >>> 1;
    }
    /**
     * generates a random number on [0,1]-real-interval
     */
    nextNumber() {
        return this._nextInt32() * (1.0 / 4294967295.0);
    }
    /**
     * generates a random number on [0,1) with 53-bit resolution
     */
    nextNumber53() {
        var a = this._nextInt32() >>> 5, b = this._nextInt32() >>> 6;
        return (a * 67108864.0 + b) * (1.0 / 9007199254740992.0);
    }
}
MTRandom.N = 624;
MTRandom.M = 397;
MTRandom.MATRIX_A = 0x9908b0df;
/* constant vector a */
MTRandom.UPPER_MASK = 0x80000000;
/* most significant w-r bits */
MTRandom.LOWER_MASK = 0x7fffffff;
export function randomGuid() {
    const guidRandom = 0x10000;
    const guidLength = 16;
    function s4() {
        return Math.floor((1 + Math.random()) * guidRandom)
            .toString(guidLength)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
/* tslint:enable */
