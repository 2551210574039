// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.orgservice
import { OrgServiceClient } from '../../orgservice/OrgServiceClient';
import { useClientTransport } from '../../../../../irt-react';
import { useMemo } from 'react';
/**
  *
  *  Service provides public details of organizations
  *
  * Defined at /auth/organization.service.model @ 214:1
  */
export function useOrgService(transport) {
    const clientTransport = useClientTransport(OrgServiceClient, { transport });
    return useMemo(() => {
        return new OrgServiceClient(clientTransport);
    }, [clientTransport]);
}
