import React from 'react';
import { Icon } from '@blueprintjs/core';
import { timeToPoint } from '~/core';
const Cancelled = () => React.createElement("span", { className: 'bp4-text-muted' },
    React.createElement(Icon, { icon: 'cross', intent: 'none' }),
    "\u00A0Cancelled");
export const TaskStatusView = React.memo((props) => {
    const { status, cancelled } = props;
    let statusContent = status.match(whenCreated => {
        return React.createElement("span", { className: 'color-primary' },
            React.createElement(Icon, { icon: 'plus', intent: 'primary' }),
            "\u00A0Created");
    }, whenStarted => {
        return React.createElement("span", { className: 'color-warning' },
            React.createElement(Icon, { icon: 'flows', intent: 'warning' }),
            "\u00A0Started");
    }, whenFinished => {
        return React.createElement("span", { className: 'color-success' },
            React.createElement(Icon, { icon: 'tick', intent: 'success' }),
            "\u00A0Finished");
    }, whenErrored => {
        return React.createElement("span", { className: 'color-danger' },
            React.createElement(Icon, { icon: 'issue', intent: 'danger' }),
            "\u00A0Failed");
    }, whenCancelled => {
        return React.createElement(Cancelled, null);
    });
    if (cancelled) {
        statusContent = React.createElement(Cancelled, null);
    }
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column'
        } },
        status.value.title &&
            React.createElement("span", null, status.value.title),
        statusContent,
        !cancelled && React.createElement("span", { className: 'bp4-text-muted' },
            "(",
            timeToPoint(status.value.timestamp),
            ")"),
        status.value.description && React.createElement("span", { className: 'bp4-text-muted' }, status.value.description)));
});
