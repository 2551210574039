// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.authservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { authServiceLogout } from '../../authservice/methods/authServiceLogout';
import { authServiceRTTI } from '../../authservice/methods/authServiceRTTI';
import { useCallback } from 'react';
/**
  *
  *  logout Erases associated with the session refresh token
  *
  * Defined at /auth/auth.service.model @ 176:5
  */
export function useAuthServiceLogout(options, context) {
    const clientTransport = useClientTransport(authServiceRTTI, options);
    const request = useCallback(() => {
        return authServiceLogout(clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
