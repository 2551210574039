// tslint:disable
// eslint-disable
// package io.protoforce.shared.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class SuccessBaseCodec {
    static register(fqn, ctor) {
        SuccessBaseCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete SuccessBaseCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in SuccessBaseCodec.registry;
    }
    static getRegistered() {
        return Object.keys(SuccessBaseCodec.registry);
    }
}
SuccessBaseCodec.registry = {};
SuccessBaseCodec.fromFQN = (fqn, value) => {
    const ctor = SuccessBaseCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface SuccessBase. Register at SuccessBaseCodec before it can be used.`);
    }
    return ctor(value);
};
SuccessBaseCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return SuccessBaseCodec.fromFQN(key, val);
};
SuccessBaseCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return SuccessBaseCodec.fromFQN(key, val);
};
SuccessBaseCodec.fromJSON = (value) => {
    return SuccessBaseCodec.fromTypeNestedJSON(value);
};
SuccessBaseCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
SuccessBaseCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
SuccessBaseCodec.toJSON = (value) => {
    return SuccessBaseCodec.toTypeNestedJSON(value);
};
