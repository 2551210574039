// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { TypeScriptExtensionHelper } from './TypeScriptExtensionHelper';
/**
  * Class io.protoforce.projects/TypeScriptExtensionSpec:Excluded (member of ADT io.protoforce.projects:TypeScriptExtensionSpec)
  *
  * Defined at /projects/manifests/build.model @ 82:5
  */
export class TypeScriptExtensionSpecExcluded {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.extensions = [];
            }
            return;
        }
        this.extensions = data.extensions;
    }
    get RTTI_CLASS() {
        return TypeScriptExtensionSpecExcluded.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptExtensionSpecExcluded.RTTI_FQN;
    }
    toJSON() {
        return TypeScriptExtensionSpecExcluded.toJSON(this);
    }
}
TypeScriptExtensionSpecExcluded.RTTI_CLASS = 'Excluded';
TypeScriptExtensionSpecExcluded.RTTI_FQN = 'io.protoforce.projects/TypeScriptExtensionSpec:Excluded';
TypeScriptExtensionSpecExcluded.toJSON = (value) => {
    return {
        extensions: value.extensions.map((e) => TypeScriptExtensionHelper.toJSON(e))
    };
};
TypeScriptExtensionSpecExcluded.fromJSON = (value) => {
    return new TypeScriptExtensionSpecExcluded({
        extensions: value.extensions.map((e) => TypeScriptExtensionHelper.fromJSON(e))
    });
};
