// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { MavenProjectLayout } from './MavenProjectLayout';
export class MavenProjectLayoutHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return MavenProjectLayoutHelper.toString(this.value);
    }
    static toString(e) {
        return MavenProjectLayout[e];
    }
    static fromString(e) {
        return MavenProjectLayout[e];
    }
    get RTTI_CLASS() {
        return MavenProjectLayoutHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return MavenProjectLayoutHelper.RTTI_FQN;
    }
    toJSON() {
        return MavenProjectLayoutHelper.toJSON(this.value);
    }
}
MavenProjectLayoutHelper.RTTI_CLASS = 'MavenProjectLayout';
MavenProjectLayoutHelper.RTTI_FQN = 'io.protoforce.projects:MavenProjectLayout';
MavenProjectLayoutHelper.all = [
    MavenProjectLayout.PLAIN,
    MavenProjectLayout.MAVEN
];
MavenProjectLayoutHelper.toJSON = (value) => {
    return MavenProjectLayout[value];
};
MavenProjectLayoutHelper.fromJSON = (value) => {
    switch (value) {
        case 'PLAIN': return MavenProjectLayout.PLAIN;
        case 'MAVEN': return MavenProjectLayout.MAVEN;
        default: throw new CodecError(`Unexpected value for enum MavenProjectLayout: '${value}'`);
    }
};
