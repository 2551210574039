// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.methods
import { AuthServiceRefreshInput } from '../models/AuthServiceRefreshInput';
import { AuthServicerefreshMethodOutputHelper } from '../models/AuthServicerefreshMethodOutputHelper';
import { authServiceRTTI } from './authServiceRTTI';
import { authServiceRefreshName } from './authServiceRefreshName';
export function authServiceRefresh(input, transport, context) {
    return transport.send(authServiceRTTI, authServiceRefreshName, new AuthServiceRefreshInput(input), {
        in: AuthServiceRefreshInput,
        out: AuthServicerefreshMethodOutputHelper,
        alternative: true,
        context
    });
}
