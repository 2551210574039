import { AuthTokens, User, UserOrganization } from '@protoforce/auth';
import { Project } from '@protoforce/projects';
import { PlanState } from '@protoforce/shared';
function getJWTExpiration(token) {
    try {
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error(`Unexpected number of parts in a token, expected 3 got ${parts.length}`);
        }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const json = JSON.parse(window.atob(base64));
        // tslint:disable-next-line:no-string-literal
        const exp = json['exp'];
        if (typeof exp !== 'number') {
            throw new Error(`Expected exp field in a payload, didn't find it`);
        }
        return new Date(exp * 1000);
    }
    catch (err) {
        console.warn(`Can't parse a JWT token: ${err}`);
        return undefined;
    }
}
function getExpirationDetails(token) {
    // If we got logged in, we need to setup the next time we need to refresh the token
    const expiresAt = getJWTExpiration(token);
    if (typeof expiresAt === 'undefined') {
        console.warn('Cannot detect expiration in the token.');
        return {
            expiresAt: undefined,
            refreshAt: undefined
        };
    }
    else {
        // 1 hour ahead of time
        const REFRESH_BUFFER = 60 * 60 * 1000;
        return {
            expiresAt: expiresAt.getTime(),
            refreshAt: expiresAt.getTime() - REFRESH_BUFFER
        };
    }
}
export function loggedInStateToJSON(state) {
    return {
        version: 1,
        tokens: state.tokens.toJSON(),
        user: state.user.toJSON(),
        planState: state.planState.toJSON(),
        orgs: state.orgs.map(o => o.toJSON()),
        projects: state.projects.map(p => p.toJSON())
    };
}
export function loggedInStateFromJSON(state) {
    if (state.version !== 1) {
        throw new Error(`Unexpected version in the storage: ${state.version}`);
    }
    const tokens = AuthTokens.fromJSON(state.tokens);
    return Object.assign({ type: 'loggedin', tokens, user: User.fromJSON(state.user), planState: PlanState.fromJSON(state.planState), orgs: state.orgs.map(o => UserOrganization.fromJSON(o)), projects: state.projects.map(p => Project.fromJSON(p)) }, getExpirationDetails(tokens.access));
}
function assumeState(type, state) {
    if (state.type !== type) {
        throw new Error(`Expected to be in state ${type}, but was in ${state.type}`);
    }
    return state;
}
export function loginReducer(state, action) {
    /**
     * Anomymous  ->   LoggingIn     -> LoggedIn
     *                               -> ConfirmMFA
     *                               -> Anonymous + Error
     *
     * ConfirmMFA ->   Confirming    -> LoggedIn
     *                               -> Confirming Error
     *            ->   ... resert    -> Anonymous
     */
    switch (action.type) {
        case 'rehydrate': return action.state;
        case 'reset': return {
            type: 'anonymous'
        };
        case 'login': return {
            type: 'loggingin',
            loginWith: action.loginWith
        };
        case 'loginError': return {
            type: 'anonymous',
            error: action.error
        };
        case 'loginSuccess': return Object.assign({ type: 'loggedin', tokens: action.response.tokens, user: action.response.user, planState: action.response.userPlan, orgs: action.response.orgs, projects: action.response.projects }, getExpirationDetails(action.response.tokens.access));
        case 'refreshToken': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { refreshing: true, refreshError: undefined });
        }
        case 'refreshTokenSuccess': {
            // ignore if we are not logged in
            if (state.type !== 'loggedin') {
                return state;
            }
            const s = assumeState('loggedin', state);
            const tokens = AuthTokens.fromJSON(Object.assign(Object.assign({}, s.tokens.toJSON()), { access: action.accessToken }));
            return Object.assign(Object.assign(Object.assign(Object.assign({}, s), { tokens }), getExpirationDetails(tokens.access)), { refreshing: undefined, refreshError: undefined });
        }
        case 'refreshTokenFailure': {
            // ignore if we are not logged in
            if (state.type !== 'loggedin') {
                return state;
            }
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { refreshing: undefined, refreshError: action.error, refreshAt: Date.now() + 60 * 1000 // Once a minute
             });
        }
        case 'loginMFA': return {
            type: 'confirm',
            confirm: action.response
        };
        case 'confirmMFA': {
            const s = assumeState('confirm', state);
            return {
                type: 'confirming',
                confirm: s.confirm
            };
        }
        case 'loginMFAError': {
            const s = assumeState('confirming', state);
            return {
                type: 'confirm',
                confirm: s.confirm,
                error: action.error
            };
        }
        case 'updateUser': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { user: action.user });
        }
        case 'updatePlanState': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { planState: action.planState });
        }
        case 'addOrg': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { orgs: [
                    ...s.orgs,
                    action.org
                ] });
        }
        case 'updateOrg': {
            const s = assumeState('loggedin', state);
            const index = s.orgs.findIndex(o => o.id.id === action.org.id.id);
            const slice = s.orgs.slice();
            slice[index] = action.org;
            return Object.assign(Object.assign({}, s), { orgs: slice });
        }
        case 'removeOrg': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { orgs: s.orgs.filter(o => o.id.id !== action.org.id) });
        }
        case 'addProject': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { projects: [
                    ...s.projects,
                    action.project
                ] });
        }
        case 'updateProject': {
            const s = assumeState('loggedin', state);
            const index = s.projects.findIndex(o => o.id.id === action.project.id.id &&
                (o.id.account === action.project.id.account || action.ignoreAccount));
            const slice = s.projects.slice();
            slice[index] = action.project;
            return Object.assign(Object.assign({}, s), { projects: [
                    ...s.projects,
                    action.project
                ] });
        }
        case 'removeProject': {
            const s = assumeState('loggedin', state);
            return Object.assign(Object.assign({}, s), { projects: s.projects.filter(o => !(o.id.id === action.projectID.id && o.id.account === action.projectID.account)) });
        }
        case 'logout': return {
            type: 'anonymous'
        };
    }
}
