// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.models
/**
  * Class io.protoforce.tasks.tasksservice.models:TryCancelOutput
  *
  * Defined at tasks.domain @ 46:5
  */
export class TasksServiceTryCancelOutput {
    get RTTI_CLASS() {
        return TasksServiceTryCancelOutput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksServiceTryCancelOutput.RTTI_FQN;
    }
    toJSON() {
        return TasksServiceTryCancelOutput.toJSON(this);
    }
}
TasksServiceTryCancelOutput.RTTI_CLASS = 'TryCancelOutput';
TasksServiceTryCancelOutput.RTTI_FQN = 'io.protoforce.tasks.tasksservice.models:TryCancelOutput';
TasksServiceTryCancelOutput.toJSON = (value) => {
    return {};
};
TasksServiceTryCancelOutput.fromJSON = (value) => {
    return new TasksServiceTryCancelOutput();
};
