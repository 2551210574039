// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { ConfirmMFASource } from './ConfirmMFASource';
/**
  *
  *  ConfirmMFAResponse provides details for further
  *  authorization procedure.
  *
  * Class io.protoforce.auth:ConfirmMFAResponse
  *
  * Defined at /auth/auth.service.model @ 43:1
  */
export class ConfirmMFAResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.sources = [];
                this.mfaToken = '';
            }
            return;
        }
        this.sources = data.sources;
        this.mfaToken = data.mfaToken;
    }
    get RTTI_CLASS() {
        return ConfirmMFAResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ConfirmMFAResponse.RTTI_FQN;
    }
    toJSON() {
        return ConfirmMFAResponse.toJSON(this);
    }
}
ConfirmMFAResponse.RTTI_CLASS = 'ConfirmMFAResponse';
ConfirmMFAResponse.RTTI_FQN = 'io.protoforce.auth:ConfirmMFAResponse';
ConfirmMFAResponse.toJSON = (value) => {
    return {
        sources: value.sources.map((e) => e.toJSON()),
        mfaToken: value.mfaToken
    };
};
ConfirmMFAResponse.fromJSON = (value) => {
    return new ConfirmMFAResponse({
        sources: value.sources.map((e) => ConfirmMFASource.fromJSON(e)),
        mfaToken: value.mfaToken
    });
};
