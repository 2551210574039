// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  logout Erases associated with the session refresh token
  *
  * Class io.protoforce.auth.authservice.models:LogoutInput
  *
  * Defined at /auth/auth.service.model @ 176:5
  */
export class AuthServiceLogoutInput {
    get RTTI_CLASS() {
        return AuthServiceLogoutInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthServiceLogoutInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.GET,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'auth'
                    },
                    {
                        type: 'word',
                        value: 'logout'
                    }
                ]
            },
            body: {
                fields: []
            }
        };
    }
    toJSON() {
        return AuthServiceLogoutInput.toJSON(this);
    }
}
AuthServiceLogoutInput.RTTI_CLASS = 'LogoutInput';
AuthServiceLogoutInput.RTTI_FQN = 'io.protoforce.auth.authservice.models:LogoutInput';
AuthServiceLogoutInput.toJSON = (value) => {
    return {};
};
AuthServiceLogoutInput.fromJSON = (value) => {
    return new AuthServiceLogoutInput();
};
