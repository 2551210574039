import React from 'react';
import { MenuItem, Tag } from '@blueprintjs/core';
import { AccountAvatar, AvatarSize } from '../AccountAvatar';
export const OrgMenuItem = React.memo((props) => {
    const { active, disabled, org, onClick, tag } = props;
    if (!org) {
        return null;
    }
    return (React.createElement(MenuItem, { active: active, disabled: disabled, onClick: onClick, text: org.displayName || org.name, labelElement: tag ? React.createElement(Tag, { minimal: true }, "Organization") : undefined, icon: React.createElement(AccountAvatar, { id: org.id, org: true, account: org, size: AvatarSize.Small }) }));
});
