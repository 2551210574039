import React, { useCallback, useState } from 'react';
import { Popover, Card, Position, Button, Divider } from '@blueprintjs/core';
import { GetUpdatesResponse, NotificationID } from '@protoforce/notifications';
import { NotificationView } from '~/components/notifications';
import { useUpdates } from '~/core/services/hooks/notificationsService/useUpdates';
export const NotificationsComponent = React.memo(() => {
    const [unread, setUnread] = useState(true);
    const { data, notifications, actions, mutate } = useUpdates();
    const remove = useCallback((id) => {
        if (!data) {
            return;
        }
        const clone = GetUpdatesResponse.fromJSON(data.toJSON());
        if (id instanceof NotificationID) {
            const index = clone.notifications.findIndex(n => n.value.id.id === id.id);
            if (index < 0) {
                return;
            }
            clone.notifications.splice(index, 1);
        }
        else {
            const index = clone.actions.findIndex(n => n.value.id.id === id.id);
            if (index < 0) {
                return;
            }
            clone.actions.splice(index, 1);
        }
        mutate(clone);
    }, [data, mutate]);
    function renderNotifs(notifs) {
        return notifs.map(n => React.createElement(NotificationView, { key: n.value.id.id, notif: n, remove: remove }));
    }
    function renderContent() {
        return (React.createElement(Card, { style: { maxHeight: 350, overflow: 'auto', padding: 5 } },
            renderNotifs(notifications),
            notifications.length > 0 && actions.length > 0 && React.createElement(Divider, null),
            renderNotifs(actions),
            notifications.length === 0 && actions.length === 0 && (React.createElement("span", { style: { padding: 20 } }, "No notifications, come back later."))));
    }
    const hasActions = actions.length > 0;
    const hasUnread = unread || hasActions;
    return (React.createElement(Popover, { content: renderContent(), position: Position.BOTTOM },
        React.createElement(Button, { minimal: true, icon: hasUnread ? 'notifications' : 'notifications-updated', intent: hasUnread ? 'primary' : undefined, onClick: () => setUnread(false) }, hasActions ? actions.length : undefined)));
});
