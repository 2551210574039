// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { JSONConverter, CodecError } from '../../../irt';
import { OAuthAccessCode } from './OAuthAccessCode';
import { OAuthAccessToken } from './OAuthAccessToken';
/**
  *
  *  OAuthAccess is an entity which holds data from one of the steps
  *  during the OAuth process.
  *
  * ADT io.protoforce.auth:OAuthAccess
  *
  * Defined at /auth/oauth.model @ 29:1
  */
export class OAuthAccess {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT OAuthAccess must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.token !== 'undefined') {
            this.value = value.token;
        }
        else if (typeof value.code !== 'undefined') {
            this.value = value.code;
        }
        else {
            throw new Error('ADT OAuthAccess constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenToken, whenCode) {
        const v = this.value;
        if (v instanceof OAuthAccessToken) {
            return whenToken(v);
        }
        else if (v instanceof OAuthAccessCode) {
            return whenCode(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenToken, whenCode) {
        const v = this.value;
        if (v instanceof OAuthAccessToken) {
            return whenToken(v);
        }
        else if (v instanceof OAuthAccessCode) {
            return whenCode(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromToken(value) {
        return new OAuthAccess({ token: value });
    }
    static fromCode(value) {
        return new OAuthAccess({ code: value });
    }
    static from(value) {
        return value instanceof OAuthAccess ? value : new OAuthAccess(undefined, value);
    }
    get RTTI_CLASS() {
        return OAuthAccess.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OAuthAccess.RTTI_FQN;
    }
    toJSON() {
        return OAuthAccess.toJSON(this);
    }
}
OAuthAccess.RTTI_CLASS = 'OAuthAccess';
OAuthAccess.RTTI_FQN = 'io.protoforce.auth:OAuthAccess';
OAuthAccess.toJSON = (value) => {
    const v = value.value;
    if (v instanceof OAuthAccessToken) {
        return JSONConverter.withTypeNested(v, 'token');
    }
    else if (v instanceof OAuthAccessCode) {
        return JSONConverter.withTypeNested(v, 'code');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
OAuthAccess.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'token': {
            const vc = val;
            return OAuthAccess.fromToken(OAuthAccessToken.fromJSON(vc));
        }
        case 'code': {
            const vc = val;
            return OAuthAccess.fromCode(OAuthAccessCode.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'OAuthAccess'`);
    }
};
