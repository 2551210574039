// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { ScalaProjectLayout } from './ScalaProjectLayout';
export class ScalaProjectLayoutHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return ScalaProjectLayoutHelper.toString(this.value);
    }
    static toString(e) {
        return ScalaProjectLayout[e];
    }
    static fromString(e) {
        return ScalaProjectLayout[e];
    }
    get RTTI_CLASS() {
        return ScalaProjectLayoutHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaProjectLayoutHelper.RTTI_FQN;
    }
    toJSON() {
        return ScalaProjectLayoutHelper.toJSON(this.value);
    }
}
ScalaProjectLayoutHelper.RTTI_CLASS = 'ScalaProjectLayout';
ScalaProjectLayoutHelper.RTTI_FQN = 'io.protoforce.projects:ScalaProjectLayout';
ScalaProjectLayoutHelper.all = [
    ScalaProjectLayout.PLAIN,
    ScalaProjectLayout.SBT
];
ScalaProjectLayoutHelper.toJSON = (value) => {
    return ScalaProjectLayout[value];
};
ScalaProjectLayoutHelper.fromJSON = (value) => {
    switch (value) {
        case 'PLAIN': return ScalaProjectLayout.PLAIN;
        case 'SBT': return ScalaProjectLayout.SBT;
        default: throw new CodecError(`Unexpected value for enum ScalaProjectLayout: '${value}'`);
    }
};
