// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.models
import { fromEitherJSON } from '../../../../../irt';
import { GenericFailure, SuccessAndUpload } from '../../../shared';
export class InternalDocsServiceuploadMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return SuccessAndUpload.fromJSON(r);
        }, (l) => {
            return GenericFailure.fromJSON(l);
        });
    }
}
