// tslint:disable
// eslint-disable
// package io.protoforce.docs.imagesearchservice
import { HTTPClientTransport } from '../../../../irt';
import { imageSearchServiceRTTI } from './methods/imageSearchServiceRTTI';
import { imageSearchServiceUnsplash } from './methods/imageSearchServiceUnsplash';
import { imageSearchServiceUnsplashRandom } from './methods/imageSearchServiceUnsplashRandom';
export class ImageSearchServiceClient {
    constructor(transport) {
        this.transport = transport;
    }
    get RTTI_CLASS() {
        return ImageSearchServiceClient.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ImageSearchServiceClient.RTTI_FQN;
    }
    static fromHTTP(endpoint, options) {
        return new ImageSearchServiceClient(new HTTPClientTransport(endpoint, options));
    }
    /**
      * Defined at /docs/doc.unsplash.model @ 18:5
      */
    unsplashRandom(_context) {
        return imageSearchServiceUnsplashRandom(this.transport, _context);
    }
    /**
      * Defined at /docs/doc.unsplash.model @ 19:5
      */
    unsplash(filter, page, _context) {
        return imageSearchServiceUnsplash({ filter, page }, this.transport, _context);
    }
}
ImageSearchServiceClient.RTTI_CLASS = imageSearchServiceRTTI.RTTI_CLASS;
ImageSearchServiceClient.RTTI_FQN = imageSearchServiceRTTI.RTTI_FQN;
