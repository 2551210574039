// tslint:disable
// eslint-disable
// package io.protoforce.tasks.react.tasksservice
import { BgTask } from '../../../projects';
import { useClientTransport, useServiceKey, useEitherQuery, useMergedTags } from '../../../../../irt-react';
import { tasksServiceListActiveTasks } from '../../tasksservice/methods/tasksServiceListActiveTasks';
import { tasksServiceListActiveTasksName } from '../../tasksservice/methods/tasksServiceListActiveTasksName';
import { tasksServiceRTTI } from '../../tasksservice/methods/tasksServiceRTTI';
import { useCallback } from 'react';
export const tasksServiceListActiveTasksTags = [
    '$pf',
    tasksServiceRTTI.RTTI_FQN,
    tasksServiceListActiveTasksName
];
/**
  * Defined at tasks.domain @ 43:5
  */
export function useTasksServiceListActiveTasks(filter, $options, $context) {
    const clientTransport = useClientTransport(tasksServiceRTTI, $options);
    const clientMethodKey = useServiceKey(tasksServiceRTTI.RTTI_FQN, tasksServiceListActiveTasksName, filter)[0];
    return useEitherQuery(typeof filter === 'undefined' ? false : clientMethodKey, useCallback((filter) => tasksServiceListActiveTasks({ filter }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: tasksServiceListActiveTasksTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.map((e) => e.toJSON()), []),
            fromCache: useCallback(v => v.map((e) => BgTask.fromJSON(e)), [])
        } }, ($options || {})), { tags: useMergedTags(tasksServiceListActiveTasksTags) }), filter);
}
