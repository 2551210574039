// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.accountservice
import { CheckUniqueNameResponse } from '../../CheckUniqueNameResponse';
import { useClientTransport, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { accountServiceCheckAccountName } from '../../accountservice/methods/accountServiceCheckAccountName';
import { accountServiceCheckAccountNameName } from '../../accountservice/methods/accountServiceCheckAccountNameName';
import { accountServiceRTTI } from '../../accountservice/methods/accountServiceRTTI';
import { useCallback } from 'react';
import { useQuery } from '@dyssent/fetcherjs';
export const accountServiceCheckAccountNameTags = [
    '$pf',
    accountServiceRTTI.RTTI_FQN,
    accountServiceCheckAccountNameName
];
/**
  *
  *  Check account name availability
  *
  *  @name Name of the potential account
  *
  * Defined at /auth/account.service.model @ 98:5
  */
export function useAccountServiceCheckAccountName(name, $options, $context) {
    const clientTransport = useClientTransport(accountServiceRTTI, $options);
    const clientMethodKey = useServiceKey(accountServiceRTTI.RTTI_FQN, accountServiceCheckAccountNameName, name)[0];
    return useQuery(typeof name === 'undefined' ? false : clientMethodKey, useCallback((name) => accountServiceCheckAccountName({ name }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: accountServiceCheckAccountNameTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.toJSON(), []),
            fromCache: useCallback(v => CheckUniqueNameResponse.fromJSON(v), [])
        } }, ($options || {})), { tags: useMergedTags(accountServiceCheckAccountNameTags) }), name);
}
