// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.methods
import { InternalDocsServiceUploadDocumentationFileInput } from '../models/InternalDocsServiceUploadDocumentationFileInput';
import { InternalDocsServiceuploadDocumentationFileMethodOutputHelper } from '../models/InternalDocsServiceuploadDocumentationFileMethodOutputHelper';
import { internalDocsServiceRTTI } from './internalDocsServiceRTTI';
import { internalDocsServiceUploadDocumentationFileName } from './internalDocsServiceUploadDocumentationFileName';
export function internalDocsServiceUploadDocumentationFile(input, transport, context) {
    return transport.send(internalDocsServiceRTTI, internalDocsServiceUploadDocumentationFileName, new InternalDocsServiceUploadDocumentationFileInput(input), {
        in: InternalDocsServiceUploadDocumentationFileInput,
        out: InternalDocsServiceuploadDocumentationFileMethodOutputHelper,
        alternative: true,
        context
    });
}
