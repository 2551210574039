// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { ConfirmMFAData } from '../../ConfirmMFAData';
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  confirmMFA is an optional step, which is skipped for users with
  *  no 2FA/MFA enabled
  *
  *  @data MFA codes that were collected from the user
  *
  * Class io.protoforce.auth.authservice.models:ConfirmMFAInput
  *
  * Defined at /auth/auth.service.model @ 162:5
  */
export class AuthServiceConfirmMFAInput {
    constructor(data) {
        this.data = data.data;
    }
    get RTTI_CLASS() {
        return AuthServiceConfirmMFAInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthServiceConfirmMFAInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.POST,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'auth'
                    },
                    {
                        type: 'word',
                        value: 'confirm'
                    },
                    {
                        type: 'word',
                        value: 'mfa'
                    }
                ]
            },
            body: {
                fields: [
                    {
                        field: {
                            name: 'codes',
                            ref: {
                                id: 'map',
                                args: [
                                    {
                                        id: 'str',
                                        args: []
                                    },
                                    {
                                        id: 'str',
                                        args: []
                                    }
                                ]
                            }
                        },
                        path: [
                            {
                                name: 'data',
                                ref: {
                                    id: 'ConfirmMFAData',
                                    args: []
                                }
                            }
                        ]
                    },
                    {
                        field: {
                            name: 'mfaToken',
                            ref: {
                                id: 'str',
                                args: []
                            }
                        },
                        path: [
                            {
                                name: 'data',
                                ref: {
                                    id: 'ConfirmMFAData',
                                    args: []
                                }
                            }
                        ]
                    }
                ]
            }
        };
    }
    toJSON() {
        return AuthServiceConfirmMFAInput.toJSON(this);
    }
}
AuthServiceConfirmMFAInput.RTTI_CLASS = 'ConfirmMFAInput';
AuthServiceConfirmMFAInput.RTTI_FQN = 'io.protoforce.auth.authservice.models:ConfirmMFAInput';
AuthServiceConfirmMFAInput.toJSON = (value) => {
    return {
        data: value.data.toJSON()
    };
};
AuthServiceConfirmMFAInput.fromJSON = (value) => {
    return new AuthServiceConfirmMFAInput({
        data: ConfirmMFAData.fromJSON(value.data)
    });
};
