// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { OAuthAccessToken } from './OAuthAccessToken';
import { SignupWithGoogle } from './SignupWithGoogle';
import { SignupWithOAuthProviderCodec } from './codecs/SignupWithOAuthProviderCodec';
import { SignupWithOAuthProviderImpl } from './SignupWithOAuthProviderImpl';
/**
  * Class io.protoforce.auth:SignupWithGithub
  *
  * Defined at /auth/providers/github.model @ 15:1
  */
export class SignupWithGithub {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.access = data.access;
        this.pass = data.pass;
        this.code = data.code;
    }
    get RTTI_CLASS() {
        return SignupWithGithub.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWithGithub.RTTI_FQN;
    }
    toSignupWithGoogle() {
        return new SignupWithGoogle({
            name: this.name,
            access: this.access,
            pass: this.pass,
            code: this.code
        });
    }
    toSignupWithOAuthProvider() {
        return new SignupWithOAuthProviderImpl({
            name: this.name,
            access: this.access,
            pass: this.pass,
            code: this.code
        });
    }
    toJSON() {
        return SignupWithGithub.toJSON(this);
    }
}
SignupWithGithub.RTTI_CLASS = 'SignupWithGithub';
SignupWithGithub.RTTI_FQN = 'io.protoforce.auth:SignupWithGithub';
SignupWithGithub.toJSON = (value) => {
    return {
        name: value.name,
        access: value.access.toJSON(),
        pass: typeof value.pass !== 'undefined' && value.pass != null ? value.pass : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    };
};
SignupWithGithub.fromJSON = (value) => {
    return new SignupWithGithub({
        name: value.name,
        access: OAuthAccessToken.fromJSON(value.access),
        pass: typeof value.pass !== 'undefined' && value.pass != null ? value.pass : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    });
};
SignupWithOAuthProviderCodec.register(SignupWithGithub.RTTI_FQN, SignupWithGithub.fromJSON);
