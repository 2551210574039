// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { IRTErrorShared } from './IRTErrorShared';
import { UnknownFailure } from './UnknownFailure';
import { RuntimeError } from '../../../irt';
/**
  * Class io.protoforce.shared:FailureBase
  *
  * Defined at /shared/error.model @ 2:1
  */
export class FailureBase extends RuntimeError {
    constructor(data) {
        super({ message: data.message });
        this.message = data.message;
    }
    get RTTI_CLASS() {
        return FailureBase.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FailureBase.RTTI_FQN;
    }
    toUnknownFailure() {
        return new UnknownFailure({
            message: this.message
        });
    }
    toIRTErrorShared() {
        return new IRTErrorShared({
            message: this.message
        });
    }
    toJSON() {
        return FailureBase.toJSON(this);
    }
}
FailureBase.RTTI_CLASS = 'FailureBase';
FailureBase.RTTI_FQN = 'io.protoforce.shared:FailureBase';
FailureBase.toJSON = (value) => {
    return {
        message: value.message
    };
};
FailureBase.fromJSON = (value) => {
    return new FailureBase({
        message: value.message
    });
};
