// tslint:disable
// eslint-disable
// package io.protoforce.auth.fileuploadservice.methods
import { FileUploadServiceProjectUploadInput } from '../models/FileUploadServiceProjectUploadInput';
import { FileUploadServiceprojectUploadMethodOutputHelper } from '../models/FileUploadServiceprojectUploadMethodOutputHelper';
import { fileUploadServiceProjectUploadName } from './fileUploadServiceProjectUploadName';
import { fileUploadServiceRTTI } from './fileUploadServiceRTTI';
export function fileUploadServiceProjectUpload(input, transport, context) {
    return transport.send(fileUploadServiceRTTI, fileUploadServiceProjectUploadName, new FileUploadServiceProjectUploadInput(input), {
        in: FileUploadServiceProjectUploadInput,
        out: FileUploadServiceprojectUploadMethodOutputHelper,
        alternative: true,
        context
    });
}
