/**
 * Encoder proxy which allows intermediate data state to avoid double encoding
 * in case of helper wrappers around the types, specifically used in websockets
 */
export class EncoderProxy {
    constructor(data, finalize) {
        this.data = data;
        this.finalize = finalize;
    }
}
/**
 * Decoder proxy which allows intermediate data state to avoid double decoding
 * in case of helper wrappers around the types, specifically used in websockets
 */
export class DecoderProxy {
    constructor(data, finalize) {
        this.data = data;
        this.finalize = finalize;
    }
}
