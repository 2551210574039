import React from 'react';
import { Card, Spinner } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useNotificationsServiceConfirm, useNotificationsServiceReject } from '@protoforce/notifications';
import { AcceptRejectButtons } from './AcceptRejectButtons';
export const ActionConfirmInvite = React.memo((props) => {
    const { notif, remove } = props;
    const [confirm, { pending: confirming }] = useNotificationsServiceConfirm({
        onSuccess: () => remove(notif.id)
    });
    const [reject, { pending: rejecting }] = useNotificationsServiceReject({
        onSuccess: () => remove(notif.id)
    });
    function onAccept() {
        confirm(notif.id);
    }
    function onReject() {
        reject(notif.id);
    }
    const isBeingProcessed = confirming || rejecting;
    return (React.createElement(Card, { title: 'Invite', style: { margin: 5, padding: 20 }, elevation: 3 },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("span", { style: { padding: 5 } },
                "You've been invited to\u00A0",
                React.createElement(Link, { to: `/${notif.org.name}` }, notif.org.displayName || notif.org.name)),
            isBeingProcessed ? (React.createElement(Spinner, { size: 14, intent: 'primary' })) : (React.createElement(AcceptRejectButtons, { onAccept: onAccept, onReject: onReject, detailed: true })))));
});
