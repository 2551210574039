import { hot /*, setConfig*/ } from 'react-hot-loader/root';
import React, { useEffect, useContext, useRef, useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FocusStyleManager } from '@blueprintjs/core';
import { ServicesTransportContext } from '@protoforce/irt-react';
import { Plan } from '@protoforce/shared';
import { Header } from '~/components/Header';
import { SecureRoute } from '~/components/secureroute';
import { UniSearchHost } from '~/components/search/UniSearch';
import { ChatWidget, ChatWidgetContext } from '~/components/general/ChatWidget';
import { asyncProduct, asyncLogin, asyncSignup, asyncBlog, asyncAuthProvider, asyncAuthVerify, asyncAbout, asyncContact, asyncPrivacy, asyncTerms, asyncPricing, asyncOrganization, asyncProject, asyncPost, asyncOrg, asyncDefault, asyncSettings, asyncDocs, asyncReset, asyncRoadmap, asyncSandbox, asyncCLI } from './async';
import { CI_BUILD, SDK_VERSION, CONFIG } from './core';
import { InternalServicesTransportContext } from './core/services/context';
import { AnalyticsContext } from './core/analytics';
import { useAuth, AuthContext } from './core/services/hooks';
import { createTransport } from './core/services/transport';
FocusStyleManager.onlyShowFocusOnTabs();
const jss = create({
    insertionPoint: 'jss-insertion-point'
});
const prod = process.env.NODE_ENV === 'production';
// if (!prod) {
//   setConfig({ logLevel: 'debug' });
// }
// tslint:disable-next-line:no-console
console.log(`%c[${!prod ? 'DEVELOPMENT' : 'PRODUCTION'}]%c build: ${CI_BUILD}`, 'background: #222; color: #bada55', 'color: inherit');
// tslint:disable-next-line:no-console
console.log(`%c[SDK]%c: ${SDK_VERSION}`, 'background: #e65100; color: #fff', 'color: inherit');
const AuthGate = (props) => {
    const { children } = props;
    const chatRef = useRef(null);
    const transport = useContext(ServicesTransportContext);
    const auth = useAuth();
    const analytics = useContext(AnalyticsContext);
    const location = useLocation();
    useEffect(() => {
        var _a, _b;
        if (!transport) {
            return;
        }
        if (auth.loginState.type === 'loggedin') {
            transport.setHeaders({ Authorization: `Bearer ${auth.loginState.tokens.access}` });
            const payer = auth.loginState.planState.plan !== Plan.Free || auth.orgs.findIndex(o => o.planState.plan !== Plan.Free) >= 0;
            (_a = chatRef.current) === null || _a === void 0 ? void 0 : _a.setUser(auth.loginState.user.name, auth.loginState.user.id.toJSON(), payer);
            if (!CONFIG.testing) {
                const clientID = auth.loginState.user.id.toJSON();
                analytics.init(clientID);
            }
        }
        else {
            transport.setHeaders({});
            (_b = chatRef.current) === null || _b === void 0 ? void 0 : _b.clearUser();
            analytics.init();
        }
    }, [auth.loginState, transport]);
    useEffect(() => {
        // TODO Restore analytics https://github.com/ProtoForce/protoforce-portal-webui/issues/11
        analytics.pageView(location.pathname + location.search);
    }, [auth.loginState, location ? location.pathname : '', location ? location.search : '']);
    return (React.createElement(AuthContext.Provider, { value: auth },
        React.createElement(ChatWidgetContext.Provider, { value: chatRef.current }, children),
        React.createElement(ChatWidget, { ref: chatRef })));
};
function App(props) {
    const { config, analytics } = props;
    const transport = useMemo(() => {
        return createTransport(config);
    }, [config]);
    return (React.createElement(AnalyticsContext.Provider, { value: analytics },
        React.createElement(ServicesTransportContext.Provider, { value: transport.transport },
            React.createElement(InternalServicesTransportContext.Provider, { value: transport.internalTransport },
                React.createElement(JssProvider, { jss: jss },
                    React.createElement(DndProvider, { backend: HTML5Backend },
                        React.createElement(AuthGate, null,
                            React.createElement(UniSearchHost, null,
                                React.createElement("div", { className: 'app bp4-dark' },
                                    React.createElement(Header, null),
                                    React.createElement("div", { className: 'app-content' },
                                        React.createElement(Switch, null,
                                            React.createElement(Route, { path: '/product', component: asyncProduct }),
                                            React.createElement(SecureRoute, { path: '/signin', component: asyncLogin, inverse: true }),
                                            React.createElement(SecureRoute, { path: '/reset', component: asyncReset, inverse: true }),
                                            React.createElement(SecureRoute, { path: '/signup', component: asyncSignup, inverse: true }),
                                            React.createElement(Route, { path: '/about', component: asyncAbout }),
                                            React.createElement(Route, { path: '/sandbox', component: asyncSandbox }),
                                            React.createElement(Route, { path: '/docs', component: asyncDocs }),
                                            React.createElement(Route, { path: '/blog', component: asyncBlog }),
                                            React.createElement(Route, { path: '/contact', component: asyncContact }),
                                            React.createElement(Route, { path: '/pricing', component: asyncPricing }),
                                            React.createElement(Route, { path: '/privacy', component: asyncPrivacy }),
                                            React.createElement(Route, { path: '/terms', component: asyncTerms }),
                                            React.createElement(Route, { path: '/roadmap', component: asyncRoadmap }),
                                            React.createElement(Route, { path: '/cli', component: asyncCLI }),
                                            React.createElement(SecureRoute, { path: '/auth/provider/:provider', component: asyncAuthProvider, inverse: true }),
                                            React.createElement(Route, { path: '/auth/verify/:entity/:code', component: asyncAuthVerify, inverse: true }),
                                            React.createElement(SecureRoute, { path: '/settings', component: asyncSettings }),
                                            React.createElement(SecureRoute, { path: '/organization', component: asyncOrganization }),
                                            React.createElement(SecureRoute, { path: '/project', component: asyncProject }),
                                            React.createElement(SecureRoute, { path: '/post', component: asyncPost }),
                                            React.createElement(Route, { path: '/:org', component: asyncOrg }),
                                            React.createElement(Route, { component: asyncDefault }))))))))))));
}
export default hot(App);
