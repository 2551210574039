// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { fromEitherJSON } from '../../../../../irt';
import { GenericFailure } from '../../../shared';
import { LoginResponse } from '../../LoginResponse';
export class AuthServicesignupMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return LoginResponse.fromJSON(r);
        }, (l) => {
            return GenericFailure.fromJSON(l);
        });
    }
}
