// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  * Enum io.protoforce.shared:HashAlgorithm
  *
  * Defined at /shared/upload.model @ 2:1
  */
export var HashAlgorithm;
(function (HashAlgorithm) {
    /**
      * Defined at /shared/upload.model @ 3:3
      */
    HashAlgorithm[HashAlgorithm["SHA1"] = 0] = "SHA1";
    /**
      * Defined at /shared/upload.model @ 4:3
      */
    HashAlgorithm[HashAlgorithm["SHA256"] = 1] = "SHA256";
})(HashAlgorithm || (HashAlgorithm = {}));
