// tslint:disable
// eslint-disable
// package io.protoforce.search.searchservice.models
import { Validator } from '../../../../../irt';
/**
  * Class io.protoforce.search.searchservice.models:SearchInput
  *
  * Defined at search.domain @ 60:5
  */
export class SearchServiceSearchInput {
    constructor(data) {
        this.text = data.text;
        this.usersLimit = data.usersLimit;
        this.orgsLimit = data.orgsLimit;
        this.projectsLimit = data.projectsLimit;
    }
    get usersLimit() {
        return this._usersLimit;
    }
    set usersLimit(value) {
        Validator.min(value, 0, 'usersLimit');
        Validator.max(value, 65535, 'usersLimit');
        Validator.integer(value, 'usersLimit');
        this._usersLimit = value;
    }
    get orgsLimit() {
        return this._orgsLimit;
    }
    set orgsLimit(value) {
        Validator.min(value, 0, 'orgsLimit');
        Validator.max(value, 65535, 'orgsLimit');
        Validator.integer(value, 'orgsLimit');
        this._orgsLimit = value;
    }
    get projectsLimit() {
        return this._projectsLimit;
    }
    set projectsLimit(value) {
        Validator.min(value, 0, 'projectsLimit');
        Validator.max(value, 65535, 'projectsLimit');
        Validator.integer(value, 'projectsLimit');
        this._projectsLimit = value;
    }
    get RTTI_CLASS() {
        return SearchServiceSearchInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SearchServiceSearchInput.RTTI_FQN;
    }
    toJSON() {
        return SearchServiceSearchInput.toJSON(this);
    }
}
SearchServiceSearchInput.RTTI_CLASS = 'SearchInput';
SearchServiceSearchInput.RTTI_FQN = 'io.protoforce.search.searchservice.models:SearchInput';
SearchServiceSearchInput.toJSON = (value) => {
    return {
        text: value.text,
        usersLimit: value.usersLimit,
        orgsLimit: value.orgsLimit,
        projectsLimit: value.projectsLimit
    };
};
SearchServiceSearchInput.fromJSON = (value) => {
    return new SearchServiceSearchInput({
        text: value.text,
        usersLimit: value.usersLimit,
        orgsLimit: value.orgsLimit,
        projectsLimit: value.projectsLimit
    });
};
