// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { Project } from '../projects';
/**
  * Class io.protoforce.auth:PinnedProjects
  *
  * Defined at /auth/account.service.model @ 7:1
  */
export class PinnedProjects {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.projects = [];
            }
            return;
        }
        this.projects = data.projects;
    }
    get RTTI_CLASS() {
        return PinnedProjects.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PinnedProjects.RTTI_FQN;
    }
    toJSON() {
        return PinnedProjects.toJSON(this);
    }
}
PinnedProjects.RTTI_CLASS = 'PinnedProjects';
PinnedProjects.RTTI_FQN = 'io.protoforce.auth:PinnedProjects';
PinnedProjects.toJSON = (value) => {
    return {
        projects: value.projects.map((e) => e.toJSON())
    };
};
PinnedProjects.fromJSON = (value) => {
    return new PinnedProjects({
        projects: value.projects.map((e) => Project.fromJSON(e))
    });
};
