// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { CodecError } from '../../../irt';
import { MFASourceKind } from './MFASourceKind';
export class MFASourceKindHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return MFASourceKindHelper.toString(this.value);
    }
    static toString(e) {
        return MFASourceKind[e];
    }
    static fromString(e) {
        return MFASourceKind[e];
    }
    get RTTI_CLASS() {
        return MFASourceKindHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return MFASourceKindHelper.RTTI_FQN;
    }
    toJSON() {
        return MFASourceKindHelper.toJSON(this.value);
    }
}
MFASourceKindHelper.RTTI_CLASS = 'MFASourceKind';
MFASourceKindHelper.RTTI_FQN = 'io.protoforce.auth:MFASourceKind';
MFASourceKindHelper.all = [
    MFASourceKind.Phone,
    MFASourceKind.App,
    MFASourceKind.FIDO
];
MFASourceKindHelper.toJSON = (value) => {
    return MFASourceKind[value];
};
MFASourceKindHelper.fromJSON = (value) => {
    switch (value) {
        case 'Phone': return MFASourceKind.Phone;
        case 'App': return MFASourceKind.App;
        case 'FIDO': return MFASourceKind.FIDO;
        default: throw new CodecError(`Unexpected value for enum MFASourceKind: '${value}'`);
    }
};
