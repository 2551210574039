import React from 'react';
import { Collapse, Pre } from '@blueprintjs/core';
import { useTasksServiceGetLog } from '@protoforce/tasks';
const TaskLogText = (props) => {
    const { taskID } = props;
    const { data, pending, error } = useTasksServiceGetLog(taskID);
    const text = (data ? data.text : pending ? 'Loading...' : error ? error.message : undefined) || '';
    return (React.createElement(Pre, null, text));
};
export const TaskLog = React.memo((props) => {
    const { taskID, open } = props;
    return (React.createElement(Collapse, { isOpen: open },
        React.createElement(TaskLogText, { taskID: taskID })));
});
