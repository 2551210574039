// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { NotFoundEntity } from './NotFoundEntity';
export class NotFoundEntityHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return NotFoundEntityHelper.toString(this.value);
    }
    static toString(e) {
        return NotFoundEntity[e];
    }
    static fromString(e) {
        return NotFoundEntity[e];
    }
    get RTTI_CLASS() {
        return NotFoundEntityHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotFoundEntityHelper.RTTI_FQN;
    }
    toJSON() {
        return NotFoundEntityHelper.toJSON(this.value);
    }
}
NotFoundEntityHelper.RTTI_CLASS = 'NotFoundEntity';
NotFoundEntityHelper.RTTI_FQN = 'io.protoforce.shared:NotFoundEntity';
NotFoundEntityHelper.all = [
    NotFoundEntity.Organization,
    NotFoundEntity.Project,
    NotFoundEntity.Revision,
    NotFoundEntity.Revisions,
    NotFoundEntity.User,
    NotFoundEntity.Task,
    NotFoundEntity.ForumTopic,
    NotFoundEntity.ForumComment,
    NotFoundEntity.Tag,
    NotFoundEntity.Release,
    NotFoundEntity.Account
];
NotFoundEntityHelper.toJSON = (value) => {
    return NotFoundEntity[value];
};
NotFoundEntityHelper.fromJSON = (value) => {
    switch (value) {
        case 'Organization': return NotFoundEntity.Organization;
        case 'Project': return NotFoundEntity.Project;
        case 'Revision': return NotFoundEntity.Revision;
        case 'Revisions': return NotFoundEntity.Revisions;
        case 'User': return NotFoundEntity.User;
        case 'Task': return NotFoundEntity.Task;
        case 'ForumTopic': return NotFoundEntity.ForumTopic;
        case 'ForumComment': return NotFoundEntity.ForumComment;
        case 'Tag': return NotFoundEntity.Tag;
        case 'Release': return NotFoundEntity.Release;
        case 'Account': return NotFoundEntity.Account;
        default: throw new CodecError(`Unexpected value for enum NotFoundEntity: '${value}'`);
    }
};
