// tslint:disable
// eslint-disable
// package io.protoforce.notifications.notificationsservice.methods
import { NotificationsServiceConfirmInput } from '../models/NotificationsServiceConfirmInput';
import { NotificationsServiceconfirmMethodOutputHelper } from '../models/NotificationsServiceconfirmMethodOutputHelper';
import { notificationsServiceConfirmName } from './notificationsServiceConfirmName';
import { notificationsServiceRTTI } from './notificationsServiceRTTI';
export function notificationsServiceConfirm(input, transport, context) {
    return transport.send(notificationsServiceRTTI, notificationsServiceConfirmName, new NotificationsServiceConfirmInput(input), {
        in: NotificationsServiceConfirmInput,
        out: NotificationsServiceconfirmMethodOutputHelper,
        alternative: true,
        context
    });
}
