// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { Validator } from '../../../irt';
/**
  * Class io.protoforce.projects/FQNRule:DropLeft (member of ADT io.protoforce.projects:FQNRule)
  *
  * Defined at /projects/manifests/naming.model @ 16:5
  */
export class FQNRuleDropLeft {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.count = 0;
            }
            return;
        }
        this.count = data.count;
    }
    get count() {
        return this._count;
    }
    set count(value) {
        Validator.min(value, 0, 'count');
        Validator.max(value, 255, 'count');
        Validator.integer(value, 'count');
        this._count = value;
    }
    get RTTI_CLASS() {
        return FQNRuleDropLeft.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FQNRuleDropLeft.RTTI_FQN;
    }
    toJSON() {
        return FQNRuleDropLeft.toJSON(this);
    }
}
FQNRuleDropLeft.RTTI_CLASS = 'DropLeft';
FQNRuleDropLeft.RTTI_FQN = 'io.protoforce.projects/FQNRule:DropLeft';
FQNRuleDropLeft.toJSON = (value) => {
    return {
        count: value.count
    };
};
FQNRuleDropLeft.fromJSON = (value) => {
    return new FQNRuleDropLeft({
        count: value.count
    });
};
