import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Popover, Card, Position, Button, Checkbox, Callout } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { TasksFilter, TasksFilterMine, TasksFilterAllInProject, TasksFilterMineInProject, useTasksServiceListActiveTasks } from '@protoforce/tasks';
import { TaskView } from '~/components/tasks';
import { useAuthContext } from '~/core/services/hooks/contexts';
import { timeToPoint } from '~/core/timeutils';
import { ProjectSelect } from '~/components/project/ProjectSelect';
const NoActiveTasksRefreshInterval = 60000;
const ActiveTasksRefreshInterval = 10000;
export const TasksComponent = React.memo(() => {
    const { user } = useAuthContext();
    const [mine, setMine] = useState(false);
    const [lastUpdate, setLastUpdate] = useState();
    const [refreshInterval, setRefreshInterval] = useState(NoActiveTasksRefreshInterval);
    const [projectID, setProjectID] = useState();
    // const handleLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    //   (e.target as HTMLElement).parentElement!.parentElement!.click(), []);
    // const filter = useMemo(() => TasksFilter.from(mine ? new TasksFilterMine() : new TasksFilterAll()), [mine]);
    const filter = useMemo(() => {
        if (projectID) {
            return TasksFilter.from(mine ? new TasksFilterMineInProject({ project: projectID }) : new TasksFilterAllInProject({ project: projectID }));
        }
        else {
            return TasksFilter.from(new TasksFilterMine());
        }
    }, [mine, projectID]);
    function onToggleMine() {
        setMine(!mine);
    }
    const { data: tasks, pending, error } = useTasksServiceListActiveTasks(filter, {
        refreshInterval,
        onSuccess: () => setLastUpdate(new Date())
    });
    useEffect(() => {
        if (tasks && tasks.length > 0) {
            setRefreshInterval(ActiveTasksRefreshInterval);
        }
        else {
            setRefreshInterval(NoActiveTasksRefreshInterval);
        }
    }, [tasks && tasks.length]);
    const onProjectSelected = useCallback((p) => {
        setProjectID(p ? p.id : undefined);
    }, []);
    function renderTasks() {
        if (!tasks) {
            return null;
        }
        return tasks.map(t => React.createElement(TaskView, { key: t.id.toJSON(), task: t }));
    }
    function renderContent() {
        // TODO Redo with classes
        return (React.createElement(Card, { style: { maxHeight: 350, minWidth: 300, maxWidth: 600, overflow: 'auto', padding: 10 } },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement("div", { style: {
                        display: 'flex',
                        marginBottom: 10,
                        marginLeft: 5,
                        marginRight: 5,
                        alignItems: 'center'
                    } },
                    React.createElement(ProjectSelect, { onSelectionChaned: onProjectSelected, selected: projectID, fill: true }),
                    projectID &&
                        React.createElement(Checkbox, { checked: mine, onChange: onToggleMine, style: { marginLeft: 5, marginBottom: 0 } }, "Mine only")),
                renderTasks(),
                tasks && tasks.length === 0 && (React.createElement("span", { style: { padding: 20 } }, "No active tasks running at the moment. Once something starts, we'll show it here.")),
                error &&
                    React.createElement(Callout, { intent: 'danger' }, error.message),
                React.createElement(Link, { to: `/${user.name}/tasks`, style: { padding: '10px 0px 10px 5px' } }, "See all tasks"),
                React.createElement("small", { className: 'bp4-text-muted', style: { padding: 5 } },
                    "Last update: ",
                    lastUpdate ? timeToPoint(lastUpdate) : 'Never',
                    " ",
                    pending ? '(Updating...)' : null))));
    }
    const hasActiveTasks = tasks && tasks.length > 0;
    return (React.createElement(Popover, { content: renderContent(), position: Position.BOTTOM },
        React.createElement(Button, { minimal: true, icon: 'build', intent: hasActiveTasks ? 'warning' : undefined }, hasActiveTasks && tasks ? tasks.length : undefined)));
});
