// tslint:disable
// eslint-disable
// package io.protoforce.tasks.tasksservice.methods
import { TasksFilter } from '../../TasksFilter';
import { TasksServiceListActiveTasksInput } from '../models/TasksServiceListActiveTasksInput';
import { TasksServicelistActiveTasksMethodOutputHelper } from '../models/TasksServicelistActiveTasksMethodOutputHelper';
import { tasksServiceListActiveTasksName } from './tasksServiceListActiveTasksName';
import { tasksServiceRTTI } from './tasksServiceRTTI';
export function tasksServiceListActiveTasks(input, transport, context) {
    return transport.send(tasksServiceRTTI, tasksServiceListActiveTasksName, new TasksServiceListActiveTasksInput(Object.assign(Object.assign({}, input), { filter: TasksFilter.from(input.filter) })), {
        in: TasksServiceListActiveTasksInput,
        out: TasksServicelistActiveTasksMethodOutputHelper,
        alternative: true,
        context
    });
}
