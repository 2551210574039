// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.models
import { AccountIdentity } from '../../../shared';
/**
  *
  *  Get account public details
  *
  *  @id Account identity
  *
  * Class io.protoforce.auth.accountservice.models:GetAccountInput
  *
  * Defined at /auth/account.service.model @ 146:5
  */
export class AccountServiceGetAccountInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return AccountServiceGetAccountInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountServiceGetAccountInput.RTTI_FQN;
    }
    toJSON() {
        return AccountServiceGetAccountInput.toJSON(this);
    }
}
AccountServiceGetAccountInput.RTTI_CLASS = 'GetAccountInput';
AccountServiceGetAccountInput.RTTI_FQN = 'io.protoforce.auth.accountservice.models:GetAccountInput';
AccountServiceGetAccountInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
AccountServiceGetAccountInput.fromJSON = (value) => {
    return new AccountServiceGetAccountInput({
        id: AccountIdentity.fromJSON(value.id)
    });
};
