import React, { useCallback } from 'react';
import { Suggest } from '@blueprintjs/select';
import { MenuItem, Button, Icon } from '@blueprintjs/core';
import { useAuthContext } from '~/core/services/hooks/contexts';
const ProjectSelectControl = Suggest.ofType();
export const ProjectSelect = React.memo((props) => {
    const { selected, disabled, fill, onSelectionChaned } = props;
    const { projects, orgs, user } = useAuthContext();
    const inputValueRenderer = useCallback((p) => {
        const account = user && p.id.account === user.id.id ? user : orgs.find(o => o.id.id === p.id.account);
        return (account ? account.name + '/' : '') + p.name;
    }, []);
    const itemRenderer = useCallback((p, itemProps) => {
        const account = user && p.id.account === user.id.id ? user : orgs.find(o => o.id.id === p.id.account);
        return (React.createElement(MenuItem, { active: itemProps.modifiers.active, text: React.createElement("span", null,
                (account === null || account === void 0 ? void 0 : account.name) || '',
                "/",
                React.createElement("b", null, p.name)), onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                // onSelectionChaned(p);
                itemProps.handleClick(e);
            } }));
    }, [orgs, user]);
    const itemsEqual = useCallback((l, r) => JSON.stringify(l.toJSON()) === JSON.stringify(r.toJSON()), []);
    const onItemSelect = useCallback((item, event) => {
        onSelectionChaned(item);
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }, [onSelectionChaned]);
    const onClearSelection = useCallback(() => {
        onSelectionChaned(undefined);
    }, [onSelectionChaned]);
    const itemPredicate = useCallback((query, item, index, exactMatch) => {
        if (exactMatch) {
            return query === item.name;
        }
        const account = user && item.id.account === user.id.id ? user : orgs.find(o => o.id.id === item.id.account);
        const textual = `${account === null || account === void 0 ? void 0 : account.name} / ${item.name} ${item.website} ${item.description}`.toLowerCase();
        return textual.indexOf(query.toLowerCase()) >= 0;
    }, [orgs, user]);
    const selectedItem = selected ? projects.find(p => p.id.id === selected.id && p.id.account === selected.account) : undefined;
    return (React.createElement(ProjectSelectControl, { inputValueRenderer: inputValueRenderer, items: projects, noResults: React.createElement(MenuItem, { disabled: true, text: 'Nothing found' }), itemRenderer: itemRenderer, popoverProps: { minimal: true }, inputProps: {
            placeholder: 'Filter by project',
            rightElement: selectedItem ?
                React.createElement(Button, { icon: 'small-cross', minimal: true, onClick: onClearSelection })
                : undefined,
            leftElement: React.createElement(Icon, { icon: 'filter' })
        }, itemsEqual: itemsEqual, onItemSelect: onItemSelect, disabled: disabled, fill: fill, selectedItem: selectedItem || null, itemPredicate: itemPredicate }));
});
