var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useReducer, useEffect, useState, useCallback, useContext } from 'react';
import { useAuthServiceLogin as useLogin, useAuthServiceConfirmMFA as useConfirmMFA, useAuthServiceRefresh as useRefresh, useAuthServiceSignup as useSignup, accountServiceCheckAccountNameTags as checkAccountNameTags } from '@protoforce/auth';
import { ServicesTransportContext } from '@protoforce/irt-react';
import { formatError } from '~/core/utils';
import { CONFIG } from '~/core';
import { loginReducer, loggedInStateFromJSON, loggedInStateToJSON } from './login';
import { signupReducer } from './signup';
import { useStorage } from '../../../../storage/useStorage';
export const databaseAuthKey = 'auth';
export function useAuth() {
    const transport = useContext(ServicesTransportContext);
    const [loaded, setLoaded] = useState(CONFIG.ssr);
    const [loginState, loginDispatch] = useReducer(loginReducer, { type: 'anonymous' });
    const [signupState, signupDispatch] = useReducer(signupReducer, {});
    const { get, put, clearAll } = useStorage(databaseAuthKey);
    function save() {
        if (loginState.type !== 'loggedin') {
            return;
        }
        put(loggedInStateToJSON(loginState));
    }
    const [doLogin] = useLogin({
        onRequest: (manager, loginWith) => {
            loginDispatch({ type: 'login', loginWith: loginWith });
        },
        onSuccess: result => result.match(loginResponse => {
            transport.setHeaders({ Authorization: `Bearer ${loginResponse.tokens.access}` });
            loginDispatch({ type: 'loginSuccess', response: loginResponse });
        }, mfaResponse => loginDispatch({ type: 'loginMFA', response: mfaResponse }), failure => {
            console.error(failure);
            loginDispatch({ type: 'loginError', error: formatError(failure) });
        }),
        onError: error => {
            console.error(error);
            loginDispatch({ type: 'loginError', error: formatError(error) });
        }
    });
    const [doConfirm] = useConfirmMFA({
        onRequest: () => {
            loginDispatch({ type: 'confirmMFA' });
        },
        onSuccess: loginResponse => {
            transport.setHeaders({ Authorization: `Bearer ${loginResponse.tokens.access}` });
            loginDispatch({ type: 'loginSuccess', response: loginResponse });
        },
        onError: error => {
            console.error(error);
            loginDispatch({ type: 'loginMFAError', error: formatError(error) });
        }
    });
    const [doSignup] = useSignup({
        onRequest: (manager, signupWith) => {
            signupDispatch({ type: 'signup', signupWith: signupWith });
        },
        onSuccess: (loginResponse, _, manager) => {
            manager.clearCacheByTags(checkAccountNameTags);
            transport.setHeaders({ Authorization: `Bearer ${loginResponse.tokens.access}` });
            loginDispatch({ type: 'loginSuccess', response: loginResponse });
            signupDispatch({ type: 'reset' });
        },
        onError: error => {
            console.error(error);
            signupDispatch({ type: 'signupError', error: formatError(error) });
        }
    });
    const [doRefresh] = useRefresh({
        onRequest: () => loginDispatch({ type: 'refreshToken' }),
        onSuccess: (accessToken) => loginDispatch({ type: 'refreshTokenSuccess', accessToken }),
        onError: error => {
            console.error(error);
            loginDispatch({ type: 'refreshTokenFailure', error: error.message });
        }
    });
    const refresh = useCallback(() => {
        if (loginState.type !== 'loggedin') {
            console.warn('Trying to refresh a token while not logged in. Ignoring.');
            return;
        }
        doRefresh(loginState.tokens);
    }, [doRefresh, loginState]);
    function signup(signupWith) {
        if (signupState.signingUp) {
            return;
        }
        doSignup(signupWith);
    }
    function presignup(email, name, provider, token) {
        signupDispatch({ type: 'presignup', email, name, provider, token });
    }
    function login(loginWith) {
        if (loginState.type !== 'anonymous') {
            return;
        }
        doLogin(loginWith);
    }
    function confirm(confirmData) {
        if (loginState.type !== 'confirm') {
            return;
        }
        doConfirm(confirmData);
    }
    function logout() {
        // Already logged out
        if (loginState.type === 'anonymous') {
            return;
        }
        loginDispatch({ type: 'logout' });
        clearAll();
    }
    function reset() {
        loginDispatch({ type: 'reset' });
        signupDispatch({ type: 'reset' });
    }
    function updateUser(updatedUser) {
        loginDispatch({ type: 'updateUser', user: updatedUser });
    }
    function updatePlanState(updatedPlan) {
        loginDispatch({ type: 'updatePlanState', planState: updatedPlan });
    }
    function addOrganization(org) {
        loginDispatch({ type: 'addOrg', org });
    }
    function updateOrganization(org) {
        loginDispatch({ type: 'updateOrg', org });
    }
    function removeOrganization(org) {
        loginDispatch({ type: 'removeOrg', org });
    }
    function addProject(project) {
        loginDispatch({ type: 'addProject', project });
    }
    function updateProject(project, ignoreAccount) {
        loginDispatch({ type: 'updateProject', project, ignoreAccount });
    }
    function removeProject(projectID) {
        loginDispatch({ type: 'removeProject', projectID });
    }
    function getAccountPlan(acc) {
        if (loginState.type !== 'loggedin') {
            return undefined;
        }
        if (acc.id === loginState.user.id.id) {
            return loginState.planState;
        }
        const o = loginState.orgs.find(oo => oo.id.id === acc.id);
        if (!o) {
            return undefined;
        }
        return o.planState;
    }
    useEffect(() => {
        if (loginState.type !== 'loggedin') {
            return;
        }
        let handle;
        if (loginState.refreshAt) {
            const when = loginState.refreshAt - Date.now();
            // @ts-ignore
            handle = setTimeout(() => {
                refresh();
            }, when > 0 ? when : 1);
        }
        save();
        return () => {
            if (handle) {
                clearTimeout(handle);
            }
        };
    }, [loginState, refresh]);
    useEffect(() => {
        function retrieve() {
            return __awaiter(this, void 0, void 0, function* () {
                const val = yield get();
                if (typeof val !== 'undefined' && val != null) {
                    try {
                        const loggedIn = loggedInStateFromJSON(val);
                        // Make sure the token isn't expierd
                        if (loggedIn.expiresAt && Date.now() < loggedIn.expiresAt) {
                            loginDispatch({ type: 'rehydrate', state: loggedIn });
                        }
                        else {
                            clearAll();
                        }
                    }
                    catch (err) {
                        console.warn(err, val);
                    }
                }
                setLoaded(true);
            });
        }
        retrieve();
    }, []);
    const actions = {
        signup,
        presignup,
        reset,
        login,
        confirm,
        logout,
        refresh,
        updateUser,
        updatePlanState,
        addOrganization,
        updateOrganization,
        removeOrganization,
        addProject,
        updateProject,
        removeProject,
    };
    const now = Date.now();
    const loginData = loginState.type === 'loggedin' ? {
        user: loginState.user,
        loggedIn: true,
        planState: loginState.planState,
        orgs: loginState.orgs,
        projects: loginState.projects,
        expiredToken: loginState.expiresAt ? now >= loginState.expiresAt : false,
        refreshingToken: loginState.refreshing,
        refreshingError: loginState.refreshError
    } : {
        orgs: [],
        projects: []
    };
    return Object.assign(Object.assign(Object.assign({}, actions), loginData), { getAccountPlan,
        loaded,
        loginState,
        signupState });
}
// @ts-ignore
export const AuthContext = React.createContext();
export function useAuthContext() {
    return useContext(AuthContext);
}
