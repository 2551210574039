// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.authservice
import { useMutation } from '@dyssent/fetcherjs';
import { useClientTransport } from '../../../../../irt-react';
import { authServiceLogin } from '../../authservice/methods/authServiceLogin';
import { authServiceRTTI } from '../../authservice/methods/authServiceRTTI';
import { useCallback } from 'react';
/**
  *
  *  login into the ProtoForce system and obtain user details. If a 2FA/MFA was
  *  set for an account - it will require an extra step to confirm MFA
  *
  *  @data One of the possible credentials of the LoginWith type
  *
  * Defined at /auth/auth.service.model @ 154:5
  */
export function useAuthServiceLogin(options, context) {
    const clientTransport = useClientTransport(authServiceRTTI, options);
    const request = useCallback((data) => {
        return authServiceLogin({ data }, clientTransport, context);
    }, [clientTransport, context]);
    return useMutation(request, options);
}
