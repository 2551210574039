// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects:PublishingGitLayout
  *
  * Defined at /projects/publishing.model @ 41:1
  */
export class PublishingGitLayout {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.branch = '';
                this.path = '';
            }
            return;
        }
        this.branch = data.branch;
        this.path = data.path;
    }
    get RTTI_CLASS() {
        return PublishingGitLayout.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingGitLayout.RTTI_FQN;
    }
    toJSON() {
        return PublishingGitLayout.toJSON(this);
    }
}
PublishingGitLayout.RTTI_CLASS = 'PublishingGitLayout';
PublishingGitLayout.RTTI_FQN = 'io.protoforce.projects:PublishingGitLayout';
PublishingGitLayout.toJSON = (value) => {
    return {
        branch: value.branch,
        path: value.path
    };
};
PublishingGitLayout.fromJSON = (value) => {
    return new PublishingGitLayout({
        branch: value.branch,
        path: value.path
    });
};
