// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingCredentialsMavenRepository } from './PublishingCredentialsMavenRepository';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { ScalaBuildManifest } from './ScalaBuildManifest';
import { ScalaCompiledPublicMaven } from './ScalaCompiledPublicMaven';
/**
  * Class io.protoforce.projects/ScalaCompiled:Maven (member of ADT io.protoforce.projects:ScalaCompiled)
  *
  * Defined at /projects/publishing.model @ 114:5
  */
export class ScalaCompiledMaven {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
        this.credentials = data.credentials;
    }
    get RTTI_CLASS() {
        return ScalaCompiledMaven.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaCompiledMaven.RTTI_FQN;
    }
    toScalaCompiledPublicMaven() {
        return new ScalaCompiledPublicMaven({
            manifest: this.manifest,
            id: this.id,
            meta: this.meta,
            target: this.target
        });
    }
    static fromScalaCompiledPublicMaven(from, credentials) {
        return new ScalaCompiledMaven({
            credentials: credentials,
            manifest: from.manifest,
            id: from.id,
            meta: from.meta,
            target: from.target
        });
    }
    loadScalaCompiledPublicMaven(from) {
        this.manifest = from.manifest;
        this.id = from.id;
        this.meta = from.meta;
        this.target = from.target;
    }
    toJSON() {
        return ScalaCompiledMaven.toJSON(this);
    }
}
ScalaCompiledMaven.RTTI_CLASS = 'Maven';
ScalaCompiledMaven.RTTI_FQN = 'io.protoforce.projects/ScalaCompiled:Maven';
ScalaCompiledMaven.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON(),
        credentials: value.credentials.toJSON()
    };
};
ScalaCompiledMaven.fromJSON = (value) => {
    return new ScalaCompiledMaven({
        manifest: ScalaBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target),
        credentials: PublishingCredentialsMavenRepository.fromJSON(value.credentials)
    });
};
