import React, { useCallback } from 'react';
import { Popover, Menu, Position, Button, Icon, Classes, MenuDivider } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
export const NewComponent = React.memo(() => {
    const handleLinkClick = useCallback((e) => e.target.parentElement.parentElement.click(), []);
    function renderMenu() {
        // TODO Preload New Organization and New Project components
        return (React.createElement(Menu, { "data-testid": 'header-new-menu' },
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: '/organization/new', className: 'bp4-menu-item pt-popover-dismiss', "data-testid": 'button-menu-new-org' },
                    React.createElement(Icon, { icon: 'office' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "New organization"))),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: '/project/new', className: 'bp4-menu-item pt-popover-dismiss', "data-testid": 'button-menu-new-project' },
                    React.createElement(Icon, { icon: 'folder-new' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "New project"))),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: '/post/new', className: 'bp4-menu-item pt-popover-dismiss', "data-testid": 'button-menu-new-post' },
                    React.createElement(Icon, { icon: 'annotation' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "New post"))),
            React.createElement(MenuDivider, null),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: '/sandbox', className: 'bp4-menu-item pt-popover-dismiss', "data-testid": 'button-menu-sandbox' },
                    React.createElement(Icon, { icon: 'code-block' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "Sandbox"))),
            React.createElement("li", { className: Classes.POPOVER_DISMISS },
                React.createElement(Link, { onClick: handleLinkClick, to: '/docs', className: 'bp4-menu-item pt-popover-dismiss', "data-testid": 'button-menu-docs' },
                    React.createElement(Icon, { icon: 'book' }),
                    React.createElement("div", { className: 'bp4-text-overflow-ellipsis bp4-fill' }, "Documentation")))));
    }
    return (React.createElement(Popover, { content: renderMenu(), position: Position.BOTTOM },
        React.createElement(Button, { minimal: true, icon: 'plus', rightIcon: 'caret-down', "data-testid": 'header-new-menu-button' })));
});
