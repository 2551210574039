// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JavaBuildManifest } from './JavaBuildManifest';
import { JavaCompiledPublicMaven } from './JavaCompiledPublicMaven';
import { PublishingCredentialsMavenRepository } from './PublishingCredentialsMavenRepository';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
/**
  * Class io.protoforce.projects/JavaCompiled:Maven (member of ADT io.protoforce.projects:JavaCompiled)
  *
  * Defined at /projects/publishing.model @ 125:5
  */
export class JavaCompiledMaven {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
        this.credentials = data.credentials;
    }
    get RTTI_CLASS() {
        return JavaCompiledMaven.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return JavaCompiledMaven.RTTI_FQN;
    }
    toJavaCompiledPublicMaven() {
        return new JavaCompiledPublicMaven({
            manifest: this.manifest,
            id: this.id,
            meta: this.meta,
            target: this.target
        });
    }
    static fromJavaCompiledPublicMaven(from, credentials) {
        return new JavaCompiledMaven({
            credentials: credentials,
            manifest: from.manifest,
            id: from.id,
            meta: from.meta,
            target: from.target
        });
    }
    loadJavaCompiledPublicMaven(from) {
        this.manifest = from.manifest;
        this.id = from.id;
        this.meta = from.meta;
        this.target = from.target;
    }
    toJSON() {
        return JavaCompiledMaven.toJSON(this);
    }
}
JavaCompiledMaven.RTTI_CLASS = 'Maven';
JavaCompiledMaven.RTTI_FQN = 'io.protoforce.projects/JavaCompiled:Maven';
JavaCompiledMaven.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON(),
        credentials: value.credentials.toJSON()
    };
};
JavaCompiledMaven.fromJSON = (value) => {
    return new JavaCompiledMaven({
        manifest: JavaBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target),
        credentials: PublishingCredentialsMavenRepository.fromJSON(value.credentials)
    });
};
