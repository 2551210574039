// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.models
import { FileUpload } from '../../../shared';
/**
  * Class io.protoforce.docs.internaldocsservice.models:UploadInput
  *
  * Defined at docs.domain @ 19:5
  */
export class InternalDocsServiceUploadInput {
    constructor(data) {
        this.location = data.location;
        this.data = data.data;
    }
    get RTTI_CLASS() {
        return InternalDocsServiceUploadInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return InternalDocsServiceUploadInput.RTTI_FQN;
    }
    toJSON() {
        return InternalDocsServiceUploadInput.toJSON(this);
    }
}
InternalDocsServiceUploadInput.RTTI_CLASS = 'UploadInput';
InternalDocsServiceUploadInput.RTTI_FQN = 'io.protoforce.docs.internaldocsservice.models:UploadInput';
InternalDocsServiceUploadInput.toJSON = (value) => {
    return {
        location: value.location,
        data: value.data.toJSON()
    };
};
InternalDocsServiceUploadInput.fromJSON = (value) => {
    return new InternalDocsServiceUploadInput({
        location: value.location,
        data: FileUpload.fromJSON(value.data)
    });
};
