// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { JavaTarget } from './JavaTarget';
import { ProtoforceTarget } from './ProtoforceTarget';
import { ScalaTarget } from './ScalaTarget';
import { TypescriptTarget } from './TypescriptTarget';
/**
  * ADT io.protoforce.projects:PublishingTask
  *
  * Defined at /projects/publishing.model @ 17:1
  */
export class PublishingTask {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT PublishingTask must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.scalaTarget !== 'undefined') {
            this.value = value.scalaTarget;
        }
        else if (typeof value.javaTarget !== 'undefined') {
            this.value = value.javaTarget;
        }
        else if (typeof value.typescriptTarget !== 'undefined') {
            this.value = value.typescriptTarget;
        }
        else if (typeof value.protoforceTarget !== 'undefined') {
            this.value = value.protoforceTarget;
        }
        else {
            throw new Error('ADT PublishingTask constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenScalaTarget, whenJavaTarget, whenTypescriptTarget, whenProtoforceTarget) {
        const v = this.value;
        if (v instanceof ScalaTarget) {
            return whenScalaTarget(v);
        }
        else if (v instanceof JavaTarget) {
            return whenJavaTarget(v);
        }
        else if (v instanceof TypescriptTarget) {
            return whenTypescriptTarget(v);
        }
        else if (v instanceof ProtoforceTarget) {
            return whenProtoforceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenScalaTarget, whenJavaTarget, whenTypescriptTarget, whenProtoforceTarget) {
        const v = this.value;
        if (v instanceof ScalaTarget) {
            return whenScalaTarget(v);
        }
        else if (v instanceof JavaTarget) {
            return whenJavaTarget(v);
        }
        else if (v instanceof TypescriptTarget) {
            return whenTypescriptTarget(v);
        }
        else if (v instanceof ProtoforceTarget) {
            return whenProtoforceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromScalaTarget(value) {
        return new PublishingTask({ scalaTarget: value });
    }
    static fromJavaTarget(value) {
        return new PublishingTask({ javaTarget: value });
    }
    static fromTypescriptTarget(value) {
        return new PublishingTask({ typescriptTarget: value });
    }
    static fromProtoforceTarget(value) {
        return new PublishingTask({ protoforceTarget: value });
    }
    static from(value) {
        return value instanceof PublishingTask ? value : new PublishingTask(undefined, value);
    }
    get RTTI_CLASS() {
        return PublishingTask.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTask.RTTI_FQN;
    }
    toJSON() {
        return PublishingTask.toJSON(this);
    }
}
PublishingTask.RTTI_CLASS = 'PublishingTask';
PublishingTask.RTTI_FQN = 'io.protoforce.projects:PublishingTask';
PublishingTask.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaTarget) {
        return JSONConverter.withTypeNested(v, 'ScalaTarget');
    }
    else if (v instanceof JavaTarget) {
        return JSONConverter.withTypeNested(v, 'JavaTarget');
    }
    else if (v instanceof TypescriptTarget) {
        return JSONConverter.withTypeNested(v, 'TypescriptTarget');
    }
    else if (v instanceof ProtoforceTarget) {
        return JSONConverter.withTypeNested(v, 'ProtoforceTarget');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
PublishingTask.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'ScalaTarget': {
            const vc = val;
            return PublishingTask.fromScalaTarget(ScalaTarget.fromJSON(vc));
        }
        case 'JavaTarget': {
            const vc = val;
            return PublishingTask.fromJavaTarget(JavaTarget.fromJSON(vc));
        }
        case 'TypescriptTarget': {
            const vc = val;
            return PublishingTask.fromTypescriptTarget(TypescriptTarget.fromJSON(vc));
        }
        case 'ProtoforceTarget': {
            const vc = val;
            return PublishingTask.fromProtoforceTarget(ProtoforceTarget.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'PublishingTask'`);
    }
};
