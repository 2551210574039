// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { License } from './License';
import { Publisher } from './Publisher';
/**
  * Class io.protoforce.projects:Common
  *
  * Defined at /projects/manifests/build.model @ 26:1
  */
export class Common {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.group = '';
                this.tags = [];
                this.licenses = [];
                this.description = '';
                this.website = '';
                this.copyright = '';
            }
            return;
        }
        this.name = data.name;
        this.group = data.group;
        this.tags = data.tags;
        this.publisher = data.publisher;
        this.licenses = data.licenses;
        this.description = data.description;
        this.website = data.website;
        this.copyright = data.copyright;
    }
    get RTTI_CLASS() {
        return Common.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Common.RTTI_FQN;
    }
    toJSON() {
        return Common.toJSON(this);
    }
}
Common.RTTI_CLASS = 'Common';
Common.RTTI_FQN = 'io.protoforce.projects:Common';
Common.toJSON = (value) => {
    return {
        name: value.name,
        group: value.group,
        tags: value.tags.slice(),
        publisher: value.publisher.toJSON(),
        licenses: value.licenses.map((e) => e.toJSON()),
        description: value.description,
        website: value.website,
        copyright: value.copyright
    };
};
Common.fromJSON = (value) => {
    return new Common({
        name: value.name,
        group: value.group,
        tags: value.tags.slice(),
        publisher: Publisher.fromJSON(value.publisher),
        licenses: value.licenses.map((e) => License.fromJSON(e)),
        description: value.description,
        website: value.website,
        copyright: value.copyright
    });
};
