// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { CodecError } from '../../../irt';
import { TypeScriptExtension } from './TypeScriptExtension';
export class TypeScriptExtensionHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return TypeScriptExtensionHelper.toString(this.value);
    }
    static toString(e) {
        return TypeScriptExtension[e];
    }
    static fromString(e) {
        return TypeScriptExtension[e];
    }
    get RTTI_CLASS() {
        return TypeScriptExtensionHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypeScriptExtensionHelper.RTTI_FQN;
    }
    toJSON() {
        return TypeScriptExtensionHelper.toJSON(this.value);
    }
}
TypeScriptExtensionHelper.RTTI_CLASS = 'TypeScriptExtension';
TypeScriptExtensionHelper.RTTI_FQN = 'io.protoforce.projects:TypeScriptExtension';
TypeScriptExtensionHelper.all = [
    TypeScriptExtension.Random,
    TypeScriptExtension.REST,
    TypeScriptExtension.Tests,
    TypeScriptExtension.JSON,
    TypeScriptExtension.React,
    TypeScriptExtension.Node
];
TypeScriptExtensionHelper.toJSON = (value) => {
    return TypeScriptExtension[value];
};
TypeScriptExtensionHelper.fromJSON = (value) => {
    switch (value) {
        case 'Random': return TypeScriptExtension.Random;
        case 'REST': return TypeScriptExtension.REST;
        case 'Tests': return TypeScriptExtension.Tests;
        case 'JSON': return TypeScriptExtension.JSON;
        case 'React': return TypeScriptExtension.React;
        case 'Node': return TypeScriptExtension.Node;
        default: throw new CodecError(`Unexpected value for enum TypeScriptExtension: '${value}'`);
    }
};
