// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { PublishingCredentialsNPMCredentials } from './PublishingCredentialsNPMCredentials';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { TypeScriptBuildManifest } from './TypeScriptBuildManifest';
import { TypescriptCompiledPublicNPM } from './TypescriptCompiledPublicNPM';
/**
  * Class io.protoforce.projects/TypescriptCompiled:NPM (member of ADT io.protoforce.projects:TypescriptCompiled)
  *
  * Defined at /projects/publishing.model @ 136:5
  */
export class TypescriptCompiledNPM {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.manifest = data.manifest;
        this.id = data.id;
        this.meta = data.meta;
        this.target = data.target;
        this.credentials = data.credentials;
    }
    get RTTI_CLASS() {
        return TypescriptCompiledNPM.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptCompiledNPM.RTTI_FQN;
    }
    toTypescriptCompiledPublicNPM() {
        return new TypescriptCompiledPublicNPM({
            manifest: this.manifest,
            id: this.id,
            meta: this.meta,
            target: this.target
        });
    }
    static fromTypescriptCompiledPublicNPM(from, credentials) {
        return new TypescriptCompiledNPM({
            credentials: credentials,
            manifest: from.manifest,
            id: from.id,
            meta: from.meta,
            target: from.target
        });
    }
    loadTypescriptCompiledPublicNPM(from) {
        this.manifest = from.manifest;
        this.id = from.id;
        this.meta = from.meta;
        this.target = from.target;
    }
    toJSON() {
        return TypescriptCompiledNPM.toJSON(this);
    }
}
TypescriptCompiledNPM.RTTI_CLASS = 'NPM';
TypescriptCompiledNPM.RTTI_FQN = 'io.protoforce.projects/TypescriptCompiled:NPM';
TypescriptCompiledNPM.toJSON = (value) => {
    return {
        manifest: value.manifest.toJSON(),
        id: value.id.toJSON(),
        meta: value.meta.toJSON(),
        target: value.target.toJSON(),
        credentials: value.credentials.toJSON()
    };
};
TypescriptCompiledNPM.fromJSON = (value) => {
    return new TypescriptCompiledNPM({
        manifest: TypeScriptBuildManifest.fromJSON(value.manifest),
        id: PublishingTaskId.fromJSON(value.id),
        meta: PublishingTaskMeta.fromJSON(value.meta),
        target: PublishingTarget.fromJSON(value.target),
        credentials: PublishingCredentialsNPMCredentials.fromJSON(value.credentials)
    });
};
