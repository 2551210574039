import { blobToString, blobFromString } from '../../types/tools';
/**
 * A set of helpers to convert types to JSON representation.
 */
export class JSONConverter {
}
JSONConverter.withTypeField = (value, field, name) => {
    const typeName = name || value.RTTI_FQN;
    const values = value.toJSON();
    // @ts-ignore values in this case is an object
    const existingField = values[field];
    const fieldValue = typeof existingField === 'undefined' ? typeName :
        Array.isArray(existingField) ? [typeName, ...existingField] : [typeName, existingField];
    return Object.assign(Object.assign({}, values), { [field]: fieldValue });
};
JSONConverter.withScalarTypeField = (value, field, name) => {
    return {
        [field]: name,
        value
    };
};
JSONConverter.withTypeNested = (value, name) => {
    const typeName = name || value.RTTI_FQN;
    return {
        [typeName]: value.toJSON()
    };
};
JSONConverter.toBigintJSON = (value) => {
    // TODO Fix to be 2**64
    const min = BigInt(-2147483648);
    const max = BigInt(2147483647);
    if (value >= min && value <= max) {
        return Number(value);
    }
    // @ts-ignore
    return '0x' + value.toString(16);
};
JSONConverter.fromBigintJSON = (value) => {
    return BigInt(value);
};
JSONConverter.toBlobJSON = (value) => {
    return blobToString(value);
};
JSONConverter.fromBlobJSON = (value) => {
    return blobFromString(value);
};
