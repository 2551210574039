// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { OAuthAccessToken } from './OAuthAccessToken';
import { SignupWithGithub } from './SignupWithGithub';
import { SignupWithOAuthProviderCodec } from './codecs/SignupWithOAuthProviderCodec';
import { SignupWithOAuthProviderImpl } from './SignupWithOAuthProviderImpl';
/**
  * Class io.protoforce.auth:SignupWithGoogle
  *
  * Defined at /auth/providers/google.model @ 12:1
  */
export class SignupWithGoogle {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.access = data.access;
        this.pass = data.pass;
        this.code = data.code;
    }
    get RTTI_CLASS() {
        return SignupWithGoogle.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWithGoogle.RTTI_FQN;
    }
    toSignupWithGithub() {
        return new SignupWithGithub({
            name: this.name,
            access: this.access,
            pass: this.pass,
            code: this.code
        });
    }
    toSignupWithOAuthProvider() {
        return new SignupWithOAuthProviderImpl({
            name: this.name,
            access: this.access,
            pass: this.pass,
            code: this.code
        });
    }
    toJSON() {
        return SignupWithGoogle.toJSON(this);
    }
}
SignupWithGoogle.RTTI_CLASS = 'SignupWithGoogle';
SignupWithGoogle.RTTI_FQN = 'io.protoforce.auth:SignupWithGoogle';
SignupWithGoogle.toJSON = (value) => {
    return {
        name: value.name,
        access: value.access.toJSON(),
        pass: typeof value.pass !== 'undefined' && value.pass != null ? value.pass : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    };
};
SignupWithGoogle.fromJSON = (value) => {
    return new SignupWithGoogle({
        name: value.name,
        access: OAuthAccessToken.fromJSON(value.access),
        pass: typeof value.pass !== 'undefined' && value.pass != null ? value.pass : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined
    });
};
SignupWithOAuthProviderCodec.register(SignupWithGoogle.RTTI_FQN, SignupWithGoogle.fromJSON);
