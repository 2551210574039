// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { FQNRule } from './FQNRule';
/**
  * Class io.protoforce.projects:ProjectNamingRule
  *
  * Defined at /projects/manifests/naming.model @ 2:1
  */
export class ProjectNamingRule {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.prefix = [];
                this.postfix = [];
            }
            return;
        }
        this.prefix = data.prefix;
        this.dropFQNSegments = data.dropFQNSegments;
        this.postfix = data.postfix;
    }
    get RTTI_CLASS() {
        return ProjectNamingRule.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ProjectNamingRule.RTTI_FQN;
    }
    toJSON() {
        return ProjectNamingRule.toJSON(this);
    }
}
ProjectNamingRule.RTTI_CLASS = 'ProjectNamingRule';
ProjectNamingRule.RTTI_FQN = 'io.protoforce.projects:ProjectNamingRule';
ProjectNamingRule.toJSON = (value) => {
    return {
        prefix: value.prefix.slice(),
        dropFQNSegments: value.dropFQNSegments.toJSON(),
        postfix: value.postfix.slice()
    };
};
ProjectNamingRule.fromJSON = (value) => {
    return new ProjectNamingRule({
        prefix: value.prefix.slice(),
        dropFQNSegments: FQNRule.fromJSON(value.dropFQNSegments),
        postfix: value.postfix.slice()
    });
};
