import React from 'react';
import { Card } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useUserBrief } from '~/core/services/hooks/userService/useUserBrief';
import { useOrgBrief } from '~/core/services/hooks/orgService/useOrgBrief';
export const NotificationUserJoined = React.memo((props) => {
    const { notif } = props;
    const { data: userData } = useUserBrief(notif.user);
    const { data: orgData } = useOrgBrief(notif.org);
    return (React.createElement(Card, { title: 'User Joined', style: { margin: 5, padding: 20 }, elevation: 3 },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("span", { style: { padding: 5 } },
                React.createElement("code", null, userData ? userData.displayName || userData.name : '?'),
                " joined",
                React.createElement(Link, { to: `/${orgData ? orgData.name : ''}` },
                    React.createElement("code", null, orgData ? orgData.displayName || orgData.name : '?')),
                " organization."))));
});
