// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/FQNRule:Last (member of ADT io.protoforce.projects:FQNRule)
  *
  * Defined at /projects/manifests/naming.model @ 12:5
  */
export class FQNRuleLast {
    get RTTI_CLASS() {
        return FQNRuleLast.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FQNRuleLast.RTTI_FQN;
    }
    toJSON() {
        return FQNRuleLast.toJSON(this);
    }
}
FQNRuleLast.RTTI_CLASS = 'Last';
FQNRuleLast.RTTI_FQN = 'io.protoforce.projects/FQNRule:Last';
FQNRuleLast.toJSON = (value) => {
    return {};
};
FQNRuleLast.fromJSON = (value) => {
    return new FQNRuleLast();
};
