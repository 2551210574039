// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/PublishingCredentials:GitCreds (member of ADT io.protoforce.projects:PublishingCredentials)
  *
  * Defined at /projects/publishing.model @ 194:5
  */
export class PublishingCredentialsGitCreds {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.user = '';
                this.privateKey = '';
                this.email = '';
            }
            return;
        }
        this.user = data.user;
        this.privateKey = data.privateKey;
        this.email = data.email;
    }
    get RTTI_CLASS() {
        return PublishingCredentialsGitCreds.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingCredentialsGitCreds.RTTI_FQN;
    }
    toJSON() {
        return PublishingCredentialsGitCreds.toJSON(this);
    }
}
PublishingCredentialsGitCreds.RTTI_CLASS = 'GitCreds';
PublishingCredentialsGitCreds.RTTI_FQN = 'io.protoforce.projects/PublishingCredentials:GitCreds';
PublishingCredentialsGitCreds.toJSON = (value) => {
    return {
        user: value.user,
        privateKey: value.privateKey,
        email: value.email
    };
};
PublishingCredentialsGitCreds.fromJSON = (value) => {
    return new PublishingCredentialsGitCreds({
        user: value.user,
        privateKey: value.privateKey,
        email: value.email
    });
};
