// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { ScalaExtensionHelper } from './ScalaExtensionHelper';
/**
  * Class io.protoforce.projects/ScalaExtensionSpec:Excluded (member of ADT io.protoforce.projects:ScalaExtensionSpec)
  *
  * Defined at /projects/manifests/build.model @ 82:5
  */
export class ScalaExtensionSpecExcluded {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.extensions = [];
            }
            return;
        }
        this.extensions = data.extensions;
    }
    get RTTI_CLASS() {
        return ScalaExtensionSpecExcluded.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaExtensionSpecExcluded.RTTI_FQN;
    }
    toJSON() {
        return ScalaExtensionSpecExcluded.toJSON(this);
    }
}
ScalaExtensionSpecExcluded.RTTI_CLASS = 'Excluded';
ScalaExtensionSpecExcluded.RTTI_FQN = 'io.protoforce.projects/ScalaExtensionSpec:Excluded';
ScalaExtensionSpecExcluded.toJSON = (value) => {
    return {
        extensions: value.extensions.map((e) => ScalaExtensionHelper.toJSON(e))
    };
};
ScalaExtensionSpecExcluded.fromJSON = (value) => {
    return new ScalaExtensionSpecExcluded({
        extensions: value.extensions.map((e) => ScalaExtensionHelper.fromJSON(e))
    });
};
