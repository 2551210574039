// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.models
import { AccountIdentity } from '../../../shared';
/**
  *
  *  Get details about a particular user.
  *
  *  @id User identity
  *
  * Class io.protoforce.auth.userservice.models:GetUserInput
  *
  * Defined at /auth/user.service.model @ 70:5
  */
export class UserServiceGetUserInput {
    constructor(data) {
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return UserServiceGetUserInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserServiceGetUserInput.RTTI_FQN;
    }
    toJSON() {
        return UserServiceGetUserInput.toJSON(this);
    }
}
UserServiceGetUserInput.RTTI_CLASS = 'GetUserInput';
UserServiceGetUserInput.RTTI_FQN = 'io.protoforce.auth.userservice.models:GetUserInput';
UserServiceGetUserInput.toJSON = (value) => {
    return {
        id: value.id.toJSON()
    };
};
UserServiceGetUserInput.fromJSON = (value) => {
    return new UserServiceGetUserInput({
        id: AccountIdentity.fromJSON(value.id)
    });
};
