// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ValidationTargetJava } from './ValidationTargetJava';
import { ValidationTargetParse } from './ValidationTargetParse';
import { ValidationTargetScala } from './ValidationTargetScala';
import { ValidationTargetTypeScript } from './ValidationTargetTypeScript';
/**
  * This is a test compilation task only, it does not assume any publishing
  *
  * ADT io.protoforce.projects:ValidationTarget
  *
  * Defined at /projects/tasks.model @ 28:1
  */
export class ValidationTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ValidationTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.parse !== 'undefined') {
            this.value = value.parse;
        }
        else if (typeof value.typeScript !== 'undefined') {
            this.value = value.typeScript;
        }
        else if (typeof value.scala !== 'undefined') {
            this.value = value.scala;
        }
        else if (typeof value.java !== 'undefined') {
            this.value = value.java;
        }
        else {
            throw new Error('ADT ValidationTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenParse, whenTypeScript, whenScala, whenJava) {
        const v = this.value;
        if (v instanceof ValidationTargetParse) {
            return whenParse(v);
        }
        else if (v instanceof ValidationTargetTypeScript) {
            return whenTypeScript(v);
        }
        else if (v instanceof ValidationTargetScala) {
            return whenScala(v);
        }
        else if (v instanceof ValidationTargetJava) {
            return whenJava(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenParse, whenTypeScript, whenScala, whenJava) {
        const v = this.value;
        if (v instanceof ValidationTargetParse) {
            return whenParse(v);
        }
        else if (v instanceof ValidationTargetTypeScript) {
            return whenTypeScript(v);
        }
        else if (v instanceof ValidationTargetScala) {
            return whenScala(v);
        }
        else if (v instanceof ValidationTargetJava) {
            return whenJava(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromParse(value) {
        return new ValidationTarget({ parse: value });
    }
    static fromTypeScript(value) {
        return new ValidationTarget({ typeScript: value });
    }
    static fromScala(value) {
        return new ValidationTarget({ scala: value });
    }
    static fromJava(value) {
        return new ValidationTarget({ java: value });
    }
    static from(value) {
        return value instanceof ValidationTarget ? value : new ValidationTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return ValidationTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ValidationTarget.RTTI_FQN;
    }
    toJSON() {
        return ValidationTarget.toJSON(this);
    }
}
ValidationTarget.RTTI_CLASS = 'ValidationTarget';
ValidationTarget.RTTI_FQN = 'io.protoforce.projects:ValidationTarget';
ValidationTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ValidationTargetParse) {
        return JSONConverter.withTypeNested(v, 'Parse');
    }
    else if (v instanceof ValidationTargetTypeScript) {
        return JSONConverter.withTypeNested(v, 'TypeScript');
    }
    else if (v instanceof ValidationTargetScala) {
        return JSONConverter.withTypeNested(v, 'Scala');
    }
    else if (v instanceof ValidationTargetJava) {
        return JSONConverter.withTypeNested(v, 'Java');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ValidationTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Parse': {
            const vc = val;
            return ValidationTarget.fromParse(ValidationTargetParse.fromJSON(vc));
        }
        case 'TypeScript': {
            const vc = val;
            return ValidationTarget.fromTypeScript(ValidationTargetTypeScript.fromJSON(vc));
        }
        case 'Scala': {
            const vc = val;
            return ValidationTarget.fromScala(ValidationTargetScala.fromJSON(vc));
        }
        case 'Java': {
            const vc = val;
            return ValidationTarget.fromJava(ValidationTargetJava.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ValidationTarget'`);
    }
};
