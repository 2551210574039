// tslint:disable
// eslint-disable
// package io.protoforce.tasks.react.tasksservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { tasksServiceRTTI } from '../../tasksservice/methods/tasksServiceRTTI';
import { tasksServiceTryCancel } from '../../tasksservice/methods/tasksServiceTryCancel';
import { useCallback } from 'react';
/**
  * Defined at tasks.domain @ 46:5
  */
export function useTasksServiceTryCancel(options, context) {
    const clientTransport = useClientTransport(tasksServiceRTTI, options);
    const request = useCallback((id) => {
        return tasksServiceTryCancel({ id }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
