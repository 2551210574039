// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { AccountID } from '../shared';
import { InviteAcceptedNotification } from './InviteAcceptedNotification';
import { NotificationID } from './NotificationID';
import { NotificationbaseCodec } from './codecs/NotificationbaseCodec';
import { NotificationbaseImpl } from './NotificationbaseImpl';
import { IRTZonedDateTime } from '../../../irt';
/**
  *
  *  User autojoined organization, only possible when confirmed email matches the org domain
  *
  * Class io.protoforce.notifications:UserJoinedNotification
  *
  * Defined at /notifications/org.model @ 21:1
  */
export class UserJoinedNotification {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.at = new IRTZonedDateTime();
            }
            return;
        }
        this.id = data.id;
        this.at = data.at;
        this.org = data.org;
        this.user = data.user;
    }
    get atAsDate() {
        return this.at.toDate();
    }
    set atAsDate(value) {
        this.at = IRTZonedDateTime.fromDate(value);
    }
    get atAsString() {
        return this.at.toJSON();
    }
    set atAsString(value) {
        this.at = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return UserJoinedNotification.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return UserJoinedNotification.RTTI_FQN;
    }
    static fromNotificationbase(from, org, user) {
        return new UserJoinedNotification({
            org: org,
            user: user,
            id: from.id,
            at: from.at
        });
    }
    loadNotificationbase(from) {
        this.id = from.id;
        this.at = from.at;
    }
    static fromLevelChangedNotification(from, user) {
        return new UserJoinedNotification({
            user: user,
            id: from.id,
            at: from.at,
            org: from.org
        });
    }
    loadLevelChangedNotification(from) {
        this.id = from.id;
        this.at = from.at;
        this.org = from.org;
    }
    toInviteAcceptedNotification() {
        return new InviteAcceptedNotification({
            id: this.id,
            at: this.at,
            org: this.org,
            user: this.user
        });
    }
    static fromAccessChangedNotification(from, org, user) {
        return new UserJoinedNotification({
            org: org,
            user: user,
            id: from.id,
            at: from.at
        });
    }
    loadAccessChangedNotification(from) {
        this.id = from.id;
        this.at = from.at;
    }
    toNotificationbase() {
        return new NotificationbaseImpl({
            id: this.id,
            at: this.at
        });
    }
    toJSON() {
        return UserJoinedNotification.toJSON(this);
    }
}
UserJoinedNotification.RTTI_CLASS = 'UserJoinedNotification';
UserJoinedNotification.RTTI_FQN = 'io.protoforce.notifications:UserJoinedNotification';
UserJoinedNotification.toJSON = (value) => {
    return {
        id: value.id.toJSON(),
        at: value.at.toJSON(),
        org: value.org.toJSON(),
        user: value.user.toJSON()
    };
};
UserJoinedNotification.fromJSON = (value) => {
    return new UserJoinedNotification({
        id: NotificationID.fromJSON(value.id),
        at: IRTZonedDateTime.fromJSON(value.at),
        org: AccountID.fromJSON(value.org),
        user: AccountID.fromJSON(value.user)
    });
};
NotificationbaseCodec.register(UserJoinedNotification.RTTI_FQN, UserJoinedNotification.fromJSON);
