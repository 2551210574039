// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BuildManifestCodec } from './codecs/BuildManifestCodec';
import { Common } from './Common';
import { ProjectNamingRule } from './ProjectNamingRule';
export class BuildManifestImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.common = data.common;
        this.naming = data.naming;
    }
    get RTTI_CLASS() {
        return BuildManifestImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BuildManifestImpl.RTTI_FQN;
    }
    toBuildManifest() {
        return new BuildManifestImpl({
            common: this.common,
            naming: this.naming
        });
    }
    toJSON() {
        return BuildManifestImpl.toJSON(this);
    }
}
BuildManifestImpl.RTTI_CLASS = 'BuildManifest';
BuildManifestImpl.RTTI_FQN = 'io.protoforce.projects:BuildManifest';
BuildManifestImpl.toJSON = (value) => {
    return {
        common: value.common.toJSON(),
        naming: value.naming.toJSON()
    };
};
BuildManifestImpl.fromJSON = (value) => {
    return new BuildManifestImpl({
        common: Common.fromJSON(value.common),
        naming: ProjectNamingRule.fromJSON(value.naming)
    });
};
BuildManifestCodec.register(BuildManifestImpl.RTTI_FQN, BuildManifestImpl.fromJSON);
