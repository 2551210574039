// tslint:disable
// eslint-disable
// package io.protoforce.notifications.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class NotificationbaseCodec {
    static register(fqn, ctor) {
        NotificationbaseCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete NotificationbaseCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in NotificationbaseCodec.registry;
    }
    static getRegistered() {
        return Object.keys(NotificationbaseCodec.registry);
    }
}
NotificationbaseCodec.registry = {};
NotificationbaseCodec.fromFQN = (fqn, value) => {
    const ctor = NotificationbaseCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface Notificationbase. Register at NotificationbaseCodec before it can be used.`);
    }
    return ctor(value);
};
NotificationbaseCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return NotificationbaseCodec.fromFQN(key, val);
};
NotificationbaseCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return NotificationbaseCodec.fromFQN(key, val);
};
NotificationbaseCodec.fromJSON = (value) => {
    return NotificationbaseCodec.fromTypeNestedJSON(value);
};
NotificationbaseCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
NotificationbaseCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
NotificationbaseCodec.toJSON = (value) => {
    return NotificationbaseCodec.toTypeNestedJSON(value);
};
