// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.methods
import { AuthServiceConfirmMFAInput } from '../models/AuthServiceConfirmMFAInput';
import { AuthServiceconfirmMFAMethodOutputHelper } from '../models/AuthServiceconfirmMFAMethodOutputHelper';
import { authServiceConfirmMFAName } from './authServiceConfirmMFAName';
import { authServiceRTTI } from './authServiceRTTI';
export function authServiceConfirmMFA(input, transport, context) {
    return transport.send(authServiceRTTI, authServiceConfirmMFAName, new AuthServiceConfirmMFAInput(input), {
        in: AuthServiceConfirmMFAInput,
        out: AuthServiceconfirmMFAMethodOutputHelper,
        alternative: true,
        context
    });
}
