// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
import { AccountBriefDataImpl } from './AccountBriefDataImpl';
import { AccountDataCodec } from './codecs/AccountDataCodec';
export class AccountDataImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
        this.bio = data.bio;
        this.url = data.url;
    }
    get RTTI_CLASS() {
        return AccountDataImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountDataImpl.RTTI_FQN;
    }
    static fromAccountBriefData(from, bio, url) {
        return new AccountDataImpl({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountBriefData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toAccountData() {
        return new AccountDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url
        });
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    static fromUserBrief(from, bio, url) {
        return new AccountDataImpl({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadUserBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromOrgMember(from, bio, url) {
        return new AccountDataImpl({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrgMember(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromOrganizationBrief(from, bio, url) {
        return new AccountDataImpl({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrganizationBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toJSON() {
        return AccountDataImpl.toJSON(this);
    }
}
AccountDataImpl.RTTI_CLASS = 'AccountData';
AccountDataImpl.RTTI_FQN = 'io.protoforce.auth:AccountData';
AccountDataImpl.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined
    };
};
AccountDataImpl.fromJSON = (value) => {
    return new AccountDataImpl({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined
    });
};
AccountDataCodec.register(AccountDataImpl.RTTI_FQN, AccountDataImpl.fromJSON);
AccountBriefDataCodec.register(AccountDataImpl.RTTI_FQN, AccountDataImpl.fromJSON);
