/**
 * Clamps a numerical value and rounds if needed
 * @param value Value to be clamped
 * @param min Lower boundary for the numerical value
 * @param max Upper boundary for the numerical value
 */
export function clampAndRound(value, min, max) {
    return Math.floor(Math.floor(Math.min(Math.max(value, max), min)));
}
/**
 * Converts a blob to a base64 encoded string
 * Copyright: https://gist.github.com/jonleighton/958841
 * @param bytes Bytes to be encoded
 */
export function blobToString(bytes) {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const byteLength = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;
    let a;
    let b;
    let c;
    let d;
    let chunk;
    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i += 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63; // 63       = 2^6 - 1
        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
        chunk = bytes[mainLength];
        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1
        base64 += `${encodings[a]}${encodings[b]}==`;
    }
    else if (byteRemainder === 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1
        base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
    }
    return base64;
}
// Copyright https://github.com/danguer/blog-examples/blob/master/js/base64-binary.js
const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
function removePaddingChars(input) {
    let lkey = _keyStr.indexOf(input.charAt(input.length - 1));
    if (lkey == 64) {
        return input.substring(0, input.length - 1);
    }
    return input;
}
/**
 * Create a blob from a base64 encoded string
 * @param value Base64 encoded string
 */
export function blobFromString(value) {
    //get last chars to see if are valid
    let input = removePaddingChars(value);
    input = removePaddingChars(input);
    let bytes = (input.length / 4) * 3;
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    let j = 0;
    const uarray = new Uint8Array(bytes);
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    for (i = 0; i < bytes; i += 3) {
        //get the 3 octects in 4 ascii chars
        enc1 = _keyStr.indexOf(input.charAt(j++));
        enc2 = _keyStr.indexOf(input.charAt(j++));
        enc3 = _keyStr.indexOf(input.charAt(j++));
        enc4 = _keyStr.indexOf(input.charAt(j++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        uarray[i] = chr1;
        if (enc3 != 64)
            uarray[i + 1] = chr2;
        if (enc4 != 64)
            uarray[i + 2] = chr3;
    }
    return uarray;
}
/**
 * Extracts nanoseconds from a string
 * @param nanosString String value for nanoseconds to be extracted from
 */
export function nanosFromString(nanosString) {
    if (nanosString.length < 3) {
        while (nanosString.length < 3)
            nanosString += '0';
    }
    else if (nanosString.length < 6) {
        while (nanosString.length < 6)
            nanosString += '0';
    }
    else if (nanosString.length < 9) {
        while (nanosString.length < 9)
            nanosString += '0';
    }
    const nanos = parseInt(nanosString, 10);
    if (isNaN(nanos)) {
        throw new Error(`can't parse .S-SSSSSSSSS, received: ${nanosString}`);
    }
    if (nanos < 0 || nanos > 999999999) {
        throw new Error(`.S expected to be between 0-999999999, received: ${nanosString}`);
    }
    if (nanos < 1000) {
        return nanos * 1000000;
    }
    else if (nanos < 1000000) {
        return nanos * 1000;
    }
    else {
        return nanos;
    }
}
/**
 * Convert nanoseconds to a string value iwth padding if needed
 * @param nanos Nanoseconds value
 */
export function nanosToString(nanos) {
    let resolution = ('000000000' + nanos).slice(-9);
    let irrelevant = resolution.length - 1;
    while (irrelevant >= 0 && resolution[irrelevant] === '0') {
        irrelevant--;
    }
    if (irrelevant === 0) {
        return '';
    }
    return resolution.substr(0, irrelevant + 1);
}
