var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route } from 'react-router';
import { useAuthContext } from '~/core/services/hooks/contexts';
import Component404 from '../general/404';
export var SecureRouteBehavior;
(function (SecureRouteBehavior) {
    SecureRouteBehavior[SecureRouteBehavior["Redirect"] = 0] = "Redirect";
    SecureRouteBehavior[SecureRouteBehavior["NotFound"] = 1] = "NotFound";
})(SecureRouteBehavior || (SecureRouteBehavior = {}));
export function SecureRoute(props) {
    const { component: cmp, behavior = SecureRouteBehavior.Redirect, redirectTo, inverse, condition } = props, rest = __rest(props, ["component", "behavior", "redirectTo", "inverse", "condition"]);
    const { loaded, loggedIn } = useAuthContext();
    const renderContent = (prps) => {
        if (!loaded) {
            return null;
        }
        if (typeof condition !== 'undefined'
            ? condition || inverse
            : loggedIn || inverse) {
            const { component: Component } = props;
            if (!Component) {
                return null;
            }
            return React.createElement(Component, Object.assign({}, prps));
        }
        switch (behavior) {
            case SecureRouteBehavior.Redirect: {
                return (React.createElement(Redirect, { to: redirectTo || '/', from: location ? location.pathname : undefined }));
            }
            case SecureRouteBehavior.NotFound: {
                return React.createElement(Component404, null);
            }
            default:
                return (React.createElement(Redirect, { to: redirectTo || '/', from: location ? location.pathname : undefined }));
        }
    };
    return React.createElement(Route, Object.assign({}, rest, { render: renderContent }));
}
