// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.orgservice
import { OrganizationBrief } from '../../OrganizationBrief';
import { useClientTransport, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { orgServiceGetOrgsBrief } from '../../orgservice/methods/orgServiceGetOrgsBrief';
import { orgServiceGetOrgsBriefName } from '../../orgservice/methods/orgServiceGetOrgsBriefName';
import { orgServiceRTTI } from '../../orgservice/methods/orgServiceRTTI';
import { useCallback } from 'react';
import { useQuery } from '@dyssent/fetcherjs';
export const orgServiceGetOrgsBriefTags = [
    '$pf',
    orgServiceRTTI.RTTI_FQN,
    orgServiceGetOrgsBriefName
];
/**
  *
  *  Brief information about a set of requested organizations.
  *
  *  @orgs List of orgs for which the details have to be returned
  *
  *  Note: returns no error if IDs are not found, just an empty array
  *
  * Defined at /auth/organization.service.model @ 222:5
  */
export function useOrgServiceGetOrgsBrief(orgs, $options, $context) {
    const clientTransport = useClientTransport(orgServiceRTTI, $options);
    const clientMethodKey = useServiceKey(orgServiceRTTI.RTTI_FQN, orgServiceGetOrgsBriefName, orgs)[0];
    return useQuery(typeof orgs === 'undefined' ? false : clientMethodKey, useCallback((orgs) => orgServiceGetOrgsBrief({ orgs }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: orgServiceGetOrgsBriefTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.map((e) => e.toJSON()), []),
            fromCache: useCallback(v => v.map((e) => OrganizationBrief.fromJSON(e)), [])
        } }, ($options || {})), { tags: useMergedTags(orgServiceGetOrgsBriefTags) }), orgs);
}
