import React from 'react';
import { useUserBrief } from '~/core/services/hooks/userService/useUserBrief';
import { Avatar } from '../account/Avatar';
import { AvatarSize } from '../account/AccountAvatar';
export var UserAvatarDisplay;
(function (UserAvatarDisplay) {
    UserAvatarDisplay[UserAvatarDisplay["Line"] = 0] = "Line";
    UserAvatarDisplay[UserAvatarDisplay["Block"] = 1] = "Block";
})(UserAvatarDisplay || (UserAvatarDisplay = {}));
export const UserAvatar = React.memo((props) => {
    const { user, subtitle, display } = props;
    const { data: userBrief } = useUserBrief(user);
    function renderLine(id, name) {
        return (React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'left'
            } },
            React.createElement(Avatar, { user: id, size: AvatarSize.Mini }),
            React.createElement("small", { style: { marginLeft: 5 }, className: 'bp4-text-muted' },
                name,
                subtitle ? ', ' + subtitle : '')));
    }
    function renderBlock(id, name) {
        return (React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'left'
            } },
            React.createElement(Avatar, { user: id, size: AvatarSize.Small }),
            React.createElement("div", { style: {
                    marginLeft: 5,
                    display: 'flex',
                    justifyContent: 'start',
                    flexDirection: 'column'
                } },
                React.createElement("span", null, name),
                subtitle && React.createElement("small", { className: 'bp4-text-muted' }, subtitle))));
    }
    const idPreviewLength = 8;
    const displayName = userBrief ? userBrief.name : user.id.substr(0, idPreviewLength);
    // TODO Make this a class instead of inline https://github.com/ProtoForce/protoforce-portal-webui/issues/16
    switch (display) {
        case UserAvatarDisplay.Block:
            return renderBlock(user, displayName);
        case UserAvatarDisplay.Line:
            return renderLine(user, displayName);
        default:
            return React.createElement("span", null, "Unknown Avatar Display");
    }
});
