import CryptoJS from 'crypto-js';
import { Hash, HashAlgorithm, GenericFailure, GenericFailureCode } from '@protoforce/shared';
import { formatClientError, ClientTransportError, ClientTransportRequestError, ClientTransportResponseError, SocketClientCriticalError, SocketClientTransportError } from '@protoforce/irt';
function seedRandom(seed) {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}
export function guid(seed) {
    const guidRandom = 0x10000;
    const guidLength = 16;
    function s4() {
        if (seed) {
            seed += 1;
        }
        const rnd = seed ? seedRandom(seed) : Math.random();
        return Math.floor((1 + rnd) * guidRandom)
            .toString(guidLength)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
export function extractBlogPostIDFromURL(route) {
    // Extract ID from the URL
    let routePost = route;
    if (routePost.indexOf('#') > 0) {
        routePost = routePost.substr(0, routePost.indexOf('#'));
    }
    if (routePost.length < 32) {
        // Not correct
        return '';
    }
    const idPart = routePost.substr(routePost.length - 32, 32);
    // xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
    const postRouteID = `${idPart.substr(0, 8)}-${idPart.substr(8, 4)}-${idPart.substr(12, 4)}-${idPart.substr(16, 4)}-${idPart.substr(20)}`;
    return postRouteID;
}
function SHA1(msg) {
    return CryptoJS.SHA1(msg).toString();
}
/* tslint:disable */
// function SHA1(msg: string) {
//   function rotate_left(n: any, s: any) {
//     return (n << s) | (n >>> (32 - s));
//   }
//   function cvt_hex(val: any) {
//     var str = '';
//     var i;
//     var v;
//     for (i = 7; i >= 0; i--) {
//       v = (val >>> (i * 4)) & 0x0f;
//       str += v.toString(16);
//     }
//     return str;
//   }
//   function Utf8Encode(string: any) {
//     string = string.replace(/\r\n/g, '\n');
//     var utftext = '';
//     for (var n = 0; n < string.length; n++) {
//       var c = string.charCodeAt(n);
//       if (c < 128) {
//         utftext += String.fromCharCode(c);
//       } else if (c > 127 && c < 2048) {
//         utftext += String.fromCharCode((c >> 6) | 192);
//         utftext += String.fromCharCode((c & 63) | 128);
//       } else {
//         utftext += String.fromCharCode((c >> 12) | 224);
//         utftext += String.fromCharCode(((c >> 6) & 63) | 128);
//         utftext += String.fromCharCode((c & 63) | 128);
//       }
//     }
//     return utftext;
//   }
//   var blockstart;
//   var i, j;
//   var W = new Array(80);
//   var H0 = 0x67452301;
//   var H1 = 0xefcdab89;
//   var H2 = 0x98badcfe;
//   var H3 = 0x10325476;
//   var H4 = 0xc3d2e1f0;
//   var A, B, C, D, E;
//   var temp;
//   msg = Utf8Encode(msg);
//   var msg_len = msg.length;
//   var word_array = new Array();
//   for (i = 0; i < msg_len - 3; i += 4) {
//     j =
//       (msg.charCodeAt(i) << 24) |
//       (msg.charCodeAt(i + 1) << 16) |
//       (msg.charCodeAt(i + 2) << 8) |
//       msg.charCodeAt(i + 3);
//     word_array.push(j);
//   }
//   switch (msg_len % 4) {
//     case 0:
//       i = 0x080000000;
//       break;
//     case 1:
//       i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000;
//       break;
//     case 2:
//       i =
//         (msg.charCodeAt(msg_len - 2) << 24) |
//         (msg.charCodeAt(msg_len - 1) << 16) |
//         0x08000;
//       break;
//     case 3:
//       i =
//         (msg.charCodeAt(msg_len - 3) << 24) |
//         (msg.charCodeAt(msg_len - 2) << 16) |
//         (msg.charCodeAt(msg_len - 1) << 8) |
//         0x80;
//       break;
//   }
//   word_array.push(i);
//   while (word_array.length % 16 != 14) word_array.push(0);
//   word_array.push(msg_len >>> 29);
//   word_array.push((msg_len << 3) & 0x0ffffffff);
//   for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
//     for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
//     for (i = 16; i <= 79; i++)
//       W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
//     A = H0;
//     B = H1;
//     C = H2;
//     D = H3;
//     E = H4;
//     for (i = 0; i <= 19; i++) {
//       temp =
//         (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
//         0x0ffffffff;
//       E = D;
//       D = C;
//       C = rotate_left(B, 30);
//       B = A;
//       A = temp;
//     }
//     for (i = 20; i <= 39; i++) {
//       temp =
//         (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff;
//       E = D;
//       D = C;
//       C = rotate_left(B, 30);
//       B = A;
//       A = temp;
//     }
//     for (i = 40; i <= 59; i++) {
//       temp =
//         (rotate_left(A, 5) +
//           ((B & C) | (B & D) | (C & D)) +
//           E +
//           W[i] +
//           0x8f1bbcdc) &
//         0x0ffffffff;
//       E = D;
//       D = C;
//       C = rotate_left(B, 30);
//       B = A;
//       A = temp;
//     }
//     for (i = 60; i <= 79; i++) {
//       temp =
//         (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff;
//       E = D;
//       D = C;
//       C = rotate_left(B, 30);
//       B = A;
//       A = temp;
//     }
//     H0 = (H0 + A) & 0x0ffffffff;
//     H1 = (H1 + B) & 0x0ffffffff;
//     H2 = (H2 + C) & 0x0ffffffff;
//     H3 = (H3 + D) & 0x0ffffffff;
//     H4 = (H4 + E) & 0x0ffffffff;
//   }
//   var temp: any =
//     cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
//   return temp.toLowerCase();
// }
/* tslint: enable */
// import forge from 'node-forge';
// const forgeHasher = forge.md.sha1.create();
// function hash(content: string): string {
//   forgeHasher.update(content);
//   return forgeHasher.digest().toHex();
// }
export function hashSHA1(content) {
    return SHA1(content);
}
export function getFileHash(file) {
    return file.content.match(whenProvided => {
        const h = new Hash();
        h.alg = HashAlgorithm.SHA1;
        h.value = hashSHA1(file.name + '#' + whenProvided.data);
        return h;
    }, () => file.hash);
}
export function formatError(error, extended) {
    if (typeof error === 'string') {
        return error;
    }
    if (error instanceof GenericFailure) {
        if (extended) {
            let res = `Code: ${GenericFailureCode[error.code]}. Message: ${error.message}.`;
            if (error.diagnostics) {
                res += `Diagnostics: ${error.diagnostics}`;
            }
            if (error.stack) {
                res += `Stack: ${error.stack}`;
            }
            return res;
        }
        else {
            return error.message;
        }
    }
    if (error instanceof ClientTransportError || error instanceof ClientTransportRequestError ||
        error instanceof ClientTransportResponseError || error instanceof SocketClientCriticalError ||
        error instanceof SocketClientTransportError) {
        return formatClientError(error, extended);
    }
    if (error instanceof Error) {
        if (extended) {
            let res = `Error: ${error.name}. Message: ${error.message}.`;
            if (error.stack) {
                res += `Stack: ${error.stack}`;
            }
            return res;
        }
        else {
            return error.message;
        }
    }
    return `Unexpected error: ${error}`;
}
