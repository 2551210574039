import { LogLevel } from '../logger';
/**
 * HTTP methods
 */
export var HTTPMethod;
(function (HTTPMethod) {
    HTTPMethod["GET"] = "GET";
    HTTPMethod["POST"] = "POST";
    HTTPMethod["PUT"] = "PUT";
    HTTPMethod["DELETE"] = "DELETE";
    HTTPMethod["PATCH"] = "PATCH";
    HTTPMethod["OPTIONS"] = "OPTIONS";
    // Not needed, only for reference here
    HTTPMethod["HEAD"] = "HEAD";
    HTTPMethod["CONNECT"] = "CONNECT";
    HTTPMethod["TRACE"] = "TRACE";
})(HTTPMethod || (HTTPMethod = {}));
export function getHTTPHeader(headers, header) {
    const value = headers[header.toLowerCase()];
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return undefined;
    }
    return Array.isArray(value) ? value[0] : value;
}
export function toHeadersMultimap(headers) {
    const res = {};
    if (headers) {
        Object.keys(headers).forEach(key => {
            const value = headers[key];
            if (typeof value === 'undefined') {
                return;
            }
            if (Array.isArray(value)) {
                res[key] = value;
            }
            else {
                res[key] = [value];
            }
        });
    }
    return res;
}
export function toHeadersMap(headers, logger) {
    const res = {};
    Object.keys(headers).forEach(key => {
        const content = headers[key];
        if (typeof content === 'undefined') {
            return;
        }
        if (Array.isArray(content)) {
            if (content.length === 0) {
                // Empty array, nothing to do
                return;
            }
            if (content.length > 1) {
                logger.logf(LogLevel.Warning, `Header ${key} is defined multiple times, only last one will be set.`);
            }
            res[key] = content[content.length - 1];
        }
        else {
            res[key] = content;
        }
    });
    return res;
}
export function isContentType(type, types) {
    if (!Array.isArray(types)) {
        return type === types;
    }
    for (let i = 0; i < types.length; i++) {
        if (types[i] === type) {
            return true;
        }
    }
    return false;
}
export var ContentType;
(function (ContentType) {
    ContentType["Header"] = "Content-Type";
    ContentType["ApplicationJson"] = "application/json";
    ContentType["TextJson"] = "text/json";
})(ContentType || (ContentType = {}));
export var CORSHeaders;
(function (CORSHeaders) {
    CORSHeaders["AllowOrigin"] = "Access-Control-Allow-Origin";
    CORSHeaders["AllowMethods"] = "Access-Control-Allow-Methods";
    CORSHeaders["MaxAge"] = "Access-Control-Max-Age";
    CORSHeaders["AllowHeaders"] = "Access-Control-Allow-Headers";
    CORSHeaders["ExposeHeaders"] = "Access-Control-Expose-Headers";
})(CORSHeaders || (CORSHeaders = {}));
export var XHeaders;
(function (XHeaders) {
    XHeaders["ResponseType"] = "X-Response-Type";
    XHeaders["Success"] = "X-Success";
    XHeaders["Failure"] = "X-Failure";
})(XHeaders || (XHeaders = {}));
export var XResponseType;
(function (XResponseType) {
    XResponseType["Success"] = "Success";
    XResponseType["Failure"] = "Failure";
})(XResponseType || (XResponseType = {}));
export var XSuccessHeader;
(function (XSuccessHeader) {
    XSuccessHeader["Left"] = "Left";
    XSuccessHeader["Right"] = "Right";
    XSuccessHeader["Scalar"] = "Scalar"; // 200
})(XSuccessHeader || (XSuccessHeader = {}));
export var XFailureHeader;
(function (XFailureHeader) {
    XFailureHeader["BadInput"] = "Bad-Input";
    XFailureHeader["ServerFailure"] = "Server-Failure"; // 501
})(XFailureHeader || (XFailureHeader = {}));
