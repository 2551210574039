import ReactGA from 'react-ga';
let initialized = false;
export function gaInit(ga, email) {
    if (initialized) {
        console.warn('Trying to initialize GA twice, skipping.');
        return;
    }
    ReactGA.initialize(ga, { gaOptions: { clientId: email } });
    ReactGA.set({ page: window.location.pathname });
    initialized = true;
}
export function gaTrackPageView(path) {
    if (!initialized) {
        return;
    }
    ReactGA.set({
        path: path || window.location.pathname
    });
    ReactGA.pageview(path || window.location.pathname);
}
export function gaTrackEvent(category, action, label, value, nonInteraction) {
    if (!initialized) {
        return;
    }
    ReactGA.event({
        category,
        action,
        label,
        nonInteraction
    });
}
export function gaTrackTiming(category, variable, value, label) {
    if (!initialized) {
        return;
    }
    ReactGA.timing({
        category,
        variable,
        value,
        label
    });
}
export function gaTrackException(description, fatal) {
    if (!initialized) {
        return;
    }
    ReactGA.exception({
        description,
        fatal
    });
}
