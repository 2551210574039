// tslint:disable
// eslint-disable
// package io.protoforce.notifications.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class ConfirmActionCodec {
    static register(fqn, ctor) {
        ConfirmActionCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete ConfirmActionCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in ConfirmActionCodec.registry;
    }
    static getRegistered() {
        return Object.keys(ConfirmActionCodec.registry);
    }
}
ConfirmActionCodec.registry = {};
ConfirmActionCodec.fromFQN = (fqn, value) => {
    const ctor = ConfirmActionCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface ConfirmAction. Register at ConfirmActionCodec before it can be used.`);
    }
    return ctor(value);
};
ConfirmActionCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return ConfirmActionCodec.fromFQN(key, val);
};
ConfirmActionCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return ConfirmActionCodec.fromFQN(key, val);
};
ConfirmActionCodec.fromJSON = (value) => {
    return ConfirmActionCodec.fromTypeNestedJSON(value);
};
ConfirmActionCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
ConfirmActionCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
ConfirmActionCodec.toJSON = (value) => {
    return ConfirmActionCodec.toTypeNestedJSON(value);
};
