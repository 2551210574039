import React from 'react';
import { Tag } from '@blueprintjs/core';
import { UniSearchMode } from './context';
export const UniSearchHelp = React.memo((props) => {
    const { searchMode } = props;
    return searchMode === UniSearchMode.All ? (React.createElement("div", { className: 'bp4-text-muted bp4-text-small', style: { padding: 20 } },
        "Search for users, companies, or projects. Use ",
        React.createElement(Tag, { minimal: true }, "user:"),
        ",",
        React.createElement(Tag, { minimal: true }, "org:"),
        ", or",
        ' ',
        React.createElement(Tag, { minimal: true }, "project:"),
        " to narrow down the search.")) : null;
});
