// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ScalaCompiledPublic } from './ScalaCompiledPublic';
import { ScalaSourceTargetPublic } from './ScalaSourceTargetPublic';
/**
  * ADT io.protoforce.projects:ScalaTargetPublic
  *
  * Defined at /projects/publishing.model @ 33:1
  */
export class ScalaTargetPublic {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ScalaTargetPublic must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.scalaCompiledPublic !== 'undefined') {
            this.value = value.scalaCompiledPublic;
        }
        else if (typeof value.scalaSourceTargetPublic !== 'undefined') {
            this.value = value.scalaSourceTargetPublic;
        }
        else {
            throw new Error('ADT ScalaTargetPublic constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenScalaCompiledPublic, whenScalaSourceTargetPublic) {
        const v = this.value;
        if (v instanceof ScalaCompiledPublic) {
            return whenScalaCompiledPublic(v);
        }
        else if (v instanceof ScalaSourceTargetPublic) {
            return whenScalaSourceTargetPublic(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenScalaCompiledPublic, whenScalaSourceTargetPublic) {
        const v = this.value;
        if (v instanceof ScalaCompiledPublic) {
            return whenScalaCompiledPublic(v);
        }
        else if (v instanceof ScalaSourceTargetPublic) {
            return whenScalaSourceTargetPublic(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromScalaCompiledPublic(value) {
        return new ScalaTargetPublic({ scalaCompiledPublic: value });
    }
    static fromScalaSourceTargetPublic(value) {
        return new ScalaTargetPublic({ scalaSourceTargetPublic: value });
    }
    static from(value) {
        return value instanceof ScalaTargetPublic ? value : new ScalaTargetPublic(undefined, value);
    }
    get RTTI_CLASS() {
        return ScalaTargetPublic.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaTargetPublic.RTTI_FQN;
    }
    toJSON() {
        return ScalaTargetPublic.toJSON(this);
    }
}
ScalaTargetPublic.RTTI_CLASS = 'ScalaTargetPublic';
ScalaTargetPublic.RTTI_FQN = 'io.protoforce.projects:ScalaTargetPublic';
ScalaTargetPublic.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaCompiledPublic) {
        return JSONConverter.withTypeNested(v, 'ScalaCompiledPublic');
    }
    else if (v instanceof ScalaSourceTargetPublic) {
        return JSONConverter.withTypeNested(v, 'ScalaSourceTargetPublic');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ScalaTargetPublic.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'ScalaCompiledPublic': {
            const vc = val;
            return ScalaTargetPublic.fromScalaCompiledPublic(ScalaCompiledPublic.fromJSON(vc));
        }
        case 'ScalaSourceTargetPublic': {
            const vc = val;
            return ScalaTargetPublic.fromScalaSourceTargetPublic(ScalaSourceTargetPublic.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ScalaTargetPublic'`);
    }
};
