// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { OAuthAccess } from './OAuthAccess';
/**
  * Class io.protoforce.auth:LoginWithGithub
  *
  * Defined at /auth/providers/github.model @ 11:1
  */
export class LoginWithGithub {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.access = data.access;
    }
    get RTTI_CLASS() {
        return LoginWithGithub.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginWithGithub.RTTI_FQN;
    }
    toJSON() {
        return LoginWithGithub.toJSON(this);
    }
}
LoginWithGithub.RTTI_CLASS = 'LoginWithGithub';
LoginWithGithub.RTTI_FQN = 'io.protoforce.auth:LoginWithGithub';
LoginWithGithub.toJSON = (value) => {
    return {
        access: value.access.toJSON()
    };
};
LoginWithGithub.fromJSON = (value) => {
    return new LoginWithGithub({
        access: OAuthAccess.fromJSON(value.access)
    });
};
