/**
 * Either Left implementation
 */
export class Left {
    constructor(value) {
        this.value = value;
    }
    /**
     * Map Either<L, A> to Either<L, B>
     * @param f Transformation from A to B
     */
    map(f) {
        return this;
    }
    /**
     * Map Either<L, A> to Either<V, B>
     * @param g Transformation from L to V
     * @param f Transformation from A to B
     */
    bimap(g, f) {
        return new Left(f(this.value));
    }
    /**
     * Fold Either<L, A> to B
     * @param whenRight Transformation from A to B
     * @param whenLeft Transformation from L to B
     */
    fold(whenRight, whenLeft) {
        return whenLeft(this.value);
    }
    /**
     * Fold Either<L, A> to V | B
     * @param whenRight Transformation from A to B
     * @param whenLeft Transformation from L to V
     */
    bifold(whenRight, whenLeft) {
        return whenLeft(this.value);
    }
    /**
     * Get the Right value
     * @param a To be used instead of Left branch if needed
     */
    getOrElse(a) {
        return a;
    }
    /**
     * Is a stored value of Left type
     */
    isLeft() {
        return true;
    }
    /**
     * Is a stored value of Right type
     */
    isRight() {
        return false;
    }
    /**
     * Swap the Either<L, A> to Either<A, L>
     */
    swap() {
        return new Right(this.value);
    }
    /**
     * If a stored value of Right type, checks whether it satisfies the filter,
     * if not - then the zero value is used to become a Left branched Either
     * @param p Filter function
     * @param zero Value to be used if Filter is not satisfied
     */
    filterOrElse(p, zero) {
        return this;
    }
    /**
     * Execute functions for right or left branches depending on the stored type
     * @param whenRight To be called when a stored value is Right
     * @param whenLeft To be called when a stored value is Left
     */
    match(whenRight, whenLeft) {
        whenLeft(this.value);
    }
    /**
     * Encode Either<L, A> to JSON EitherJSON<LS, AS>
     * @param whenRight Transformation from A to AS
     * @param whenLeft Transformation from L to LS
     */
    toJSON(whenRight, whenLeft) {
        return {
            left: whenLeft(this.value),
            right: undefined
        };
    }
}
export class Right {
    constructor(value) {
        this.value = value;
    }
    /**
     * Map Either<L, A> to Either<L, B>
     * @param f Transformation from A to B
     */
    map(f) {
        return new Right(f(this.value));
    }
    /**
     * Map Either<L, A> to Either<V, B>
     * @param g Transformation from L to V
     * @param f Transformation from A to B
     */
    bimap(g, f) {
        return new Right(g(this.value));
    }
    /**
     * Fold Either<L, A> to B
     * @param whenRight Transformation from A to B
     * @param whenLeft Transformation from L to B
     */
    fold(whenRight, whenLeft) {
        return whenRight(this.value);
    }
    /**
     * Fold Either<L, A> to V | B
     * @param whenRight Transformation from A to B
     * @param whenLeft Transformation from L to V
     */
    bifold(whenRight, whenLeft) {
        return whenRight(this.value);
    }
    /**
     * Get the Right value
     * @param a To be used instead of Left branch if needed
     */
    getOrElse(a) {
        return this.value;
    }
    /**
     * Is a stored value of Left type
     */
    isLeft() {
        return false;
    }
    /**
     * Is a stored value of Right type
     */
    isRight() {
        return true;
    }
    /**
     * Swap the Either<L, A> to Either<A, L>
     */
    swap() {
        return new Left(this.value);
    }
    /**
     * If a stored value of Right type, checks whether it satisfies the filter,
     * if not - then the zero value is used to become a Left branched Either
     * @param p Filter function
     * @param zero Value to be used if Filter is not satisfied
     */
    filterOrElse(p, zero) {
        return p(this.value) ? this : left(zero);
    }
    /**
     * Execute functions for right or left branches depending on the stored type
     * @param whenRight To be called when a stored value is Right
     * @param whenLeft To be called when a stored value is Left
     */
    match(whenRight, whenLeft) {
        whenRight(this.value);
    }
    /**
     * Encode Either<L, A> to JSON EitherJSON<LS, AS>
     * @param whenRight Transformation from A to AS
     * @param whenLeft Transformation from L to LS
     */
    toJSON(whenRight, whenLeft) {
        return {
            right: whenRight(this.value)
        };
    }
}
/**
 * Helper function to create a Right branches Either from a Right value
 * @param a Value of right type
 */
export const right = (a) => {
    return new Right(a);
};
/**
 * Helper function to create a Left branches Either from a Left value
 * @param a Value of left type
 */
export const left = (l) => {
    return new Left(l);
};
/**
 * Creates Either from JSON
 * @param value JSON encoded Either value
 * @param whenRight Transformation to be applied when a stored value is of Left type
 * @param whenLeft Transformation to be applied when a stored value is of Right type
 */
export function fromEitherJSON(value, whenRight, whenLeft) {
    if (typeof value.left !== 'undefined') {
        return left(whenLeft(value.left));
    }
    else if (typeof value.right !== 'undefined') {
        return right(whenRight(value.right));
    }
    throw new Error(`fromEitherJSON expects the value to have either left or right, but got: '${value}'`);
}
