// tslint:disable
// eslint-disable
// package io.protoforce.search
import { Iterator } from '../shared';
import { UserBrief } from '../auth';
/**
  * Class io.protoforce.search:SearchUsersResponse
  *
  * Defined at search.domain @ 23:1
  */
export class SearchUsersResponse {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.results = [];
            }
            return;
        }
        this.results = data.results;
        this.iterator = data.iterator;
    }
    get RTTI_CLASS() {
        return SearchUsersResponse.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SearchUsersResponse.RTTI_FQN;
    }
    toJSON() {
        return SearchUsersResponse.toJSON(this);
    }
}
SearchUsersResponse.RTTI_CLASS = 'SearchUsersResponse';
SearchUsersResponse.RTTI_FQN = 'io.protoforce.search:SearchUsersResponse';
SearchUsersResponse.toJSON = (value) => {
    return {
        results: value.results.map((e) => e.toJSON()),
        iterator: value.iterator.toJSON()
    };
};
SearchUsersResponse.fromJSON = (value) => {
    return new SearchUsersResponse({
        results: value.results.map((e) => UserBrief.fromJSON(e)),
        iterator: Iterator.fromJSON(value.iterator)
    });
};
