// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
/**
  * Identifier io.protoforce.shared:AccountName
  *
  * Defined at /shared/ids.model @ 10:1
  */
export class AccountName {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
    }
    get RTTI_CLASS() {
        return AccountName.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AccountName.RTTI_FQN;
    }
    toJSON() {
        return AccountName.toJSON(this);
    }
}
AccountName.RTTI_CLASS = 'AccountName';
AccountName.RTTI_FQN = 'io.protoforce.shared:AccountName';
AccountName.toJSON = (value) => {
    const kvs = [
        `name=${encodeURIComponent(value.name)}`
    ];
    return `${AccountName.RTTI_CLASS}#${kvs.join(';')}`;
};
AccountName.fromJSON = (value) => {
    const err = `Identifier ${AccountName.RTTI_CLASS}.fromJSON input (${value}) is malformed.`;
    const chunks = value.split('#');
    if (chunks.length !== 2) {
        throw new CodecError(err);
    }
    if (chunks[0] !== AccountName.RTTI_CLASS) {
        throw new CodecError(`${err} Expected to start with '${AccountName.RTTI_CLASS}#'`);
    }
    const values = chunks[1].split(';');
    if (values.length !== 1) {
        throw new CodecError(`${err} Expects 1 key values, got ${values.length}`);
    }
    const valueMap = {};
    for (let i = 0; i < values.length; i++) {
        const kv = values[i].split('=');
        if (kv.length !== 2) {
            throw new CodecError(`${err} Must have key=value pairs comma separated.`);
        }
        valueMap[kv[0]] = kv[1];
    }
    const data = {
        name: decodeURIComponent(valueMap['name'])
    };
    return new AccountName(data);
};
