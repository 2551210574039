// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.fileuploadservice
import { FileUploadServiceClient } from '../../fileuploadservice/FileUploadServiceClient';
import { useClientTransport } from '../../../../../irt-react';
import { useMemo } from 'react';
/**
  *
  *  File upload services
  *
  * Defined at /auth/upload.service.model @ 6:1
  */
export function useFileUploadService(transport) {
    const clientTransport = useClientTransport(FileUploadServiceClient, { transport });
    return useMemo(() => {
        return new FileUploadServiceClient(clientTransport);
    }, [clientTransport]);
}
