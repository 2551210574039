import { LogLevel, ConsoleLogger, JSONCodec, HTTPClientTransport } from '@protoforce/irt';
// const slowDownHooks = [{
//   onSend: async () => {
//     await new Promise(resolve => setTimeout(() => resolve(), 10000));
//   }
// }];
export function createTransport(config) {
    const logLevel = LogLevel[config.loggingLevel];
    const logger = new ConsoleLogger(logLevel);
    const codec = new JSONCodec(logLevel > LogLevel.Debug ? false : true);
    // const transport = new HTTPHybridTransport(
    //   config.restEndpoint,
    //   config.wsEndpoint,
    //   {
    //     logger,
    //     codecs: {
    //       'application/json': codec,
    //       'text/json': codec
    //     }
    //   }
    // );
    const transport = new HTTPClientTransport(config.restEndpoint, {
        logger,
        codecs: {
            'application/json': codec,
            'text/json': codec
        },
        // hooks: slowDownHooks,
        timeout: 600000
    });
    const internalTransport = new HTTPClientTransport('http://localhost:8081/api/internal/', { logger });
    return {
        config,
        logLevel,
        logger,
        codec,
        transport,
        internalTransport
    };
}
