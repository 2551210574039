// tslint:disable
// eslint-disable
// package io.protoforce.tasks
import { JSONConverter, CodecError } from '../../../irt';
import { TasksFilterAllInProject } from './TasksFilterAllInProject';
import { TasksFilterMine } from './TasksFilterMine';
import { TasksFilterMineInProject } from './TasksFilterMineInProject';
/**
  * ADT io.protoforce.tasks:TasksFilter
  *
  * Defined at tasks.domain @ 27:1
  */
export class TasksFilter {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT TasksFilter must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.mine !== 'undefined') {
            this.value = value.mine;
        }
        else if (typeof value.allInProject !== 'undefined') {
            this.value = value.allInProject;
        }
        else if (typeof value.mineInProject !== 'undefined') {
            this.value = value.mineInProject;
        }
        else {
            throw new Error('ADT TasksFilter constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenMine, whenAllInProject, whenMineInProject) {
        const v = this.value;
        if (v instanceof TasksFilterMine) {
            return whenMine(v);
        }
        else if (v instanceof TasksFilterAllInProject) {
            return whenAllInProject(v);
        }
        else if (v instanceof TasksFilterMineInProject) {
            return whenMineInProject(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenMine, whenAllInProject, whenMineInProject) {
        const v = this.value;
        if (v instanceof TasksFilterMine) {
            return whenMine(v);
        }
        else if (v instanceof TasksFilterAllInProject) {
            return whenAllInProject(v);
        }
        else if (v instanceof TasksFilterMineInProject) {
            return whenMineInProject(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromMine(value) {
        return new TasksFilter({ mine: value });
    }
    static fromAllInProject(value) {
        return new TasksFilter({ allInProject: value });
    }
    static fromMineInProject(value) {
        return new TasksFilter({ mineInProject: value });
    }
    static from(value) {
        return value instanceof TasksFilter ? value : new TasksFilter(undefined, value);
    }
    get RTTI_CLASS() {
        return TasksFilter.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TasksFilter.RTTI_FQN;
    }
    toJSON() {
        return TasksFilter.toJSON(this);
    }
}
TasksFilter.RTTI_CLASS = 'TasksFilter';
TasksFilter.RTTI_FQN = 'io.protoforce.tasks:TasksFilter';
TasksFilter.toJSON = (value) => {
    const v = value.value;
    if (v instanceof TasksFilterMine) {
        return JSONConverter.withTypeNested(v, 'Mine');
    }
    else if (v instanceof TasksFilterAllInProject) {
        return JSONConverter.withTypeNested(v, 'AllInProject');
    }
    else if (v instanceof TasksFilterMineInProject) {
        return JSONConverter.withTypeNested(v, 'MineInProject');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
TasksFilter.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Mine': {
            const vc = val;
            return TasksFilter.fromMine(TasksFilterMine.fromJSON(vc));
        }
        case 'AllInProject': {
            const vc = val;
            return TasksFilter.fromAllInProject(TasksFilterAllInProject.fromJSON(vc));
        }
        case 'MineInProject': {
            const vc = val;
            return TasksFilter.fromMineInProject(TasksFilterMineInProject.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'TasksFilter'`);
    }
};
