import React from 'react';
import { Card } from '@blueprintjs/core';
import { AccessLevelHelper } from '@protoforce/shared';
export const NotificationAccessChanged = React.memo((props) => {
    const { notif } = props;
    return (React.createElement(Card, { title: 'Access Changed', style: { margin: 5, padding: 20 }, elevation: 3 },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("span", { style: { padding: 5 } },
                "Your ",
                notif.project.account,
                "/",
                notif.project.name,
                " access has been changed to ",
                AccessLevelHelper.toString(notif.access)))));
});
