// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.methods
import { InternalDocsServiceSaveDocumentationInput } from '../models/InternalDocsServiceSaveDocumentationInput';
import { InternalDocsServicesaveDocumentationMethodOutputHelper } from '../models/InternalDocsServicesaveDocumentationMethodOutputHelper';
import { internalDocsServiceRTTI } from './internalDocsServiceRTTI';
import { internalDocsServiceSaveDocumentationName } from './internalDocsServiceSaveDocumentationName';
export function internalDocsServiceSaveDocumentation(input, transport, context) {
    return transport.send(internalDocsServiceRTTI, internalDocsServiceSaveDocumentationName, new InternalDocsServiceSaveDocumentationInput(input), {
        in: InternalDocsServiceSaveDocumentationInput,
        out: InternalDocsServicesaveDocumentationMethodOutputHelper,
        alternative: true,
        context
    });
}
