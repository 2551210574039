import React, { useMemo } from 'react';
import { createManagerWithMemoryCache, FetcherConfig } from '@dyssent/fetcherjs';
import { useServerStorage } from './useServerStorage';
export const FETCHER_SERVER_CONFIG_KEY = '__FETCHER_DATA__';
export const FetcherServerConfig = React.memo((props) => {
    const { children } = props;
    const storage = useServerStorage();
    const fetcherProps = useMemo(() => {
        const data = storage.get(FETCHER_SERVER_CONFIG_KEY, undefined);
        const manager = createManagerWithMemoryCache({
        // request: {
        //   type: 'query',
        //   retries: 5
        // }
        }, {
            defaultTTL: 0 // 5000
        }, data);
        return {
            managerInstance: manager
        };
    }, []);
    return (React.createElement(FetcherConfig, Object.assign({}, fetcherProps), children));
});
