var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTPMethod, toHeadersMap } from '../../http';
export function doFetchRequest(req, options) {
    const { timeout, logger } = options;
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        const timeoutHandler = setTimeout(() => {
            controller.abort();
            reject(new Error(`Request timed out`));
        }, timeout);
        try {
            const signal = controller.signal;
            const response = yield fetch(req.url, {
                method: req.method,
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: toHeadersMap(req.headers, logger),
                redirect: 'follow',
                referrer: 'no-referrer',
                body: req.method === HTTPMethod.GET ? undefined : req.payload,
                signal
            });
            const text = yield response.text();
            clearTimeout(timeoutHandler);
            const headers = {};
            response.headers.forEach((v, k) => headers[k] = v);
            const res = {
                status: response.status,
                payload: text,
                headers
            };
            resolve(res);
        }
        catch (err) {
            clearTimeout(timeoutHandler);
            reject(err);
        }
    }));
    promise.cancel = controller.abort;
    return promise;
}
