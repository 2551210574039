// tslint:disable
// eslint-disable
// package io.protoforce.auth
/**
  *
  *  LoginWithEmail can be used to login using an email and a password.
  *
  * Class io.protoforce.auth:LoginWithEmail
  *
  * Defined at /auth/login.model @ 5:1
  */
export class LoginWithEmail {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.email = '';
                this.pass = '';
            }
            return;
        }
        this.email = data.email;
        this.pass = data.pass;
    }
    get RTTI_CLASS() {
        return LoginWithEmail.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginWithEmail.RTTI_FQN;
    }
    toJSON() {
        return LoginWithEmail.toJSON(this);
    }
}
LoginWithEmail.RTTI_CLASS = 'LoginWithEmail';
LoginWithEmail.RTTI_FQN = 'io.protoforce.auth:LoginWithEmail';
LoginWithEmail.toJSON = (value) => {
    return {
        email: value.email,
        pass: value.pass
    };
};
LoginWithEmail.fromJSON = (value) => {
    return new LoginWithEmail({
        email: value.email,
        pass: value.pass
    });
};
