// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { Plan } from './Plan';
export class PlanHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return PlanHelper.toString(this.value);
    }
    static toString(e) {
        return Plan[e];
    }
    static fromString(e) {
        return Plan[e];
    }
    get RTTI_CLASS() {
        return PlanHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PlanHelper.RTTI_FQN;
    }
    toJSON() {
        return PlanHelper.toJSON(this.value);
    }
}
PlanHelper.RTTI_CLASS = 'Plan';
PlanHelper.RTTI_FQN = 'io.protoforce.shared:Plan';
PlanHelper.all = [
    Plan.Free,
    Plan.Plus,
    Plan.Pro
];
PlanHelper.toJSON = (value) => {
    return Plan[value];
};
PlanHelper.fromJSON = (value) => {
    switch (value) {
        case 'Free': return Plan.Free;
        case 'Plus': return Plan.Plus;
        case 'Pro': return Plan.Pro;
        default: throw new CodecError(`Unexpected value for enum Plan: '${value}'`);
    }
};
