// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { JSONConverter, CodecError } from '../../../irt';
import { ScalaCompiled } from './ScalaCompiled';
import { ScalaSourceTarget } from './ScalaSourceTarget';
/**
  * ADT io.protoforce.projects:ScalaTarget
  *
  * Defined at /projects/publishing.model @ 32:1
  */
export class ScalaTarget {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT ScalaTarget must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.scalaCompiled !== 'undefined') {
            this.value = value.scalaCompiled;
        }
        else if (typeof value.scalaSourceTarget !== 'undefined') {
            this.value = value.scalaSourceTarget;
        }
        else {
            throw new Error('ADT ScalaTarget constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenScalaCompiled, whenScalaSourceTarget) {
        const v = this.value;
        if (v instanceof ScalaCompiled) {
            return whenScalaCompiled(v);
        }
        else if (v instanceof ScalaSourceTarget) {
            return whenScalaSourceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenScalaCompiled, whenScalaSourceTarget) {
        const v = this.value;
        if (v instanceof ScalaCompiled) {
            return whenScalaCompiled(v);
        }
        else if (v instanceof ScalaSourceTarget) {
            return whenScalaSourceTarget(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromScalaCompiled(value) {
        return new ScalaTarget({ scalaCompiled: value });
    }
    static fromScalaSourceTarget(value) {
        return new ScalaTarget({ scalaSourceTarget: value });
    }
    static from(value) {
        return value instanceof ScalaTarget ? value : new ScalaTarget(undefined, value);
    }
    get RTTI_CLASS() {
        return ScalaTarget.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaTarget.RTTI_FQN;
    }
    toJSON() {
        return ScalaTarget.toJSON(this);
    }
}
ScalaTarget.RTTI_CLASS = 'ScalaTarget';
ScalaTarget.RTTI_FQN = 'io.protoforce.projects:ScalaTarget';
ScalaTarget.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ScalaCompiled) {
        return JSONConverter.withTypeNested(v, 'ScalaCompiled');
    }
    else if (v instanceof ScalaSourceTarget) {
        return JSONConverter.withTypeNested(v, 'ScalaSourceTarget');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
ScalaTarget.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'ScalaCompiled': {
            const vc = val;
            return ScalaTarget.fromScalaCompiled(ScalaCompiled.fromJSON(vc));
        }
        case 'ScalaSourceTarget': {
            const vc = val;
            return ScalaTarget.fromScalaSourceTarget(ScalaSourceTarget.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'ScalaTarget'`);
    }
};
