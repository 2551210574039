// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Enum io.protoforce.projects:ScalaExtension
  *
  * Defined at /projects/manifests/scala.model @ 7:1
  */
export var ScalaExtension;
(function (ScalaExtension) {
    /**
      * Defined at /projects/manifests/scala.model @ 9:5
      */
    ScalaExtension[ScalaExtension["Random"] = 0] = "Random";
    /**
      * Defined at /projects/manifests/scala.model @ 10:5
      */
    ScalaExtension[ScalaExtension["Metadata"] = 1] = "Metadata";
    /**
      * Defined at /projects/manifests/scala.model @ 11:5
      */
    ScalaExtension[ScalaExtension["CodecsIRT"] = 2] = "CodecsIRT";
    /**
      * Defined at /projects/manifests/scala.model @ 14:5
      */
    ScalaExtension[ScalaExtension["CodecsCirce"] = 3] = "CodecsCirce";
    /**
      * Defined at /projects/manifests/scala.model @ 15:5
      */
    ScalaExtension[ScalaExtension["CodecTests"] = 4] = "CodecTests";
})(ScalaExtension || (ScalaExtension = {}));
