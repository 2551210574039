// tslint:disable
// eslint-disable
// package io.protoforce.docs.imagesearchservice.methods
import { ImageSearchServiceUnsplashInput } from '../models/ImageSearchServiceUnsplashInput';
import { ImageSearchServiceunsplashMethodOutputHelper } from '../models/ImageSearchServiceunsplashMethodOutputHelper';
import { imageSearchServiceRTTI } from './imageSearchServiceRTTI';
import { imageSearchServiceUnsplashName } from './imageSearchServiceUnsplashName';
export function imageSearchServiceUnsplash(input, transport, context) {
    return transport.send(imageSearchServiceRTTI, imageSearchServiceUnsplashName, new ImageSearchServiceUnsplashInput(input), {
        in: ImageSearchServiceUnsplashInput,
        out: ImageSearchServiceunsplashMethodOutputHelper,
        alternative: true,
        context
    });
}
