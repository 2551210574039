// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
import { AccountBriefDataImpl } from './AccountBriefDataImpl';
import { AccountDataCodec } from './codecs/AccountDataCodec';
import { AccountDataImpl } from './AccountDataImpl';
import { OrganizationDataCodec } from './codecs/OrganizationDataCodec';
export class OrganizationDataImpl {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.isBusiness = false;
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
        this.bio = data.bio;
        this.url = data.url;
        this.isBusiness = data.isBusiness;
        this.businessName = data.businessName;
    }
    get RTTI_CLASS() {
        return OrganizationDataImpl.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return OrganizationDataImpl.RTTI_FQN;
    }
    static fromAccountBriefData(from, bio, url, isBusiness, businessName) {
        return new OrganizationDataImpl({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountBriefData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toOrganizationData() {
        return new OrganizationDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url,
            isBusiness: this.isBusiness,
            businessName: this.businessName
        });
    }
    static fromUserBrief(from, bio, url, isBusiness, businessName) {
        return new OrganizationDataImpl({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadUserBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromOrgMember(from, bio, url, isBusiness, businessName) {
        return new OrganizationDataImpl({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrgMember(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromOrganizationBrief(from, bio, url, isBusiness, businessName) {
        return new OrganizationDataImpl({
            bio: bio,
            url: url,
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadOrganizationBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    toAccountData() {
        return new AccountDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url
        });
    }
    static fromUser(from, isBusiness, businessName) {
        return new OrganizationDataImpl({
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadUser(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    static fromAccountData(from, isBusiness, businessName) {
        return new OrganizationDataImpl({
            isBusiness: isBusiness,
            businessName: businessName,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadAccountData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    toJSON() {
        return OrganizationDataImpl.toJSON(this);
    }
}
OrganizationDataImpl.RTTI_CLASS = 'OrganizationData';
OrganizationDataImpl.RTTI_FQN = 'io.protoforce.auth:OrganizationData';
OrganizationDataImpl.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        isBusiness: value.isBusiness,
        businessName: typeof value.businessName !== 'undefined' && value.businessName != null ? value.businessName : undefined
    };
};
OrganizationDataImpl.fromJSON = (value) => {
    return new OrganizationDataImpl({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        isBusiness: value.isBusiness,
        businessName: typeof value.businessName !== 'undefined' && value.businessName != null ? value.businessName : undefined
    });
};
OrganizationDataCodec.register(OrganizationDataImpl.RTTI_FQN, OrganizationDataImpl.fromJSON);
AccountBriefDataCodec.register(OrganizationDataImpl.RTTI_FQN, OrganizationDataImpl.fromJSON);
AccountDataCodec.register(OrganizationDataImpl.RTTI_FQN, OrganizationDataImpl.fromJSON);
