// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice
import { HTTPClientTransport } from '../../../../irt';
import { internalDocsServiceRTTI } from './methods/internalDocsServiceRTTI';
import { internalDocsServiceSave } from './methods/internalDocsServiceSave';
import { internalDocsServiceSaveDocumentation } from './methods/internalDocsServiceSaveDocumentation';
import { internalDocsServiceUpload } from './methods/internalDocsServiceUpload';
import { internalDocsServiceUploadDocumentationFile } from './methods/internalDocsServiceUploadDocumentationFile';
export class InternalDocsServiceClient {
    constructor(transport) {
        this.transport = transport;
    }
    get RTTI_CLASS() {
        return InternalDocsServiceClient.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return InternalDocsServiceClient.RTTI_FQN;
    }
    static fromHTTP(endpoint, options) {
        return new InternalDocsServiceClient(new HTTPClientTransport(endpoint, options));
    }
    /**
      * Defined at docs.domain @ 18:5
      */
    save(location, doc, _context) {
        return internalDocsServiceSave({ location, doc }, this.transport, _context);
    }
    /**
      * Defined at docs.domain @ 19:5
      */
    upload(location, data, _context) {
        return internalDocsServiceUpload({ location, data }, this.transport, _context);
    }
    /**
      * Defined at docs.domain @ 21:5
      */
    saveDocumentation(structure, articles, searchIndex, _context) {
        return internalDocsServiceSaveDocumentation({ structure, articles, searchIndex }, this.transport, _context);
    }
    /**
      * Defined at docs.domain @ 22:5
      */
    uploadDocumentationFile(article, data, _context) {
        return internalDocsServiceUploadDocumentationFile({ article, data }, this.transport, _context);
    }
}
InternalDocsServiceClient.RTTI_CLASS = internalDocsServiceRTTI.RTTI_CLASS;
InternalDocsServiceClient.RTTI_FQN = internalDocsServiceRTTI.RTTI_FQN;
