// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { SignupWith } from '../../SignupWith';
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  signup is used to create a new user
  *
  *  @data One of the options of the SignupWith type
  *
  * Class io.protoforce.auth.authservice.models:SignupInput
  *
  * Defined at /auth/auth.service.model @ 137:5
  */
export class AuthServiceSignupInput {
    constructor(data) {
        this.data = data.data;
    }
    get RTTI_CLASS() {
        return AuthServiceSignupInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthServiceSignupInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.POST,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'auth'
                    },
                    {
                        type: 'word',
                        value: 'signup'
                    }
                ]
            },
            body: {
                fields: [
                    {
                        field: {
                            name: 'data',
                            ref: {
                                id: 'SignupWith',
                                args: []
                            }
                        },
                        path: []
                    }
                ]
            }
        };
    }
    toJSON() {
        return AuthServiceSignupInput.toJSON(this);
    }
}
AuthServiceSignupInput.RTTI_CLASS = 'SignupInput';
AuthServiceSignupInput.RTTI_FQN = 'io.protoforce.auth.authservice.models:SignupInput';
AuthServiceSignupInput.toJSON = (value) => {
    return {
        data: value.data.toJSON()
    };
};
AuthServiceSignupInput.fromJSON = (value) => {
    return new AuthServiceSignupInput({
        data: SignupWith.fromJSON(value.data)
    });
};
