// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { PlanHelper } from './PlanHelper';
import { IRTZonedDateTime } from '../../../irt';
/**
  *
  *  Outlines the state of the plan the user is on.
  *
  * Class io.protoforce.shared:PlanState
  *
  * Defined at /shared/plans.model @ 23:1
  */
export class PlanState {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.plan = PlanHelper.all[0];
            }
            return;
        }
        this.plan = data.plan;
        this.paymentCapable = data.paymentCapable;
        this.validTill = data.validTill;
    }
    get RTTI_CLASS() {
        return PlanState.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PlanState.RTTI_FQN;
    }
    toJSON() {
        return PlanState.toJSON(this);
    }
}
PlanState.RTTI_CLASS = 'PlanState';
PlanState.RTTI_FQN = 'io.protoforce.shared:PlanState';
PlanState.toJSON = (value) => {
    return {
        plan: PlanHelper.toJSON(value.plan),
        paymentCapable: typeof value.paymentCapable !== 'undefined' && value.paymentCapable != null ? value.paymentCapable : undefined,
        validTill: typeof value.validTill !== 'undefined' && value.validTill != null ? value.validTill.toJSON() : undefined
    };
};
PlanState.fromJSON = (value) => {
    return new PlanState({
        plan: PlanHelper.fromJSON(value.plan),
        paymentCapable: typeof value.paymentCapable !== 'undefined' && value.paymentCapable != null ? value.paymentCapable : undefined,
        validTill: typeof value.validTill !== 'undefined' && value.validTill != null ? IRTZonedDateTime.fromJSON(value.validTill) : undefined
    });
};
