// tslint:disable
// eslint-disable
// package io.protoforce.auth.accountservice.models
import { AccountServiceGetAccountOutput } from './AccountServiceGetAccountOutput';
import { fromEitherJSON } from '../../../../../irt';
import { NotFoundFailure } from '../../../shared';
export class AccountServicegetAccountMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return AccountServiceGetAccountOutput.fromJSON(r);
        }, (l) => {
            return NotFoundFailure.fromJSON(l);
        });
    }
}
