// tslint:disable
// eslint-disable
// package io.protoforce.search
import { ProjectDataCodec } from '../projects';
import { ProjectName } from '../shared';
/**
  * Class io.protoforce.search:SearchProject
  *
  * Defined at search.domain @ 18:1
  */
export class SearchProject {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.isPublic = false;
            }
            return;
        }
        this.name = data.name;
        this.isPublic = data.isPublic;
        this.repository = data.repository;
        this.website = data.website;
        this.description = data.description;
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return SearchProject.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SearchProject.RTTI_FQN;
    }
    toJSON() {
        return SearchProject.toJSON(this);
    }
}
SearchProject.RTTI_CLASS = 'SearchProject';
SearchProject.RTTI_FQN = 'io.protoforce.search:SearchProject';
SearchProject.toJSON = (value) => {
    return {
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined,
        id: value.id.toJSON()
    };
};
SearchProject.fromJSON = (value) => {
    return new SearchProject({
        name: value.name,
        isPublic: value.isPublic,
        repository: typeof value.repository !== 'undefined' && value.repository != null ? value.repository : undefined,
        website: typeof value.website !== 'undefined' && value.website != null ? value.website : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined,
        id: ProjectName.fromJSON(value.id)
    });
};
ProjectDataCodec.register(SearchProject.RTTI_FQN, SearchProject.fromJSON);
