import { useMediaQuery as useMediaQueryResponsive } from 'react-responsive';
export function useDeviceMediaQuery() {
    const isDesktopOrLaptop = useMediaQueryResponsive({
        query: '(min-device-width: 1224px)'
    });
    const isPortrait = useMediaQueryResponsive({ query: '(orientation: portrait)' });
    const isRetina = useMediaQueryResponsive({ query: '(min-resolution: 2dppx)' });
    return {
        desktop: isDesktopOrLaptop,
        mobile: !isDesktopOrLaptop,
        portrait: isPortrait,
        retina: isRetina
    };
}
export function useMediaQuery(minWidth) {
    return useMediaQueryResponsive({
        query: `(min-width: ${minWidth}px)`
    });
}
export var MediaQuerySize;
(function (MediaQuerySize) {
    MediaQuerySize[MediaQuerySize["xxs"] = 0] = "xxs";
    MediaQuerySize[MediaQuerySize["xs"] = 480] = "xs";
    MediaQuerySize[MediaQuerySize["sm"] = 640] = "sm";
    MediaQuerySize[MediaQuerySize["md"] = 768] = "md";
    MediaQuerySize[MediaQuerySize["lg"] = 1024] = "lg";
    MediaQuerySize[MediaQuerySize["xl"] = 1289] = "xl";
    MediaQuerySize[MediaQuerySize["xxl"] = 1536] = "xxl";
})(MediaQuerySize || (MediaQuerySize = {}));
export function useMediaQuerySize() {
    const xs = useMediaQuery(MediaQuerySize.xs);
    const sm = useMediaQuery(MediaQuerySize.sm);
    const md = useMediaQuery(MediaQuerySize.md);
    const lg = useMediaQuery(MediaQuerySize.lg);
    const xl = useMediaQuery(MediaQuerySize.xl);
    const xxl = useMediaQuery(MediaQuerySize.xxl);
    if (xxl) {
        return MediaQuerySize.xxl;
    }
    if (xl) {
        return MediaQuerySize.xl;
    }
    if (lg) {
        return MediaQuerySize.lg;
    }
    if (md) {
        return MediaQuerySize.md;
    }
    if (sm) {
        return MediaQuerySize.sm;
    }
    if (xs) {
        return MediaQuerySize.xs;
    }
    return MediaQuerySize.xxs;
}
