// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/PublishingTarget:Releases (member of ADT io.protoforce.projects:PublishingTarget)
  *
  * Defined at /projects/publishing.model @ 199:5
  */
export class PublishingTargetReleases {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.url = '';
            }
            return;
        }
        this.url = data.url;
    }
    get RTTI_CLASS() {
        return PublishingTargetReleases.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTargetReleases.RTTI_FQN;
    }
    toJSON() {
        return PublishingTargetReleases.toJSON(this);
    }
}
PublishingTargetReleases.RTTI_CLASS = 'Releases';
PublishingTargetReleases.RTTI_FQN = 'io.protoforce.projects/PublishingTarget:Releases';
PublishingTargetReleases.toJSON = (value) => {
    return {
        url: value.url
    };
};
PublishingTargetReleases.fromJSON = (value) => {
    return new PublishingTargetReleases({
        url: value.url
    });
};
