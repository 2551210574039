// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Class io.protoforce.projects/PublishingTarget:Both (member of ADT io.protoforce.projects:PublishingTarget)
  *
  * Defined at /projects/publishing.model @ 200:5
  */
export class PublishingTargetBoth {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.url = '';
            }
            return;
        }
        this.url = data.url;
    }
    get RTTI_CLASS() {
        return PublishingTargetBoth.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingTargetBoth.RTTI_FQN;
    }
    toJSON() {
        return PublishingTargetBoth.toJSON(this);
    }
}
PublishingTargetBoth.RTTI_CLASS = 'Both';
PublishingTargetBoth.RTTI_FQN = 'io.protoforce.projects/PublishingTarget:Both';
PublishingTargetBoth.toJSON = (value) => {
    return {
        url: value.url
    };
};
PublishingTargetBoth.fromJSON = (value) => {
    return new PublishingTargetBoth({
        url: value.url
    });
};
