// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { IRTZonedDateTime } from '../../../irt';
/**
  * Class io.protoforce.projects/BgTaskStatus:Created (member of ADT io.protoforce.projects:BgTaskStatus)
  *
  * Defined at /projects/tasks.model @ 72:5
  */
export class BgTaskStatusCreated {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.timestamp = new IRTZonedDateTime();
            }
            return;
        }
        this.timestamp = data.timestamp;
        this.title = data.title;
        this.description = data.description;
    }
    get timestampAsDate() {
        return this.timestamp.toDate();
    }
    set timestampAsDate(value) {
        this.timestamp = IRTZonedDateTime.fromDate(value);
    }
    get timestampAsString() {
        return this.timestamp.toJSON();
    }
    set timestampAsString(value) {
        this.timestamp = IRTZonedDateTime.fromJSON(value);
    }
    get RTTI_CLASS() {
        return BgTaskStatusCreated.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskStatusCreated.RTTI_FQN;
    }
    toJSON() {
        return BgTaskStatusCreated.toJSON(this);
    }
}
BgTaskStatusCreated.RTTI_CLASS = 'Created';
BgTaskStatusCreated.RTTI_FQN = 'io.protoforce.projects/BgTaskStatus:Created';
BgTaskStatusCreated.toJSON = (value) => {
    return {
        timestamp: value.timestamp.toJSON(),
        title: typeof value.title !== 'undefined' && value.title != null ? value.title : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined
    };
};
BgTaskStatusCreated.fromJSON = (value) => {
    return new BgTaskStatusCreated({
        timestamp: IRTZonedDateTime.fromJSON(value.timestamp),
        title: typeof value.title !== 'undefined' && value.title != null ? value.title : undefined,
        description: typeof value.description !== 'undefined' && value.description != null ? value.description : undefined
    });
};
