import { useCallback, useMemo } from 'react';
function unpackServiceKeyType(k) {
    switch (typeof k) {
        case 'boolean':
            return k;
        case 'number':
            return k;
        case 'string':
            return k;
        case 'undefined':
            return k;
        case 'function': {
            throw new Error(`There should be no other types besides service classes in a key. toJSON method is missing.`);
        }
        case 'object': {
            if (k === null) {
                return k;
            }
            if (Array.isArray(k)) {
                return k.map(ak => unpackServiceKeyType(ak));
            }
            // See if this is a serializable object, or a class in other words
            const maybeSerializable = k;
            if (maybeSerializable.toJSON) {
                return maybeSerializable.toJSON();
            }
            const maybeADT = k;
            if (maybeADT.flatMap && maybeADT.flatValue && maybeADT.value && maybeADT.flatValue) {
                return unpackServiceKeyType([maybeADT.value.RTTI_FQN, maybeADT.value]);
            }
            // We checked it has no serialize, so it must be the other type
            const ko = k;
            const unpacked = {};
            Object.keys(k).forEach(key => (unpacked[key] = unpackServiceKeyType(ko[key])));
            return unpacked;
        }
        default:
            throw new Error(`Unexpected type: ${typeof k} in unpackServiceKeyType`);
    }
}
export function computeServiceKey(key) {
    if (typeof key === 'function') {
        return () => unpackServiceKeyType(key());
    }
    if (key === false) {
        return false;
    }
    return unpackServiceKeyType(key);
}
export function useServiceKey(service, method, ...args) {
    const func = useCallback((...forArgs) => computeServiceKey([service, method, ...forArgs]), [service, method]);
    const key = useMemo(() => {
        return func(...args);
    }, [...args]);
    return [key, func];
}
