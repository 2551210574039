// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.methods
import { UserServiceGetUsersBriefInput } from '../models/UserServiceGetUsersBriefInput';
import { UserServicegetUsersBriefMethodOutputHelper } from '../models/UserServicegetUsersBriefMethodOutputHelper';
import { userServiceGetUsersBriefName } from './userServiceGetUsersBriefName';
import { userServiceRTTI } from './userServiceRTTI';
export function userServiceGetUsersBrief(input, transport, context) {
    return transport.send(userServiceRTTI, userServiceGetUsersBriefName, new UserServiceGetUsersBriefInput(input), {
        in: UserServiceGetUsersBriefInput,
        out: UserServicegetUsersBriefMethodOutputHelper,
        context
    });
}
