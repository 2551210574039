// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * Enum io.protoforce.projects:TypeScriptProjectLayout
  *
  * Defined at /projects/manifests/typescript.model @ 2:1
  */
export var TypeScriptProjectLayout;
(function (TypeScriptProjectLayout) {
    /**
      * Defined at /projects/manifests/typescript.model @ 3:5
      */
    TypeScriptProjectLayout[TypeScriptProjectLayout["PACKAGED"] = 0] = "PACKAGED";
    /**
      * Defined at /projects/manifests/typescript.model @ 4:5
      */
    TypeScriptProjectLayout[TypeScriptProjectLayout["PLAIN"] = 1] = "PLAIN";
})(TypeScriptProjectLayout || (TypeScriptProjectLayout = {}));
