// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { ConfirmInviteAction } from './ConfirmInviteAction';
import { JSONConverter, CodecError } from '../../../irt';
/**
  * ADT io.protoforce.notifications:Action
  *
  * Defined at /notifications/action.model @ 31:1
  */
export class Action {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT Action must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.confirmInviteAction !== 'undefined') {
            this.value = value.confirmInviteAction;
        }
        else {
            throw new Error('ADT Action constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenConfirmInviteAction) {
        const v = this.value;
        if (v instanceof ConfirmInviteAction) {
            return whenConfirmInviteAction(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenConfirmInviteAction) {
        const v = this.value;
        if (v instanceof ConfirmInviteAction) {
            return whenConfirmInviteAction(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromConfirmInviteAction(value) {
        return new Action({ confirmInviteAction: value });
    }
    static from(value) {
        return value instanceof Action ? value : new Action(undefined, value);
    }
    get RTTI_CLASS() {
        return Action.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Action.RTTI_FQN;
    }
    toJSON() {
        return Action.toJSON(this);
    }
}
Action.RTTI_CLASS = 'Action';
Action.RTTI_FQN = 'io.protoforce.notifications:Action';
Action.toJSON = (value) => {
    const v = value.value;
    if (v instanceof ConfirmInviteAction) {
        return JSONConverter.withTypeNested(v, 'ConfirmInviteAction');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
Action.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'ConfirmInviteAction': {
            const vc = val;
            return Action.fromConfirmInviteAction(ConfirmInviteAction.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'Action'`);
    }
};
