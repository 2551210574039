// tslint:disable
// eslint-disable
// package io.protoforce.notifications.notificationsservice.models
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  Get the latest updates
  *
  * Class io.protoforce.notifications.notificationsservice.models:GetUpdatesInput
  *
  * Defined at /notifications/notifications.service.model @ 27:5
  */
export class NotificationsServiceGetUpdatesInput {
    get RTTI_CLASS() {
        return NotificationsServiceGetUpdatesInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotificationsServiceGetUpdatesInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.GET,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'notifs'
                    },
                    {
                        type: 'word',
                        value: 'updates'
                    }
                ]
            },
            body: {
                fields: []
            }
        };
    }
    toJSON() {
        return NotificationsServiceGetUpdatesInput.toJSON(this);
    }
}
NotificationsServiceGetUpdatesInput.RTTI_CLASS = 'GetUpdatesInput';
NotificationsServiceGetUpdatesInput.RTTI_FQN = 'io.protoforce.notifications.notificationsservice.models:GetUpdatesInput';
NotificationsServiceGetUpdatesInput.toJSON = (value) => {
    return {};
};
NotificationsServiceGetUpdatesInput.fromJSON = (value) => {
    return new NotificationsServiceGetUpdatesInput();
};
