// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { UUIDDefault, Validator, CodecError } from '../../../irt';
/**
  *
  *  Notification identifier
  *
  * Identifier io.protoforce.notifications:NotificationID
  *
  * Defined at /notifications/notification.model @ 7:1
  */
export class NotificationID {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.id = UUIDDefault;
            }
            return;
        }
        this.id = data.id;
    }
    get id() {
        return this._id;
    }
    set id(value) {
        Validator.guid(value, 'id');
        this._id = value;
    }
    get RTTI_CLASS() {
        return NotificationID.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return NotificationID.RTTI_FQN;
    }
    toJSON() {
        return NotificationID.toJSON(this);
    }
}
NotificationID.RTTI_CLASS = 'NotificationID';
NotificationID.RTTI_FQN = 'io.protoforce.notifications:NotificationID';
NotificationID.toJSON = (value) => {
    const kvs = [
        `id=${encodeURIComponent(value.id)}`
    ];
    return `${NotificationID.RTTI_CLASS}#${kvs.join(';')}`;
};
NotificationID.fromJSON = (value) => {
    const err = `Identifier ${NotificationID.RTTI_CLASS}.fromJSON input (${value}) is malformed.`;
    const chunks = value.split('#');
    if (chunks.length !== 2) {
        throw new CodecError(err);
    }
    if (chunks[0] !== NotificationID.RTTI_CLASS) {
        throw new CodecError(`${err} Expected to start with '${NotificationID.RTTI_CLASS}#'`);
    }
    const values = chunks[1].split(';');
    if (values.length !== 1) {
        throw new CodecError(`${err} Expects 1 key values, got ${values.length}`);
    }
    const valueMap = {};
    for (let i = 0; i < values.length; i++) {
        const kv = values[i].split('=');
        if (kv.length !== 2) {
            throw new CodecError(`${err} Must have key=value pairs comma separated.`);
        }
        valueMap[kv[0]] = kv[1];
    }
    const data = {
        id: decodeURIComponent(valueMap['id'])
    };
    return new NotificationID(data);
};
