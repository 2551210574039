// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { AccountBriefDataCodec } from './codecs/AccountBriefDataCodec';
import { AccountBriefDataImpl } from './AccountBriefDataImpl';
import { AccountDataCodec } from './codecs/AccountDataCodec';
import { AccountDataImpl } from './AccountDataImpl';
import { AccountID } from '../shared';
import { OrganizationBrief } from './OrganizationBrief';
import { UserBrief } from './UserBrief';
/**
  *
  *  User full information
  *
  * Class io.protoforce.auth:User
  *
  * Defined at /auth/user.model @ 53:1
  */
export class User {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
            }
            return;
        }
        this.name = data.name;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
        this.bio = data.bio;
        this.url = data.url;
        this.id = data.id;
    }
    get RTTI_CLASS() {
        return User.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return User.RTTI_FQN;
    }
    static fromAccountBriefData(from, bio, url, id) {
        return new User({
            bio: bio,
            url: url,
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar
        });
    }
    loadAccountBriefData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
    }
    static fromUserBrief(from, bio, url) {
        return new User({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id
        });
    }
    loadUserBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
    }
    toAccountData() {
        return new AccountDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            bio: this.bio,
            url: this.url
        });
    }
    static fromAccountData(from, id) {
        return new User({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadAccountData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    toOrganizationBrief() {
        return new OrganizationBrief({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            id: this.id
        });
    }
    static fromOrganizationCreateRequest(from, id) {
        return new User({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadOrganizationCreateRequest(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    static fromOrganizationData(from, id) {
        return new User({
            id: id,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            bio: from.bio,
            url: from.url
        });
    }
    loadOrganizationData(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.bio = from.bio;
        this.url = from.url;
    }
    static fromOrganizationBrief(from, bio, url) {
        return new User({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id
        });
    }
    loadOrganizationBrief(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
    }
    toAccountBriefData() {
        return new AccountBriefDataImpl({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar
        });
    }
    static fromOrgMember(from, bio, url) {
        return new User({
            bio: bio,
            url: url,
            name: from.name,
            displayName: from.displayName,
            avatar: from.avatar,
            id: from.id
        });
    }
    loadOrgMember(from) {
        this.name = from.name;
        this.displayName = from.displayName;
        this.avatar = from.avatar;
        this.id = from.id;
    }
    toUserBrief() {
        return new UserBrief({
            name: this.name,
            displayName: this.displayName,
            avatar: this.avatar,
            id: this.id
        });
    }
    toJSON() {
        return User.toJSON(this);
    }
}
User.RTTI_CLASS = 'User';
User.RTTI_FQN = 'io.protoforce.auth:User';
User.toJSON = (value) => {
    return {
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        id: value.id.toJSON()
    };
};
User.fromJSON = (value) => {
    return new User({
        name: value.name,
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        avatar: typeof value.avatar !== 'undefined' && value.avatar != null ? value.avatar : undefined,
        bio: typeof value.bio !== 'undefined' && value.bio != null ? value.bio : undefined,
        url: typeof value.url !== 'undefined' && value.url != null ? value.url : undefined,
        id: AccountID.fromJSON(value.id)
    });
};
AccountBriefDataCodec.register(User.RTTI_FQN, User.fromJSON);
AccountDataCodec.register(User.RTTI_FQN, User.fromJSON);
