// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.userservice
import { useClientTransport, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { UserBrief } from '../../UserBrief';
import { useCallback } from 'react';
import { useQuery } from '@dyssent/fetcherjs';
import { userServiceGetUsersBrief } from '../../userservice/methods/userServiceGetUsersBrief';
import { userServiceGetUsersBriefName } from '../../userservice/methods/userServiceGetUsersBriefName';
import { userServiceRTTI } from '../../userservice/methods/userServiceRTTI';
export const userServiceGetUsersBriefTags = [
    '$pf',
    userServiceRTTI.RTTI_FQN,
    userServiceGetUsersBriefName
];
/**
  *
  *  Brief information about a set of requested users.
  *
  *  @users List of users for which the details have to be returned
  *
  *  Note: returns no error if IDs are not found, just an empty array
  *
  * Defined at /auth/user.service.model @ 63:5
  */
export function useUserServiceGetUsersBrief(users, $options, $context) {
    const clientTransport = useClientTransport(userServiceRTTI, $options);
    const clientMethodKey = useServiceKey(userServiceRTTI.RTTI_FQN, userServiceGetUsersBriefName, users)[0];
    return useQuery(typeof users === 'undefined' ? false : clientMethodKey, useCallback((users) => userServiceGetUsersBrief({ users }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: userServiceGetUsersBriefTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.map((e) => e.toJSON()), []),
            fromCache: useCallback(v => v.map((e) => UserBrief.fromJSON(e)), [])
        } }, ($options || {})), { tags: useMergedTags(userServiceGetUsersBriefTags) }), users);
}
