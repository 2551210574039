// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { BgTaskStatusCancelled } from './BgTaskStatusCancelled';
import { BgTaskStatusCreated } from './BgTaskStatusCreated';
import { BgTaskStatusErrored } from './BgTaskStatusErrored';
import { BgTaskStatusFinished } from './BgTaskStatusFinished';
import { BgTaskStatusStarted } from './BgTaskStatusStarted';
import { JSONConverter, CodecError } from '../../../irt';
/**
  * ADT io.protoforce.projects:BgTaskStatus
  *
  * Defined at /projects/tasks.model @ 67:1
  */
export class BgTaskStatus {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT BgTaskStatus must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.created !== 'undefined') {
            this.value = value.created;
        }
        else if (typeof value.started !== 'undefined') {
            this.value = value.started;
        }
        else if (typeof value.finished !== 'undefined') {
            this.value = value.finished;
        }
        else if (typeof value.errored !== 'undefined') {
            this.value = value.errored;
        }
        else if (typeof value.cancelled !== 'undefined') {
            this.value = value.cancelled;
        }
        else {
            throw new Error('ADT BgTaskStatus constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenCreated, whenStarted, whenFinished, whenErrored, whenCancelled) {
        const v = this.value;
        if (v instanceof BgTaskStatusCreated) {
            return whenCreated(v);
        }
        else if (v instanceof BgTaskStatusStarted) {
            return whenStarted(v);
        }
        else if (v instanceof BgTaskStatusFinished) {
            return whenFinished(v);
        }
        else if (v instanceof BgTaskStatusErrored) {
            return whenErrored(v);
        }
        else if (v instanceof BgTaskStatusCancelled) {
            return whenCancelled(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenCreated, whenStarted, whenFinished, whenErrored, whenCancelled) {
        const v = this.value;
        if (v instanceof BgTaskStatusCreated) {
            return whenCreated(v);
        }
        else if (v instanceof BgTaskStatusStarted) {
            return whenStarted(v);
        }
        else if (v instanceof BgTaskStatusFinished) {
            return whenFinished(v);
        }
        else if (v instanceof BgTaskStatusErrored) {
            return whenErrored(v);
        }
        else if (v instanceof BgTaskStatusCancelled) {
            return whenCancelled(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromCreated(value) {
        return new BgTaskStatus({ created: value });
    }
    static fromStarted(value) {
        return new BgTaskStatus({ started: value });
    }
    static fromFinished(value) {
        return new BgTaskStatus({ finished: value });
    }
    static fromErrored(value) {
        return new BgTaskStatus({ errored: value });
    }
    static fromCancelled(value) {
        return new BgTaskStatus({ cancelled: value });
    }
    static from(value) {
        return value instanceof BgTaskStatus ? value : new BgTaskStatus(undefined, value);
    }
    get RTTI_CLASS() {
        return BgTaskStatus.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return BgTaskStatus.RTTI_FQN;
    }
    toJSON() {
        return BgTaskStatus.toJSON(this);
    }
}
BgTaskStatus.RTTI_CLASS = 'BgTaskStatus';
BgTaskStatus.RTTI_FQN = 'io.protoforce.projects:BgTaskStatus';
BgTaskStatus.toJSON = (value) => {
    const v = value.value;
    if (v instanceof BgTaskStatusCreated) {
        return JSONConverter.withTypeNested(v, 'Created');
    }
    else if (v instanceof BgTaskStatusStarted) {
        return JSONConverter.withTypeNested(v, 'Started');
    }
    else if (v instanceof BgTaskStatusFinished) {
        return JSONConverter.withTypeNested(v, 'Finished');
    }
    else if (v instanceof BgTaskStatusErrored) {
        return JSONConverter.withTypeNested(v, 'Errored');
    }
    else if (v instanceof BgTaskStatusCancelled) {
        return JSONConverter.withTypeNested(v, 'Cancelled');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
BgTaskStatus.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'Created': {
            const vc = val;
            return BgTaskStatus.fromCreated(BgTaskStatusCreated.fromJSON(vc));
        }
        case 'Started': {
            const vc = val;
            return BgTaskStatus.fromStarted(BgTaskStatusStarted.fromJSON(vc));
        }
        case 'Finished': {
            const vc = val;
            return BgTaskStatus.fromFinished(BgTaskStatusFinished.fromJSON(vc));
        }
        case 'Errored': {
            const vc = val;
            return BgTaskStatus.fromErrored(BgTaskStatusErrored.fromJSON(vc));
        }
        case 'Cancelled': {
            const vc = val;
            return BgTaskStatus.fromCancelled(BgTaskStatusCancelled.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'BgTaskStatus'`);
    }
};
