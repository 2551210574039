import { extractHeaders } from './common';
import { HTTPMethod, toHeadersMap } from '../../http';
export function doXMLHTTPRequest(req, options) {
    const { timeout, logger } = options;
    const r = new XMLHttpRequest();
    const promise = new Promise((resolve, reject) => {
        r.onreadystatechange = function () {
            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
            if (r.readyState === 4) {
                const res = {
                    status: r.status,
                    payload: r.responseText,
                    headers: extractHeaders(r.getAllResponseHeaders())
                };
                resolve(res);
            }
        };
        r.open(req.method, req.url, true);
        const headers = toHeadersMap(req.headers, logger);
        Object.keys(headers).forEach(key => {
            r.setRequestHeader(key, headers[key]);
        });
        r.timeout = timeout;
        // Suppresses: error TS2345: Argument of type 'CodecDataTypes | undefined' is not assignable to parameter of type 'Document | XMLHttpRequestBodyInit | null | undefined'
        // Alternative way once TS version is matching: req.payload as XMLHttpRequestBodyInit
        // @ts-ignore
        r.send(req.method === HTTPMethod.GET ? undefined : req.payload);
    });
    promise.cancel = r.abort;
    return promise;
}
