// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { SignupWithBaseCodec } from './codecs/SignupWithBaseCodec';
import { SignupWithBaseImpl } from './SignupWithBaseImpl';
/**
  *
  *  Sign up with a phone number
  *
  * Class io.protoforce.auth:SignupWithPhone
  *
  * Defined at /auth/signup.model @ 40:1
  */
export class SignupWithPhone {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.phone = '';
                this.pass = '';
            }
            return;
        }
        this.displayName = data.displayName;
        this.name = data.name;
        this.location = data.location;
        this.code = data.code;
        this.phone = data.phone;
        this.pass = data.pass;
    }
    get RTTI_CLASS() {
        return SignupWithPhone.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return SignupWithPhone.RTTI_FQN;
    }
    static fromSignupWithBase(from, phone, pass) {
        return new SignupWithPhone({
            phone: phone,
            pass: pass,
            displayName: from.displayName,
            name: from.name,
            location: from.location,
            code: from.code
        });
    }
    loadSignupWithBase(from) {
        this.displayName = from.displayName;
        this.name = from.name;
        this.location = from.location;
        this.code = from.code;
    }
    toSignupWithBase() {
        return new SignupWithBaseImpl({
            displayName: this.displayName,
            name: this.name,
            location: this.location,
            code: this.code
        });
    }
    static fromSignupWithEmail(from, phone) {
        return new SignupWithPhone({
            phone: phone,
            displayName: from.displayName,
            name: from.name,
            location: from.location,
            code: from.code,
            pass: from.pass
        });
    }
    loadSignupWithEmail(from) {
        this.displayName = from.displayName;
        this.name = from.name;
        this.location = from.location;
        this.code = from.code;
        this.pass = from.pass;
    }
    toJSON() {
        return SignupWithPhone.toJSON(this);
    }
}
SignupWithPhone.RTTI_CLASS = 'SignupWithPhone';
SignupWithPhone.RTTI_FQN = 'io.protoforce.auth:SignupWithPhone';
SignupWithPhone.toJSON = (value) => {
    return {
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined,
        phone: value.phone,
        pass: value.pass
    };
};
SignupWithPhone.fromJSON = (value) => {
    return new SignupWithPhone({
        displayName: typeof value.displayName !== 'undefined' && value.displayName != null ? value.displayName : undefined,
        name: value.name,
        location: typeof value.location !== 'undefined' && value.location != null ? value.location : undefined,
        code: typeof value.code !== 'undefined' && value.code != null ? value.code : undefined,
        phone: value.phone,
        pass: value.pass
    });
};
SignupWithBaseCodec.register(SignupWithPhone.RTTI_FQN, SignupWithPhone.fromJSON);
