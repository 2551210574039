import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { UserBrief, OrganizationBrief } from '@protoforce/auth';
import { SearchProject } from '@protoforce/search';
import { UniSearchView } from './UniSearchView';
import { UniSearchContext, UniSearchMode } from './context';
export * from './context';
export const UniSearchHost = React.memo((props) => {
    const history = useHistory();
    const [listener, setListener] = useState();
    const startSearch = useCallback((mode, onClose) => {
        setListener({
            mode,
            onClose
        });
    }, []);
    const onViewClose = useCallback((picked) => {
        if (!listener) {
            return;
        }
        if (listener.onClose) {
            listener.onClose(picked);
        }
        else if (picked) {
            let path = '';
            if (picked instanceof UserBrief) {
                path = `/${picked.name}`;
            }
            else if (picked instanceof OrganizationBrief) {
                path = `/${picked.name}`;
            }
            else if (picked instanceof SearchProject) {
                path = `/${picked.id.account}/${picked.id.name}`;
            }
            history.push(path);
        }
        setListener(undefined);
    }, [listener]);
    const context = useMemo(() => {
        return {
            startSearch
        };
    }, [startSearch]);
    return (React.createElement(UniSearchContext.Provider, { value: context },
        props.children,
        React.createElement(UniSearchView, { placeholder: 'Search...', isOpen: listener ? true : false, searchMode: listener ? listener.mode : UniSearchMode.All, onClose: onViewClose })));
});
