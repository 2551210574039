// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.models
import { AuthTokens } from '../../AuthTokens';
import { HTTPMethod } from '../../../../../irt';
/**
  *
  *  Refresh your access token, providing the refresh token.
  *  Returns an access token which is now valid again, or failure
  *  if something went wrong.
  *
  * Class io.protoforce.auth.authservice.models:RefreshInput
  *
  * Defined at /auth/auth.service.model @ 170:6
  */
export class AuthServiceRefreshInput {
    constructor(data) {
        this.tokens = data.tokens;
    }
    get RTTI_CLASS() {
        return AuthServiceRefreshInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return AuthServiceRefreshInput.RTTI_FQN;
    }
    static get restSpec() {
        return {
            method: HTTPMethod.POST,
            extractor: {
                queryParameters: {},
                pathSpec: [
                    {
                        type: 'word',
                        value: 'auth'
                    },
                    {
                        type: 'word',
                        value: 'refresh'
                    }
                ]
            },
            body: {
                fields: [
                    {
                        field: {
                            name: 'access',
                            ref: {
                                id: 'str',
                                args: []
                            }
                        },
                        path: [
                            {
                                name: 'tokens',
                                ref: {
                                    id: 'AuthTokens',
                                    args: []
                                }
                            }
                        ]
                    },
                    {
                        field: {
                            name: 'refresh',
                            ref: {
                                id: 'str',
                                args: []
                            }
                        },
                        path: [
                            {
                                name: 'tokens',
                                ref: {
                                    id: 'AuthTokens',
                                    args: []
                                }
                            }
                        ]
                    }
                ]
            }
        };
    }
    toJSON() {
        return AuthServiceRefreshInput.toJSON(this);
    }
}
AuthServiceRefreshInput.RTTI_CLASS = 'RefreshInput';
AuthServiceRefreshInput.RTTI_FQN = 'io.protoforce.auth.authservice.models:RefreshInput';
AuthServiceRefreshInput.toJSON = (value) => {
    return {
        tokens: value.tokens.toJSON()
    };
};
AuthServiceRefreshInput.fromJSON = (value) => {
    return new AuthServiceRefreshInput({
        tokens: AuthTokens.fromJSON(value.tokens)
    });
};
