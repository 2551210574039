// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { OAuthAccess } from './OAuthAccess';
/**
  * Class io.protoforce.auth:LoginWithGoogle
  *
  * Defined at /auth/providers/google.model @ 6:1
  */
export class LoginWithGoogle {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.access = data.access;
    }
    get RTTI_CLASS() {
        return LoginWithGoogle.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return LoginWithGoogle.RTTI_FQN;
    }
    toJSON() {
        return LoginWithGoogle.toJSON(this);
    }
}
LoginWithGoogle.RTTI_CLASS = 'LoginWithGoogle';
LoginWithGoogle.RTTI_FQN = 'io.protoforce.auth:LoginWithGoogle';
LoginWithGoogle.toJSON = (value) => {
    return {
        access: value.access.toJSON()
    };
};
LoginWithGoogle.fromJSON = (value) => {
    return new LoginWithGoogle({
        access: OAuthAccess.fromJSON(value.access)
    });
};
