import { JSONCodec } from '../../codec';
import { HTTPMethod, ContentType } from '../http';
import { doXMLHTTPRequest, doFetchRequest } from './http';
import { DummyLogger } from '../../logger';
export function sanitizeEndpoint(endpoint) {
    if (!endpoint || endpoint.length === 0) {
        return endpoint;
    }
    if (endpoint.endsWith('/') || endpoint.endsWith('\\')) {
        endpoint = endpoint.substr(0, endpoint.length - 1);
    }
    return endpoint;
}
const defaultJsonCodec = new JSONCodec();
const defaultHTTPClientTransportCommonOptions = {
    timeout: 60 * 1000,
    logger: new DummyLogger(),
    headers: {},
    defaultContentType: ContentType.ApplicationJson,
    codecs: {
        [ContentType.ApplicationJson]: defaultJsonCodec,
        [ContentType.TextJson]: defaultJsonCodec
    },
    hooks: [],
    useServiceFQN: true
};
export const defaultHTTPClientTransportOptions = Object.assign(Object.assign({}, defaultHTTPClientTransportCommonOptions), { useServiceFQN: false, requestFunction: typeof XMLHttpRequest !== 'undefined' ? doXMLHTTPRequest : doFetchRequest });
export const defaultHTTPWebsocketClientTransportOptions = Object.assign(Object.assign({}, defaultHTTPClientTransportCommonOptions), { protocols: [], buzzerContext: undefined });
export function formatHTTPRequest(req) {
    let res = `${HTTPMethod[req.method]} ${req.url}\nHeaders:\n${JSON.stringify(req.headers, undefined, 2)}`;
    if (req.payload) {
        res += `\n\nPayload:\n${JSON.stringify(req.payload)}`;
    }
    return res;
}
export function formatHTTPResponse(res) {
    return `Code: ${res.status}\nHeaders:\n${JSON.stringify(res.headers, undefined, 2)}\nPayload:\n\n${res.payload}`;
}
