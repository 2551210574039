// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.authservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { authServiceConfirmMFA } from '../../authservice/methods/authServiceConfirmMFA';
import { authServiceRTTI } from '../../authservice/methods/authServiceRTTI';
import { useCallback } from 'react';
/**
  *
  *  confirmMFA is an optional step, which is skipped for users with
  *  no 2FA/MFA enabled
  *
  *  @data MFA codes that were collected from the user
  *
  * Defined at /auth/auth.service.model @ 162:5
  */
export function useAuthServiceConfirmMFA(options, context) {
    const clientTransport = useClientTransport(authServiceRTTI, options);
    const request = useCallback((data) => {
        return authServiceConfirmMFA({ data }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
