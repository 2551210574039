import React from 'react';
import { Card } from '@blueprintjs/core';
import { UserLevelHelper } from '@protoforce/shared';
import { useOrgBrief } from '~/core/services/hooks/orgService/useOrgBrief';
export const NotificationLevelChanged = React.memo((props) => {
    const { notif } = props;
    const { data: orgData } = useOrgBrief(notif.org);
    return (React.createElement(Card, { title: 'Level Changed', style: { margin: 5, padding: 20 }, elevation: 3 },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("span", { style: { padding: 5 } },
                "Your ",
                orgData ? orgData.displayName || orgData.name : '?',
                " level has been changed to ",
                UserLevelHelper.toString(notif.level)))));
});
