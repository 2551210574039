// tslint:disable
// eslint-disable
// package io.protoforce.docs.imagesearchservice.models
/**
  * Class io.protoforce.docs.imagesearchservice.models:UnsplashRandomInput
  *
  * Defined at /docs/doc.unsplash.model @ 18:5
  */
export class ImageSearchServiceUnsplashRandomInput {
    get RTTI_CLASS() {
        return ImageSearchServiceUnsplashRandomInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ImageSearchServiceUnsplashRandomInput.RTTI_FQN;
    }
    toJSON() {
        return ImageSearchServiceUnsplashRandomInput.toJSON(this);
    }
}
ImageSearchServiceUnsplashRandomInput.RTTI_CLASS = 'UnsplashRandomInput';
ImageSearchServiceUnsplashRandomInput.RTTI_FQN = 'io.protoforce.docs.imagesearchservice.models:UnsplashRandomInput';
ImageSearchServiceUnsplashRandomInput.toJSON = (value) => {
    return {};
};
ImageSearchServiceUnsplashRandomInput.fromJSON = (value) => {
    return new ImageSearchServiceUnsplashRandomInput();
};
