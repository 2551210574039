// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { CodecError } from '../../../irt';
import { HashAlgorithm } from './HashAlgorithm';
export class HashAlgorithmHelper {
    constructor(value) {
        this.value = value;
    }
    toString() {
        return HashAlgorithmHelper.toString(this.value);
    }
    static toString(e) {
        return HashAlgorithm[e];
    }
    static fromString(e) {
        return HashAlgorithm[e];
    }
    get RTTI_CLASS() {
        return HashAlgorithmHelper.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return HashAlgorithmHelper.RTTI_FQN;
    }
    toJSON() {
        return HashAlgorithmHelper.toJSON(this.value);
    }
}
HashAlgorithmHelper.RTTI_CLASS = 'HashAlgorithm';
HashAlgorithmHelper.RTTI_FQN = 'io.protoforce.shared:HashAlgorithm';
HashAlgorithmHelper.all = [
    HashAlgorithm.SHA1,
    HashAlgorithm.SHA256
];
HashAlgorithmHelper.toJSON = (value) => {
    return HashAlgorithm[value];
};
HashAlgorithmHelper.fromJSON = (value) => {
    switch (value) {
        case 'SHA1': return HashAlgorithm.SHA1;
        case 'SHA256': return HashAlgorithm.SHA256;
        default: throw new CodecError(`Unexpected value for enum HashAlgorithm: '${value}'`);
    }
};
