// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.methods
import { InternalDocsServiceSaveInput } from '../models/InternalDocsServiceSaveInput';
import { InternalDocsServicesaveMethodOutputHelper } from '../models/InternalDocsServicesaveMethodOutputHelper';
import { internalDocsServiceRTTI } from './internalDocsServiceRTTI';
import { internalDocsServiceSaveName } from './internalDocsServiceSaveName';
export function internalDocsServiceSave(input, transport, context) {
    return transport.send(internalDocsServiceRTTI, internalDocsServiceSaveName, new InternalDocsServiceSaveInput(input), {
        in: InternalDocsServiceSaveInput,
        out: InternalDocsServicesaveMethodOutputHelper,
        alternative: true,
        context
    });
}
