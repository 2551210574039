// TODO Optimizations, consider moving zipping files to a separate module with
// async download, this is around 30KB
import JSZip from 'jszip';
import saveAs from 'file-saver';
export function saveZip(name, content) {
    const zip = new JSZip();
    content.forEach(f => zip.file(f.name, f.content));
    zip
        .generateAsync({
        type: 'blob'
    })
        .then((zipContent) => {
        saveAs.saveAs(zipContent, name);
    })
        .catch(err => {
        console.error(err);
    });
}
export function saveFile(name, content) {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    saveAs.saveAs(blob, name);
}
export function loadZip(data, onLoaded, onError) {
    const z = new JSZip();
    z.loadAsync(data)
        .then((zip) => {
        const keys = Object.keys(zip.files);
        const res = {};
        let counter = keys.length;
        let error;
        const onIterationComplete = () => {
            counter--;
            if (counter > 0) {
                return;
            }
            if (error) {
                onError(error);
            }
            else {
                onLoaded(res);
            }
        };
        keys.forEach((filename) => {
            // Skip folders
            // MACOSX is a folder mac puts some hidden files in, we ignore such files
            // e.g. __MACOSX/.some.model
            if (filename.endsWith('/') || filename.startsWith('__MACOSX/')) {
                onIterationComplete();
                return;
            }
            zip.files[filename]
                .async('text')
                .then((fileData) => {
                res[filename] = fileData;
                onIterationComplete();
            })
                .catch((reason) => {
                error = '' + reason;
                onIterationComplete();
            });
        });
    })
        .catch((reason) => {
        onError("Doesn't seem to be a valid zip file.");
        // onError('' + reason);
    });
}
