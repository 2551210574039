// tslint:disable
// eslint-disable
// package io.protoforce.auth.authservice.methods
import { AuthServiceSignupInput } from '../models/AuthServiceSignupInput';
import { AuthServicesignupMethodOutputHelper } from '../models/AuthServicesignupMethodOutputHelper';
import { SignupWith } from '../../SignupWith';
import { authServiceRTTI } from './authServiceRTTI';
import { authServiceSignupName } from './authServiceSignupName';
export function authServiceSignup(input, transport, context) {
    return transport.send(authServiceRTTI, authServiceSignupName, new AuthServiceSignupInput(Object.assign(Object.assign({}, input), { data: SignupWith.from(input.data) })), {
        in: AuthServiceSignupInput,
        out: AuthServicesignupMethodOutputHelper,
        alternative: true,
        context
    });
}
