import { useCallback } from 'react';
import { defaultRetryDecay, useQuery } from '@dyssent/fetcherjs';
export function useEitherQuery(key, request, options, ...args) {
    const validate = useCallback((payload) => {
        // @ts-ignore
        return Promise.resolve(payload.bifold(right => (options.validate ? options.validate(right) : undefined), left => left));
    }, [options.validate]);
    const transform = useCallback((payload) => {
        return Promise.resolve(payload.bifold((vr) => (options.transform ? options.transform(vr) : vr), _ => {
            throw new Error(`Should never get into transform with a Left branch of Either`);
        }));
    }, [options.transform]);
    const retryDecay = useCallback((attempts, e) => {
        const should = options.shouldRetry ? options.shouldRetry(e) : true;
        if (!should) {
            return false;
        }
        return options.retryDecay
            ? typeof options.retryDecay === 'function'
                ? options.retryDecay(attempts, e)
                : options.retryDecay
            : defaultRetryDecay(attempts);
    }, [options.shouldRetry, options.retryDecay]);
    return useQuery(key, request, Object.assign(Object.assign({}, options), { transform,
        retryDecay,
        validate }), ...args);
}
