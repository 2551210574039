import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
export function VideoView(props) {
    const { url, type, autoPlay = false, loop = true, muted = true, style } = props;
    const videoRef = useRef(null);
    const [isPaused, setIsPaused] = useState(true);
    const playPause = useCallback(() => {
        if (isPaused) {
            videoRef.current.play();
        }
        else {
            videoRef.current.pause();
        }
        setIsPaused(p => !p);
    }, [isPaused]);
    return (React.createElement("div", { className: 'video-wrapper', onClick: playPause, style: Object.assign({ display: 'table' }, (style || {})) },
        React.createElement("video", { ref: videoRef, autoPlay: autoPlay, loop: loop, muted: muted, width: '100%', height: '100%' },
            React.createElement("source", { src: url, type: type })),
        React.createElement("div", { className: classNames('play-button', {
                show: isPaused, hide: !isPaused
            }) })));
}
