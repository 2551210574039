import { useEffect, useRef, useMemo } from 'react';
function isAppleOS() {
    const os = typeof navigator !== 'undefined' ? navigator.platform : undefined;
    return os == null || typeof os === 'undefined' ? false : /Mac|iPod|iPhone|iPad/.test(os);
}
function isTextInput(e) {
    const elem = e.target;
    const editable = elem.closest('input, textarea, [contenteditable=true]');
    if (editable == null) {
        return false;
    }
    const editableInput = editable;
    // skip checkboxes, switches, and radios, thye shouldn't block as they are not
    // real text edits
    if (editable.tagName.toLowerCase() === 'input') {
        const inputType = editableInput.type;
        if (inputType === 'checkbox' || inputType === 'radio') {
            return false;
        }
    }
    // skip readonly and disabled
    if (editableInput.readOnly || editableInput.disabled) {
        return false;
    }
    return true;
}
const KeyCodes = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    20: 'capslock',
    27: 'esc',
    32: 'space',
    33: 'pageup',
    34: 'pagedown',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    45: 'ins',
    46: 'del',
    48: '0',
    49: '1',
    50: '2',
    51: '3',
    52: '4',
    53: '5',
    54: '6',
    55: '7',
    56: '8',
    57: '9',
    65: 'a',
    66: 'b',
    67: 'c',
    68: 'd',
    69: 'e',
    70: 'f',
    71: 'g',
    72: 'h',
    73: 'i',
    74: 'j',
    75: 'k',
    76: 'l',
    77: 'm',
    78: 'n',
    79: 'o',
    80: 'p',
    81: 'q',
    82: 'r',
    83: 's',
    84: 't',
    85: 'u',
    86: 'v',
    87: 'w',
    88: 'x',
    89: 'y',
    90: 'z',
    96: 'num0',
    97: 'num1',
    98: 'num2',
    99: 'num3',
    100: 'num4',
    101: 'num5',
    102: 'num6',
    103: 'num7',
    104: 'num8',
    105: 'num9',
    106: '*',
    107: '+',
    109: '-',
    110: '.',
    111: '/',
    112: 'f1',
    113: 'f2',
    114: 'f3',
    115: 'f4',
    116: 'f5',
    117: 'f6',
    118: 'f7',
    119: 'f8',
    120: 'f9',
    121: 'f10',
    122: 'f11',
    123: 'f12',
    186: ';',
    187: '=',
    188: ',',
    189: '-',
    190: '.',
    191: '/',
    192: '`',
    219: '[',
    220: '\\',
    221: ']',
    222: '\''
};
const KeyMods = {
    16: 'shift',
    17: 'ctrl',
    18: 'alt',
    91: 'meta',
    93: 'meta',
    224: 'meta'
};
const KeyModsMask = {
    alt: 1,
    ctrl: 2,
    meta: 4,
    shift: 8
};
const KeyAliases = {
    cmd: 'meta',
    command: 'meta',
    escape: 'esc',
    minus: '-',
    mod: isAppleOS() ? 'meta' : 'ctrl',
    option: 'alt',
    plus: '+',
    return: 'enter',
    win: 'meta'
};
const KeyWithShift = {
    '~': '`',
    '!': '1',
    '@': '2',
    '#': '3',
    $: '4',
    '%': '5',
    '^': '6',
    '&': '7',
    '*': '8',
    '(': '9',
    ')': '0',
    _: '-',
    '+': '=',
    '{': '[',
    '}': ']',
    '|': '\\',
    ':': ';',
    '"': '\'',
    '<': ',',
    '>': '.',
    '?': '/'
};
export function hotKeyMatch(left, right) {
    return left.key === right.key && left.mods === right.mods;
}
export function parseHotKey(hotkey) {
    const chunks = hotkey.replace(/\s/g, '').toLowerCase().split('+');
    let mods = 0;
    let key;
    for (let chunk of chunks) {
        if (chunk === '') {
            throw new Error(`Unexpected chunk with no key provided '${chunk}'. Expected: ctrl + 1, or similar, got ${hotkey}`);
        }
        if (KeyAliases[chunk]) {
            chunk = KeyAliases[chunk];
        }
        if (KeyModsMask[chunk]) {
            mods += KeyModsMask[chunk];
        }
        else if (KeyWithShift[chunk]) {
            mods += KeyModsMask.shift;
            key = KeyWithShift[chunk];
        }
        else {
            if (key) {
                throw new Error(`There should be only one key specified, got extra one ${key} for hot key ${hotkey}`);
            }
            key = chunk.toLowerCase();
        }
    }
    return {
        key,
        mods
    };
}
// function keyboardEventToHotKeyString(event: KeyboardEvent): string {
//   const { which } = event;
//   const chunks: string[] = [];
//   if (event.altKey) {
//     chunks.push('alt');
//   }
//   if (event.ctrlKey) {
//     chunks.push('ctrl');
//   }
//   if (event.shiftKey) {
//     chunks.push('shift');
//   }
//   if (event.metaKey) {
//     chunks.push('meta');
//   }
//   if (KeyMods[which]) {
//     // Nothing to act upon, just a mod key
//   } else
//   if (KeyCodes[which]) {
//     chunks.push(KeyCodes[which]);
//   } else {
//     chunks.push(String.fromCharCode(which).toLowerCase());
//   }
//   return chunks.join(' + ');
// }
export function keyboardEventToHotKey(event) {
    const { which } = event;
    let mods = 0;
    let key;
    if (KeyMods[which]) {
        // No actual key
    }
    else if (KeyCodes[which]) {
        key = KeyCodes[which];
    }
    else {
        key = String.fromCharCode(which).toLowerCase();
    }
    if (event.altKey) {
        mods += KeyModsMask.alt;
    }
    if (event.ctrlKey) {
        mods += KeyModsMask.ctrl;
    }
    if (event.shiftKey) {
        mods += KeyModsMask.shift;
    }
    if (event.metaKey) {
        mods += KeyModsMask.meta;
    }
    return {
        key,
        mods
    };
}
export function useHotKey(config) {
    const { disabled } = config;
    const parsedHotkey = useMemo(() => {
        return parseHotKey(config.hotkey);
    }, [config.hotkey]);
    const hotkeyRef = useRef(parsedHotkey);
    hotkeyRef.current = parsedHotkey;
    // We'll keep those in ref, so that we don't require stable handlers and
    // at the same time don't re-subscribe on each render.
    const configRef = useRef(config);
    configRef.current = config;
    useEffect(() => {
        if (!config.scopeElement) {
            return;
        }
        config.scopeElement.tabIndex = config.scopeElementTabIndex || 0;
    }, [config.scopeElement, config.scopeElementTabIndex]);
    useEffect(() => {
        if (disabled || !document) {
            return;
        }
        function onKey(event, cb) {
            if (!configRef.current.enableInInputs && isTextInput(event)) {
                return;
            }
            if (!hotKeyMatch(hotkeyRef.current, keyboardEventToHotKey(event))) {
                return;
            }
            if (configRef.current.scopeElement && !configRef.current.scopeElement.contains(event.target)) {
                return;
            }
            if (configRef.current.stopPropagation) {
                event.stopPropagation();
            }
            if (configRef.current.preventDefault) {
                event.preventDefault();
            }
            return cb(event);
        }
        function onKeyDown(event) {
            if (!configRef.current.onKeyDown) {
                return;
            }
            return onKey(event, configRef.current.onKeyDown);
        }
        function onKeyUp(event) {
            if (!configRef.current.onKeyUp) {
                return;
            }
            return onKey(event, configRef.current.onKeyUp);
        }
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [disabled]);
}
