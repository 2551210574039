// tslint:disable
// eslint-disable
// package io.protoforce.auth.react.authservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { authServiceRTTI } from '../../authservice/methods/authServiceRTTI';
import { authServiceSignup } from '../../authservice/methods/authServiceSignup';
import { useCallback } from 'react';
/**
  *
  *  signup is used to create a new user
  *
  *  @data One of the options of the SignupWith type
  *
  * Defined at /auth/auth.service.model @ 137:5
  */
export function useAuthServiceSignup(options, context) {
    const clientTransport = useClientTransport(authServiceRTTI, options);
    const request = useCallback((data) => {
        return authServiceSignup({ data }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
