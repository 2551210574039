// tslint:disable
// eslint-disable
// package io.protoforce.auth
import { MFASourceKindHelper } from './MFASourceKindHelper';
/**
  *
  *  ConfirmMFASource provides the details of the source the
  *  MFA code has to be obtained from.
  *
  * Class io.protoforce.auth:ConfirmMFASource
  *
  * Defined at /auth/auth.service.model @ 21:1
  */
export class ConfirmMFASource {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.source = '';
                this.kind = MFASourceKindHelper.all[0];
            }
            return;
        }
        this.message = data.message;
        this.source = data.source;
        this.kind = data.kind;
    }
    get RTTI_CLASS() {
        return ConfirmMFASource.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ConfirmMFASource.RTTI_FQN;
    }
    toJSON() {
        return ConfirmMFASource.toJSON(this);
    }
}
ConfirmMFASource.RTTI_CLASS = 'ConfirmMFASource';
ConfirmMFASource.RTTI_FQN = 'io.protoforce.auth:ConfirmMFASource';
ConfirmMFASource.toJSON = (value) => {
    return {
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined,
        source: value.source,
        kind: MFASourceKindHelper.toJSON(value.kind)
    };
};
ConfirmMFASource.fromJSON = (value) => {
    return new ConfirmMFASource({
        message: typeof value.message !== 'undefined' && value.message != null ? value.message : undefined,
        source: value.source,
        kind: MFASourceKindHelper.fromJSON(value.kind)
    });
};
