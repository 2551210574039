// tslint:disable
// eslint-disable
// package io.protoforce.projects
/**
  * echo "//registry.npmjs.org/:_authToken=${NPM_TOKEN}" > ~/.npmrc
  *  npm whoami
  *  npm publish --access public
  *
  * Class io.protoforce.projects/PublishingCredentials.NPMCredentials:Token (member of ADT io.protoforce.projects/PublishingCredentials:NPMCredentials)
  *
  * Defined at /projects/publishing.model @ 185:9
  */
export class PublishingCredentialsNPMCredentialsToken {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.value = '';
            }
            return;
        }
        this.scope = data.scope;
        this.value = data.value;
    }
    get RTTI_CLASS() {
        return PublishingCredentialsNPMCredentialsToken.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return PublishingCredentialsNPMCredentialsToken.RTTI_FQN;
    }
    toJSON() {
        return PublishingCredentialsNPMCredentialsToken.toJSON(this);
    }
}
PublishingCredentialsNPMCredentialsToken.RTTI_CLASS = 'Token';
PublishingCredentialsNPMCredentialsToken.RTTI_FQN = 'io.protoforce.projects/PublishingCredentials.NPMCredentials:Token';
PublishingCredentialsNPMCredentialsToken.toJSON = (value) => {
    return {
        scope: typeof value.scope !== 'undefined' && value.scope != null ? value.scope : undefined,
        value: value.value
    };
};
PublishingCredentialsNPMCredentialsToken.fromJSON = (value) => {
    return new PublishingCredentialsNPMCredentialsToken({
        scope: typeof value.scope !== 'undefined' && value.scope != null ? value.scope : undefined,
        value: value.value
    });
};
