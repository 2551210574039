// tslint:disable
// eslint-disable
// package io.protoforce.docs.internaldocsservice.models
/**
  * Class io.protoforce.docs.internaldocsservice.models:SaveInput
  *
  * Defined at docs.domain @ 18:5
  */
export class InternalDocsServiceSaveInput {
    constructor(data) {
        this.location = data.location;
        this.doc = data.doc;
    }
    get RTTI_CLASS() {
        return InternalDocsServiceSaveInput.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return InternalDocsServiceSaveInput.RTTI_FQN;
    }
    toJSON() {
        return InternalDocsServiceSaveInput.toJSON(this);
    }
}
InternalDocsServiceSaveInput.RTTI_CLASS = 'SaveInput';
InternalDocsServiceSaveInput.RTTI_FQN = 'io.protoforce.docs.internaldocsservice.models:SaveInput';
InternalDocsServiceSaveInput.toJSON = (value) => {
    return {
        location: value.location,
        doc: value.doc
    };
};
InternalDocsServiceSaveInput.fromJSON = (value) => {
    return new InternalDocsServiceSaveInput({
        location: value.location,
        doc: value.doc
    });
};
