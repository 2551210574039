import React, { useState } from 'react';
import { MTRandom } from '~/core';
import { PaletteNineDual } from '~/core/colors';
import { AvatarSize, deterministicCircle, useCapitalNameLetters, AvatarBorderRadius } from './common';
export const AccountAvatarUser = React.memo((props) => {
    const dim = props.size || AvatarSize.Normal;
    const palette = PaletteNineDual;
    const name = useCapitalNameLetters(props);
    const [rnd] = useState(new MTRandom());
    rnd.setSeed(MTRandom.hash(props.id.id));
    const fontSize = 64;
    const scale = 1; // size / fontSize;
    const colors = palette[rnd.nextInt32([0, palette.length - 1])];
    const primaryCircle = deterministicCircle(rnd);
    const MIN_CIRCLES = 1;
    const MAX_CIRCLES = 3;
    const MIN_CIRCLE_SIZE = 16;
    const MAX_CIRCLE_SIZE = 64;
    const circlesCount = rnd.nextInt32([MIN_CIRCLES, MAX_CIRCLES]);
    const circles = [];
    for (let i = 0; i < circlesCount; i++) {
        circles.push(deterministicCircle(rnd, MIN_CIRCLE_SIZE, MAX_CIRCLE_SIZE));
    }
    return (React.createElement("div", { style: {
            overflow: 'hidden',
            borderRadius: AvatarBorderRadius,
            position: 'relative',
            width: dim,
            height: dim
        } },
        React.createElement("svg", { viewBox: '0 0 128 128', style: {
                position: 'absolute',
                left: 0,
                top: 0
            }, width: dim, height: dim },
            React.createElement("rect", { x: '0', y: '0', width: '128', height: '128', style: {
                    fill: colors[0]
                } }),
            React.createElement("circle", { key: 'primaryCircle', r: primaryCircle.radius, cx: primaryCircle.x, cy: primaryCircle.y, fill: colors[1] }),
            circles.map((c, key) => (React.createElement("circle", { r: c.radius, cx: c.x, cy: c.y, fill: '#ffffff20', key: key }))),
            name && (React.createElement("text", { x: '64', y: '64', transform: `scale(${scale}, ${scale})`, fill: 'white', style: { cursor: 'default' }, fontSize: fontSize, textAnchor: 'middle', alignmentBaseline: 'central' }, name)))));
});
