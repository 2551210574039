import React, { useMemo } from 'react';
import { PublishingTask } from '@protoforce/projects';
import { Lang } from '@protoforce/shared';
import { FileIcon, LangFileIcons } from '~/components/general/FileIcon';
export const TaskPublishView = React.memo((props) => {
    const { task, style } = props;
    const lang = useMemo(() => {
        const tp = task.task;
        return tp instanceof PublishingTask ? tp.match(whenScalaTarget => Lang.Scala, whenJavaTarget => Lang.Java, whenTypescriptTarget => Lang.TypeScript, whenProtoforceTarget => undefined) : tp.match(whenScalaTarget => Lang.Scala, whenJavaTarget => Lang.Java, whenTypescriptTarget => Lang.TypeScript, whenProtoforceTarget => undefined);
    }, [task.task]);
    return (React.createElement("div", { style: Object.assign({ display: 'flex', alignItems: 'center' }, (style || {})) },
        typeof lang !== 'undefined' && React.createElement(FileIcon, { key: lang, file: LangFileIcons[lang], style: { marginRight: 5, width: 16, height: 16 } }),
        "Publish ",
        typeof lang !== 'undefined' ? Lang[lang] : 'Sources'));
});
