// tslint:disable
// eslint-disable
// package io.protoforce.shared
/**
  *
  *  User level within the organization.
  *
  * Enum io.protoforce.shared:UserLevel
  *
  * Defined at /shared/perms.model @ 11:1
  */
export var UserLevel;
(function (UserLevel) {
    /**
      *
      *  Owner has maximum privileges.
      *
      * Defined at /shared/perms.model @ 15:5
      */
    UserLevel[UserLevel["Owner"] = 0] = "Owner";
    /**
      *
      *  Admin has all the same privileges as the owner,
      *  but ability to become or remove an owner.
      *
      * Defined at /shared/perms.model @ 20:5
      */
    UserLevel[UserLevel["Admin"] = 1] = "Admin";
    /**
      *
      *  Members have no access to organization configuration,
      *  can only work with projects, blog, and other non
      *  administrative functions.
      *
      * Defined at /shared/perms.model @ 26:5
      */
    UserLevel[UserLevel["Member"] = 2] = "Member";
})(UserLevel || (UserLevel = {}));
