// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { GitSourceTargetImpl } from './GitSourceTargetImpl';
import { ProtoforceTargetGit } from './ProtoforceTargetGit';
import { ProtoforceTargetPublicGit } from './ProtoforceTargetPublicGit';
import { PublishingCredentialsGitCreds } from './PublishingCredentialsGitCreds';
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { ScalaBuildManifest } from './ScalaBuildManifest';
import { ScalaSourceTargetPublicGit } from './ScalaSourceTargetPublicGit';
/**
  * Class io.protoforce.projects/ScalaSourceTarget:Git (member of ADT io.protoforce.projects:ScalaSourceTarget)
  *
  * Defined at /projects/publishing.model @ 57:5
  */
export class ScalaSourceTargetGit {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.credentials = data.credentials;
        this.id = data.id;
        this.manifest = data.manifest;
        this.meta = data.meta;
    }
    get RTTI_CLASS() {
        return ScalaSourceTargetGit.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return ScalaSourceTargetGit.RTTI_FQN;
    }
    static fromGitSourceTarget(from, id, manifest, meta) {
        return new ScalaSourceTargetGit({
            id: id,
            manifest: manifest,
            meta: meta,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials
        });
    }
    loadGitSourceTarget(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
    }
    toProtoforceTargetGit() {
        return new ProtoforceTargetGit({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials,
            id: this.id,
            meta: this.meta
        });
    }
    static fromProtoforceTargetPublicGit(from, credentials, manifest) {
        return new ScalaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toProtoforceTargetPublicGit() {
        return new ProtoforceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            meta: this.meta
        });
    }
    static fromTypescriptSourceTargetPublicGit(from, credentials, manifest) {
        return new ScalaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadTypescriptSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toScalaSourceTargetPublicGit() {
        return new ScalaSourceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            manifest: this.manifest,
            meta: this.meta
        });
    }
    static fromTypescriptSourceTargetGit(from, manifest) {
        return new ScalaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadTypescriptSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromProtoforceTargetGit(from, manifest) {
        return new ScalaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromScalaSourceTargetPublicGit(from, credentials) {
        return new ScalaSourceTargetGit({
            credentials: credentials,
            target: from.target,
            layout: from.layout,
            id: from.id,
            manifest: from.manifest,
            meta: from.meta
        });
    }
    loadScalaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.manifest = from.manifest;
        this.meta = from.meta;
    }
    static fromJavaSourceTargetGit(from, manifest) {
        return new ScalaSourceTargetGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            credentials: from.credentials,
            id: from.id,
            meta: from.meta
        });
    }
    loadJavaSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.credentials = from.credentials;
        this.id = from.id;
        this.meta = from.meta;
    }
    toGitSourceTarget() {
        return new GitSourceTargetImpl({
            target: this.target,
            layout: this.layout,
            credentials: this.credentials
        });
    }
    static fromJavaSourceTargetPublicGit(from, credentials, manifest) {
        return new ScalaSourceTargetGit({
            credentials: credentials,
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadJavaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toJSON() {
        return ScalaSourceTargetGit.toJSON(this);
    }
}
ScalaSourceTargetGit.RTTI_CLASS = 'Git';
ScalaSourceTargetGit.RTTI_FQN = 'io.protoforce.projects/ScalaSourceTarget:Git';
ScalaSourceTargetGit.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        credentials: value.credentials.toJSON(),
        id: value.id.toJSON(),
        manifest: value.manifest.toJSON(),
        meta: value.meta.toJSON()
    };
};
ScalaSourceTargetGit.fromJSON = (value) => {
    return new ScalaSourceTargetGit({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        credentials: PublishingCredentialsGitCreds.fromJSON(value.credentials),
        id: PublishingTaskId.fromJSON(value.id),
        manifest: ScalaBuildManifest.fromJSON(value.manifest),
        meta: PublishingTaskMeta.fromJSON(value.meta)
    });
};
