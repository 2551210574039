// tslint:disable
// eslint-disable
// package io.protoforce.notifications.react.notificationsservice
import { useClientTransport, useEitherMutation } from '../../../../../irt-react';
import { notificationsServiceConfirm } from '../../notificationsservice/methods/notificationsServiceConfirm';
import { notificationsServiceRTTI } from '../../notificationsservice/methods/notificationsServiceRTTI';
import { useCallback } from 'react';
/**
  *
  *  Confirm an action
  *
  *  @id Action ID
  *
  * Defined at /notifications/notifications.service.model @ 58:5
  */
export function useNotificationsServiceConfirm(options, context) {
    const clientTransport = useClientTransport(notificationsServiceRTTI, options);
    const request = useCallback((id) => {
        return notificationsServiceConfirm({ id }, clientTransport, context);
    }, [clientTransport, context]);
    return useEitherMutation(request, options);
}
