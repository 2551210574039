// tslint:disable
// eslint-disable
// package io.protoforce.search.react.searchservice
import { SearchResponse } from '../../SearchResponse';
import { useClientTransport, useServiceKey, useMergedTags } from '../../../../../irt-react';
import { searchServiceRTTI } from '../../searchservice/methods/searchServiceRTTI';
import { searchServiceSearch } from '../../searchservice/methods/searchServiceSearch';
import { searchServiceSearchName } from '../../searchservice/methods/searchServiceSearchName';
import { useCallback } from 'react';
import { useQuery } from '@dyssent/fetcherjs';
export const searchServiceSearchTags = [
    '$pf',
    searchServiceRTTI.RTTI_FQN,
    searchServiceSearchName
];
/**
  * Defined at search.domain @ 60:5
  */
export function useSearchServiceSearch(text, usersLimit, orgsLimit, projectsLimit, $options, $context) {
    const clientTransport = useClientTransport(searchServiceRTTI, $options);
    const clientMethodKey = useServiceKey(searchServiceRTTI.RTTI_FQN, searchServiceSearchName, text, usersLimit, orgsLimit, projectsLimit)[0];
    return useQuery(typeof text === 'undefined' ? false : clientMethodKey, useCallback((text, usersLimit, orgsLimit, projectsLimit) => searchServiceSearch({ text, usersLimit, orgsLimit, projectsLimit }, clientTransport, $context), [clientTransport, $context]), Object.assign(Object.assign({ 
        // Batcher tags can be overriden, but by default rely fully on the definition,
        // of the function, therefore on the tags which are consistent no matter what extra
        // bits are supplied by the user.
        batcherTags: searchServiceSearchTags, 
        // These provide means to encode classes into JSON and reconstruct them afterwards
        storage: {
            toCache: useCallback(v => v.toJSON(), []),
            fromCache: useCallback(v => SearchResponse.fromJSON(v), [])
        } }, ($options || {})), { tags: useMergedTags(searchServiceSearchTags) }), text, usersLimit, orgsLimit, projectsLimit);
}
