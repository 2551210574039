// tslint:disable
// eslint-disable
// package io.protoforce.auth.userservice.models
import { fromEitherJSON } from '../../../../../irt';
import { NotFoundFailure } from '../../../shared';
import { User } from '../../User';
export class UserServicegetUserMethodOutputHelper {
    static toJSON(value) {
        return value.toJSON((r) => {
            return r.toJSON();
        }, (l) => {
            return l.toJSON();
        });
    }
    static fromJSON(value) {
        return fromEitherJSON(value, (r) => {
            return User.fromJSON(r);
        }, (l) => {
            return NotFoundFailure.fromJSON(l);
        });
    }
}
