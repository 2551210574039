import { useContext } from 'react';
import { MissingTransportError } from './error';
import { ServicesTransportContext } from './context';
export function useClientTransport(service, options) {
    const contextTransport = useContext(ServicesTransportContext);
    const clientTransport = (options === null || options === void 0 ? void 0 : options.transport) || contextTransport;
    if (!clientTransport) {
        throw new MissingTransportError(service);
    }
    return clientTransport;
}
