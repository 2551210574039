import React, { useState, useEffect } from 'react';
import { Icon, Tooltip } from '@blueprintjs/core';
import { AvatarSize, AvatarBorderRadius } from './common';
import { AccountAvatarOrg } from './AccountAvatarOrg';
import { AccountAvatarUser } from './AccountAvatarUser';
export * from './AccountAvatarOrg';
export * from './AccountAvatarUser';
export * from './common';
export const AccountAvatar = React.memo((props) => {
    const { id, account, file, name, size, org } = props;
    const [avatarLoaded, setAvatarLoaded] = useState(false);
    // TODO Display maybe an exclamation mark in the corner when avatarBroken is true https://github.com/ProtoForce/protoforce-portal-webui/issues/13
    const [avatarBroken, setAvatarBroken] = useState(false);
    const [fileResult, setFileResult] = useState(undefined);
    const dim = size || AvatarSize.Normal;
    const avatar = fileResult || (account && account.avatar);
    useEffect(() => {
        setAvatarBroken(false);
        setAvatarLoaded(false);
        if (!FileReader || !file) {
            setFileResult(undefined);
            return;
        }
        const fr = new FileReader();
        fr.onload = (ev) => setFileResult(fr.result);
        fr.readAsDataURL(file);
    }, [file]);
    return (React.createElement("div", { style: {
            overflow: 'hidden',
            borderRadius: AvatarBorderRadius,
            position: 'relative',
            width: dim,
            height: dim
        } },
        avatar && (React.createElement("img", { src: avatar, style: {
                width: dim,
                height: dim,
                visibility: avatarLoaded ? 'visible' : 'hidden',
                position: 'absolute',
                left: 0,
                top: 0
            }, alt: 'avatar', onLoad: () => setAvatarLoaded(true), onError: () => setAvatarBroken(true) })),
        (!avatar || !avatarLoaded) &&
            (org ? (React.createElement(AccountAvatarOrg, { id: id, size: dim, account: account, name: name })) : (React.createElement(AccountAvatarUser, { id: id, size: dim, account: account, name: name }))),
        avatarBroken &&
            React.createElement("span", { style: {
                    position: 'absolute',
                    top: 0,
                    left: 0
                } },
                React.createElement(Tooltip, { content: 'Could not load the avatar', className: 'bp4-dark' },
                    React.createElement(Icon, { icon: 'warning-sign', iconSize: dim === AvatarSize.Mini ? 8 : 16, intent: 'danger' })))));
});
