import React from 'react';
import { Divider } from '@blueprintjs/core';
import { UniSearchHelp } from './UniSearchHelp';
import { UniSearchMode } from './context';
export const UniSearchNoResult = React.memo((props) => {
    const { searchMode } = props;
    return (React.createElement("div", null,
        React.createElement("h3", { className: 'bp4-text-muted', style: { textAlign: 'center' } }, "No results."),
        searchMode === UniSearchMode.All && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(UniSearchHelp, { searchMode: searchMode })))));
});
