import React from 'react';
import { CONFIG } from './config';
import { gaTrackEvent, gaTrackPageView, gaInit, gaTrackTiming, gaTrackException } from './analytics/ga';
// import { fbInit, fbTrackPageView, fbTrackEvent, fbTrackCustomEvent, fbTrackSubscribe } from './analytics/facebook';
export class Analytics {
    constructor(config) {
        this.config = config;
        this.ready = false;
    }
    init(clientID, clientEmail) {
        gaInit(this.config.googleAnalytics, clientID);
        // fbInit(this.config.facebookPixel, clientEmail);
        this.ready = true;
    }
    pageView(path) {
        if (!this.ready) {
            return;
        }
        // fbTrackPageView();
        gaTrackPageView(path);
    }
    event(category, action, label, value, nonInteraction) {
        if (!this.ready) {
            return;
        }
        // fbTrackEvent(category, { action, label, value, nonInteraction });
        gaTrackEvent(category, action, label, value, nonInteraction);
    }
    timing(category, variable, value, label) {
        if (!this.ready) {
            return;
        }
        gaTrackTiming(category, variable, value, label);
    }
    exception(description, fatal) {
        if (!this.ready) {
            return;
        }
        gaTrackException(description, fatal);
    }
    finishSubscribe(subid) {
        if (!this.ready) {
            return;
        }
        gaTrackEvent('Subscription', 'Finished', subid);
        // fbTrackCustomEvent('Subscription', { subid, action: 'Finished' });
        // fbTrackSubscribe();
    }
    openSubscribe(subid) {
        if (!this.ready) {
            return;
        }
        gaTrackEvent('Subscription', 'Opened', subid);
        // fbTrackCustomEvent('Subscription', { subid, action: 'Opened' });
    }
    sandbox(action, label) {
        if (!this.ready) {
            return;
        }
        gaTrackEvent('Sandbox', action, label);
        // fbTrackCustomEvent('Sandbox', { action, label });
    }
    signup(action, method) {
        if (!this.ready) {
            return;
        }
        gaTrackEvent('Signup', action, method);
        // fbTrackCustomEvent('Signup', { action, method });
    }
}
export const analytics = new Analytics(CONFIG);
export const AnalyticsContext = React.createContext(analytics);
