// tslint:disable
// eslint-disable
// package io.protoforce.auth.codecs
import { CodecError, JSONConverter } from '../../../../irt';
export class AccountDataCodec {
    static register(fqn, ctor) {
        AccountDataCodec.registry[fqn] = ctor;
    }
    static deregister(fqn) {
        delete AccountDataCodec.registry[fqn];
    }
    static isRegistered(fqn) {
        return fqn in AccountDataCodec.registry;
    }
    static getRegistered() {
        return Object.keys(AccountDataCodec.registry);
    }
}
AccountDataCodec.registry = {};
AccountDataCodec.fromFQN = (fqn, value) => {
    const ctor = AccountDataCodec.registry[fqn];
    if (!ctor) {
        throw new CodecError(`Unknown class name ${fqn} for interface AccountData. Register at AccountDataCodec before it can be used.`);
    }
    return ctor(value);
};
AccountDataCodec.fromTypeNestedJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    return AccountDataCodec.fromFQN(key, val);
};
AccountDataCodec.fromTypeFieldJSON = (value, field) => {
    const keylen = Array.isArray(value[field]) ? value[field].length : 0;
    // @ts-ignore
    const key = keylen > 0 ? value[field][0] : value[field];
    const val = keylen > 1 ? Object.assign(Object.assign({}, value), { [field]: keylen === 2 ? value[field][1] : value[field].slice(1) }) : value;
    return AccountDataCodec.fromFQN(key, val);
};
AccountDataCodec.fromJSON = (value) => {
    return AccountDataCodec.fromTypeNestedJSON(value);
};
AccountDataCodec.toTypeNestedJSON = (value) => {
    return JSONConverter.withTypeNested(value);
};
AccountDataCodec.toTypeFieldJSON = (value, field) => {
    return JSONConverter.withTypeField(value, field);
};
AccountDataCodec.toJSON = (value) => {
    return AccountDataCodec.toTypeNestedJSON(value);
};
