// tslint:disable
// eslint-disable
// package io.protoforce.shared
import { Hash } from './Hash';
import { Validator } from '../../../irt';
/**
  *
  *  File upload details
  *
  * Class io.protoforce.shared:FileUpload
  *
  * Defined at /shared/upload.model @ 24:1
  */
export class FileUpload {
    constructor(data, skipDefaults) {
        if (!data) {
            if (!skipDefaults) {
                this.name = '';
                this.contentType = '';
                this.size = 0;
            }
            return;
        }
        this.name = data.name;
        this.contentType = data.contentType;
        this.size = data.size;
        this.hash = data.hash;
    }
    get size() {
        return this._size;
    }
    set size(value) {
        Validator.min(value, 0, 'size');
        Validator.integer(value, 'size');
        this._size = value;
    }
    get RTTI_CLASS() {
        return FileUpload.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return FileUpload.RTTI_FQN;
    }
    toJSON() {
        return FileUpload.toJSON(this);
    }
}
FileUpload.RTTI_CLASS = 'FileUpload';
FileUpload.RTTI_FQN = 'io.protoforce.shared:FileUpload';
FileUpload.toJSON = (value) => {
    return {
        name: value.name,
        contentType: value.contentType,
        size: value.size,
        hash: value.hash.toJSON()
    };
};
FileUpload.fromJSON = (value) => {
    return new FileUpload({
        name: value.name,
        contentType: value.contentType,
        size: value.size,
        hash: Hash.fromJSON(value.hash)
    });
};
