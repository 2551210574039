// tslint:disable
// eslint-disable
// package io.protoforce.notifications
import { AccessChangedNotification } from './AccessChangedNotification';
import { InviteAcceptedNotification } from './InviteAcceptedNotification';
import { JSONConverter, CodecError } from '../../../irt';
import { LevelChangedNotification } from './LevelChangedNotification';
import { UserJoinedNotification } from './UserJoinedNotification';
/**
  * ADT io.protoforce.notifications:Notification
  *
  * Defined at /notifications/notification.model @ 26:1
  */
export class Notification {
    constructor(value, unambiguous) {
        if (!value) {
            if (!unambiguous) {
                throw new Error('ADT Notification must have value provided during instantiation.');
            }
            this.value = unambiguous;
            return;
        }
        if (typeof value.inviteAcceptedNotification !== 'undefined') {
            this.value = value.inviteAcceptedNotification;
        }
        else if (typeof value.userJoinedNotification !== 'undefined') {
            this.value = value.userJoinedNotification;
        }
        else if (typeof value.levelChangedNotification !== 'undefined') {
            this.value = value.levelChangedNotification;
        }
        else if (typeof value.accessChangedNotification !== 'undefined') {
            this.value = value.accessChangedNotification;
        }
        else {
            throw new Error('ADT Notification constructor value must have at least one field defined');
        }
    }
    get flatValue() {
        return this.value;
    }
    map(mapper) {
        return mapper(this.value);
    }
    flatMap(mapper) {
        return mapper(this.flatValue);
    }
    match(whenInviteAcceptedNotification, whenUserJoinedNotification, whenLevelChangedNotification, whenAccessChangedNotification) {
        const v = this.value;
        if (v instanceof InviteAcceptedNotification) {
            return whenInviteAcceptedNotification(v);
        }
        else if (v instanceof UserJoinedNotification) {
            return whenUserJoinedNotification(v);
        }
        else if (v instanceof LevelChangedNotification) {
            return whenLevelChangedNotification(v);
        }
        else if (v instanceof AccessChangedNotification) {
            return whenAccessChangedNotification(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive match when type is '${typeof v}'`);
    }
    flatMatch(whenInviteAcceptedNotification, whenUserJoinedNotification, whenLevelChangedNotification, whenAccessChangedNotification) {
        const v = this.value;
        if (v instanceof InviteAcceptedNotification) {
            return whenInviteAcceptedNotification(v);
        }
        else if (v instanceof UserJoinedNotification) {
            return whenUserJoinedNotification(v);
        }
        else if (v instanceof LevelChangedNotification) {
            return whenLevelChangedNotification(v);
        }
        else if (v instanceof AccessChangedNotification) {
            return whenAccessChangedNotification(v);
        }
        throw new Error(`Inconsistent ADT instance, non exhaustive flatMatch when type is '${typeof v}'`);
    }
    static fromInviteAcceptedNotification(value) {
        return new Notification({ inviteAcceptedNotification: value });
    }
    static fromUserJoinedNotification(value) {
        return new Notification({ userJoinedNotification: value });
    }
    static fromLevelChangedNotification(value) {
        return new Notification({ levelChangedNotification: value });
    }
    static fromAccessChangedNotification(value) {
        return new Notification({ accessChangedNotification: value });
    }
    static from(value) {
        return value instanceof Notification ? value : new Notification(undefined, value);
    }
    get RTTI_CLASS() {
        return Notification.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return Notification.RTTI_FQN;
    }
    toJSON() {
        return Notification.toJSON(this);
    }
}
Notification.RTTI_CLASS = 'Notification';
Notification.RTTI_FQN = 'io.protoforce.notifications:Notification';
Notification.toJSON = (value) => {
    const v = value.value;
    if (v instanceof InviteAcceptedNotification) {
        return JSONConverter.withTypeNested(v, 'InviteAcceptedNotification');
    }
    else if (v instanceof UserJoinedNotification) {
        return JSONConverter.withTypeNested(v, 'UserJoinedNotification');
    }
    else if (v instanceof LevelChangedNotification) {
        return JSONConverter.withTypeNested(v, 'LevelChangedNotification');
    }
    else if (v instanceof AccessChangedNotification) {
        return JSONConverter.withTypeNested(v, 'AccessChangedNotification');
    }
    else {
        throw new CodecError('Inconsistent ADT internal type, value: ' + v);
    }
};
Notification.fromJSON = (value) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    switch (key) {
        case 'InviteAcceptedNotification': {
            const vc = val;
            return Notification.fromInviteAcceptedNotification(InviteAcceptedNotification.fromJSON(vc));
        }
        case 'UserJoinedNotification': {
            const vc = val;
            return Notification.fromUserJoinedNotification(UserJoinedNotification.fromJSON(vc));
        }
        case 'LevelChangedNotification': {
            const vc = val;
            return Notification.fromLevelChangedNotification(LevelChangedNotification.fromJSON(vc));
        }
        case 'AccessChangedNotification': {
            const vc = val;
            return Notification.fromAccessChangedNotification(AccessChangedNotification.fromJSON(vc));
        }
        default: throw new CodecError(`Unexpected key '${key}' for ADT 'Notification'`);
    }
};
