// tslint:disable
// eslint-disable
// package io.protoforce.projects
import { ProtoforceTargetPublicGit } from './ProtoforceTargetPublicGit';
import { PublishingGitLayout } from './PublishingGitLayout';
import { PublishingTarget } from './PublishingTarget';
import { PublishingTaskId } from './PublishingTaskId';
import { PublishingTaskMeta } from './PublishingTaskMeta';
import { TypeScriptBuildManifest } from './TypeScriptBuildManifest';
/**
  * Class io.protoforce.projects/TypescriptSourceTargetPublic:Git (member of ADT io.protoforce.projects:TypescriptSourceTargetPublic)
  *
  * Defined at /projects/publishing.model @ 68:5
  */
export class TypescriptSourceTargetPublicGit {
    constructor(data, skipDefaults) {
        if (!data) {
            return;
        }
        this.target = data.target;
        this.layout = data.layout;
        this.id = data.id;
        this.manifest = data.manifest;
        this.meta = data.meta;
    }
    get RTTI_CLASS() {
        return TypescriptSourceTargetPublicGit.RTTI_CLASS;
    }
    get RTTI_FQN() {
        return TypescriptSourceTargetPublicGit.RTTI_FQN;
    }
    static fromGitSourceTarget(from, id, manifest, meta) {
        return new TypescriptSourceTargetPublicGit({
            id: id,
            manifest: manifest,
            meta: meta,
            target: from.target,
            layout: from.layout
        });
    }
    loadGitSourceTarget(from) {
        this.target = from.target;
        this.layout = from.layout;
    }
    static fromScalaSourceTargetGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadScalaSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromProtoforceTargetPublicGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromJavaSourceTargetGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadJavaSourceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromProtoforceTargetGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadProtoforceTargetGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromJavaSourceTargetPublicGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadJavaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    static fromScalaSourceTargetPublicGit(from, manifest) {
        return new TypescriptSourceTargetPublicGit({
            manifest: manifest,
            target: from.target,
            layout: from.layout,
            id: from.id,
            meta: from.meta
        });
    }
    loadScalaSourceTargetPublicGit(from) {
        this.target = from.target;
        this.layout = from.layout;
        this.id = from.id;
        this.meta = from.meta;
    }
    toProtoforceTargetPublicGit() {
        return new ProtoforceTargetPublicGit({
            target: this.target,
            layout: this.layout,
            id: this.id,
            meta: this.meta
        });
    }
    toJSON() {
        return TypescriptSourceTargetPublicGit.toJSON(this);
    }
}
TypescriptSourceTargetPublicGit.RTTI_CLASS = 'Git';
TypescriptSourceTargetPublicGit.RTTI_FQN = 'io.protoforce.projects/TypescriptSourceTargetPublic:Git';
TypescriptSourceTargetPublicGit.toJSON = (value) => {
    return {
        target: value.target.toJSON(),
        layout: value.layout.toJSON(),
        id: value.id.toJSON(),
        manifest: value.manifest.toJSON(),
        meta: value.meta.toJSON()
    };
};
TypescriptSourceTargetPublicGit.fromJSON = (value) => {
    return new TypescriptSourceTargetPublicGit({
        target: PublishingTarget.fromJSON(value.target),
        layout: PublishingGitLayout.fromJSON(value.layout),
        id: PublishingTaskId.fromJSON(value.id),
        manifest: TypeScriptBuildManifest.fromJSON(value.manifest),
        meta: PublishingTaskMeta.fromJSON(value.meta)
    });
};
